import axios from 'axios';
import Auth from '../lib/auth';

import { addNotification } from './notifications';

export const DOCUMENTS_REQUEST = 'DOCUMENTS_REQUEST';
export const DOCUMENTS_SUCCESS = 'DOCUMENTS_SUCCESS';
export const DOCUMENTS_ERROR = 'DOCUMENTS_ERROR';

export const UPLOAD_CPD_REQUEST = 'UPLOAD_CPD_REQUEST';
export const UPLOAD_CPD_SUCCESS = 'UPLOAD_CPD_SUCCESS';
export const UPLOAD_CPD_ERROR = 'UPLOAD_CPD_ERROR';

export const UPDATE_CPD_REQUEST = 'UPDATE_CPD_REQUEST';
export const UPDATE_CPD_SUCCESS = 'UPDATE_CPD_SUCCESS';
export const UPDATE_CPD_ERROR = 'UPDATE_CPD_ERROR';

export const DELETE_CPD_REQUEST = 'DELETE_CPD_REQUEST';
export const DELETE_CPD_SUCCESS = 'DELETE_CPD_SUCCESS';
export const DELETE_CPD_ERROR = 'DELETE_CPD_ERROR';

export const UPLOAD_ATTACH_REQUEST = 'UPLOAD_ATTACH_REQUEST';
export const UPLOAD_ATTACH_SUCCESS = 'UPLOAD_ATTACH_SUCCESS';
export const UPLOAD_ATTACH_ERROR = 'UPLOAD_ATTACH_ERROR';

export const DELETE_ATTACH_REQUEST = 'DELETE_ATTACH_REQUEST';
export const DELETE_ATTACH_SUCCESS = 'DELETE_ATTACH_SUCCESS';
export const DELETE_ATTACH_ERROR = 'DELETE_ATTACH_ERROR';

export const UPLOAD_PRR_REQUEST = 'UPLOAD_PRR_REQUEST';
export const UPLOAD_PRR_SUCCESS = 'UPLOAD_PRR_SUCCESS';
export const UPLOAD_PRR_ERROR = 'UPLOAD_PRR_ERROR';

export const UPDATE_PRR_REQUEST = 'UPDATE_PRR_REQUEST';
export const UPDATE_PRR_SUCCESS = 'UPDATE_PRR_SUCCESS';
export const UPDATE_PRR_ERROR = 'UPDATE_PRR_ERROR';

export const DELETE_PRR_REQUEST = 'DELETE_PRR_REQUEST';
export const DELETE_PRR_SUCCESS = 'DELETE_PRR_SUCCESS';
export const DELETE_PRR_ERROR = 'DELETE_PRR_ERROR';

export const CLEAN_DOCUMENTS = 'CLEAN_DOCUMENTS';

// Get Documents Actions
export function getDocuments(query) {
    return async dispatch => {
        try {
            const token = Auth.getLocalJwt();
            dispatch(getDocumentsRequest());
            const res = await axios.post('/api/cpds/query', query, { headers: { 'Authorization': 'Bearer ' + token } });
            if( res.data && res.data.status === 1) {
                dispatch(getDocumentsSuccess(res.data.payload));
            } else {
                dispatch(getDocumentsError(res.data.payload));
            }
        } catch (error) {
            dispatch(getDocumentsError(error));
        }
    };
};

function getDocumentsRequest() {
    return {
        type: 'DOCUMENTS_REQUEST',
        isFetching: true
    };
}

function getDocumentsSuccess (payload) {
    return {
        type: 'DOCUMENTS_SUCCESS',
        isFetching: false,
        error: false,
        payload
    };
}

function getDocumentsError(errorMessage) {
    return {
        type: 'DOCUMENTS_ERROR',
        isFetching: false,
        error: true,
        errorMessage
    };
}

// Upload Cpd Actions
export function uploadCpd(formData) {
    return async dispatch => {
        try {
            const token = Auth.getLocalJwt();
            dispatch(uploadCpdRequest());
            const res = await axios.post('/api/cpds', formData, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data'
                }
            });
            if( res.data && res.data.status === 1) {
                dispatch(uploadCpdSuccess(res.data.payload));

                // Create notification for upload cpd success
                dispatch(addNotification({
                    message: 'CPD subido correctamente',
                    color: 'green',
                    icon: 'tick'
                }));
            } else {
                dispatch(uploadCpdError(res.data.payload));
            }
        } catch (error) {
            dispatch(uploadCpdError(error));

            // Create notification for upload cpd error
            dispatch(addNotification({
                message: 'Error al subir el CPD',
                color: 'red',
                icon: 'cross'
            }));
        }
    };
};

function uploadCpdRequest() {
    return {
        type: 'UPLOAD_CPD_REQUEST',
        isFetching: true
    };
}

function uploadCpdSuccess (cpd) {
    return {
        type: 'UPLOAD_CPD_SUCCESS',
        isFetching: false,
        error: false,
        cpd
    };
}

function uploadCpdError(errorMessage) {
    return {
        type: 'UPLOAD_CPD_ERROR',
        isFetching: false,
        error: true,
        errorMessage
    };
}

// Update Cpd Actions
export function updateCpd(cpdId, formData) {
    return async dispatch => {
        try {
            const token = Auth.getLocalJwt();
            dispatch(updateCpdRequest());
            const res = await axios.put('/api/cpds/' + cpdId, formData, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data'
                }
            });
            if( res.data && res.data.status === 1) {
                dispatch(updateCpdSuccess(res.data.payload));

                // Create notification for update cpd success
                dispatch(addNotification({
                    message: 'CPD sustituido correctamente',
                    color: 'green',
                    icon: 'tick'
                }));
            } else {
                dispatch(updateCpdError(res.data.payload));
            }
        } catch (error) {
            dispatch(updateCpdError(error));

            // Create notification for update cpd error
            dispatch(addNotification({
                message: 'Error al sustituir el CPD.',
                color: 'red',
                icon: 'cross'
            }));
        }
    };
};

function updateCpdRequest() {
    return {
        type: 'UPDATE_CPD_REQUEST',
        isFetching: true
    };
}

function updateCpdSuccess (cpd) {
    return {
        type: 'UPDATE_CPD_SUCCESS',
        isFetching: false,
        error: false,
        cpd
    };
}

function updateCpdError(errorMessage) {
    return {
        type: 'UPDATE_CPD_ERROR',
        isFetching: false,
        error: true,
        errorMessage
    };
}

// Delete CPD Actions
export function deleteCpd(cpdId) {
    return async dispatch => {
        try {
            const token = Auth.getLocalJwt();
            dispatch(deleteCpdRequest());
            const res = await axios.delete('/api/cpds/' + cpdId, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data'
                }
            });
            if( res.data && res.data.status === 1) {
                dispatch(deleteCpdSuccess(res.data.payload));

                // Create notification for update cpd success
                dispatch(addNotification({
                    message: 'CPD eliminado correctamente',
                    color: 'green',
                    icon: 'tick'
                }));
            } else {
                dispatch(deleteCpdError(res.data.payload));
            }
        } catch (error) {
            dispatch(deleteCpdError(error));

            // Create notification for delete cpd error
            dispatch(addNotification({
                message: 'Error al eliminar el CPD',
                color: 'red',
                icon: 'cross'
            }));
        }
    };
};

function deleteCpdRequest() {
    return {
        type: 'DELETE_CPD_REQUEST',
        isFetching: true
    };
}

function deleteCpdSuccess (cpd) {
    return {
        type: 'DELETE_CPD_SUCCESS',
        isFetching: false,
        error: false,
        cpd
    };
}

function deleteCpdError(errorMessage) {
    return {
        type: 'DELETE_CPD_ERROR',
        isFetching: false,
        error: true,
        errorMessage
    };
}

// Upload Attach Actions
export function uploadAttach(cpdId, formData) {
    return async dispatch => {
        try {
            const token = Auth.getLocalJwt();
            dispatch(uploadAttachRequest());
            const res = await axios.post('/api/cpds/' + cpdId + '/anexos', formData, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data'
                }
            });
            if( res.data && res.data.status === 1) {
                dispatch(uploadAttachSuccess(res.data.payload));

                // Create notification for upload attach success
                dispatch(addNotification({
                    message: 'Anexo subido correctamente',
                    color: 'green',
                    icon: 'tick'
                }));
            } else {
                dispatch(uploadAttachError(res.data.payload));
            }
        } catch (error) {
            dispatch(uploadAttachError(error));

            // Create notification for upload attach error
            dispatch(addNotification({
                message: 'Error al subir el Anexo',
                color: 'red',
                icon: 'cross'
            }));
        }
    };
};

function uploadAttachRequest() {
    return {
        type: 'UPLOAD_ATTACH_REQUEST',
        isFetching: true
    };
}

function uploadAttachSuccess (cpd) {
    return {
        type: 'UPLOAD_ATTACH_SUCCESS',
        isFetching: false,
        error: false,
        cpd
    };
}

function uploadAttachError(errorMessage) {
    return {
        type: 'UPLOAD_ATTACH_ERROR',
        isFetching: false,
        error: true,
        errorMessage
    };
}

// Delete Attach Actions
export function deleteAttach(cpdId, attachId) {
    return async dispatch => {
        try {
            const token = Auth.getLocalJwt();
            dispatch(deleteAttachRequest());
            const res = await axios.delete('/api/cpds/' + cpdId + '/anexos/' + attachId, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data'
                }
            });
            if( res.data && res.data.status === 1) {
                dispatch(deleteAttachSuccess(res.data.payload));

                // Create notification for upload attach success
                dispatch(addNotification({
                    message: 'Anexo eliminado correctamente',
                    color: 'green',
                    icon: 'tick'
                }));
            } else {
                dispatch(deleteAttachError(res.data.payload));
            }
        } catch (error) {
            dispatch(deleteAttachError(error));

            // Create notification for delete attach error
            dispatch(addNotification({
                message: 'Error al eliminar el Anexo',
                color: 'red',
                icon: 'cross'
            }));
        }
    };
};

function deleteAttachRequest() {
    return {
        type: 'DELETE_ATTACH_REQUEST',
        isFetching: true
    };
}

function deleteAttachSuccess (cpd) {
    return {
        type: 'DELETE_ATTACH_SUCCESS',
        isFetching: false,
        error: false,
        cpd
    };
}

function deleteAttachError(errorMessage) {
    return {
        type: 'DELETE_ATTACH_ERROR',
        isFetching: false,
        error: true,
        errorMessage
    };
}

// Upload Prorogation Actions
export function uploadPrr(cpdId, formData) {
    return async dispatch => {
        try {
            const token = Auth.getLocalJwt();
            dispatch(uploadPrrRequest());
            const res = await axios.post('/api/cpds/' + cpdId + '/prorrogas', formData, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data'
                }
            });
            if( res.data && res.data.status === 1) {
                dispatch(uploadPrrSuccess(res.data.payload));

                // Create notification for upload prorogation success
                dispatch(addNotification({
                    message: 'Prórroga subida correctamente',
                    color: 'green',
                    icon: 'tick'
                }));
            } else {
                dispatch(uploadPrrError(res.data.payload));
            }
        } catch (error) {
            dispatch(uploadPrrError(error));

            // Create notification for delete prorogation error
            dispatch(addNotification({
                message: 'Error al subir la Prórroga',
                color: 'red',
                icon: 'cross'
            }));
        }
    };
};

function uploadPrrRequest() {
    return {
        type: 'UPLOAD_PRR_REQUEST',
        isFetching: true
    };
}

function uploadPrrSuccess (prr) {
    return {
        type: 'UPLOAD_PRR_SUCCESS',
        isFetching: false,
        error: false,
        prr
    };
}

function uploadPrrError(errorMessage) {
    return {
        type: 'UPLOAD_PRR_ERROR',
        isFetching: false,
        error: true,
        errorMessage
    };
}

// Update Prorogation Actions
export function updatePrr(prrId, formData) {
    return async dispatch => {
        try {
            const token = Auth.getLocalJwt();
            dispatch(updatePrrRequest());
            const res = await axios.put('/api/prorrogas/' + prrId, formData, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data'
                }
            });
            if( res.data && res.data.status === 1) {
                dispatch(updatePrrSuccess(res.data.payload));

                // Create notification for update prorogation success
                dispatch(addNotification({
                    message: 'Prórroga sustituida correctamente',
                    color: 'green',
                    icon: 'tick'
                }));
            } else {
                dispatch(updatePrrError(res.data.payload));
            }
        } catch (error) {
            dispatch(updatePrrError(error));

            // Create notification for update prorogation error
            dispatch(addNotification({
                message: 'Error al sustituir la Prórroga',
                color: 'red',
                icon: 'cross'
            }));
        }
    };
};

function updatePrrRequest() {
    return {
        type: 'UPDATE_PRR_REQUEST',
        isFetching: true
    };
}

function updatePrrSuccess (prr) {
    return {
        type: 'UPDATE_PRR_SUCCESS',
        isFetching: false,
        error: false,
        prr
    };
}

function updatePrrError(errorMessage) {
    return {
        type: 'UPDATE_PRR_ERROR',
        isFetching: false,
        error: true,
        errorMessage
    };
}

// Delete Prorogation Actions
export function deletePrr(prrId) {
    return async dispatch => {
        try {
            const token = Auth.getLocalJwt();
            dispatch(deletePrrRequest());
            const res = await axios.delete('/api/prorrogas/' + prrId, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data'
                }
            });
            if( res.data && res.data.status === 1) {
                dispatch(deletePrrSuccess(res.data.payload));

                // Create notification for delete prorogation success
                dispatch(addNotification({
                    message: 'Prórroga eliminada correctamente',
                    color: 'green',
                    icon: 'tick'
                }));
            } else {
                dispatch(deletePrrError(res.data.payload));
            }
        } catch (error) {
            dispatch(deletePrrError(error));

            // Create notification for delete prorogation error
            dispatch(addNotification({
                message: 'Error al eliminar la Prórroga',
                color: 'red',
                icon: 'cross'
            }));
        }
    };
};

function deletePrrRequest() {
    return {
        type: 'DELETE_PRR_REQUEST',
        isFetching: true
    };
}

function deletePrrSuccess (prr) {
    return {
        type: 'DELETE_PRR_SUCCESS',
        isFetching: false,
        error: false,
        prr
    };
}

function deletePrrError(errorMessage) {
    return {
        type: 'DELETE_PRR_ERROR',
        isFetching: false,
        error: true,
        errorMessage
    };
}

// Clean documents action
export function cleanDocuments() {
    return {
        type: 'CLEAN_DOCUMENTS',
        isFetching: false,
        error: false,
        payload: []
    };
};
