import { combineReducers } from 'redux';

import auditLog from './auditLog';
import auth from './auth/';
import customers from './customers';
import documents from './documents';
import organizationalStructure from './organizationalStructure';
import users from './users';
import stats from './stats';
import notifications from './notifications';
import signs from './signs';
import employees from './employees';
import employeeDocuments from './employeeDocuments';
import employeeStats from './employeeStats';

const appReducer = combineReducers({
    auditLog,
    auth,
    customers,
    documents,
    notifications,
    organizationalStructure,
    signs,
    stats,
    users,
    employees,
    employeeDocuments,
    employeeStats
});

const rootReducer = (state, action) => {

    // Reset redux state to default when sign-out
    if (action.type === 'SIGNOUT') {
        state = undefined;
    }

    return appReducer(state, action)
};

export default rootReducer;