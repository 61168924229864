export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const MARK_NOTIFICATION_AS_SHOWN = 'MARK_NOTIFICATION_AS_SHOWN';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';

export const addNotification = (notification) => {
    return {
        type: ADD_NOTIFICATION,
        notification
    };
};

export const markNotificationAsShown = (notificationId) => {
    return {
        type: MARK_NOTIFICATION_AS_SHOWN,
        notificationId
    };
};

export const deleteNotification = (notificationId) => {
    return {
        type: DELETE_NOTIFICATION,
        notificationId
    };
};