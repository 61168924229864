import React, { useRef, useEffect } from 'react';
import SignaturePad from 'react-signature-pad-wrapper';

const SignatureComponent = ({ onSignatureChange, isSignatureCleared, setIsSignatureCleared, saveSignature, setSaveSignature }) => {
    const signaturePadRef = useRef(null);

    useEffect(() => {
        if (isSignatureCleared) {
            signaturePadRef.current.clear();
            onSignatureChange('');
            setIsSignatureCleared(false);
        }
    }, [isSignatureCleared]);

    useEffect(() => {
        if (saveSignature) {
            const signature = signaturePadRef.current.toDataURL();
            onSignatureChange(signature);
            setSaveSignature(false);
        }
    }, [saveSignature]);

    return (
        <div className='sign'>
            <div style={{border: '1px solid'}}>
                <SignaturePad
                    ref={signaturePadRef}
                    options={{penColor: 'blue', width: 600, height: 400, className: 'sigCanvas'}}
                />
            </div>
        </div>
    );
};

export default SignatureComponent;
