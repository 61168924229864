import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import _ from 'lodash';
import moment from 'moment';
import Modal from '@atlaskit/modal-dialog';
import { Icon, Popover, PopoverInteractionKind, Position, Menu, MenuItem } from "@blueprintjs/core";
import { isEmail } from 'validator';

import { getOrgStructure } from '../actions/organizationalStructure';
import { getCustomers } from '../actions/customers';
import { getUsers, updateUser, createUser, deleteUser, changeUserStatus, changeUserPassword } from '../actions/users';

import CustomIcon from '../components/CustomIcon';
import UserCreateDialog from '../components/UserCreateDialog';
import UserEditDialog from '../components/UserEditDialog';
import UserPermissionsDialog from '../components/UserPermissionsDialog';
import UserChangePasswordDialog from '../components/UserChangePasswordDialog';
import VirtualizedTable from '../components/virtualized-table';

class UsersPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedUser: null,
            userData: null,
            modalIsOpen: false,
            modalType: null,
            organizationalStructureListItems: [],
            customersList: [],
            canSubmit: false
        };

        this.handleCreateUser = this.handleCreateUser.bind(this);
        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.selectUser = this.selectUser.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.onUpdateOrgListItems = this.onUpdateOrgListItems.bind(this);
        this.onUpdateCustomersList = this.onUpdateCustomersList.bind(this);
        this.onUpdateSelectedUser = this.onUpdateSelectedUser.bind(this);
        this.onChangeFormData = this.onChangeFormData.bind(this);
        this.onCreateUser = this.onCreateUser.bind(this);
    };

    componentDidMount() {
        this.props.getOrgStructure();
        this.props.getCustomers();
        this.props.getUsers();

        // Control what user type can access
        if(this.props.connectedUser && this.props.connectedUser.get('tipo') === 'cliente') {
            this.props.history.push('/');
        }
    }

    componentDidUpdate(prevProps, prevState) {

        // Calculate initial customersList, based on complete customers
        if (
            (this.props.customers !== prevProps.customers) ||
            ((this.state.modalIsOpen !== prevState.modalIsOpen)  && !this.state.modalIsOpen)
        ) {
            let customersList = [];

            if (this.props.customers && this.props.customers.size > 0) {
                this.props.customers.forEach(customer => {
                    customersList.push({
                        ...customer.toJS(),
                        checked: false
                    });
                });

                this.setState({
                    customersList
                });
            }
        }

        // Calculate initial organizationalStructureListItems, based on complete orgStructure
        if (
            (this.props.orgStructure !== prevProps.orgStructure) ||
            ((this.state.modalIsOpen !== prevState.modalIsOpen)  && !this.state.modalIsOpen)
        ) {
            let listItems = [];

            if (this.props.orgStructure && this.props.orgStructure.size > 0) {
                this.props.orgStructure.get('delegaciones').forEach(delegacion => {

                    // Add delegacion item
                    listItems.push({
                        level: 'delegacion',
                        name: delegacion.get('nombre'),
                        id: `${delegacion.get('delegacion_id')}`,
                        opened: false,
                        checked: false
                    });
                    delegacion.get('oficinas').forEach(oficina => {

                        // Add oficina item
                        listItems.push({
                            level: 'oficina',
                            name: oficina.get('nombre'),
                            id: `${delegacion.get('delegacion_id')}_${oficina.get('oficina_id')}`,
                            delegacion_id: `${delegacion.get('delegacion_id')}`,
                            opened: false,
                            show: false,
                            checked: false
                        });
                        oficina.get('centros').forEach(centro => {

                            // Add centro item
                            listItems.push({
                                level: 'centro',
                                name: centro.get('nombre'),
                                id: `${delegacion.get('delegacion_id')}_${oficina.get('oficina_id')}_${centro.get('centro_id')}`,
                                delegacion_id: `${delegacion.get('delegacion_id')}`,
                                oficina_id: `${oficina.get('oficina_id')}`,
                                show: false,
                                checked: false
                            });
                        });
                    });
                });

                this.setState({
                    organizationalStructureListItems: listItems
                });
            }
        }
    }

    handleCreateUser() {
        this.setState({
            modalIsOpen: true,
            modalType: 'createUser'
        });
    }

    // Handle selected menu action type
    handleMenuClick(actionType) {
        const selectedUser = this.props.users.find(user => user.get('_id') === this.state.selectedUser);

        switch (actionType) {

            // Handle edit user dialog: an initial canSubmit = true is needed
            case 'editUser':
                this.setState({
                    modalIsOpen: true,
                    modalType: actionType,
                    canSubmit: true
                });
                break;

            // Handle user change permissions dialog
            case 'changePermissions':
                let listItems = this.state.organizationalStructureListItems.slice(),
                    customersList = this.state.customersList.slice();

                // Local function, that affects listItems content
                // Find an item in the list and check it (item_id can be one of these: delegacionId, delegacionId_oficinaId or delegacionId_oficinaId_centroId)
                // Used to update the items in the list based on user orgStructure
                const findAndCheckItem = (level, item_id) => {
                    listItems.map(listItem => {
                        if (level === 'delegacion' && listItem.level === 'delegacion' && item_id === listItem.id) {
                            listItem.checked = true;
                        }
                        if (level === 'oficina' && listItem.level === 'oficina' && item_id === listItem.id) {
                            listItem.checked = true;
                        }
                        if (level === 'centro' && listItem.level === 'centro' && item_id === listItem.id) {
                            listItem.checked = true;
                        }
                        return listItem;
                    });
                }

                // Step 1: Update organizationalStructureListItems, based on selected user's orgStructure
                if (selectedUser && selectedUser.get('estructura_organizativa')) {
                    selectedUser.get('estructura_organizativa').get('delegaciones').forEach(delegacion => {

                        // Find and check delegacion item
                        findAndCheckItem('delegacion', `${delegacion.get('delegacion_id')}`);

                        delegacion.get('oficinas').forEach(oficina => {

                            // Find and check oficina item
                            findAndCheckItem('oficina', `${delegacion.get('delegacion_id')}_${oficina.get('oficina_id')}`);

                            oficina.get('centros').forEach(centro => {

                                // Find and check oficina item
                                findAndCheckItem('centro', `${delegacion.get('delegacion_id')}_${oficina.get('oficina_id')}_${centro.get('centro_id')}`);
                            });
                        });
                    });
                }

                // Step 2: Update customersList, based on selected user's cliente_id
                if (selectedUser && selectedUser.get('cliente_id')) {
                    selectedUser.get('cliente_id').forEach(cliente => {
                        customersList.map(customer => {
                            if (customer._id === cliente.get('_id')) {
                                customer.checked = true;
                            }
                            return customer;
                        });
                    });
                }

                // Step 3: Update state
                this.setState({
                    modalIsOpen: true,
                    modalType: actionType,
                    organizationalStructureListItems: listItems,
                    customersList
                });

                break;
            default:

                // By default, just open modal and specify the modal type
                this.setState({
                    modalIsOpen: true,
                    modalType: actionType,
                    canSubmit: false
                });
        }
    }

    // Popover actions
    selectUser(userId) {
        this.setState({
            selectedUser: userId
        });
    }

    closeModal() {
        this.setState({
            modalIsOpen: false,
            modalType: null
        })
    }

    onUpdateOrgListItems(listItems) {
        this.setState({
            organizationalStructureListItems: listItems
        });
    }

    onUpdateCustomersList(customersList) {
        this.setState({
            customersList
        });
    }

    onUpdateSelectedUser(updateType) {
        let selectedUser = this.props.users.find(user => user.get('_id') === this.state.selectedUser).toJS();

        switch (updateType) {
            case 'editUser':
                if (this.state.canSubmit) {
                    Object.assign(selectedUser, { ...this.state.userData });

                    // Clean unnecessary fields
                    delete(selectedUser.validEmail);

                    this.props.updateUser(selectedUser);
                    this.setState({
                        userData: null
                    });
                }
                break;
            case 'changePassword':
                if (this.state.canSubmit) {

                    // Dispatch change user action, passing the user _id and the needed password object
                    this.props.changeUserPassword(selectedUser._id, {
                        newPassword: this.state.userData.password,
                        verifyPassword: this.state.userData.verifyPassword,
                        sendEmail: this.state.userData.sendEmail
                    });

                    this.setState({
                        userData: null
                    });
                }
                break;
            case 'changePermissions':

                // Clean fields not updateable in this case
                delete(selectedUser.nombre);
                delete(selectedUser.apellidos);
                delete(selectedUser.email);
                delete(selectedUser.telefono);
                delete(selectedUser.tipo);
                delete(selectedUser.emails_sent);
                delete(selectedUser.estado);

                // Compose field estructura_organizativa and add it to selectedUser
                let userOrgStructure = {},
                    checkedDelegaciones = [],
                    checkedOficinas = [],
                    checkedCentros = [],
                    checkedCustomers = [];

                // Obtain the checked delegaciones, oficinas and centros
                this.state.organizationalStructureListItems.forEach(listItem => {
                    if (listItem.level === 'delegacion' && listItem.checked) {
                        checkedDelegaciones.push(listItem);
                    }
                    if (listItem.level === 'oficina' && listItem.checked) {
                        checkedOficinas.push(listItem);
                    }
                    if (listItem.level === 'centro' && listItem.checked) {
                        checkedCentros.push(listItem);
                    }
                });

                // Prepare userOrgStructure object if necessary
                if (checkedDelegaciones.length > 0 && !userOrgStructure.delegaciones) {
                    userOrgStructure.delegaciones = [];
                }

                checkedDelegaciones.forEach(checkedDelegacion => {

                    // Prepare object if necessary
                    if (checkedOficinas.length > 0 && !checkedDelegacion.oficinas) {
                        checkedDelegacion.oficinas = [];
                    }

                    checkedOficinas.forEach(checkedOficina => {

                        // Prepare object if necessary
                        if (checkedCentros.length > 0 && !checkedOficina.centros) {
                            checkedOficina.centros = [];
                        }

                        checkedCentros.forEach(checkedCentro => {
                            if (checkedCentro.delegacion_id === checkedDelegacion.id && checkedCentro.oficina_id === checkedOficina.id.substr(checkedOficina.id.indexOf('_') + 1)) {
                                checkedOficina.centros.push({
                                    centro_id: parseInt(checkedCentro.id.substr(checkedCentro.id.lastIndexOf('_') + 1), 10),
                                    nombre: checkedCentro.name
                                });
                            }
                        });

                        if (checkedOficina.delegacion_id === checkedDelegacion.id) {
                            checkedDelegacion.oficinas.push({
                                oficina_id: parseInt(checkedOficina.id.substr(checkedOficina.id.indexOf('_') + 1), 10),
                                nombre: checkedOficina.name,
                                centros: checkedOficina.centros
                            });
                        }
                    });

                    userOrgStructure.delegaciones.push({
                        delegacion_id: parseInt(checkedDelegacion.id, 10),
                        nombre: checkedDelegacion.name,
                        oficinas: checkedDelegacion.oficinas
                    });
                });

                selectedUser.estructura_organizativa = userOrgStructure;

                // Compose field cliente_id and add it to selectedUser
                this.state.customersList.forEach(customer => {
                    if (customer.checked) {
                        checkedCustomers.push(customer._id);
                    }
                });

                selectedUser.cliente_id = checkedCustomers;

                this.props.updateUser(selectedUser);
                break;
            case 'enableUser':
                this.props.changeUserStatus(selectedUser._id, 'activo');
                break;
            case 'disableUser':
                this.props.changeUserStatus(selectedUser._id, 'inactivo');
                break;
            default:
                return;
        }

        this.closeModal();
    }

    onDeleteSelectedUser = () => {
        this.props.deleteUser(this.state.selectedUser);
        this.closeModal();
    };

    onChangeFormData(actionType, formData) {
        let canSubmit = false;

        if (actionType === 'createUser') {
            canSubmit =
                formData && formData.nombre && formData.nombre.length > 0 &&
                formData.email && formData.email.length > 0 && isEmail(formData.email) &&
                formData.tipo && formData.tipo.tipo !== -1 &&
                formData.password && formData.verifyPassword &&
                formData.password.length > 5 && formData.password === formData.verifyPassword;

            // User of type 'cliente' needs a valid customer
            if (formData.tipo.tipo === 'cliente') {
                canSubmit = canSubmit && formData.cliente_id._id !== -1;
            }

            this.setState({
                userData: formData,
                canSubmit
            });
        }

        if (actionType === 'editUser') {
            canSubmit = formData && formData.nombre && formData.nombre.length > 0 && formData.email && formData.email.length > 0 && isEmail(formData.email);
            this.setState({
                userData: formData,
                canSubmit
            });
        }

        if (actionType === 'changePassword') {
            canSubmit = formData && formData.password && formData.verifyPassword && formData.password.length > 5 && formData.password === formData.verifyPassword;
            this.setState({
                userData: formData,
                canSubmit
            });
        }
    }

    onCreateUser() {
        if (this.state.canSubmit) {
            let newUser = { ...this.state.userData };

            newUser.tipo = newUser.tipo.tipo;
            if (newUser.tipo !== 'cliente') {
                newUser.cliente_id = [];
            } else {
                newUser.cliente_id = [newUser.cliente_id._id];
            }

            // Obtain true/false for sendEmail
            newUser.sendEmail = !!newUser.sendEmail;

            // Clean unnecessary fields
            delete(newUser.passwordMatches);
            delete(newUser.validEmail);
            delete(newUser.validPassword);

            this.props.createUser(newUser);
            this.setState({
                userData: null
            });
        }
        this.closeModal();
    }

    render() {
        const { modalIsOpen, modalType, organizationalStructureListItems, customersList, canSubmit } = this.state;
        const selectedUser = this.props.users.find(user => user.get('_id') === this.state.selectedUser);
        const { connectedUser } = this.props;
        let modal = null;

        if (modalIsOpen) {
            switch (modalType) {
                case 'createUser':
                    modal = {
                        title: 'Crear usuario',
                        body: (
                            <UserCreateDialog
                                connectedUser={connectedUser}
                                customers={this.props.customers}
                                onChangeFormData={this.onChangeFormData.bind(this, 'createUser')}
                            />
                        ),
                        footer: (
                            <div className="p15 text-right">
                                <button
                                    key="cancel"
                                    type="button"
                                    className="btn btn-sm"
                                    label={'CANCELAR'}
                                    style={{
                                        color: 'gray',
                                        marginRight: 15
                                    }}
                                    onClick={this.closeModal}
                                >
                                    {'CANCELAR'}
                                </button>
                                <button
                                    key="accept"
                                    type="button"
                                    className="btn btn-sm btn-success"
                                    onClick={this.onCreateUser}
                                    disabled={!canSubmit}
                                >
                                    {'CREAR USUARIO'}
                                </button>
                            </div>
                        )
                    };
                    break;
                case 'editUser':
                    modal = {
                        title: 'Editar usuario',
                        body: (
                            <UserEditDialog
                                selectedUser={selectedUser}
                                onChangeFormData={this.onChangeFormData.bind(this, modalType)}
                            />
                        ),
                        footer: (
                            <div className="p15 text-right">
                                <button
                                    key="cancel"
                                    type="button"
                                    className="btn btn-sm"
                                    label={'CANCELAR'}
                                    style={{
                                        color: 'gray',
                                        marginRight: 15
                                    }}
                                    onClick={this.closeModal}
                                >
                                    {'CANCELAR'}
                                </button>
                                <button
                                    key="accept"
                                    type="button"
                                    className="btn btn-sm btn-success"
                                    onClick={this.onUpdateSelectedUser.bind(this, modalType)}
                                    disabled={!canSubmit}
                                >
                                    {'GUARDAR'}
                                </button>
                            </div>
                        )
                    };
                    break;
                case 'changePassword':
                    modal = {
                        title: 'Cambiar contraseña de usuario',
                        body: (
                            <UserChangePasswordDialog
                                selectedUser={selectedUser}
                                onChangeFormData={this.onChangeFormData.bind(this, modalType)}
                            />
                        ),
                        footer: (
                            <div className="p15 text-right">
                                <button
                                    key="cancel"
                                    type="button"
                                    className="btn btn-sm"
                                    label={'CANCELAR'}
                                    style={{
                                        color: 'gray',
                                        marginRight: 15
                                    }}
                                    onClick={this.closeModal}
                                >
                                    {'CANCELAR'}
                                </button>
                                <button
                                    key="accept"
                                    type="button"
                                    className="btn btn-sm btn-success"
                                    onClick={this.onUpdateSelectedUser.bind(this, modalType)}
                                    disabled={!canSubmit}
                                >
                                    {'CAMBIAR CONTRASEÑA'}
                                </button>
                            </div>
                        )
                    };
                    break;
                case 'changePermissions':
                    modal = {
                        title: 'Configurar permisos de usuario',
                        body: (
                            <UserPermissionsDialog
                                selectedUser={selectedUser.toJS()}
                                orgListItems={organizationalStructureListItems}
                                onUpdateOrgListItems={this.onUpdateOrgListItems}
                                customersList={customersList}
                                onUpdateCustomersList={this.onUpdateCustomersList}
                            />
                        ),
                        footer: (
                            <div className="p15 text-right">
                                <button
                                    key="cancel"
                                    type="button"
                                    className="btn btn-sm"
                                    label={'CANCELAR'}
                                    style={{
                                        color: 'gray',
                                        marginRight: 15
                                    }}
                                    onClick={this.closeModal}
                                >
                                    {'CANCELAR'}
                                </button>
                                <button
                                    key="accept"
                                    type="button"
                                    className="btn btn-sm btn-success"
                                    onClick={this.onUpdateSelectedUser.bind(this, modalType)}
                                >
                                    {'GUARDAR'}
                                </button>
                            </div>
                        )
                    };
                    break;
                case 'enableUser':
                case 'disableUser':
                    modal = {
                        title: modalType === 'enableUser' ? 'Activar usuario' : 'Desactivar usuario',
                        body: (
                            <div>
                                <div className="row mb5">
                                    <div className="col-12">
                                        <span style={{ fontWeight: 'bold', color: '#076BAD' }}>{selectedUser.get('full_name')}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12" style={{ color: '#076BAD' }}>
                                        <span>(Tipo: </span>
                                        <span style={{ fontWeight: 'bold' }}>{_.capitalize(selectedUser.get('tipo'))}</span>
                                        {selectedUser.get('tipo') === 'cliente' ? (
                                            <div style={{ display: 'inline' }}>
                                                <span>, Cliente: </span>
                                                <span style={{ fontWeight: 'bold' }}>{selectedUser.toJS()['cliente_id'][0]['nombre']}</span>
                                            </div>
                                        ) : null}
                                        <span>)</span>
                                    </div>
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    <div className="row mb10">
                                        <div className="col-12">
                                            <span>Se va a {modalType === 'enableUser' ? 'activar' : 'desactivar'} el usuario, ¿estás seguro?.</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            {modalType === 'enableUser' ? (
                                                <span>El usuario podrá conectarse y realizar las acciones según su perfil y su visibilidad.</span>
                                            ) : (
                                                <span>El usuario no se podrá conectar ni realizar ninguna acción en la aplicación.</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ),
                        footer: (
                            <div className="p15 text-right">
                                <button
                                    key="cancel"
                                    type="button"
                                    className="btn btn-sm"
                                    label={'CANCELAR'}
                                    style={{
                                        color: 'gray',
                                        marginRight: 15
                                    }}
                                    onClick={this.closeModal}
                                >
                                    {'CANCELAR'}
                                </button>
                                <button
                                    key="accept"
                                    type="button"
                                    className={modalType === 'enableUser' ? "btn btn-sm btn-success" : "btn btn-sm btn-danger"}
                                    onClick={this.onUpdateSelectedUser.bind(this, modalType)}
                                >
                                    {modalType === 'enableUser' ? (
                                        'ACTIVAR USUARIO'
                                    ) : (
                                        'DESACTIVAR USUARIO'
                                    )}
                                </button>
                            </div>
                        )
                    };

                    break;
                case 'deleteUser':
                    modal = {
                        title: 'Eliminar usuario',
                        body: (
                            <div>
                                <div className="row mb5">
                                    <div className="col-12">
                                        <span style={{ fontWeight: 'bold', color: '#076BAD' }}>{selectedUser.get('full_name')}</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12" style={{ color: '#076BAD' }}>
                                        <span>(Tipo: </span>
                                        <span style={{ fontWeight: 'bold' }}>{_.capitalize(selectedUser.get('tipo'))}</span>
                                        {selectedUser.get('tipo') === 'cliente' ? (
                                            <div style={{ display: 'inline' }}>
                                                <span>, Cliente: </span>
                                                <span style={{ fontWeight: 'bold' }}>{selectedUser.toJS()['cliente_id'][0]['nombre']}</span>
                                            </div>
                                        ) : null}
                                        <span>)</span>
                                    </div>
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    <div className="row mb10">
                                        <div className="col-12">
                                            <span>Se va a eliminar el usuario, ¿estás seguro?.</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <span>El usuario no aparecerá, y ya no se podrá conectar ni realizar ninguna acción en la aplicación.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ),
                        footer: (
                            <div className="p15 text-right">
                                <button
                                    key="cancel"
                                    type="button"
                                    className="btn btn-sm"
                                    label={'CANCELAR'}
                                    style={{
                                        color: 'gray',
                                        marginRight: 15
                                    }}
                                    onClick={this.closeModal}
                                >
                                    {'CANCELAR'}
                                </button>
                                <button
                                    key="accept"
                                    type="button"
                                    className={"btn btn-sm btn-danger"}
                                    onClick={this.onDeleteSelectedUser}
                                >ELIMINAR USUARIO
                                </button>
                            </div>
                        )
                    };
                    break;
                default:
                    modal = {
                        title: '',
                        body: null,
                        footer: null
                    };
            }
        }

        const tableSettings = {
            sortable: true,
            filterable: true,
            defaultSortFields: ['fecha_creacion'],
            defaultSortDirections: ['desc'],
            height: 550,
            rowHeight: 50,
            strippedRows: false,
            rowStyle: {
                fontSize: 13
            }
        };

        const tableData = this.props.users.toJS();

        const tableColumns = {
            nombre: {
                title: "Nombre",
                columnType: 'text',
                dataField: 'nombre',
                width: 150,
                style: {},
                sortable: true,
                filterable: true
            },
            apellidos: {
                title: "Apellidos",
                columnType: 'text',
                dataField: 'apellidos',
                width: 150,
                style: {},
                sortable: true,
                filterable: true
            },
            email: {
                title: "Email",
                columnType: 'text',
                dataField: 'email',
                width: 250,
                style: {},
                sortable: true,
                filterable: true
            },
            tipo: {
                title: "Tipo",
                columnType: 'function',
                dataField: 'tipo',
                width: 130,
                style: {},
                sortable: true,
                filterable: false,
                nodeContent: (value) => {
                    return _.capitalize(value);
                }
            },
            cliente: {
                title: "Cliente",
                columnType: 'function',
                dataField: ['tipo', 'cliente_id'],
                width: 200,
                style: {},
                sortable: false,
                filterable: false,
                nodeContent: (value) => {
                    if(value[0] === 'administrador'){
                        return 'Todos';
                    }

                    if(value[1] && value[1].length === 0) {
                        return null;
                    }

                    if(value[1] && value[1].length === 1) {
                        return value[1][0].nombre;
                    }

                    if(value[1] && value[1].length > 1) {
                        return `( ${value[1].length} )`;
                    }

                    return null;
                }
            },
            telefono: {
                title: "Teléfono",
                columnType: 'text',
                dataField: 'telefono',
                width: 120,
                style: {},
                sortable: true,
                filterable: true
            },
            fecha_alta: {
                title: "Fecha alta",
                columnType: 'function',
                dataField: 'fecha_creacion',
                width: 120,
                style: {},
                sortable: true,
                filterable: true,
                nodeContent: (value) => {
                    return value ? moment(value).format('DD/MM/YYYY') : '--';
                }
            },
            emails_sent: {
                title: "¿Email enviado?",
                columnType: 'function',
                dataField: 'emails_sent',
                width: 100,
                style: {
                    alignItems: 'center'
                },
                sortable: true,
                filterable: false,
                nodeContent: (value) => {
                    let iconType = value === 0 ? 'cross' : 'envelope';

                    return (
                        <CustomIcon
                            className=""
                            iconType={iconType}
                            size={18}
                            counter={value}
                            color="#183650"
                        />
                    );

                }
            },
            estado: {
                title: "Estado",
                columnType: 'function',
                dataField: 'estado',
                width: 100,
                style: { padding: 0, paddingRight: 15 },
                sortable: true,
                filterable: false,
                nodeContent: (value) => {
                    let status = value === 'activo' ? 'success' : value === 'inactivo' ? 'danger' : 'info';
                    return (
                        <div>
                            <span
                                className={'badge badge-' + status}
                                style={{
                                    padding: '5px 8px',
                                    fontSize: 12
                                }}
                            >
                                {_.capitalize(value)}
                            </span>
                        </div>
                    );
                }
            },
            actions: {
                title: '',
                columnType: 'function',
                dataField: ['_id', 'tipo'],
                width: 30,
                style: { alignItems: 'center'},
                sortable: false,
                filterable: false,
                nodeContent: (value) => {
                    if(this.props.user.get('_id') !== value[0] && this.props.user.get('tipo') === 'gestor' && value[1] === 'gestor') {
                        return null;
                    }

                    return (
                        <Popover
                            content={popoverContent}
                            interactionKind={PopoverInteractionKind.CLICK}
                            position={Position.BOTTOM_RIGHT}
                        >
                            <Icon
                                className="icon-custom"
                                title={false}
                                icon={'more'}
                                iconSize={18}
                                color="#183650"
                                onClick={this.selectUser.bind(this, value[0])}
                            />
                        </Popover>
                    );
                }
            }
        };

        const popoverContent = (
            <Menu>
                <MenuItem icon="edit" onClick={this.handleMenuClick.bind(this, 'editUser')} text="Editar" />
                <MenuItem icon="key" onClick={this.handleMenuClick.bind(this, 'changePassword')} text="Cambiar contraseña" />
                {selectedUser && selectedUser.get('tipo') !== 'administrador' ? (
                    <MenuItem icon="shield" onClick={this.handleMenuClick.bind(this, 'changePermissions')} text="Configurar permisos" />
                ) : null}
                {selectedUser && selectedUser.get('estado') === 'activo' ? (
                    <MenuItem icon="lock" onClick={this.handleMenuClick.bind(this, 'disableUser')} text="Desactivar" />
                ) : null}
                {selectedUser && selectedUser.get('estado') === 'inactivo' ? (
                    <MenuItem icon="unlock" onClick={this.handleMenuClick.bind(this, 'enableUser')} text="Activar" />
                ) : null}
                <MenuItem icon="trash" onClick={this.handleMenuClick.bind(this, 'deleteUser')} text="Eliminar usuario" />
            </Menu>
        );

        return (
            <div>
                <div style={{ marginTop: 20, marginBottom: 20, display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ marginLeft: 20 }}>
                        <div style={{ fontSize: 18, fontWeight: 'bold', marginTop: 8 }}>
                            <span>Total: {this.props.users && this.props.users.size} | </span>
                            <span>Activos: {this.props.users && this.props.users.filter(user => user.get('estado') === 'activo').size} | </span>
                            <span>Inactivos: {this.props.users && this.props.users.filter(user => user.get('estado') === 'inactivo').size}</span>
                        </div>
                    </div>
                    <div style={{ marginRight: 20 }}>
                        {connectedUser && connectedUser.get('tipo') !== 'cliente' ? (
                            <div style={{ color: '#fff' }}>
                                <a className="btn btn-custom btn-sm" onClick={this.handleCreateUser}>Crear Usuario</a>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <VirtualizedTable
                                tableSettings={tableSettings}
                                tableColumns={tableColumns}
                                tableData={tableData}
                            />
                        </div>
                    </div>
                </div>
                {modalIsOpen && (
                    <Modal
                        heading={modal.title}
                        footer={() => modal.footer}
                        autoFocus={false}
                        onClose={() => {}}
                    >
                        {modal.body}
                    </Modal>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {

    // Reducers
    const orgStructureReducer = state.organizationalStructure;
    const customersReducer = state.customers;
    const usersReducer = state.users;
    const sessionReducer = state.auth.session;

    // Organizational Structure
    const orgStructure = orgStructureReducer.get('payload') || Map({});

    // Customers
    const customers = customersReducer.get('payload') || List([]);

    // Users
    const users = usersReducer.get('payload') || List([]);

    // Session
    const connectedUser = sessionReducer.get('user') || Map({});

    return {
        orgStructure,
        customers,
        users,
        connectedUser
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getOrgStructure: () => dispatch(getOrgStructure()),
        getCustomers: () => dispatch(getCustomers()),
        getUsers: () => dispatch(getUsers()),
        updateUser: (user) => dispatch(updateUser(user)),
        createUser: (user) => dispatch(createUser(user)),
        deleteUser: (userId) => dispatch(deleteUser(userId)),
        changeUserStatus: (userId, status) => dispatch(changeUserStatus(userId, status)),
        changeUserPassword: (userId, passwordObject) => dispatch(changeUserPassword(userId, passwordObject))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
