import jwt from 'jsonwebtoken';
import moment from 'moment';
import {signout} from "../actions/auth";

let instance = null;

class Auth {
    constructor() {
        if (!instance) {
            instance = this;
        }

        return instance;
    }

    init(store) {
        this.store = store;
    }

    user() {
        return JSON.parse(localStorage.getItem('fds.user'));
    }

    isLoggedIn() {
        let token = this.getLocalJwt();
        if (token) {
            if (!!this.user() && !this.isTokenExpired(token)) {
                return true;
            } else {
                this.signout();
                return false;
            }
        } else {
            this.signout();
            return false;
        }
    }

    completeSignin(user, token) {
        localStorage.setItem('fds.user', JSON.stringify(user));
        localStorage.setItem('fds.authToken', token);
    }

    saveJwtTokenToLocalStorage(token) {
        localStorage.setItem('fds.authToken', token);
    }

    signout() {
        localStorage.removeItem('fds.user');
        localStorage.removeItem('fds.authToken');
    }

    getHeaderJwt(authHeader) {
        if (authHeader) {
            const part = authHeader.split(' ');
            if (part.length === 2 && part[0] === 'Bearer') {
                const token = part[1];
                return token;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    getLocalJwt() {
        const token = localStorage.getItem('fds.authToken');
        if (token) {
            return token;
        } else {
            return null;
        }
    }

    isTokenExpired(token) {
        if (token) {
            const decoded = jwt.decode(token);
            if (decoded) {
                const tokenExp = decoded.exp,
                    now = moment().unix().valueOf();

                if (tokenExp - now > 0) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    /**
     * When a request of axios is made, we check the response to remove expired JWT
     */
    checkResponse(response) {
        let token = this.getLocalJwt();
        let newToken = this.getHeaderJwt(response.headers.authorization);
        if (newToken && (newToken !== token)) {
            this.saveJwtTokenToLocalStorage(newToken);
        }
        return response;
    };

    /**
     * When a request of axios is made, we check the response to remove expired JWT
     */
    checkErrorResponse(error) {
        if (error.response.status === 401) {
            if (this.getLocalJwt()) {
                this.store.dispatch(signout());
            }
        }
        return Promise.reject(error);
    };
}

export default new Auth();