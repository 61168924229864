import React, { useEffect, useState } from "react";
import { List, Map } from "immutable";
import { connect } from "react-redux";
import moment from "moment";
import _ from 'lodash';

// Components
import VirtualizedTable from '../../components/virtualized-table';
import SignDocument from "../SignDocument";
import CustomIcon from '../../components/CustomIcon';
import FiltersArea from './filtersArea';
import { Checkbox } from '@blueprintjs/core';
import Modal from '@atlaskit/modal-dialog';

// Actions
import { getEmployeeDetails, getEmployees } from '../../actions/employees';
import { getCustomers } from "../../actions/customers";
import { getEmployeeDocuments, downloadEmployeeDocuments } from "../../actions/employeeDocuments";

const EmployeeDocuments = (props) => {

    const [signDialogIsOpen, setSignDialogIsOpen] = useState(false);
    const [signDocumentId, setSignDocumentId] = useState(null);
    const [signEmployeeId, setSignEmployeeId] = useState(null);
    const [documentProps, setDocumentProps] = useState({});
    const [allSelectedDocs, setAllSelectedDocs] = useState(false);
    const [selectedDocs, setSelectedDocs] = useState(new Set());

    useEffect(() => {
        props.getCustomers();
        props.getEmployees();
    }, []);

    useEffect(() => {
        if (signEmployeeId) props.getEmployeeDetails(signEmployeeId);
    }, [signEmployeeId]);

    function prepareQuery(query) {
        props.getEmployeeDocuments(query);
    };

    const tableData = props.employeeDocuments && props.employeeDocuments.toJS();

    function handleMenuClick(documentId, action) {
        if (action === 'sign') {
            setSignDialogIsOpen(true);
            setSignDocumentId(documentId);
        }
    }

    // Control the state of allSelectedDocs and selectedDocs
    function handleCheckbox(type, data) {
        let getSelectedDocs;
        if (type === 'mainTableAll') {
            getSelectedDocs = new Set();
            if (!allSelectedDocs) data.forEach(id => getSelectedDocs.add(id));
            setAllSelectedDocs(!allSelectedDocs);
            setSelectedDocs(getSelectedDocs);
        } else if (type === 'mainTable') {
            getSelectedDocs = new Set(selectedDocs);
            getSelectedDocs.has(data) ? getSelectedDocs.delete(data) : getSelectedDocs.add(data);
            setAllSelectedDocs(getSelectedDocs.size === props.employeeDocuments.count());
            setSelectedDocs(getSelectedDocs);
        }
    }

    // Employees List Area
    const tableSettings = {
        sortable: true,
        filterable: false,
        defaultSortFields: ['fecha_creacion'],
        defaultSortDirections: ['desc'],
        height: 485,
        rowHeight: 54,
        strippedRows: false,
        rowStyle: {
            fontSize: 13
        }
    };

    const tableColumns = {
        checkbox: {
            title: (
                <Checkbox
                    checked={ allSelectedDocs }
                    onChange={() => handleCheckbox('mainTableAll', tableData.map(doc => doc.id))}
                    style={{ marginTop: 10 }}
                />
            ),
            columnType: 'function',
            dataField: 'id',
            width: 50,
            style: { display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', maxWidth: 50 },
            sortable: false,
            filterable: false,
            nodeContent: value => {
                return (
                    <div style={{display: 'flex'}}>
                        <Checkbox
                            checked={selectedDocs.has(value)}
                            onChange={() => handleCheckbox('mainTable', value)}
                            style={{ marginTop: 5 }}
                        />
                    </div>
                );
            }
        },
        nombre: {
            title: 'Nombre',
            columnType: 'function',
            dataField: 'id',
            width: 200,
            style: { maxWidth: 250 },
            sortable: true,
            filterable: false,
            nodeContent: (documentId) => {
                return (
                    <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                        <span
                            style={{ fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer', whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
                            onClick={() => {
                                // obtener el id del trabajador para ver el documento
                                const employeeId = props.employeeDocuments.find(document => document.get('id') === documentId).get('trabajador_id');
                                setSignEmployeeId(employeeId);
                                setDocumentProps(props.employeeDocuments.find(document => document.get('id') === documentId));
                                handleMenuClick(documentId, 'sign');
                            }}
                        >
                            {props.employeeDocuments.find(document => document.get('id') === documentId).get('nombre')}
                        </span>
                    </div>
                );
            }
        },
        cliente: {
            title: "Cliente",
            columnType: 'function',
            dataField: 'cliente_id',
            width: 150,
            style: { maxWidth: 150 },
            sortable: true,
            filterable: false,
            nodeContent: (value) => {
                const customer = props.customers.find(customer => customer.get('_id') === value);

                if (customer) {
                    return customer.get('nombre');
                } else {
                    return null;
                }
            }
        },
        trabajador: {
            title: "Trabajador",
            columnType: 'function',
            dataField: 'trabajador_id',
            width: 300,
            style: {},
            sortable: true,
            filterable: false,
            nodeContent: (value) => {
                const employee = props.employees.find(employee => employee.get('trabajador_id') === value);

                if (employee) {
                    return employee.get('nombre');
                } else {
                    return null;
                }
            }
        },
        tipo: {
            title: "Tipo",
            columnType: 'text',
            dataField: 'tipo',
            width: 100,
            style: { maxWidth: 100 },
            sortable: true,
            filterable: false
        },
        requiere_firma: {
            title: "Requiere firma",
            columnType: 'boolean',
            dataField: 'requiere_firma',
            width: 60,
            style: { textTransform: 'uppercase', maxWidth: 150 },
            sortable: true,
            filterable: false
        },
        fecha_creacion: {
            title: "Fecha documento",
            columnType: 'function',
            dataField: 'fecha_creacion',
            width: 180,
            style: { maxWidth: 150 },
            sortable: true,
            filterable: false,
            nodeContent: (value) => {
                return moment(value).format('DD/MM/YYYY') ;
            }
        },
        fecha_firma: {
            title: "Fecha firma",
            columnType: 'function',
            dataField: 'fecha_firma',
            width: 180,
            style: { maxWidth: 125 },
            sortable: true,
            filterable: false,
            nodeContent: (value) => {
                return value ? moment(value).format('DD/MM/YYYY') : '--';
            }
        },
        estado: {
            title: "Estado",
            columnType: 'function',
            dataField: 'estado',
            width: 100,
            style: { maxWidth: 100 },
            sortable: true,
            filterable: false,
            nodeContent: (value) => {
                let status = value === 'firmado' ? 'success' : value === 'pendiente' ? 'danger' : 'info';
                return (
                    (!!value ? (
                        <div>
                            <span
                                className={'badge badge-' + status}
                                style={{
                                    padding: '5px 8px',
                                    fontSize: 12
                            }}>{_.capitalize(value)}</span>
                        </div>
                    ) : (
                        <div>--</div>
                    ))
                );
            }
        },
        actions: {
            title: 'Ver documento',
            columnType: 'function',
            dataField: 'id',
            width: 40,
            style: { maxWidth: 140 },
            sortable: false,
            filterable: false,
            nodeContent: (documentId) => {
                return (
                    <div style={{display: 'flex'}}>
                        <CustomIcon
                            iconType="document"
                            size={20}
                            title="Firmar"
                            color="#183650"
                            style={{margin: 'auto'}}
                            onClick={() => {
                                // obtener el id del trabajador para ver el documento
                                const employeeId = props.employeeDocuments.find(document => document.get('id') === documentId).get('trabajador_id');
                                setSignEmployeeId(employeeId);
                                setDocumentProps(props.employeeDocuments.find(document => document.get('id') === documentId));
                                handleMenuClick(documentId, 'sign');
                            }}
                        />
                    </div>
                );
            }
        }
    };

    return (
        <div>
            <FiltersArea 
                prepareQuery={prepareQuery}
                employeesIsFetching={props.employeesIsFetching}
                employeesFilterOptions={props.employees.toJS()}
                customerFilterOptions={props.customers.toJS()}
            />
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: '18px', fontWeight: 'bold', marginRight: 10 }}>
                            <span>Documentos del trabajador</span>
                            {selectedDocs.size > 0 && (
                                <button
                                    className="btn btn-custom btn-sm mr-3"
                                    onClick={() => {
                                        const downloadDocumentsIds = [];
                                        for (const doc of selectedDocs) downloadDocumentsIds.push(doc);
                                        props.downloadEmployeeDocuments(downloadDocumentsIds)
                                    }}
                                    style={{ height: '32px', margin: 'auto' }}
                                >
                                    <span>Descargar ({ selectedDocs.size })</span>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <VirtualizedTable 
                            tableSettings={tableSettings}
                            tableColumns={tableColumns}
                            tableData={tableData}
                        />
                    </div>
                </div>
            </div>
            {signDialogIsOpen && documentProps && (
                <Modal
                    onClose={() => setSignDialogIsOpen(false)}
                    width={'xxl-large'}
                    autoFocus={false}
                    footer={() => (
                        <div className="p15 text-right">
                            <button
                                key="close"
                                type="button"
                                className="btn btn-sm"
                                label={'CERRAR'}
                                style={{
                                    color: 'gray',
                                    marginRight: 15
                                }}
                                onClick={() => setSignDialogIsOpen(false)}
                            >
                                {'CERRAR'}
                            </button>
                        </div>
                    )}
                >
                    <SignDocument
                        documentId={signDocumentId} 
                        employeeId={signEmployeeId} 
                        documentProps={documentProps} 
                        employeeDetails={props.employeeDetails}
                        setSignDialogIsOpen={setSignDialogIsOpen}
                    />
                </Modal>
            )}
        </div>
    );
};

function mapStateToProps(state) {

    // Reducers
    const customersReducer = state.customers;
    const employeesReducer = state.employees;
    const employeeDocumentsReduce = state.employeeDocuments;

    // Customers
    let customers = List([
        Map({
            _id: -1,
            nombre: 'Todos'
        })
    ]);
    (customersReducer.get('payload') || List([])).map(customer => {
        return customers = customers.insert(
            customers.size,
            customer
                .delete('cif')
                .delete('estado')
                .delete('fecha_actualizacion')
                .delete('fecha_creacion')
                .delete('__v')
                .delete('id')
        );
    });

    // Employees
    let employees = List([
        Map({
            trabajador_id: -1,
            nombre: 'Todos'
        })
    ]);
    (employeesReducer.get('employeesList') || List([])).map(employee => {
        return employees = employees.insert(
            employees.size,
            employee
                .delete('documento_identificativo')
                .delete('email')
                .delete('telefono')
                .delete('clientes')
                .delete('estado')
        );
    });
    employees = employees.map(employee => {
        const employeeConcatName = `${employee.get('trabajador_id')} - ${employee.get('nombre')} ${employee.get('apellidos')}`;
        return employee.get('nombre') === 'Todos' ? employee : employee.set('nombre', employeeConcatName);
    });
    const employeesIsFetching = employeesReducer.get('isFetching') || false;

    // Employee Documents
    const employeeDocuments = employeeDocumentsReduce.get('documents') || [];
    const employeeDocumentsIsFetching = employeeDocumentsReduce.get('downloadDocumentIsFetching') || false;

    // Employee Details
    const employeeDetails = employeesReducer.get('employeeDetails') || {};

    return {
        customers, employeeDocuments, employeeDocumentsIsFetching, employees, employeesIsFetching, employeeDetails
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getEmployees: (query) => dispatch(getEmployees(query)),
        getEmployeeDetails: (query) => dispatch(getEmployeeDetails(query)),
        getEmployeeDocuments: (query) => dispatch(getEmployeeDocuments(query)),
        downloadEmployeeDocuments: (query) => dispatch(downloadEmployeeDocuments(query)),
        getCustomers: () => dispatch(getCustomers())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDocuments);
