import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import classnames from 'classnames';

// Components
import CustomersListConfiguration from './CustomersListConfiguration';
import OrganizationalStructureConfiguration from './OrganizationalStructureConfiguration';

class UserPermissionsDialog extends Component {
    constructor(props){
        super(props);

        this.state = {
            tabIndex: 0
        };

        this.handleTabChange = this.handleTabChange.bind(this);
    }

    handleTabChange(index) {
        this.setState({
            tabIndex: index
        });
    }

    render() {
        const { tabIndex } = this.state;

        return (
            <div>
                <Tabs
                    selectedIndex={tabIndex}
                    onSelect={this.handleTabChange}
                    selectedTabClassName="active"
                >
                    <TabList className="nav nav-tabs nav-tabs-simple">
                        <Tab className="nav-item">
                            <a className={classnames({ active: tabIndex === 0})}>Estructura organizativa</a>
                        </Tab>
                        {this.props.selectedUser && this.props.selectedUser.tipo === 'gestor' ? (
                            <Tab className="nav-item">
                                <a className={classnames({ active: tabIndex === 1})}>Clientes</a>
                            </Tab>
                        ) : null}
                    </TabList>
                    <TabPanel>
                        <OrganizationalStructureConfiguration
                            listItems={this.props.orgListItems}
                            onUpdateListItems={this.props.onUpdateOrgListItems}
                        />
                    </TabPanel>
                    {this.props.selectedUser && this.props.selectedUser.tipo === 'gestor' ? (
                        <TabPanel>
                            <CustomersListConfiguration
                                customersList={this.props.customersList}
                                onUpdateCustomersList={this.props.onUpdateCustomersList}
                            />
                        </TabPanel>
                    ) : null}
                </Tabs>
            </div>
        );
    }
}

export default UserPermissionsDialog;