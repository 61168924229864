import React, {Component} from 'react';

// Components
import CustomIcon from '../components/CustomIcon';
import Select from '../components/virtualized-select';
import { attachTypes } from "./attachTypes";

class DocumentsPageAttachsDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedAttachType: -1,
            selectedAttachFile: -1
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.selectedAttachType !== prevState.selectedAttachType ||
            this.state.selectedAttachFile !== prevState.selectedAttachFile
        ) {
            this.props.onFormData({
                fdType: 'uploadAttach',
                cpdId: this.props.doc.id,
                attachType: this.state.selectedAttachType,
                attachFile: this.state.selectedAttachFile
            });
        }

        if (
            prevProps.canSubmit !== this.props.canSubmit &&
            !this.props.canSubmit &&
            this.state.selectedAttachFile !== -1 &&
            this.state.selectedAttachType !== -1
        ) {
            this.setState({
                selectedAttachType: -1,
                selectedAttachFile: -1
            });
        }
    }

    attachsTable = () => {
        let table = [];

        this.props.attachs.forEach( (attach) => {
            table.push(
                <tr key={attach._id} >
                    <th scope="row" style={{ verticalAlign: 'middle' }} >{attach.nombre}</th>
                    <td style={{ width: 200, verticalAlign: 'middle' }} >
                        { attachTypes.filter( att => {
                                return att.get('tipo') === attach.tipo;
                            }).get(0).get('desc')
                        }
                    </td>
                    <td style={{ width: 104 }} >
                        <CustomIcon
                            style={{ float: 'left' }}
                            iconType="download"
                            size={ 20 }
                            counter={ attach.descargas }
                            title="Descargar"
                            color="#183650"
                            onClick={this.props.downloadAttach.bind(this, this.props.doc.id, attach._id)}
                        />
                        <CustomIcon
                            iconType="trash"
                            size={ 20 }
                            title="Eliminar"
                            color="#183650"
                            onClick={this.props.deleteAttach.bind(this, this.props.doc.id, attach._id)}
                        />
                    </td>
                </tr>
            );
        } );

        return table;
    };

    render() {
        // States
        const { selectedAttachType, selectedAttachFile } = this.state;
        // Props
        const { doc } = this.props;

        // Styles
        const styles = {
            modal: {
                subtitle: {
                    display: 'flex',
                    padding: '15px 0',
                    fontSize: 16,
                    fontWeight: 'bold',
                    color: '#076BAD'
                }
            },
            uploadDoc: {
                display: 'flex',
                alignItems: 'center',
                padding: '15px 0',
                button: {
                    label: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 0,
                        cursor: 'pointer'
                    },
                    input: {
                        display: 'none'
                    }
                },
                selectedFileName: {
                    marginLeft: 10
                },
                sendButton: {
                    display: 'flex',
                    alignItems: 'flex-end',
                    paddingBottom: '2px'
                }
            },
            filtersArea: {
                wrapper: {
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    margin: '15px 0',
                    fontSize: 14
                },
                item: {
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: 20,
                    input: {
                        width: '100%'
                    }
                }
            }
        };

        return (
            <div>
                <div style={styles.modal.subtitle}>
                    <span>{doc.nombre}</span>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <table className="table" style={{ marginBottom: 0 }} >
                            <thead>
                            <tr>
                                <th scope="col" style={{border: 'none'}}>Nombre documento</th>
                                <th scope="col" style={{border: 'none'}}>Tipo anexo</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.attachsTable()}
                            </tbody>
                        </table>
                    </div>
                </div>
                <hr style={{ marginTop: 0 }} />
                <div className="row">
                    <div className="col" style={{
                        fontSize: 18,
                        fontWeight: 'bold'
                    }}>Añadir Anexo:
                    </div>
                </div>
                <div style={styles.uploadDoc}>
                    <div style={styles.uploadDoc.button}
                         className="btn btn-custom btn-sm">
                        <label style={styles.uploadDoc.button.label}>
                            <input
                                style={{display: 'none'}}
                                type="file"
                                onChange={(e) => this.setState({selectedAttachFile: e.target.files[0]})}
                            />
                            <span>Seleccionar fichero</span>
                        </label>
                    </div>
                    <span style={styles.uploadDoc.selectedFileName}>
                        {selectedAttachFile === -1 ? 'No se ha seleccionado ningún fichero.' : selectedAttachFile.name}
                    </span>
                </div>
                <div className="row">
                    <div className="col-4">
                        <div className="mb10 fw600" id="TipoAnexo">Tipo de Anexo</div>
                        <Select
                            options={attachTypes.toJS()}
                            value={selectedAttachType}
                            labelKey='desc'
                            valueKey='tipo'
                            matchProp='desc'
                            multi={false}
                            searchable={true}
                            clearable={false}
                            onChange={(selectedValue) => this.setState({selectedAttachType: selectedValue.tipo})}
                            parentId="TipoAnexo"
                        />
                    </div>
                    <div style={styles.uploadDoc.sendButton}>
                        <button
                            key="accept"
                            type="button"
                            className="btn btn-sm btn-success"
                            onClick={this.props.uploadAttach}
                            disabled={!this.props.canSubmit}
                        >
                            {'SUBIR ANEXO'}
                        </button>
                    </div>
                </div>

            </div>
        );
    }
}

export default DocumentsPageAttachsDialog;