import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import VirtualizedSelect from 'react-virtualized-select';
import scrollbarSize from 'dom-helpers/util/scrollbarSize';

class CustomVirtualizedSelect extends React.Component {

    shouldComponentUpdate(nextProps, nextState){

        // Checking each prop, the lazy component and the popover
        return Object.keys(this.props).some(key => this.props[key] !== nextProps[key]);
    }

    getTextHeight = (text, font) => {

        let element = document.getElementById(this.props.parentId);
        let optionsWidth = this.props.optionsWidth || 500;
        let rectStyle = null;

        if (element) {

            rectStyle = window.getComputedStyle(element);
            let paddingRight = parseFloat(rectStyle.paddingRight.replace('px', ''));
            let paddingLeft = parseFloat(rectStyle.paddingLeft.replace('px', ''));
            optionsWidth = element.offsetWidth - paddingRight - paddingLeft;
        }

        let div = document.createElement('div');
        // Assuming default height max size of 200px - 2 pixels from border bottom and border top (default config)
        // TODO (RJC): Try to find a better solution because this one fails in some cases
        let scrollBarSize = this.props.options.length > 4 ? scrollbarSize() : 0;

        div.innerHTML = text;
        div.style.position = 'absolute';
        div.style.top = '-9999px';
        div.style.left = '-9999px';

        div.style.fontFamily = rectStyle ? rectStyle.fontFamily : 'Ubuntu';
        div.style.fontWeight = rectStyle ? rectStyle.fontWeight : 'normal';
        div.style.fontSize = rectStyle ? rectStyle.fontSize : '13px';

        div.style.width = (optionsWidth - 2 - scrollBarSize) + 'px';
        div.style.padding = '8px';
        div.style.lineHeight = rectStyle ? rectStyle.lineHeight : '1.42857143';
        div.style.verticalAlign = 'middle';

        document.body.appendChild(div);
        let size = div.offsetHeight || 35;
        //totalSize += size;
        document.body.removeChild(div);

        return size;
    };

    // TODO: move customOptionRenderer to option renderer lib with other option renderers to be used
    customOptionRenderer = ({ focusedOption, focusedOptionIndex, focusOption, key, labelKey, option, options, selectValue, style, valueArray },) => {


        let query = {}, selected;

        if (labelKey) {
            query[labelKey] = option[labelKey];
            selected = _.findIndex(valueArray, query) > -1;
        } else {
            selected = false;
        }

        // Mark select all option if all values are selected
        if (this.props.multi && this.props.allowSelectAll && this.props.options.length === valueArray.length) {
            selected = true;
        }

        let customStyle = Object.assign({}, style);

        customStyle.borderBottom = '1px solid #e0e0e0';
        customStyle.padding = 8;
        customStyle.verticalAlign = 'middle';

        if (_.isEqual(focusedOption, option)) {
            customStyle.backgroundColor = '#F5F5F5';
        }

        return (
            <div
                key={key}
                onTouchStart={() => selectValue(option)}
                onClick={() => selectValue(option)}
                onMouseOver={() => focusOption(option)}
                style={customStyle}
            >
                <span>
                    {this.props.multi ? (
                        <input type="checkbox" checked={selected} onChange={()=>{}} style={{ margin: '5px' }}/>
                    ) : null}
                    {option[labelKey]}
                </span>
            </div>
        )
    };

    render() {
        let optionHeight = this.props.optionHeight ? this.props.optionHeight : this.props.defaultOptionHeight;

        return (
            <VirtualizedSelect
                ref="select"
                value={this.props.value}
                options={this.props.allowSelectAll ? [this.props.allOption, ...this.props.options] : this.props.options}
                multi={this.props.multi}
                allowSelectAll={this.props.allowSelectAll}
                removeSelected={this.props.removeSelected}
                scrollMenuIntoView={this.props.scrollMenuIntoView}
                onChange={(selected)=> {
                    if (selected && selected.length > 0 && selected[selected.length - 1].value === this.props.allOption.value) {
                        if (this.props.value.length === this.props.options.length) {
                            return this.props.onChange([]);
                        } else {
                            return this.props.onChange(this.props.options, 'all');
                        }
                    }
                    return this.props.onChange(selected);
                }}
                arrowRenderer={this.props.arrowRenderer}
                autoBlur={this.props.autoBlur}
                autoFocus={this.props.autoFocus}
                autosize={this.props.autosize}
                backspaceRemoves={this.props.backspaceRemoves}
                backspaceToRemoveMessage={this.props.backspaceToRemoveMessage}
                className={this.props.className}
                clearAllText={this.props.clearAllText}
                clearRenderer={this.props.clearRenderer}
                clearValueText={this.props.clearValueText}
                clearable={this.props.clearable}
                closeOnSelect={this.props.closeOnSelect}
                deleteRemoves={this.props.deleteRemoves}
                delimiter={this.props.delimiter}
                disabled={this.props.disabled}
                escapeClearsValue={this.props.escapeClearsValue}
                filterOption={this.props.filterOption}
                filterOptions={this.props.filterOptions}
                id={this.props.id}
                ignoreAccents={this.props.ignoreAccents}
                ignoreCase={this.props.ignoreCase}
                inputProps={this.props.inputProps}
                inputStyle={this.props.inputStyle}
                inputRenderer={this.props.inputRenderer}
                instanceId={this.props.instanceId}
                isLoading={this.props.isLoading}
                joinValues={this.props.joinValues}
                labelKey={this.props.labelKey}
                matchPos={this.props.matchPos}
                matchProp={this.props.matchProp}
                menuBuffer={this.props.menuBuffer}
                menuContainerStyle={this.props.menuContainerStyle}
                menuRenderer={this.props.menuRenderer}
                menuStyle={this.props.menuStyle}
                name={this.props.name}
                noResultsText={this.props.noResultsText}
                onBlur={this.props.onBlur}
                onBlurResetsInput={this.props.onBlurResetsInput}
                onClose={this.props.onClose}
                onCloseResetsInput={this.props.onCloseResetsInput}
                onFocus={this.props.onFocus}
                onInputChange={this.props.onInputChange}
                onInputKeyDown={this.props.onInputKeyDown}
                onMenuScrollToBottom={this.props.onMenuScrollToBottom}
                onOpen={this.props.onOpen}
                openOnClick={this.props.openOnClick}
                openOnFocus={this.props.openOnFocus}
                optionClassName={this.props.optionClassName}
                pageSize={this.props.pageSize}
                placeholder={this.props.placeholder}
                required={this.props.required}
                resetValue={this.props.resetValue}
                searchable={this.props.searchable}
                simpleValue={this.props.simpleValue}
                style={this.props.style}
                tabIndex={this.props.tabIndex}
                tabSelectsValue={this.props.tabSelectsValue}
                valueKey={this.props.valueKey}
                valueRenderer={this.props.valueRenderer}
                wrapperStyle={this.props.wrapperStyle}
                isOpen={this.props.isOpen}
                optionRenderer={this.props.optionRenderer ? this.props.optionRenderer : this.customOptionRenderer}
                optionHeight={({ option }) => { return this.props.optionsWidth || this.props.parentId ? this.getTextHeight(option[this.props.labelKey], this.props.fontData) : optionHeight }}
                valueComponent={this.props.multi ? conf => {
                    const { id, children } = conf;
                    let valueToReturn = null;
                    let query = {};
                    query[this.props.labelKey] = children[0];
                    let index = _.findIndex(this.props.value, query);

                    if (this.props.value.length <= this.props.maxVisibleSelectedOptions) {
                        if (index === this.props.value.length - 1) {
                            valueToReturn = (
                                <div id={id}
                                     style={{ display: 'inline-block', padding: 8 }}
                                >
                                    {children}
                                </div>
                            );
                        } else if (index >= 0 && index < this.props.maxVisibleSelectedOptions) {
                            valueToReturn = (
                                <div id={id}
                                     style={{ display: 'inline-block', padding: 8 }}
                                >
                                    {children},
                                </div>
                            );
                        } else if (index === this.props.maxVisibleSelectedOptions) {
                            valueToReturn = (
                                <div id={id}
                                     style={{ display: 'inline-block', padding: 8}}
                                >
                                    {children}
                                </div>
                            );
                        } else {
                            valueToReturn = (<span></span>);
                        }
                    } else {
                        if (index === 0) {
                            valueToReturn = (
                                <div id={id}
                                     style={{ display: 'inline-block', padding: 8 }}
                                >
                                    {this.props.value.length === this.props.options.length ? 'Todos los valores' : this.props.selectedOptionsMaxPlaceholder}
                                </div>
                            );
                        } else {
                            valueToReturn = (<span></span>);
                        }
                    }

                    return valueToReturn;
                } : this.props.valueComponent}
            />
        );
    }
}

CustomVirtualizedSelect.defaultProps = {
    maxVisibleSelectedOptions: 50,
    selectedOptionsMaxPlaceholder: 'Varios valores',
    defaultOptionHeight: 35,
    fontData: {
        fontFamily: 'Ubuntu',
        fontSize: '13px',
        fontWeight: 'normal'
    },
    placeholder: '',
    allOption: {
        name: 'Todos los valores',
        value: '*'
    }
};

CustomVirtualizedSelect.propTypes = {
    addLabelText: PropTypes.string,       // placeholder displayed when you want to add a label on a multi-value input
    'aria-label': PropTypes.string,       // Aria label (for assistive tech)
    'aria-labelledby': PropTypes.string,	// HTML ID of an element that should be used as the label (for assistive tech)
    arrowRenderer: PropTypes.func,		// Create drop-down caret element
    autoBlur: PropTypes.bool,             // automatically blur the component when an option is selected
    autofocus: PropTypes.bool,            // autofocus the component on mount
    autosize: PropTypes.bool,             // whether to enable autosizing or not
    backspaceRemoves: PropTypes.bool,     // whether backspace removes an item if there is no text input
    backspaceToRemoveMessage: PropTypes.string,  // Message to use for screenreaders to press backspace to remove the current item - {label} is replaced with the item label
    className: PropTypes.string,          // className for the outer element
    clearAllText: PropTypes.any,          // title for the "clear" control when multi: true
    clearRenderer: PropTypes.func,        // create clearable x element
    clearValueText: PropTypes.any,        // title for the "clear" control
    clearable: PropTypes.bool,            // should it be possible to reset value
    deleteRemoves: PropTypes.bool,        // whether backspace removes an item if there is no text input
    delimiter: PropTypes.string,          // delimiter to use to join multiple values for the hidden field value
    disabled: PropTypes.bool,             // whether the Select is disabled or not
    escapeClearsValue: PropTypes.bool,    // whether escape clears the value when the menu is closed
    filterOption: PropTypes.func,         // method to filter a single option (option, filterString)
    filterOptions: PropTypes.any,         // boolean to enable default filtering or function to filter the options array ([options], filterString, [values])
    ignoreAccents: PropTypes.bool,        // whether to strip diacritics when filtering
    ignoreCase: PropTypes.bool,           // whether to perform case-insensitive filtering
    inputProps: PropTypes.object,         // custom attributes for the Input
    inputStyle: PropTypes.object,         // custom style for Input
    inputRenderer: PropTypes.func,        // returns a custom input component
    instanceId: PropTypes.string,         // set the components instanceId
    isLoading: PropTypes.bool,            // whether the Select is loading externally or not (such as options being loaded)
    joinValues: PropTypes.bool,           // joins multiple values into a single form field with the delimiter (legacy mode)
    labelKey: PropTypes.string,           // path of the label value in option objects
    matchPos: PropTypes.string,           // (any|start) match the start or entire string when filtering
    matchProp: PropTypes.string,          // (any|label|value) which option property to filter on
    menuBuffer: PropTypes.number,         // optional buffer (in px) between the bottom of the viewport and the bottom of the menu
    menuContainerStyle: PropTypes.object, // optional style to apply to the menu container
    menuRenderer: PropTypes.func,         // renders a custom menu with options
    menuStyle: PropTypes.object,          // optional style to apply to the menu
    multi: PropTypes.bool,                // multi-value input
    name: PropTypes.string,               // generates a hidden <input /> tag with this field name for html forms
    noResultsText: PropTypes.any,         // placeholder displayed when there are no matching search results
    onBlur: PropTypes.func,               // onBlur handler: function (event) {}
    onBlurResetsInput: PropTypes.bool,    // whether input is cleared on blur
    onChange: PropTypes.func,             // onChange handler: function (newValue) {}
    onClose: PropTypes.func,              // fires when the menu is closed
    onCloseResetsInput: PropTypes.bool,   // whether input is cleared when menu is closed through the arrow
    onFocus: PropTypes.func,              // onFocus handler: function (event) {}
    onInputChange: PropTypes.func,        // onInputChange handler: function (inputValue) {}
    onInputKeyDown: PropTypes.func,       // input keyDown handler: function (event) {}
    onMenuScrollToBottom: PropTypes.func, // fires when the menu is scrolled to the bottom; can be used to paginate options
    onOpen: PropTypes.func,               // fires when the menu is opened
    onValueClick: PropTypes.func,         // onClick handler for value labels: function (value, event) {}
    openAfterFocus: PropTypes.bool,       // boolean to enable opening dropdown when focused
    openOnFocus: PropTypes.bool,          // always open options menu on focus
    optionClassName: PropTypes.string,    // additional class(es) to apply to the <Option /> elements
    optionComponent: PropTypes.func,      // option component to render in dropdown
    optionRenderer: PropTypes.func,       // optionRenderer: function (option) {}
    options: PropTypes.array,             // array of options
    pageSize: PropTypes.number,           // number of entries to page when using page up/down keys
    placeholder: PropTypes.any,           // field placeholder, displayed when there's no value
    removeSelected: PropTypes.bool,       // whether the selected option is removed from the dropdown on multi selects
    required: PropTypes.bool,             // applies HTML5 required attribute when needed
    resetValue: PropTypes.any,            // value to use when you clear the control
    scrollMenuIntoView: PropTypes.bool,   // boolean to enable the viewport to shift so that the full menu fully visible when engaged
    searchable: PropTypes.bool,           // whether to enable searching feature or not
    simpleValue: PropTypes.bool,          // pass the value to onChange as a simple value (legacy pre 1.0 mode), defaults to false
    style: PropTypes.object,              // optional style to apply to the control
    tabIndex: PropTypes.string,           // optional tab index of the control
    tabSelectsValue: PropTypes.bool,      // whether to treat tabbing out while focused to be value selection
    value: PropTypes.any,                 // initial field value
    valueComponent: PropTypes.func,       // value component to render
    valueKey: PropTypes.string,           // path of the label value in option objects
    valueRenderer: PropTypes.func,        // valueRenderer: function (option) {}
    wrapperStyle: PropTypes.object,       // optional style to apply to the component wrapper
    optionHeight: PropTypes.any,          // Option height. Number or function
    maxVisibleSelectedOptions: PropTypes.number,   // Number of selected options visible in selected component before change value for placeholder
    selectedOptionsMaxPlaceholder: PropTypes.string,  // Placeholder to use when multiple values has been selected
    defaultOptionHeight: PropTypes.number,    // Default option height in pixels (can be overwritten)
    optionsWidth: PropTypes.number,       // options width. Needed to correctly calculate row height
    fontData: PropTypes.object,           // font data object. Needed to correctly calculate row height. Defaults to Ubuntu 13px normal
    isOpen: PropTypes.bool                // if true opens the menu list
};

export default CustomVirtualizedSelect;
