import { FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_ERROR, FORGOT_PASSWORD_CLEAN_ERROR } from '../../actions/auth';
import { Map } from "immutable";

const auth = (state, action = {}) => {
    state = state || Map({
        isFetching: false,
        success: false,
        error: false,
        errorCode: '',
        payload: null
    });

    let newState;

    switch (action.type) {
        case FORGOT_PASSWORD_REQUEST:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error
            });
            break;
        case FORGOT_PASSWORD_SUCCESS:
            newState = state.merge({
                isFetching: action.isFetching,
                success: action.success,
                error: action.error
            });
            break;
        case FORGOT_PASSWORD_ERROR:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            });
            break;
        case FORGOT_PASSWORD_CLEAN_ERROR:
            newState = state.merge({
                isFetching: false,
                success: false,
                error: false,
                errorCode: '',
                payload: null
            });
            break;
        default:
            newState = state;
    }

    return newState;
};

export default auth;
