import React, { Component } from 'react';
import { Checkbox } from "@blueprintjs/core";
import { isEmail } from 'validator';

import TextInput from '../components/text-input';
import Select from '../components/virtualized-select';

class UserCreateDialog extends Component {
    constructor(props){
        super(props);

        this.state = {
            nombre: '',
            apellidos: '',
            email: '',
            telefono: '',
            tipo: {
                tipo: -1, nombre: 'Selecciona tipo...'
            },
            cliente_id: {
                _id: -1, nombre: 'Selecciona cliente...'
            },
            password: '',
            verifyPassword: '',
            sendEmail: false,
            validEmail: true,
            validPassword: false,
            passwordMatches: false
        };

        this.onChangeInput = this.onChangeInput.bind(this);
    }

    onChangeInput(inputName, inputValue) {
        this.setState({
            [inputName]: inputValue,
            ...(inputName === 'email') ? { validEmail: isEmail(inputValue) } : {},
            ...(inputName === 'password') ? { validPassword: inputValue.length > 5 } : {},
            ...(inputName === 'password') ? { passwordMatches: inputValue === this.state.verifyPassword } : {},
            ...(inputName === 'verifyPassword') ? { passwordMatches: inputValue === this.state.password } : {}
        }, () => this.props.onChangeFormData(this.state));
    }

    render() {
        const { nombre, apellidos, email, telefono, tipo, cliente_id, password, verifyPassword, sendEmail, validEmail, validPassword, passwordMatches } = this.state;
        const { connectedUser } = this.props;

        const userTypeOptions = connectedUser && connectedUser.get('tipo') === 'administrador' ? [{
            tipo: -1, nombre: 'Selecciona tipo...'
        }, {
            tipo: 'cliente', nombre: 'Cliente'
        }, {
            tipo: 'gestor', nombre: 'Gestor'
        }, {
            tipo: 'administrador', nombre: 'Administrador'
        }] : [{
            tipo: -1, nombre: 'Selecciona tipo...'
        }, {
            tipo: 'cliente', nombre: 'Cliente'
        }];

        return (
            <div style={{ marginTop: 20 }}>
                <div className="row">
                    <div className="col-6">
                        <TextInput
                            value={nombre}
                            style={{ borderColor: '#ccc' }}
                            onChange={(event) => this.onChangeInput('nombre', event.target.value)}
                            labelText={'Nombre *'}
                        />
                    </div>
                    <div className="col-6">
                        <TextInput
                            value={apellidos}
                            style={{ borderColor: '#ccc' }}
                            onChange={(event) => this.onChangeInput('apellidos', event.target.value)}
                            labelText={'Apellidos'}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <TextInput
                            value={email}
                            style={{ borderColor: '#ccc' }}
                            onChange={(event) => this.onChangeInput('email', event.target.value)}
                            labelText={'Email *'}
                            helpText={validEmail ? 'Introduce un email válido' : null}
                            errorText={!validEmail ? 'Email no válido' : null}
                        />
                    </div>
                    <div className="col-6">
                        <TextInput
                            value={telefono}
                            style={{ borderColor: '#ccc' }}
                            onChange={(event) => this.onChangeInput('telefono', event.target.value)}
                            labelText={'Teléfono'}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 form-group form-group-default">
                        <div className="mb10" id="Tipo">Tipo *</div>
                        <Select
                            style={{}}
                            options={userTypeOptions}
                            value={tipo}
                            labelKey='nombre'
                            valueKey='tipo'
                            matchProp='tipo'
                            multi={false}
                            searchable={false}
                            clearable={false}
                            onChange={(valueSelected) => this.onChangeInput('tipo', valueSelected)}
                            parentId="Tipo"
                        />
                    </div>
                    {tipo.tipo === 'cliente' ? (
                    <div className="col-6 form-group form-group-default">
                        <div className="mb10" id="Cliente">Cliente *</div>
                        <Select
                            style={{}}
                            options={[{
                                _id: -1, nombre: 'Selecciona cliente...'
                            }].concat(this.props.customers.toJS())}
                            value={cliente_id}
                            labelKey='nombre'
                            valueKey='_id'
                            matchProp='_id'
                            multi={false}
                            searchable={false}
                            clearable={false}
                            onChange={(valueSelected) => this.onChangeInput('cliente_id', valueSelected)}
                            parentId="Cliente"
                        />
                    </div>
                    ) : null}
                </div>
                <div className="row">
                    <div className="col-6">
                        <TextInput
                            type={'password'}
                            value={password}
                            style={{ borderColor: '#ccc' }}
                            onChange={(event) => this.onChangeInput('password', event.target.value)}
                            labelText={'Contraseña *'}
                            helpText={validPassword ? 'Al menos 6 caracteres' : null}
                            errorText={!validPassword ? 'Contraseña demasiado corta' : null}
                        />
                    </div>
                    <div className="col-6">
                        <TextInput
                            type={'password'}
                            value={verifyPassword}
                            style={{ borderColor: '#ccc' }}
                            onChange={(event) => this.onChangeInput('verifyPassword', event.target.value)}
                            labelText={'Vuelve a introducir la contraseña *'}
                            helpText={passwordMatches ? 'Las contraseñas coinciden' : null}
                            errorText={!passwordMatches ? 'Las contraseñas no coinciden' : null}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div style={{ display: 'flex', height: 30 }}>
                            <Checkbox
                                checked={sendEmail}
                                onChange={(event) => this.onChangeInput('sendEmail', event.target.checked)}
                                label=""
                            />
                            <div>{`Enviar email al usuario`}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UserCreateDialog;