import {Map, fromJS, List} from "immutable";

import {
    GET_CUSTOMERS_SUCCESS, GET_CUSTOMERS_REQUEST, GET_CUSTOMERS_ERROR,
    CREATE_CUSTOMER_REQUEST, CREATE_CUSTOMER_SUCCESS, CREATE_CUSTOMER_ERROR,
    UPDATE_CUSTOMER_REQUEST, UPDATE_CUSTOMER_SUCCESS, UPDATE_CUSTOMER_ERROR,
    CHANGE_STATUS_CUSTOMER_REQUEST, CHANGE_STATUS_CUSTOMER_SUCCESS, CHANGE_STATUS_CUSTOMER_ERROR,
    DELETE_CUSTOMER_REQUEST, DELETE_CUSTOMER_SUCCESS, DELETE_CUSTOMER_ERROR,
} from '../actions/customers';

const customers = (state = Map({
  payload: List([]),
  isFetching: false,
  error: false
}), action) => {
  let newState, updatedPayload, index, payload = state.get('payload');
  switch (action.type) {

      // Requests
      case GET_CUSTOMERS_REQUEST:
      case CREATE_CUSTOMER_REQUEST:
      case UPDATE_CUSTOMER_REQUEST:
      case CHANGE_STATUS_CUSTOMER_REQUEST:
      case DELETE_CUSTOMER_REQUEST:
          newState = state.merge({
              isFetching: action.isFetching,
              error: action.error
          });
          return newState;

      // Success
      case GET_CUSTOMERS_SUCCESS:
          newState = state.merge({
              payload: fromJS(action.payload),
              isFetching: action.isFetching,
              error: action.error
          });
          return newState;

      case CREATE_CUSTOMER_SUCCESS:

          // Check if payload is a List (Array)
          if(List.isList(payload)) {
              updatedPayload = payload.push(fromJS(action.payload));

              // If payload is a Map the payload to be updated is action.payload itself
          } else if(Map.isMap(payload)) {
              updatedPayload = fromJS(action.payload);
          }

          newState = state.merge({
              isFetching: action.isFetching,
              error: action.error,
              created: action.created,
              payload: updatedPayload
          });
          return newState;

      case UPDATE_CUSTOMER_SUCCESS:
      case CHANGE_STATUS_CUSTOMER_SUCCESS:

          // Check if payload is a List (Array)
          if(List.isList(payload)) {

              // Find the index of the customer to be updated inside the List
              index = payload.findIndex(item => {
                  return item.get('_id') === action.payload._id;
              });

              // Update the payload list
              if (index !== -1) {
                  updatedPayload = payload.update(index, () => {
                      return fromJS(action.payload);
                  });
              }


              // If payload is a Map the payload to be updated is action.payload itself
          } else if(Map.isMap(state.get('payload'))) {
              updatedPayload = fromJS(action.payload);
          }
          newState = state
              .merge({
                  isFetching: action.isFetching,
                  error: action.error,
                  updated: action.updated,
                  payload: updatedPayload
              });
          return newState;
      case DELETE_CUSTOMER_SUCCESS:
          updatedPayload = state.get('payload').filter( customer => {
              return customer.get('_id') !== action.payload._id;
          });
          newState = state.merge({
              payload: updatedPayload,
              isFetching: action.isFetching,
              error: action.error
          });
          return newState;

      // Errors
      case GET_CUSTOMERS_ERROR:
      case CREATE_CUSTOMER_ERROR:
      case UPDATE_CUSTOMER_ERROR:
      case CHANGE_STATUS_CUSTOMER_ERROR:
      case DELETE_CUSTOMER_ERROR:
          newState = state.merge({
              isFetching: action.isFetching,
              error: action.error,
              errorMessage: action.errorMessage
          });
          return newState;
      default:
          return state;
  }
};

export default customers;
