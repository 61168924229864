import { Map, fromJS, List } from "immutable";
import {
    EMPLOYEES_REQUEST,
    EMPLOYEES_SUCCESS,
    EMPLOYEES_ERROR,
    EMPLOYEE_DETAILS_REQUEST,
    EMPLOYEE_DETAILS_SUCCESS,
    EMPLOYEE_DETAILS_ERROR
} from '../actions/employees';

const employees = (state = Map({
    employeesList: List([]),
    employeeDetails: Map({}),
    isFetching: false,
    error: false,
    errorMessage: ''
}), action) => {
    let newState;

    switch (action.type) {
        // Get Employees
        case EMPLOYEES_REQUEST:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case EMPLOYEES_SUCCESS:
            newState = state.merge({
                employeesList: fromJS(action.payload),
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case EMPLOYEES_ERROR:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            });
            return newState;

        // Get Employee Details
        case EMPLOYEE_DETAILS_REQUEST:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case EMPLOYEE_DETAILS_SUCCESS:
            newState = state.merge({
                employeeDetails: fromJS(action.payload),
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case EMPLOYEE_DETAILS_ERROR:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            });
            return newState;

        default:
            return state;
    }
};

export default employees;
