import { Map, fromJS } from "immutable";

import { GET_STATS_CUSTOMER_REQUEST, GET_STATS_CUSTOMER_SUCCESS, GET_STATS_CUSTOMER_ERROR,
         GET_STATS_CONTROL_PANEL_REQUEST, GET_STATS_CONTROL_PANEL_SUCCESS, GET_STATS_CONTROL_PANEL_ERROR } from '../actions/stats';

const initialState = fromJS({
    customer: {
        isFetching: false,
        error: false,
        payload: {}
    },
    controlPanel: {
        isFetching: false,
        error: false,
        payload: {}
    }
});

const stats = (state = initialState, action) => {
    let newState;

    switch (action.type) {
        case GET_STATS_CUSTOMER_REQUEST:
            newState = state.setIn(['customer', 'isFetching'], action.isFetching);
            return newState;

        case GET_STATS_CUSTOMER_SUCCESS:
            newState = state.mergeIn(['customer'], Map({
                isFetching: action.isFetching,
                payload: fromJS(action.payload)
            }));
            return newState;

        case GET_STATS_CUSTOMER_ERROR:
            newState = state.mergeIn(['customer'], Map({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            }));
            return newState;

        case GET_STATS_CONTROL_PANEL_REQUEST:
            newState = state.setIn(['controlPanel', 'isFetching'], action.isFetching);
            return newState;

        case GET_STATS_CONTROL_PANEL_SUCCESS:
            newState = state.mergeIn(['controlPanel'], Map({
                isFetching: action.isFetching,
                payload: fromJS(action.payload)
            }));
            return newState;

        case GET_STATS_CONTROL_PANEL_ERROR:
            newState = state.mergeIn(['controlPanel'], Map({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            }));
            return newState;

        default:
        return state;
    }
};

export default stats;
