import axios from 'axios';
import Auth from '../lib/auth';
import utilsLib from './utils.lib';

export const CREATE_SIGN_REQUEST = 'CREATE_SIGN_REQUEST';
export const CREATE_SIGN_SUCCESS = 'CREATE_SIGN_SUCCESS';
export const CREATE_SIGN_ERROR = 'CREATE_SIGN_ERROR';

// Create sign
export function createSign(docs_JSON) {
    return async (dispatch) => {
        try {
            dispatch(createSignRequest());

            const token = Auth.getLocalJwt();
            const res = await axios.post('/api/firma', { docs_JSON }, { headers: { 'Authorization': 'Bearer ' + token } });

            dispatch(utilsLib.response(res, createSignSuccess, createSignError));
        } catch (err) {
            dispatch(utilsLib.response(err, createSignError));
        }
    };
}

function createSignRequest() {
    return {
        type: CREATE_SIGN_REQUEST,
        isFetching: true
    };
}

function createSignSuccess (payload) {
    return {
        type: CREATE_SIGN_SUCCESS,
        isFetching: false,
        error: false,
        payload
    };
}

function createSignError(errorMessage) {
    return {
        type: CREATE_SIGN_ERROR,
        isFetching: false,
        error: true,
        errorMessage
    };
}
