import { combineReducers } from 'redux';

import session from './session';
import forgotPassword from './forgotPassword';
import checkToken from './checkToken';
import resetPassword from './resetPassword';

export default combineReducers({
    session,
    forgotPassword,
    checkToken,
    resetPassword
})