import React, { useEffect } from "react";
import { List, Map } from "immutable";
import { connect } from "react-redux";
import _ from 'lodash';

// Components
import VirtualizedTable from '../../components/virtualized-table';
import CustomIcon from '../../components/CustomIcon';
import FiltersArea from './filtersArea';

// Actions
import { getEmployees } from '../../actions/employees';
import { getCustomers } from "../../actions/customers";

const EmployeePage = (props) => {

    useEffect(() => {
        props.getCustomers();
    }, []);

    function prepareQuery(query) {
        props.getEmployees(query);
    };

    const tableData = props.employees && props.employees.toJS();

    // Employees List Area
    const tableSettings = {
        sortable: true,
        filterable: false,
        defaultSortFields: ['apellidos'],
        defaultSortDirections: ['asc'],
        height: 485,
        rowHeight: 54,
        strippedRows: false,
        rowStyle: {
            fontSize: 13
        }
    };

    const tableColumns = {
        codigo: {
            title: 'Código',
            columnType: 'function',
            dataField: 'trabajador_id',
            width: 40,
            style: { maxWidth: 100 },
            sortable: true,
            filterable: false,
            nodeContent: (employeeId) => {
                return (
                    <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                        <span
                            style={{ fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => props.history.push('/trabajadores/trabajador/' + employeeId)}
                        >{employeeId}</span>
                    </div>
                );
            }
        },
        documento_identificativo: {
            title: "NIF/NIE",
            columnType: 'text',
            dataField: 'documento_identificativo',
            width: 100,
            style: {},
            sortable: true,
            filterable: false
        },
        nombre: {
            title: "Nombre",
            columnType: 'text',
            dataField: 'nombre',
            width: 100,
            style: {},
            sortable: true,
            filterable: false
        },
        apellidos: {
            title: "Apellidos",
            columnType: 'text',
            dataField: 'apellidos',
            width: 100,
            style: {},
            sortable: true,
            filterable: false
        },
        clientes: {
            title: "Clientes",
            columnType: 'function',
            dataField: 'clientes',
            width: 400,
            style: {},
            sortable: true,
            filterable: false,
            nodeContent: (value) => {
                // match clientes ids with props customers _id and get nombre
                const customerNames = value.map(id => {
                    const customer = props.customers.find(customer => customer.get('_id') === id);
                    return customer ? customer.get('nombre') : null;
                });

                // filter null values
                return <span 
                    style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
                    title={customerNames.filter(Boolean).join('; ')}
                    >
                        {customerNames.filter(Boolean).join('; ')}
                    </span>
                ;
            }
        },
        estado: {
            title: "Estado",
            columnType: 'function',
            dataField: 'estado',
            width: 100,
            style: { maxWidth: 100 },
            sortable: true,
            filterable: false,
            nodeContent: (value) => {
                let status = value === 'activo' ? 'success' : value === 'inactivo' ? 'danger' : 'info';
                return (
                    <div style={{}}>
                        <span
                            className={'badge badge-' + status}
                            style={{
                                padding: '5px 8px',
                                fontSize: 12
                        }}>{_.capitalize(value)}</span>
                    </div>
                );
            }
        },
        actions: {
            title: 'Ver detalle',
            columnType: 'function',
            dataField: 'trabajador_id',
            width: 100,
            style: { maxWidth: 100 },
            sortable: false,
            filterable: false,
            nodeContent: (employeeId) => {
                return (
                    <div style={{display: 'flex'}}>
                        <CustomIcon
                            iconType="user"
                            size={20}
                            title="Perfil"
                            color="#183650"
                            style={{margin: 'auto'}}
                            onClick={() => props.history.push('/trabajadores/trabajador/' + employeeId)}
                        />
                    </div>
                );
            }
        }
    };

    return (
        <div>
            <FiltersArea 
                prepareQuery={prepareQuery}
                employeesIsFetching={props.employeesIsFetching}
                employeesFilterOptions={props.employeesFilterOptions.toJS()}
                customerFilterOptions={props.customers.toJS()}
            />
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <VirtualizedTable 
                            tableSettings={tableSettings}
                            tableColumns={tableColumns}
                            tableData={tableData}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

function mapStateToProps(state) {

    // Reducers
    const customersReducer = state.customers;
    const employeesReducer = state.employees;

    // Customers
    let customers = List([
        Map({
            _id: -1,
            nombre: 'Todos'
        })
    ]);
    (customersReducer.get('payload') || List([])).map(customer => {
        return customers = customers.insert(
            customers.size,
            customer
                .delete('cif')
                .delete('estado')
                .delete('fecha_actualizacion')
                .delete('fecha_creacion')
                .delete('__v')
                .delete('id')
        );
    });

    // Employees
    const employees = employeesReducer.get('employeesList') || List([]);
    const employeesIsFetching = employeesReducer.get('isFetching') || false;
    // Employees
    let employeesFilterOptions = List([
        Map({
            trabajador_id: -1,
            nombre: 'Todos'
        })
    ]);
    employees.map(employee => {
        return employeesFilterOptions = employeesFilterOptions.insert(
            employees.size,
            employee
                .delete('documento_identificativo')
                .delete('nombre')
                .delete('apellidos')
                .delete('email')
                .delete('telefono')
                .delete('clientes')
                .delete('estado')
        );
    });
    employeesFilterOptions = employeesFilterOptions.map(employee => {
        return employee.get('nombre') === 'Todos' ? employee : employee.set('nombre', employee.get('trabajador_id'));
    });

    return {
        customers, employees, employeesIsFetching, employeesFilterOptions
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getEmployees: (query) => dispatch(getEmployees(query)),
        getCustomers: () => dispatch(getCustomers())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeePage);
