import React, { Component}  from 'react';
import { NavLink } from 'react-router-dom';
import { Icon, Popover, PopoverInteractionKind, Position, Menu, MenuDivider, MenuItem } from "@blueprintjs/core";

// Help PDF document and Sign EXE application
import helpPdfDocument from '../assets/AyudaConectaFeniciaETT.pdf';
import signApplicationExecutable from '../assets/FirmaDigitalFeniciaETT.msi';

// Assets
import logo from '../assets/img/logo_fenicia.png';

const styles = {
    header: {
        display: 'flex',
        backgroundColor: '#fff',
        height: 60,
        padding: '0 20px',
        maxWidth: '100%',
        logo: {
            wrapper: {
                padding: '10px 0',
                marginRight: 20
            }
        },
        nav: {
            ul: {
                display: 'flex',
                flex: '80%',
                margin: 0,
                padding: 0,
                listStyle: 'none',
                maxWidth: 'calc(100% - 260px)'
            }
        },
        userIcon: {
            display: 'flex',
            alignSelf: 'flex-end',
            alignItems: 'center',
            height: 60,
            color: '#000',
            cursor: 'pointer',
            margin: 'auto'
        }
    }
};

class Header extends Component {

    render() {
        let { user } = this.props;

        const popoverContent = (
            <Menu>
                <MenuItem icon="id-number" onClick={ () => this.props.history.push('/perfil-usuario')} text="Mi perfil" />
                <MenuDivider />
                <MenuItem icon="log-out" onClick={ () => this.props.signout() } text="Salir" />
            </Menu>
        );

        return (
            <header style={styles.header}>
                <div style={styles.header.logo.wrapper}>
                    <NavLink to="/">
                        <img src={logo} alt="" height="40" />
                    </NavLink>
                </div>

                <ul style={styles.header.nav.ul}>
                    <li className="nav-item">
                        <NavLink to="/" activeClassName="active" exact>Mis CPD's</NavLink>
                    </li>
                    <li className="nav-item">
                        <a href={signApplicationExecutable} target="_blank">Descargar Agente</a>
                    </li>
                    <li className="nav-item">
                        <a href={helpPdfDocument} target="_blank">Ayuda</a>
                    </li>

                    {user.get('tipo') !== 'cliente' ? (
                        <li className="nav-item">
                            <NavLink to="/panel-control" activeClassName="active">Panel de Control</NavLink>
                        </li>
                    ) : null}

                    {user.get('tipo') === 'administrador' && (
                        <li className="nav-item">
                            <NavLink to="/trabajadores" activeClassName="active">Trabajadores</NavLink>
                        </li>
                    )}
                </ul>
                <div style={styles.header.userIcon}>
                    <Popover
                        content={popoverContent}
                        interactionKind={PopoverInteractionKind.CLICK}
                        position={Position.BOTTOM_RIGHT}
                    >
                        <Icon
                            title={false}
                            icon={'user'}
                            iconSize={26}
                        />
                    </Popover>
                </div>
            </header>
        );
    }
}

export default Header;