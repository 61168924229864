import React, {Component} from 'react';

// Components
import Select from '../components/virtualized-select';
import DateInput from '../components/date-input';
import moment from "moment/moment";

class CreateCpdDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedCustomerId: -1,
            selectedDelegationId: -1,
            selectedOfficeId: -1,
            selectedCenterId: -1,
            docDate: moment(),
            selectedFile: -1
        }
    }

    sendFormData() {
        this.props.onFormData({
            fdType: 'uploadCpd',
            cliente_id: this.state.selectedCustomerId,
            delegacion_id: this.state.selectedDelegationId,
            oficina_id: this.state.selectedOfficeId,
            centro_id: this.state.selectedCenterId,
            fecha_documento: this.state.docDate,
            cpd: this.state.selectedFile
        });
    }

    updateFiltersState(key, value) {
        switch (key) {
            case 'customerId':
                this.setState({
                    selectedCustomerId: value
                }, () => this.sendFormData());
                break;
            case 'delegationId':
                this.setState({
                    selectedDelegationId: value
                }, () => this.sendFormData());
                break;
            case 'officeId':
                this.setState({
                    selectedOfficeId: value
                }, () => this.sendFormData());
                break;
            case 'centerId':
                this.setState({
                    selectedCenterId: value
                }, () => this.sendFormData());
                break;
            case 'docDate':
                this.setState({
                    docDate: value.endOf('day')
                }, () => this.sendFormData());
                break;
            case 'file':
                this.setState({
                    selectedFile: value
                }, () => this.sendFormData());
                break;
            default:
                break;
        }
    }

    render() {
        // States
        const {
            selectedCustomerId,
            selectedDelegationId,
            selectedOfficeId,
            selectedCenterId,
            docDate,
            selectedFile
        } = this.state;

        // Props
        const { filters } = this.props;
        // Filters Area
        let officeFilterOptions =  filters.offices,
            centerFilterOptions = filters.centers;

        if (selectedDelegationId !== -1) {
            // Offices filter
            officeFilterOptions = officeFilterOptions.filter(office => {
                return office.delegacion_id === selectedDelegationId || office.oficina_id === -1;
            });
            // Centers filter
            centerFilterOptions = centerFilterOptions.filter(center => {
                return center.delegacion_id === selectedDelegationId || center.centro_id === -1;
            });
        }
        if (selectedOfficeId !== -1) {
            // Centers filter
            centerFilterOptions = centerFilterOptions.filter(center => {
                return center.oficina_id === selectedOfficeId || center.centro_id === -1;
            });
        }
        
        

        const styles = {
            uploadDoc: {
                display: 'flex',
                alignItems: 'center',
                padding: '15px 0',
                button: {
                    label: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 0,
                        cursor: 'pointer'
                    },
                    input: {
                        display: 'none'
                    }
                },
                selectedFileName: {
                    marginLeft: 10
                }
            },
            filtersArea: {
                wrapper: {
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    margin: '15px 0',
                    fontSize: 14
                },
                item: {
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: 20,
                    width: 250,
                    input: {
                        width: '100%'
                    }
                }
            }
        };
        const subtitleText = 'El CPD subido se encontrará en estado "Pendiente" y deberá estar firmado electrónicamente por FeniciaETT.';

        return (
            <div>
                <div className="mb15">
                    <span>{ subtitleText }</span>
                </div>
                <div style={styles.uploadDoc}>
                    <div
                        style={ styles.uploadDoc.button }
                        className="btn btn-custom btn-sm"
                    >
                        <label
                            style={ styles.uploadDoc.button.label }
                        >
                            <input
                                style={ styles.uploadDoc.button.input }
                                type="file"
                                onChange={(e) => this.updateFiltersState('file', e.target.files[0])}
                            />
                            <span>Seleccionar fichero</span>
                        </label>
                    </div>
                    <span style={ styles.uploadDoc.selectedFileName }>
                        { selectedFile === -1 ? 'No se ha seleccionado ningún fichero.' : selectedFile.name }
                    </span>
                </div>
                <div style={styles.filtersArea.wrapper}>
                    <div style={ styles.filtersArea.item }>
                        <div className="mb10 fw600" id="Cliente">Cliente</div>
                        <Select
                            style={styles.filtersArea.item.input}
                            options={ filters.customers }
                            value={selectedCustomerId}
                            labelKey='nombre'
                            valueKey='_id'
                            matchProp='nombre'
                            multi={false}
                            searchable={true}
                            clearable={false}
                            onChange={(selectedValue) => this.updateFiltersState('customerId', selectedValue._id)}
                            parentId="Cliente"
                        />
                    </div>
                    <div style={ styles.filtersArea.item }>
                        <div className="mb10 fw600">Fecha documento</div>
                        <DateInput
                            onChange={(selectedValue) => this.updateFiltersState('docDate', selectedValue)}
                            value={ docDate }
                            inputWrapperStyle={{height: 40}}
                            inputStyle={{height: 36, borderRadius: 0, border: '1px solid #ccc'}}
                            iconStyle={{top: 9, right: 12}}
                        />
                    </div>
                </div>
                <div style={styles.filtersArea.wrapper}>
                    <div style={ styles.filtersArea.item }>
                        <div className="mb10 fw600" id="Delegacion">Delegación</div>
                        <Select
                            style={styles.filtersArea.item.input}
                            options={ filters.delegations }
                            value={selectedDelegationId}
                            labelKey='nombre'
                            valueKey='delegacion_id'
                            matchProp='nombre'
                            multi={false}
                            searchable={true}
                            clearable={false}
                            onChange={(selectedValue) => this.updateFiltersState('delegationId', selectedValue.delegacion_id)}
                            parentId="Delegacion"
                        />
                    </div>
                    <div style={ styles.filtersArea.item }>
                        <div className="mb10 fw600" id="Oficina">Oficina</div>
                        <Select
                            style={styles.filtersArea.item.input}
                            options={ officeFilterOptions }
                            value={selectedOfficeId}
                            labelKey='nombre'
                            valueKey='oficina_id'
                            matchProp='nombre'
                            multi={false}
                            searchable={true}
                            clearable={false}
                            onChange={(selectedValue) => this.updateFiltersState('officeId', selectedValue.oficina_id)}
                            parentId="Oficina"
                        />
                    </div>
                    <div style={ styles.filtersArea.item }>
                        <div className="mb10 fw600" id="Centro">Centro</div>
                        <Select
                            style={styles.filtersArea.item.input}
                            options={ centerFilterOptions }
                            value={selectedCenterId}
                            labelKey='nombre'
                            valueKey='centro_id'
                            matchProp='nombre'
                            multi={false}
                            searchable={true}
                            clearable={false}
                            onChange={(selectedValue) => this.updateFiltersState('centerId', selectedValue.centro_id)}
                            parentId="Centro"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateCpdDialog;