import FileSaver from 'file-saver';

/**
 * Store the received blob as a file and downloads it on client device using FileSaver library
 * @param res, response given by superagent request
 */
export function saveFile(res) {
    let startIndex, endIndex, docName;

    // get the file name from header response
    if (res.headers['content-disposition'].indexOf('"') !== -1) {
        startIndex = res.headers['content-disposition'].indexOf('"') + 1;
        endIndex = res.headers['content-disposition'].length - 1;
    } else if (res.headers['content-disposition'].indexOf('=') !== -1) {
        startIndex = res.headers['content-disposition'].indexOf('=') + 1;
    } else {
        startIndex = res.headers['content-disposition'] = 0;
    }

    if (endIndex) {
        docName = res.headers['content-disposition'].substring(startIndex, endIndex);
    } else {
        docName = res.headers['content-disposition'].substring(startIndex);
    }
    FileSaver.saveAs(res.data, docName);
}