import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Grid, Column, Table, AutoSizer } from 'react-virtualized';
import Immutable from 'immutable';
import scrollbarSize from 'dom-helpers/util/scrollbarSize';
import _ from 'lodash';
import numeral from 'numeral';
import moment from 'moment';
import { grey, white } from "material-colors";

import * as styles from './virtualized-table-styles';

// Components
import DateInput from './date-input';
import CustomVirtualizedSelect from './virtualized-select.jsx';
import DraggableCell from './draggableCell.jsx';
import withDragDropContext from '../lib/dragdropcontext';

// Global vars
let globalTableHeight = 0;
let globalPrevIndex = -1;
let globalIsScrolling = false;
//let prevScroll = 0;

class VirtualizedTable extends PureComponent {

    constructor(props) {

        super(props);

        let { tableColumns, tableData } = props;

        let stateTableSettings, totalWidth = 0, headerFields = [], initialTableData, filters = {};

        if(tableData) {
            initialTableData = Immutable.Map.isMap(tableData) || Immutable.List.isList(tableData) ? tableData.toJS() : tableData;
        }

        // Merge tableSettings prop with default props
        stateTableSettings = _.merge({}, VirtualizedTable.defaultProps.tableSettings);
        stateTableSettings = _.merge(stateTableSettings, props.tableSettings);

        Object.keys(tableColumns).map((column, key) => {

            if (tableColumns[column].filterable) {

                if (tableColumns[column].columnType === 'number') {
                    filters[column] = {
                        type: tableColumns[column].columnType,
                        field: tableColumns[column].dataField instanceof Array ? tableColumns[column].dataField[tableColumns[column].filterableIndex] : tableColumns[column].dataField,
                        minValue: '',
                        maxValue: ''
                    };
                } else if (tableColumns[column].columnType === 'date') {
                    filters[column] = {
                        type: tableColumns[column].columnType,
                        field: tableColumns[column].dataField instanceof Array ? tableColumns[column].dataField[tableColumns[column].filterableIndex] : tableColumns[column].dataField,
                        minValue: moment(''),
                        maxValue: moment('')
                    };
                } else if (tableColumns[column].useListOfValues){

                    let listOfValuesData, uniqueArray;

                    if (tableColumns[column].listOfValues) {
                        listOfValuesData = tableColumns[column].listOfValues;
                    } else {

                        uniqueArray = _.uniqBy(initialTableData, tableColumns[column].dataField);
                        listOfValuesData = [];

                        for (let i = 0; i < uniqueArray.length; i++) {
                            listOfValuesData.push({
                                _id: i,
                                name: uniqueArray[i][column],
                                value: uniqueArray[i][column]
                            });
                        }
                    }

                    filters[column] = {
                        type: tableColumns[column].columnType,
                        field: tableColumns[column].dataField instanceof Array ? tableColumns[column].dataField[tableColumns[column].filterableIndex] : tableColumns[column].dataField,
                        value: '',
                        listOfValues: listOfValuesData
                    };
                } else {
                    filters[column] = {
                        type: tableColumns[column].columnType,
                        field: tableColumns[column].dataField instanceof Array ? tableColumns[column].dataField[tableColumns[column].filterableIndex] : tableColumns[column].dataField,
                        value: ''
                    };
                }

            }

            headerFields.push(column);
            totalWidth += tableColumns[column].width;
            return null;
        });

        globalTableHeight = initialTableData.length * stateTableSettings.rowHeight;

        let sortFields = [];
        if (stateTableSettings.multiSortable) {
            for (let i = 0; i < stateTableSettings.defaultSortFields.length; i++) {

                let fieldName = stateTableSettings.defaultSortFields[i];
                let dataField, dataType;

                if (tableColumns[fieldName]) {
                    dataField = tableColumns[fieldName].dataField instanceof Array ? tableColumns[fieldName].dataField[tableColumns[fieldName].sortableIndex] : tableColumns[fieldName].dataField;
                    dataType = tableColumns[fieldName].columnType || '';
                } else {
                    dataField = fieldName;
                    dataType = '';
                }

                let sortFieldValue;
                switch(dataType) {
                    case 'text':
                        sortFieldValue = row => this.deepValue(row, dataField) ? this.deepValue(row, dataField).toLowerCase() : dataField;
                        break;
                    case 'date':
                        sortFieldValue = row => moment(this.deepValue(row, dataField)).format('YYYYMMDD');
                        break;
                    default:
                        sortFieldValue = dataField;
                }

                sortFields.push(sortFieldValue);
            }
        } else {

            let fieldName = stateTableSettings.defaultSortFields[0];
            let dataField, dataType;

            if (tableColumns[fieldName]) {
                dataField = tableColumns[fieldName].dataField instanceof Array ? tableColumns[fieldName].dataField[tableColumns[fieldName].sortableIndex] : tableColumns[fieldName].dataField;
                dataType = tableColumns[fieldName].columnType || '';
            } else {
                dataField = fieldName;
                dataType = '';
            }

            let sortFieldValue;
            switch(dataType) {
                case 'text':
                    sortFieldValue = row => this.deepValue(row, dataField) ? this.deepValue(row, dataField).toLowerCase() : dataField;
                    break;
                case 'date':
                    sortFieldValue = row => moment(this.deepValue(row, dataField)).format('YYYYMMDD');
                    break;
                default:
                    sortFieldValue = dataField;
            }

            sortFields.push(sortFieldValue);
        }

        this.updatedScroll = false;

        this.state = {
            tableSettings: stateTableSettings,
            totalWidth: totalWidth,
            tableHeader: headerFields,
            tableData: _.orderBy(initialTableData, sortFields, stateTableSettings.multiSortable ? stateTableSettings.defaultSortDirections : stateTableSettings.defaultSortDirections[0]),
            originalTableData: tableData instanceof Array ? tableData : tableData.toJS(),
            sortFields: sortFields,
            plainSortFields: stateTableSettings.multiSortable ? stateTableSettings.defaultSortFields : [stateTableSettings.defaultSortFields[0]],
            sortDirections: stateTableSettings.multiSortable ? stateTableSettings.defaultSortDirections : [stateTableSettings.defaultSortDirections[0]],
            headerHeight: stateTableSettings.headerHeight,
            filters: filters,
            rowCount: initialTableData.length,
            scrollBarSize: 0,
            filterRowHeight: stateTableSettings.filterRowHeight,
            draggingIndex: -1
        };

        this.setHeaderGrid = this.setHeaderGrid.bind(this);
        this.setFilterGrid = this.setFilterGrid.bind(this);
        this.setBodyTable = this.setBodyTable.bind(this);
        this.setFooterGrid = this.setFooterGrid.bind(this);
        this.deepValue = this.deepValue.bind(this);
        this.isNumeric = this.isNumeric.bind(this);
        this.sortTableData = this.sortTableData.bind(this);
        this.onChangeSortField = this.onChangeSortField.bind(this);
        this.filterTableData = this.filterTableData.bind(this);
        this.onChangeTextField = this.onChangeTextField.bind(this);
        this.calculateFooterOperation = this.calculateFooterOperation.bind(this);
        this.getData = this.getData.bind(this);
        this.getScrollWidth = this.getScrollWidth.bind(this);
        this.getHeaderRowHeight = this.getHeaderRowHeight.bind(this);
        this.getFilterRowHeight = this.getFilterRowHeight.bind(this);
        this.getRowHeight = this.getRowHeight.bind(this);
        this.getColumnWidth = this.getColumnWidth.bind(this);
        this.getTableRowStyle = this.getTableRowStyle.bind(this);
        this.isSortEnabled = this.isSortEnabled.bind(this);
        this.noRowsRenderer = this.noRowsRenderer.bind(this);
        this.renderCell = this.renderCell.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
        this.renderFilterRow = this.renderFilterRow.bind(this);
        this.renderFooter = this.renderFooter.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
        this.onMoveCell = this.onMoveCell.bind(this);
        this.updateRowHeight = this.updateRowHeight.bind(this);
        this.onScrollUpdate = this.onScrollUpdate.bind(this);
    }

    componentWillReceiveProps(nextProps) {

        let { tableColumns, tableData } = nextProps;

        let stateTableSettings, totalWidth = 0, headerFields = [], initialTableData, filters = {}, updatedState = {};

        // Check if table settings has changed
        if (!_.isEqual(nextProps.tableSettings, this.props.tableSettings)) {

            // Merge tableSettings prop with default props
            stateTableSettings = _.merge({}, VirtualizedTable.defaultProps.tableSettings);
            stateTableSettings = _.merge(stateTableSettings, nextProps.tableSettings);

            globalTableHeight = this.state.tableData.length * stateTableSettings.rowHeight;

            let sortFields = [];
            if (stateTableSettings.multiSortable) {
                for (let i = 0; i < stateTableSettings.defaultSortFields.length; i++) {

                    let fieldName = stateTableSettings.defaultSortFields[i];
                    let dataField, dataType;

                    if (tableColumns[fieldName]) {
                        dataField = tableColumns[fieldName].dataField instanceof Array ? tableColumns[fieldName].dataField[tableColumns[fieldName].sortableIndex] : tableColumns[fieldName].dataField;
                        dataType = tableColumns[fieldName].columnType || '';
                    } else {
                        dataField = fieldName;
                        dataType = '';
                    }

                    let sortFieldValue;
                    switch(dataType) {
                        case 'text':
                            sortFieldValue = row => this.deepValue(row, dataField) ? this.deepValue(row, dataField).toLowerCase() : dataField;
                            break;
                        case 'date':
                            sortFieldValue = row => moment(this.deepValue(row, dataField)).format('YYYYMMDD');
                            break;
                        default:
                            sortFieldValue = dataField;
                    }

                    sortFields.push(sortFieldValue);
                }
            } else {

                let fieldName = stateTableSettings.defaultSortFields[0];
                let dataField, dataType;

                if (tableColumns[fieldName]) {
                    dataField = tableColumns[fieldName].dataField instanceof Array ? tableColumns[fieldName].dataField[tableColumns[fieldName].sortableIndex] : tableColumns[fieldName].dataField;
                    dataType = tableColumns[fieldName].columnType || '';
                } else {
                    dataField = fieldName;
                    dataType = '';
                }

                let sortFieldValue;
                switch(dataType) {
                    case 'text':
                        sortFieldValue = row => this.deepValue(row, dataField) ? this.deepValue(row, dataField).toLowerCase() : dataField;
                        break;
                    case 'date':
                        sortFieldValue = row => moment(this.deepValue(row, dataField)).format('YYYYMMDD');
                        break;
                    default:
                        sortFieldValue = dataField;
                }

                sortFields.push(sortFieldValue);
            }

            updatedState.tableSettings = stateTableSettings;
            updatedState.tableData = _.orderBy(this.state.originalTableData, sortFields, stateTableSettings.multiSortable ? stateTableSettings.defaultSortDirections : stateTableSettings.defaultSortDirections[0])
            updatedState.sortFields = sortFields;
            updatedState.plainSortFields = stateTableSettings.multiSortable ? stateTableSettings.defaultSortFields : [stateTableSettings.defaultSortFields[0]];
            updatedState.sortDirections = stateTableSettings.multiSortable ? stateTableSettings.defaultSortDirections : [stateTableSettings.defaultSortDirections[0]];
            updatedState.headerHeight = stateTableSettings.headerHeight;
            updatedState.filterRowHeight = stateTableSettings.filterRowHeight;
        }

        // Check if table columns has changed
        if (!_.isEqual(nextProps.tableColumns, this.props.tableColumns)) {

            Object.keys(tableColumns).map((column, key) => {
                if (tableColumns[column].filterable) {

                    if (tableColumns[column].columnType === 'number') {
                        filters[column] = {
                            type: tableColumns[column].columnType,
                            field: tableColumns[column].dataField instanceof Array ? tableColumns[column].dataField[tableColumns[column].filterableIndex] : tableColumns[column].dataField,
                            minValue: '',
                            maxValue: ''
                        };
                    } else if (tableColumns[column].columnType === 'date') {
                        filters[column] = {
                            type: tableColumns[column].columnType,
                            field: tableColumns[column].dataField instanceof Array ? tableColumns[column].dataField[tableColumns[column].filterableIndex] : tableColumns[column].dataField,
                            minValue: moment(''),
                            maxValue: moment('')
                        };
                    } else if (tableColumns[column].useListOfValues){

                        let listOfValuesData, uniqueArray;

                        if (tableColumns[column].listOfValues) {
                            listOfValuesData = tableColumns[column].listOfValues;
                        } else {

                            uniqueArray = _.uniqBy(this.state.originalTableData, tableColumns[column].dataField);
                            listOfValuesData = [];

                            for (let i = 0; i < uniqueArray.length; i++) {
                                listOfValuesData.push({
                                    _id: i,
                                    name: uniqueArray[i][column]
                                });
                            }
                        }

                        filters[column] = {
                            type: tableColumns[column].columnType,
                            field: tableColumns[column].dataField instanceof Array ? tableColumns[column].dataField[tableColumns[column].filterableIndex] : tableColumns[column].dataField,
                            value: '',
                            listOfValues: listOfValuesData
                        };
                    } else {
                        filters[column] = {
                            type: tableColumns[column].columnType,
                            field: tableColumns[column].dataField instanceof Array ? tableColumns[column].dataField[tableColumns[column].filterableIndex] : tableColumns[column].dataField,
                            value: ''
                        };
                    }

                }

                headerFields.push(column);
                totalWidth += tableColumns[column].width;
                return null;
            });

            updatedState.totalWidth = totalWidth;
            updatedState.tableHeader = headerFields;
            updatedState.filters = filters;
        }

        // Check if table data has changed
        if (!_.isEqual(nextProps.tableData, this.props.tableData)) {

            if(tableData) {
                initialTableData = Immutable.Map.isMap(tableData) || Immutable.List.isList(tableData) ? tableData.toJS() : tableData;
            }

            globalTableHeight = initialTableData.length * this.state.tableSettings.rowHeight;

            updatedState.originalTableData = initialTableData;
            updatedState.tableData = _.orderBy(initialTableData, this.state.sortFields, this.state.sortDirections);
            updatedState.rowCount = initialTableData.length;
        }

        // If updatedState is not an empty object means something has changed and we need to update state to reflect those changes
        if (Object.keys(updatedState).length > 0 ) {
            this.setState(updatedState, this.getScrollWidth(updatedState.tableSettings ? updatedState.tableSettings.height : null));
        }
    }

    componentWillUpdate(nextProps, nextState) {

        // Force update when tableSettings has changed
        if (!_.isEqual(this.state.tableSettings, nextState.tableSettings)) {

            if (nextState.tableSettings.showHeader && this.headerGrid) {
                this.headerGrid.recomputeGridSize();
            }

            if (nextState.tableSettings.filterable && this.filterGrid) {
                this.filterGrid.recomputeGridSize();
            }

            if (this.bodyTable) {
                this.bodyTable.forceUpdateGrid();
                this.bodyTable.recomputeRowHeights();
            }

            if (nextState.tableSettings.showFooter && this.footerGrid) {
                this.footerGrid.recomputeGridSize();
            }
        }

        // Force update when sort has changed or tableColumns has changed
        if (!_.isEqual(this.state.sortFields, nextState.sortFields) || !_.isEqual(this.state.sortDirections, nextState.sortDirections) || !_.isEqual(this.props.tableColumns, nextProps.tableColumns)) {

            if (nextState.tableSettings.showHeader && this.headerGrid) {
                this.headerGrid.recomputeGridSize();
            }

            if (nextState.tableSettings.filterable && this.filterGrid) {
                this.filterGrid.recomputeGridSize();
            }

            if (this.bodyTable) {
                this.bodyTable.forceUpdateGrid();
                this.bodyTable.recomputeRowHeights();
            }

            if (nextState.tableSettings.showFooter && this.footerGrid) {
                this.footerGrid.recomputeGridSize();
            }
        }

        // Force update grids and table when tableHeader or data have changed
        if (!_.isEqual(this.state.tableHeader, nextState.tableHeader) || !_.isEqual(this.state.tableData, nextState.tableData)){

            if (nextState.tableSettings.showHeader && this.headerGrid) {
                this.headerGrid.recomputeGridSize();
            }

            if (nextState.tableSettings.filterable && this.filterGrid) {
                this.filterGrid.recomputeGridSize();
            }

            if (this.bodyTable) {
                this.bodyTable.forceUpdateGrid();
                this.bodyTable.recomputeRowHeights();
            }

            if (nextState.tableSettings.showFooter && this.footerGrid) {
                this.footerGrid.recomputeGridSize();
            }
        }
    }

    componentDidMount() {

        // Add events for resize and load, so we can automatically adjust row heights
        window.addEventListener('resize', _.debounce(this.updateDimensions, 200));
        window.addEventListener('load', this.updateDimensions);

        // Get first scroll width
        this.getScrollWidth();
    }

    componentDidUpdate() {
        this.getScrollWidth();
    }

    componentWillUnmount() {

        // Remove event listeners when unmount
        window.removeEventListener('resize', this.updateDimensions);
        window.removeEventListener('load', this.updateDimensions);
    }

    updateDimensions(scroll) {

        // Recalculate row heights when screen has been resized or table has scrolled
        if (this.headerGrid) {
            this.headerGrid.recomputeGridSize();
        }

        if (this.filterGrid) {
            this.filterGrid.recomputeGridSize();
        }

        if (this.bodyTable) {
            this.bodyTable.recomputeRowHeights();
        }
    }

    // Set refs for header, filter, table and footer
    setHeaderGrid(ref) {
        this.headerGrid = ref;
    }

    setFilterGrid (ref) {
        this.filterGrid = ref;
    }

    setBodyTable(ref) {
        this.bodyTable = ref;
    }

    setFooterGrid(ref) {
        this.footerGrid = ref;
    }

    // Local function to obtain deep object value based on path ('field.subfield')
    deepValue(obj, path) {
        const deepPath = path.split('.'),
            deepPathLen = deepPath.length;
        for (let i = 0; i < deepPathLen; i++) {
            if (obj && deepPath[i]) {
                obj = obj[deepPath[i]];
            }
        }
        return obj;
    }

    // Checks if a value is a number
    isNumeric(input) {
        // eslint-disable-next-line
        return (input - 0) == input && ('' + input).trim().length > 0;
    }

    getTextHeight(text, styleSettings) {

        // Function that creates a div with certain styles and measures it to obtain it's height
        let div = document.createElement('div');

        div.innerHTML = text;
        div.style.position = 'absolute';
        div.style.top = '-9999px';
        div.style.left = '-9999px';

        div.style.fontFamily = styleSettings.fontFamily;
        div.style.fontWeight = styleSettings.fontWeight;
        div.style.fontSize = styleSettings.fontSize;
        div.style.width = styleSettings.width;
        div.style.lineHeight = styleSettings.lineHeight;
        div.style.verticalAlign = styleSettings.verticalAlign;
        div.style.wordWrap = styleSettings.wordWrap;
        div.style.whiteSpace = styleSettings.whiteSpace;
        div.style.textAlign = styleSettings.textAlign;
        div.style.flex = styleSettings.flex;
        div.style.textOverflow = styleSettings.textOverflow;
        div.style.paddingLeft = styleSettings.paddingLeft;
        div.style.paddingRight = styleSettings.paddingRight;
        div.style.paddingTop = styleSettings.paddingTop;
        div.style.paddingBottom = styleSettings.paddingBottom;
        div.style.borderLeft = styleSettings.borderLeft;
        div.style.borderRight = styleSettings.borderRight;
        div.style.borderTop = styleSettings.borderTop;
        div.style.borderBottom = styleSettings.borderBottom;
        div.style.minWidth = styleSettings.minWidth;

        document.body.appendChild(div);
        let size = div.offsetHeight || 35;
        document.body.removeChild(div);

        return size;
    }

    getHeaderRowHeight ({ index }) {

        // Function that calculates header row height
        let { tableColumns } = this.props;
        let { tableHeader, tableSettings } = this.state;

        let currentCellHeight, maxCellHeight = 0;

        // For each column in the row, obtains it's computed style and then calls getTextHeight
        for (let i = 0; i < tableHeader.length; i++) {

            let fieldName = tableHeader[i];
            let dataField = tableColumns[fieldName].dataField instanceof Array ? tableColumns[fieldName].dataField[tableColumns[fieldName].sortableIndex] : tableColumns[fieldName].dataField;

            let rect =  document.getElementById('Header' + i + dataField);
            let rectStyle = rect ? window.getComputedStyle(rect) : null;
            let cellStyle = {};
            let iconWidth = _.findIndex(this.state.sortFields, function(o) { return o === dataField }) > -1 ? 31 : 0;
            let width = rectStyle ? parseFloat(rectStyle.width.replace('px', '')) : 0;

            if (rectStyle) {
                cellStyle.fontFamily = rectStyle.fontFamily;
                cellStyle.fontWeight = rectStyle.fontWeight;
                cellStyle.fontSize = rectStyle.fontSize;
                cellStyle.width = `${(width - iconWidth)}px`;
                cellStyle.lineHeight = rectStyle.lineHeight;
                cellStyle.verticalAlign = rectStyle.verticalAlign;
                cellStyle.wordWrap = rectStyle.wordWrap;
                cellStyle.whiteSpace = rectStyle.whiteSpace;
                cellStyle.textAlign = rectStyle.textAlign;
                cellStyle.flex = rectStyle.flex;
                cellStyle.textOverflow = rectStyle.textOverflow;
                cellStyle.paddingLeft = rectStyle.paddingLeft;
                cellStyle.paddingRight = rectStyle.paddingRight;
                cellStyle.paddingTop = '10px';
                cellStyle.paddingBottom = '10px';
                cellStyle.borderLeft = rectStyle.borderLeft;
                cellStyle.borderRight = rectStyle.borderRight;
                cellStyle.borderTop = rectStyle.borderTop;
                cellStyle.borderBottom = rectStyle.borderBottom;
                cellStyle.minWidth = `${(tableColumns[fieldName].width - tableSettings.rowStyle.paddingLeft - tableSettings.rowStyle.paddingRight)}px`;
            }

            currentCellHeight = Object.keys(cellStyle).length > 0 ? this.getTextHeight(tableColumns[fieldName].title, cellStyle) : tableSettings.headerHeight;

            // Row height will always be the biggest of it's cells so, if cell height is greater than current max, change that value with the new one
            if (currentCellHeight > maxCellHeight) {
                maxCellHeight = currentCellHeight;
            }
        }

        // Update state with headerHeight (height prop in Grid must be a number)
        this.setState({
            headerHeight: maxCellHeight > 0 && maxCellHeight >= tableSettings.rowHeight ? maxCellHeight : tableSettings.headerHeight
        });

        return maxCellHeight > 0 && maxCellHeight >= tableSettings.rowHeight ? maxCellHeight : tableSettings.headerHeight;
    }

    getFilterRowHeight ( { index }) {

        let { tableColumns } = this.props;
        let { tableHeader, tableSettings } = this.state;

        let filterRowHeight = tableSettings.filterRowHeight;

        // Filter row height defaults to 36px but grows to 72 if there are number or date filters (because both of them have two values)
        // If custom filterRowHeight has been specified in tableSettings and that value is bigger than 72, keep that value
        for (let i = 0; i < tableHeader.length; i++) {
            if ((tableColumns[tableHeader[i]].columnType === 'number' && tableColumns[tableHeader[i]].filterable) || (tableColumns[tableHeader[i]].columnType === 'date' && tableColumns[tableHeader[i]].filterable)) {
                filterRowHeight = filterRowHeight < 72 ? 72 : filterRowHeight;
                break;
            }
        }

        // Update state with filterRowHeight (height prop in Grid must be a number)
        if (filterRowHeight !== this.state.filterRowHeight) {
            this.setState({
                filterRowHeight: filterRowHeight
            });
        }

        return filterRowHeight;
    }

    getRowHeight ({ index }) {

        // Function that calculates table row height for a given index
        let { tableColumns } = this.props;
        let { tableHeader, tableData, tableSettings } = this.state;
        let maxLength = 0, maxDatafield = null, maxFieldName = null;
        let currentCellHeight, maxCellHeight = 0;

        if (globalIsScrolling) {
            return tableSettings.rowHeight;
        } else {
            // Code changed due to performance issues in tables with 10+ columns in Chrome, Safari (Firefox worked fine)
            // Obtain longest column in the row
            for (let i = 0; i< tableHeader.length; i++) {
                if (tableColumns[tableHeader[i]].columnType === 'text') {

                    let fieldName = tableHeader[i];
                    let dataField = tableColumns[fieldName].dataField instanceof Array ? tableColumns[fieldName].dataField[tableColumns[fieldName].sortableIndex] : tableColumns[fieldName].dataField;

                    if (_.get(tableData[index], dataField) && _.get(tableData[index], dataField).length > maxLength) {
                        maxLength = _.get(tableData[index], dataField).length;
                        maxDatafield = dataField;
                        maxFieldName = fieldName;
                    }
                }
            }

            if (maxLength > 0 && maxDatafield && maxFieldName) {

                let rect =  document.getElementById('Cell' + index + maxDatafield);
                let rectStyle = rect ? window.getComputedStyle(rect) : null;
                let cellStyle = {};

                if (rectStyle) {
                    cellStyle.fontFamily = rectStyle.fontFamily;
                    cellStyle.fontWeight = rectStyle.fontWeight;
                    cellStyle.fontSize = rectStyle.fontSize;
                    cellStyle.width = rectStyle.width;
                    cellStyle.lineHeight = rectStyle.lineHeight;
                    cellStyle.verticalAlign = rectStyle.verticalAlign;
                    cellStyle.wordWrap = rectStyle.wordWrap;
                    cellStyle.whiteSpace = rectStyle.whiteSpace;
                    cellStyle.textAlign = rectStyle.textAlign;
                    cellStyle.flex = rectStyle.flex;
                    cellStyle.textOverflow = rectStyle.textOverflow;
                    cellStyle.paddingTop = '10px';
                    cellStyle.paddingBottom = '10px';
                    cellStyle.borderLeft = rectStyle.borderLeft;
                    cellStyle.borderRight = rectStyle.borderRight;
                    cellStyle.borderTop = rectStyle.borderTop;
                    cellStyle.borderBottom = rectStyle.borderBottom;
                    cellStyle.minWidth = `${(tableColumns[maxFieldName].width - tableSettings.rowStyle.paddingLeft - tableSettings.rowStyle.paddingRight)}px`;
                }

                currentCellHeight = Object.keys(cellStyle).length > 0 ? this.getTextHeight(_.get(tableData[index], maxDatafield), cellStyle) : tableSettings.rowHeight;

                // Row height will always be the biggest of it's cells so, if cell height is greater than current max, change that value with the new one
                if (currentCellHeight > maxCellHeight) {
                    maxCellHeight = currentCellHeight;
                }
            }

            // If index is lower than globalPrevIndex means render has started again, so we set globalTableHeight to 0 again
            if (index <= globalPrevIndex) {
                globalTableHeight = 0;
            }

            globalPrevIndex = index;
            globalTableHeight += maxCellHeight > 0 && maxCellHeight >= tableSettings.rowHeight ? maxCellHeight : tableSettings.rowHeight;

            // If maxCellHeight is still smaller than the one defined in tableSettings, then return tableSettings value
            return maxCellHeight > 0 && maxCellHeight >= tableSettings.rowHeight ? maxCellHeight : tableSettings.rowHeight;
        }
    }

    getColumnWidth ({ index }){

        // Returns column width from tableColumns. If not specified, defaults to 40px.
        let { tableColumns } = this.props;
        let { tableHeader } = this.state;

        return tableColumns && tableHeader && tableColumns[tableHeader[index]] ? tableColumns[tableHeader[index]].width : 40;
    }

    getScrollWidth(height) {

        let size = 0;

        // If we are not using dynamic row heights, we have to recalculate global table height
        if (!this.state.tableSettings.useDynamicRowHeight) {
            globalTableHeight = this.state.tableData.length * this.state.tableSettings.rowHeight;
        }

        let tableHeight = height ? height : this.state.tableSettings.height;
        if (globalTableHeight > tableHeight) {
            size = scrollbarSize();
        }

        if (size !== this.state.scrollBarSize) {
            this.setState({
                scrollBarSize: size
            });
        }
    }

    getTableRowStyle ({ index }, width) {

        // Returns table row style
        let { totalWidth, tableSettings, scrollBarSize } = this.state;
        let rowStyle = _.cloneDeep(this.state.tableSettings.rowStyle || {});

        rowStyle.minWidth = totalWidth + scrollBarSize;
        rowStyle.width = width > totalWidth ? width : totalWidth + scrollBarSize;

        if (index < 0) {
            // Built in table header. We are not using it.
            return {};
        } else {
            if (tableSettings.strippedRows && index % 2 === 0){
                rowStyle.backgroundColor = grey[100];
                rowStyle.borderBottom = tableSettings.rowStyle.borderBottom;
                rowStyle.borderBottomColor = tableSettings.rowStyle.borderBottomColor;
            } else {
                rowStyle.backgroundColor = white;
                rowStyle.borderBottom = tableSettings.rowStyle.borderBottom;
                rowStyle.borderBottomColor = tableSettings.rowStyle.borderBottomColor;
            }

            // Conditional Style
            if (tableSettings.conditionalRowStyle && tableSettings.conditionalRowStyle.length > 0) {
                tableSettings.conditionalRowStyle.forEach((condition) => {

                    switch(condition.operation) {
                        case '=':
                            if (this.state.tableData[index][condition.dataField] === condition.value) {
                                rowStyle = _.merge(rowStyle, condition.style);
                            }
                            break;
                        case '!=':
                            if (this.state.tableData[index][condition.dataField] !== condition.value) {
                                rowStyle = _.merge(rowStyle, condition.style);
                            }
                            break;
                        case '<':
                            if (this.state.tableData[index][condition.dataField] < condition.value) {
                                rowStyle = _.merge(rowStyle, condition.style);
                            }
                            break;
                        case '<=':
                            if (this.state.tableData[index][condition.dataField] <= condition.value) {
                                rowStyle = _.merge(rowStyle, condition.style);
                            }
                            break;
                        case '>':
                            if (this.state.tableData[index][condition.dataField] > condition.value) {
                                rowStyle = _.merge(rowStyle, condition.style);
                            }
                            break;
                        case '>=':
                            if (this.state.tableData[index][condition.dataField] >= condition.value) {
                                rowStyle = _.merge(rowStyle, condition.style);
                            }
                            break;
                        default:
                            break;
                    }
                });
            }
        }

        return rowStyle;
    }


    getData (tableData, index) {

        // Returns tableData for a given index
        return tableData[index];
    }

    isSortEnabled () {

        return this.state.tableSettings.tableSortRowLimit > this.state.tableData.length;
    }

    sortTableData(tableData, sortFields, sortDirections) {

        // Returns sorted data by sort fields and sort directions
        return this.isSortEnabled() && this.state.tableSettings.sortable ? _.orderBy(tableData, sortFields, sortDirections) : tableData;
    }


    filterTableData(originalTableData, filters) {

        // Returns filtered data
        let { tableColumns } = this.props;
        let appliedFilters = {};

        // Keep only filters with value
        Object.keys(filters).map((col, key) => {
            switch(filters[col].type) {
                case 'text':
                case 'function':
                case 'boolean':
                case 'translate':
                    if (filters[col].value !== '') {
                        appliedFilters[col] = filters[col];
                    }
                    break;
                case 'date':
                case 'number':
                    if (filters[col].minValue !== '' || filters[col].maxValue !== '') {
                        appliedFilters[col] = filters[col];
                    }
                    break;
                default:
                    break;
            }
            return null;
        });

        // Only apply filters if at least one of them has value, otherwise return original table data
        if (Object.keys(appliedFilters).length > 0) {

            return _.filter(originalTableData, (row)=> {

                let include = true;

                // Check applied filters depending of its data type. If include it's false, means current row it's not returned
                Object.keys(appliedFilters).forEach((key)=> {

                    let fieldValue = _.get(row, appliedFilters[key].field) || '';
                    switch(appliedFilters[key].type) {
                        case 'text':
                        case 'function':
                            if (appliedFilters[key].listOfValues) {
                                if(fieldValue.toString().toLowerCase().indexOf(appliedFilters[key].value.value.toString().toLowerCase()) === -1) {
                                    include = false;
                                }
                            } else {
                                if(fieldValue.toString().toLowerCase().indexOf(appliedFilters[key].value.toString().toLowerCase()) === -1) {
                                    include = false;
                                }
                            }
                            break;
                        case 'number':
                            if (appliedFilters[key].minValue !== '') {
                                if (fieldValue < appliedFilters[key].minValue) {
                                    include = false;
                                }
                            }

                            if (appliedFilters[key].maxValue !== '') {
                                if (fieldValue > appliedFilters[key].maxValue) {
                                    include = false;
                                }
                            }
                            break;
                        case 'date':
                            if (appliedFilters[key].minValue.isValid()) {
                                if (appliedFilters[key].minValue.isSameOrAfter(fieldValue)) {
                                    include = false;
                                }
                            }

                            if (appliedFilters[key].maxValue.isValid()) {
                                if (appliedFilters[key].maxValue.isSameOrBefore(fieldValue)) {
                                    include = false;
                                }
                            }
                            break;
                        case 'boolean':
                            if (appliedFilters[key].listOfValues) {
                                if (fieldValue ? 'yes'.toString().toLowerCase().indexOf(appliedFilters[key].value.value.toLowerCase()) === -1 : 'no'.toString().toLowerCase().indexOf(appliedFilters[key].value.value.toLowerCase()) === -1) {
                                    include = false;
                                }
                            } else {
                                if (fieldValue ? 'yes'.toString().toLowerCase().indexOf(appliedFilters[key].value.toLowerCase()) === -1 : 'no'.toString().toLowerCase().indexOf(appliedFilters[key].value.toLowerCase()) === -1) {
                                    include = false;
                                }
                            }
                            break;
                        case 'translate':

                            let translatedFieldValue = '';
                            let translatedObject = _.find(tableColumns[key].translatedData, { original: fieldValue });

                            if (translatedObject && Object.keys(translatedObject).length > 0) {
                                translatedFieldValue = translatedObject.translated;
                            }

                            if(translatedFieldValue.toString().toLowerCase().indexOf(appliedFilters[key].value.toLowerCase()) === -1) {
                                include = false;
                            }
                            break;
                        default:
                            break;
                    }
                });

                if (include) {
                    return row;
                }
            });

        } else {
            return originalTableData;
        }
    }

    calculateFooterOperation(dataField, operation){

        // Returns calculated footer data
        let result = 0;
        if (dataField && operation && this.state.tableData.length > 0) {
            if (operation === 'count') {
                result = this.state.tableData.length;
            } else {
                let values = [];
                this.state.tableData.map(dataRow => {
                    values.push(Number(this.deepValue(dataRow, dataField)));
                    return null;
                });

                switch(operation) {
                    case 'sum':
                        result = _.sum(values);
                        break;
                    case 'avg':
                    case 'mean':
                        result = _.sum(values) / values.length;
                        break;
                    case 'min':
                        result = _.min(values);
                        break;
                    case 'max':
                        result = _.max(values);
                        break;
                    default:
                        break;
                }
            }
        }
        return result;
    }

    onChangeSortField(fieldName) {

        // Function that returns sorted data when a new sort has been applied
        let { tableColumns } = this.props;
        let { tableSettings } = this.state;

        let sortFields = _.cloneDeep(this.state.sortFields);
        let plainSortFields = _.cloneDeep(this.state.plainSortFields);
        let sortDirections = _.cloneDeep(this.state.sortDirections);

        let dataField = tableColumns[fieldName].dataField instanceof Array ? tableColumns[fieldName].dataField[tableColumns[fieldName].sortableIndex] : tableColumns[fieldName].dataField;
        let dataType = tableColumns[fieldName].columnType;
        let fieldIndex = _.findIndex(plainSortFields, (o)=> { return o === dataField; });

        let sortFieldValue;
        switch(dataType) {
            case 'text':
                sortFieldValue = row => this.deepValue(row, dataField) ? this.deepValue(row, dataField).toLowerCase() : dataField;
                break;
            case 'date':
                sortFieldValue = row => moment(this.deepValue(row, dataField)).format('YYYYMMDD');
                break;
            default:
                sortFieldValue = dataField;
        }

        if (tableColumns[fieldName].sortable) {
            if (tableSettings.multiSortable) {
                if (fieldIndex > -1) {

                    // Field already exists in sortFields array
                    switch (sortDirections[fieldIndex]) {
                        case 'asc':
                            // Change to desc direction
                            sortDirections[fieldIndex] = 'desc';
                            break;
                        case 'desc':
                            // Remove field from sortFields
                            sortFields.splice(fieldIndex, 1);
                            plainSortFields.splice(fieldIndex, 1);
                            sortDirections.splice(fieldIndex, 1);
                            break;
                        default:
                            break;
                    }
                } else {

                    // Field doesn't exists in sortFields array, so we have to add it with asc direction
                    sortFields.push(sortFieldValue);
                    plainSortFields.push(dataField);
                    sortDirections.push('asc');
                }
            } else {


                if (sortDirections[0] === 'asc' && plainSortFields[0] === dataField) {

                    sortDirections = [];
                    sortDirections.push('desc');

                } else {

                    sortFields = [];
                    sortFields.push(sortFieldValue);
                    plainSortFields = [];
                    plainSortFields.push(dataField);
                    sortDirections = [];
                    sortDirections.push('asc');
                }
            }

            let sortedData = this.sortTableData(this.filterTableData(this.state.originalTableData, this.state.filters), sortFields, sortDirections);
            this.props.onUpdate(sortFields, sortDirections, this.state.filters);

            this.setState({
                tableData: sortedData,
                sortFields: sortFields,
                plainSortFields: plainSortFields,
                sortDirections: sortDirections
            });
        }
    }

    onChangeTextField(field, valueType, e) {

        // Function that filters data when an input filter is applied (text, function, boolean, translate, number)
        let { filters, originalTableData, sortFields, sortDirections } = this.state;

        // Check if changed field is filterable
        if (filters.hasOwnProperty(field)) {
            let updatedFilters = _.cloneDeep(filters);

            switch(updatedFilters[field].type) {
                case 'text':
                case 'function':
                case 'boolean':
                case 'translate':
                    updatedFilters[field].value = e.target.value;
                    break;
                case 'number':
                    if (valueType === 'min' && (this.isNumeric(e.target.value) || e.target.value === '')) {
                        updatedFilters[field].minValue = e.target.value === '' ? e.target.value : parseFloat(e.target.value);
                    } else if (valueType === 'max' && (this.isNumeric(e.target.value) || e.target.value === '')) {
                        updatedFilters[field].maxValue = e.target.value === '' ? e.target.value : parseFloat(e.target.value);
                    }
                    break;
                default:
                    break;
            }

            // Filter data with new filters
            let filteredData = this.filterTableData(originalTableData, updatedFilters);

            // Sort filteredData according to actual sorting settings
            if (this.state.tableSettings.sortable) {
                filteredData = this.sortTableData(filteredData, sortFields, sortDirections);
            }

            //this.props.onUpdate(sortFields, sortDirections, updatedFilters);

            // There is a filter for the changed field
            this.setState({
                tableData: filteredData,
                filters: updatedFilters,
                rowCount: filteredData.length
            }, () => {

                if (this.state.tableSettings.showHeader) {
                    this.headerGrid.recomputeGridSize();
                }

                if (this.state.tableSettings.filterable) {
                    this.filterGrid.recomputeGridSize();
                }

                if (this.props.onRowCountChange) {
                    this.props.onRowCountChange(filteredData.length);
                }
                this.getScrollWidth();

            });
        }
    }

    onChangeDateField(field, valueType, date) {

        // Function that filters data when a date filter is applied
        let { filters, originalTableData, sortFields, sortDirections } = this.state;

        if (filters.hasOwnProperty(field)) {
            let updatedFilters = _.cloneDeep(filters);

            if (valueType === 'min') {
                updatedFilters[field].minValue = date;
            } else if (valueType === 'max') {
                updatedFilters[field].maxValue = date;
            }

            // Filter data with updated filters
            let filteredData = this.filterTableData(originalTableData, updatedFilters);

            // Sort filteredData according to actual sorting settings
            if (this.state.tableSettings.sortable) {
                filteredData = this.sortTableData(filteredData, sortFields, sortDirections);
            }

            //this.onUpdate(sortFields, sortDirections, updatedFilters);

            // There is a filter for the changed field
            this.setState({
                tableData: filteredData,
                filters: updatedFilters,
                rowCount: filteredData.length
            }, () => {

                if (this.state.tableSettings.showHeader) {
                    this.headerGrid.recomputeGridSize();
                }

                if (this.state.tableSettings.filterable) {
                    this.filterGrid.recomputeGridSize();
                }

                if (this.props.onRowCountChange) {
                    this.props.onRowCountChange(filteredData.length);
                }
                this.getScrollWidth();

            });
        }
    }

    onChangeSelectField(field, value) {

        // Function that filters data when a select filter is applied
        let { filters, originalTableData, sortFields, sortDirections } = this.state;

        if (filters.hasOwnProperty(field)) {

            let updatedFilters = _.cloneDeep(filters);

            if (value) {
                updatedFilters[field].value = value;
            } else {
                updatedFilters[field].value = '';
            }

            //updatedFilters[field].value = value.constructor === Array ? '' : value;

            // Filter data with updated filters
            let filteredData = this.filterTableData(originalTableData, updatedFilters);

            // Sort filteredData according to actual sorting settings
            if (this.state.tableSettings.sortable) {
                filteredData = this.sortTableData(filteredData, sortFields, sortDirections);
            }

            //this.props.onUpdate(sortFields, sortDirections, updatedFilters);

            // There is a filter for the changed field
            this.setState({
                tableData: filteredData,
                filters: updatedFilters,
                rowCount: filteredData.length
            }, () => {

                if (this.state.tableSettings.showHeader) {
                    this.headerGrid.recomputeGridSize();
                }

                if (this.state.tableSettings.filterable) {
                    this.filterGrid.recomputeGridSize();
                }

                if (this.props.onRowCountChange) {
                    this.props.onRowCountChange(filteredData.length);
                }
                this.getScrollWidth();

            });
        }
    }

    onMoveCell(newIndex, oldIndex) {

        // Function that changes tableHeader order when dropping a dragged element
        let tableHeader = _.cloneDeep(this.state.tableHeader);
        let columnToMove = tableHeader[oldIndex];

        tableHeader.splice(oldIndex, 1);
        tableHeader.splice(newIndex, 0, columnToMove);

        this.setState({
            tableHeader: tableHeader
        });
    }

    noRowsRenderer () {

        // Render when table data has no rows
        return (
            <div className="noRows">
                {/*{i18n.t('common.noDataForAppliedFiltersMessage')}*/}
                No hay datos para los filtros aplicados
            </div>
        );
    }

    renderCell ({ cellData, dataKey, rowData, rowIndex, isScrolling }, column, columnIndex, style) {

        // Function that renders table cells
        let { tableColumns } = this.props;
        let dataValue;
        let cellToRender;
        let field_type;

        if (tableColumns[column].dataField && tableColumns[column].dataField.constructor === Array) {
            dataValue = [];
            for (let i = 0; i < tableColumns[column].dataField.length; i++) {
                dataValue[i] = this.deepValue(rowData, tableColumns[column].dataField[i]);
            }
        } else {
            dataValue = this.deepValue(rowData, tableColumns[column].dataField);
        }

        field_type = tableColumns[column].field_type ? tableColumns[column].field_type:  'dimension';

        switch(tableColumns[column].columnType) {
            case 'date':

                if (dataValue && field_type === 'dimension') {
                    if (tableColumns[column].date_mask) {
                        cellToRender = dataValue ? moment(dataValue, tableColumns[column].date_mask).format(tableColumns[column].format || '') : '';
                    } else {
                        cellToRender = dataValue ? moment(dataValue).format(tableColumns[column].format || '') : '';
                    }
                } else {
                    cellToRender = dataValue;
                }
                break;
            case 'text':
                cellToRender = dataValue;
                break;
            case 'number':
                cellToRender = dataValue ? numeral(dataValue).format(tableColumns[column].format || '') : 0;
                break;
            case 'function':
                cellToRender = tableColumns[column].nodeContent(dataValue);
                break;
            case 'boolean':
                cellToRender = dataValue ? 'si' : 'no';
                break;
            case 'translate':
                let value = _.find(tableColumns[column].translatedData, { original: dataValue });
                cellToRender = value ? value.translated : '';
                break;
            default:
                break;
        }

        if (isScrolling && !globalIsScrolling && (columnIndex === this.state.tableHeader.length -1)) {
            globalIsScrolling = true;
        } else if (!isScrolling && globalIsScrolling && (columnIndex === this.state.tableHeader.length -1)) {
            globalIsScrolling = false;
            this.updateRowHeight();
        }

        let customStyle = this.getTableRowStyle({ index: rowIndex }, null);

        delete(customStyle.borderBottom);
        delete(customStyle.borderBottomColor);
        delete(customStyle.borderBottomWidth);
        delete(customStyle.borderBottomStyle);
        delete(customStyle.width);
        delete(customStyle.minWidth);

        if (tableColumns[column]) {

            if (tableColumns[column].style) {
                Object.keys(tableColumns[column].style).forEach(styleField => {
                    customStyle[styleField] = tableColumns[column].style[styleField];
                });
            }

            customStyle.display = 'flex';
            customStyle.flexDirection = 'column';
            customStyle.justifyContent = 'center';

            if (tableColumns[column].columnType === 'text') {
                customStyle.wordWrap = 'normal';
                if (this.state.tableSettings.useDynamicRowHeight) {
                    customStyle.whiteSpace = 'pre-line';
                } else {
                    customStyle.whiteSpace = 'nowrap';
                }
            }
        }

        // Search for border keys and delete them
        Object.keys(customStyle).forEach((key) => {
            if (key.indexOf('order') >= 0) {
                delete(customStyle[key]);
            }
        });

        // TODO: find a better solution for scrolling performance issues
        if (isScrolling && (this.state.tableData.length * Object.keys(this.props.tableColumns).length > 8000)) {
            return (
                <div id={'Cell' + rowIndex + dataKey}
                     key={rowIndex + dataKey}
                     style={{ ...customStyle, overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                    .....
                </div>
            );
        } else {
            return (
                <div id={'Cell' + rowIndex + dataKey}
                     key={rowIndex + dataKey}
                     style={{ ...customStyle, overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                    {cellToRender}
                </div>
            );
        }
    }

    renderHeader({ columnIndex, key, rowIndex, style }){

        // Function that renders header cells
        let { tableColumns } = this.props;
        let { tableHeader, tableSettings, plainSortFields, sortDirections } = this.state;

        let icon = null;
        let customStyle = Object.assign({}, style);
        customStyle = _.merge(customStyle, tableSettings.headerStyle);

        let isSortEnabled = this.isSortEnabled();
        let dataField = tableColumns[tableHeader[columnIndex]].dataField instanceof Array ? tableColumns[tableHeader[columnIndex]].dataField[tableColumns[tableHeader[columnIndex]].sortableIndex] : tableColumns[tableHeader[columnIndex]].dataField;

        customStyle.minWidth = tableColumns[tableHeader[columnIndex]] ? tableColumns[tableHeader[columnIndex]].width : 0;
        customStyle.maxWidth = tableColumns[tableHeader[columnIndex]] && tableColumns[tableHeader[columnIndex]].style ? tableColumns[tableHeader[columnIndex]].style.maxWidth : null;
        customStyle.cursor = isSortEnabled && tableSettings.sortable && tableColumns[tableHeader[columnIndex]].sortable ? 'pointer' : 'default';
        customStyle.textAlign = tableColumns[tableHeader[columnIndex]] && tableColumns[tableHeader[columnIndex]].style ? tableColumns[tableHeader[columnIndex]].style.textAlign : (customStyle.textAlign || null);

        if (tableColumns[tableHeader[columnIndex]] && tableColumns[tableHeader[columnIndex]].style && tableColumns[tableHeader[columnIndex]].style.paddingLeft) {
            customStyle.paddingLeft =  tableColumns[tableHeader[columnIndex]].style.paddingLeft;
        }
        if (tableColumns[tableHeader[columnIndex]] && tableColumns[tableHeader[columnIndex]].style && tableColumns[tableHeader[columnIndex]].style.paddingRight) {
            customStyle.paddingRight = tableColumns[tableHeader[columnIndex]].style.paddingRight;
        }

        if (!tableSettings.filterable) {
            customStyle.borderBottom = '1px solid';
            customStyle.borderBottomColor = grey[300];
        }

        let fieldIndex = _.findIndex(plainSortFields, function(o) { return o === dataField });

        // Render sort icons
        if (tableSettings.sortable && this.isSortEnabled())  {
            icon = sortDirections[fieldIndex] === 'desc' ? (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: customStyle.textAlign === 'right' ? 'row-reverse' : 'row' }}>
                    <i className="material-icons" style={{ height: 24, width: 24 }}>arrow_drop_down</i>
                    {tableSettings.multiSortable ? (
                        <span style={{ fontSize: 11 }}>
                        {fieldIndex + 1}
                    </span>
                    ) : null}
                </div>
            ) : sortDirections[fieldIndex] === 'asc' ? (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: customStyle.textAlign === 'right' ? 'row-reverse' : 'row' }}>
                    <i className="material-icons" style={{ height: 24, width: 24 }}>arrow_drop_up</i>
                    {tableSettings.multiSortable ? (
                        <span style={{ fontSize: 11 }}>
                        {fieldIndex + 1}
                    </span>
                    ) : null}
                </div>
            ) : null;
        }

        let justifyContentValue;
        switch (customStyle.textAlign) {
            case 'left':
                justifyContentValue = 'flex-start';
                break;
            case 'right':
                justifyContentValue = 'flex-end';
                break;
            case 'center':
                justifyContentValue = 'center';
                break;
            default:
                break;
        }

        let cellContent = (
            <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1, justifyContent: justifyContentValue, flexWrap: 'wrap', overflow: 'hidden' }}>
                {customStyle.textAlign === 'right' ? (icon) : null}
                <span style={{ display: 'block', overflow: 'hidden', textOverflow: 'ellipsis' }}>{tableColumns[tableHeader[columnIndex]] ? tableColumns[tableHeader[columnIndex]].title : null}</span>
                {customStyle.textAlign !== 'right' ? (icon) : null}
            </div>
        );

        if (tableSettings.isDraggable) {
            return (
                <DraggableCell
                    key={columnIndex + key}
                    id={columnIndex}
                    canDrag={true}
                    onDrag={(index)=> {this.onDrag(index, columnIndex)}}
                    moveCell={(originalIndex)=>{ this.onMoveCell(columnIndex, originalIndex)}}
                    hoverCell={()=> {}}
                    cellContent={(
                        <div
                            id={'Header' + columnIndex + dataField}
                            key={columnIndex + key}
                            style={customStyle}
                            onClick={isSortEnabled && tableColumns[tableHeader[columnIndex]].sortable ? ()=> this.onChangeSortField(tableHeader[columnIndex]) : null}
                        >
                            {cellContent}
                        </div>
                    )}
                />
            );
        } else {
            return (
                <div
                    id={'Header' + columnIndex + dataField}
                    key={columnIndex + key}
                    style={customStyle}
                    onClick={isSortEnabled && tableColumns[tableHeader[columnIndex]].sortable ? ()=> this.onChangeSortField(tableHeader[columnIndex]) : null}
                >
                    {cellContent}
                </div>
            );
        }
    }

    renderFilterRow ({ columnIndex, key, rowIndex, style }){

        // Function that renders filter row cells
        let { tableColumns } = this.props;
        let { tableHeader, tableSettings, filters } = this.state;

        let customStyle = Object.assign({}, style);
        let customInputStyle = Object.assign({}, styles.defaultInputStyle);
        let customSelectStyle = Object.assign({}, styles.defaultSelectStyle);
        customStyle = _.merge(customStyle, tableSettings.filterRowStyle);
        customStyle.minWidth = tableColumns[tableHeader[columnIndex]] ? tableColumns[tableHeader[columnIndex]].width : 0;
        customStyle.maxWidth = tableColumns[tableHeader[columnIndex]] && tableColumns[tableHeader[columnIndex]].style ? tableColumns[tableHeader[columnIndex]].style.maxWidth : null;

        // Get column width from header, and then apply custom padding
        let inputFilterRow = null;
        let dataField = tableColumns[tableHeader[columnIndex]].dataField instanceof Array ? tableColumns[tableHeader[columnIndex]].dataField[tableColumns[tableHeader[columnIndex]].sortableIndex] : tableColumns[tableHeader[columnIndex]].dataField;
        let element =  document.getElementById('Header' + columnIndex + dataField);
        let width = element ? element.offsetWidth - 2 : 80;

        if (tableColumns[tableHeader[columnIndex]] && tableColumns[tableHeader[columnIndex]].style && tableColumns[tableHeader[columnIndex]].style.paddingLeft) {
            width -= tableColumns[tableHeader[columnIndex]].style.paddingLeft;
        } else {
            width -= styles.defaultHeaderStyle.paddingLeft;
        }

        if (tableColumns[tableHeader[columnIndex]] && tableColumns[tableHeader[columnIndex]].style && tableColumns[tableHeader[columnIndex]].style.paddingRight) {
            width -= tableColumns[tableHeader[columnIndex]].style.paddingRight;
        } else {
            width -= styles.defaultHeaderStyle.paddingRight;
        }

        let selectWrapperStyle = {
            width: '100%',
            minWidth: customSelectStyle.minWidth,
            height: customSelectStyle.height,
            lineHeight: customSelectStyle.lineHeight
        };

        let selectDivStyle = {
            minWidth: customSelectStyle.minWidth,
            height: customSelectStyle.height,
            lineHeight: customSelectStyle.lineHeight,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end'
        };

        customStyle.textAlign = tableColumns[tableHeader[columnIndex]] && tableColumns[tableHeader[columnIndex]].style ? tableColumns[tableHeader[columnIndex]].style.textAlign : (customStyle.textAlign || null);
        customInputStyle.textAlign = customStyle.textAlign;

        // Assign input code to inputFilterRow only if column is filterable
        if (tableColumns[tableHeader[columnIndex]].filterable) {
            switch (tableColumns[tableHeader[columnIndex]].columnType) {
                case 'text':
                case 'boolean':
                case 'function':
                case 'translate':
                    if (tableColumns[tableHeader[columnIndex]].useListOfValues) {

                        inputFilterRow = (
                            <CustomVirtualizedSelect
                                value={filters[tableHeader[columnIndex]].value}
                                options={filters[tableHeader[columnIndex]].listOfValues}
                                onChange={(valueSelected) => this.onChangeSelectField(tableHeader[columnIndex], valueSelected)}
                                labelKey="name"
                                valueKey="value"
                                matchProp="name"
                                wrapperStyle={selectWrapperStyle}
                                style={selectDivStyle}
                                inputStyle={customSelectStyle}
                                clearable={true}
                                searchable={true}
                                deleteRemoves={true}
                                scrollMenuIntoView={false}
                                placeholder={''}
                                optionsWidth={customStyle.minWidth > width ? customStyle.minWidth : width}
                            />
                        );

                    } else {
                        inputFilterRow = (
                            <input
                                placeholder={'Buscar'}
                                style={customInputStyle}
                                onChange={this.onChangeTextField.bind(this, tableHeader[columnIndex], '')}
                                value={filters[tableHeader[columnIndex]].value}
                            />
                        );
                    }
                    break;
                case 'number':
                    inputFilterRow = (
                        <div>
                            <input
                                placeholder={'>='}
                                style={_.merge(Object.assign({}, customInputStyle), { marginBottom: 5 })}
                                onChange={this.onChangeTextField.bind(this, tableHeader[columnIndex], 'min')}
                                value={filters[tableHeader[columnIndex]].minValue}
                            />
                            <input
                                placeholder={'<='}
                                style={customInputStyle}
                                onChange={this.onChangeTextField.bind(this, tableHeader[columnIndex], 'max')}
                                value={filters[tableHeader[columnIndex]].maxValue}
                            />
                        </div>
                    );
                    break;
                case 'date':
                    inputFilterRow = (
                        <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, width: '100%'}}>
                            <DateInput
                                placeholder={'>='}
                                style={{ maxHeight: 30 }}
                                inputStyle={_.merge(Object.assign({}, customInputStyle), { marginBottom: 5 })}
                                iconStyle={{ height: 20, width: 20 }}
                                onChange={this.onChangeDateField.bind(this, tableHeader[columnIndex], 'min')}
                                value={filters[tableHeader[columnIndex]].minValue || null}
                            />
                            <DateInput
                                placeholder={'<='}
                                inputStyle={customInputStyle}
                                iconStyle={{ height: 20, width: 20 }}
                                onChange={this.onChangeDateField.bind(this, tableHeader[columnIndex], 'max')}
                                value={filters[tableHeader[columnIndex]].maxValue || null}
                            />
                        </div>
                    );
                    break;
                default:
                    break;
            }
        }

        return (
            <div
                id={'filterRow' + columnIndex}
                key={key}
                style={customStyle}
            >
                {inputFilterRow}
            </div>
        );
    }

    renderFooter({ columnIndex, key, rowIndex, style }){

        // Function that renders footer cells
        let { tableColumns  } = this.props;
        let { tableHeader, tableSettings } = this.state;

        let customStyle = Object.assign({}, style);
        customStyle = _.merge(customStyle, tableSettings.footerStyle);
        let dataField = tableColumns[tableHeader[columnIndex]].dataField instanceof Array ? tableColumns[tableHeader[columnIndex]].dataField[tableColumns[tableHeader[columnIndex]].sortableIndex] : tableColumns[tableHeader[columnIndex]].dataField;

        customStyle.minWidth = tableColumns[tableHeader[columnIndex]] ? tableColumns[tableHeader[columnIndex]].width : 0;
        customStyle.maxWidth = tableColumns[tableHeader[columnIndex]] && tableColumns[tableHeader[columnIndex]].style ? tableColumns[tableHeader[columnIndex]].style.maxWidth : null;
        customStyle.textAlign = tableColumns[tableHeader[columnIndex]] && tableColumns[tableHeader[columnIndex]].style ? tableColumns[tableHeader[columnIndex]].style.textAlign : (customStyle.textAlign || null);

        // Text Align using flex
        switch(customStyle.textAlign) {
            case 'left':
                customStyle.justifyContent = 'flex-start';
                break;
            case 'right':
                customStyle.justifyContent = 'flex-end';
                break;
            case 'center':
                customStyle.justifyContent = 'center';
                break;
            default:
                customStyle.justifyContent = 'flex-start';
                break;
        }

        if (tableColumns[tableHeader[columnIndex]] && tableColumns[tableHeader[columnIndex]].style && tableColumns[tableHeader[columnIndex]].style.paddingLeft) {
            customStyle.paddingLeft =  tableColumns[tableHeader[columnIndex]].style.paddingLeft;
        }
        if (tableColumns[tableHeader[columnIndex]] && tableColumns[tableHeader[columnIndex]].style && tableColumns[tableHeader[columnIndex]].style.paddingRight) {
            customStyle.paddingRight = tableColumns[tableHeader[columnIndex]].style.paddingRight;
        }

        return (
            <div
                key={key}
                style={customStyle}
            >
                <span style={{ display: 'block', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {tableColumns[tableHeader[columnIndex]] && tableColumns[tableHeader[columnIndex]].footer && tableColumns[tableHeader[columnIndex]].footer.type === 'text' ? tableColumns[tableHeader[columnIndex]].footer.value : null}
                    {tableColumns[tableHeader[columnIndex]] && tableColumns[tableHeader[columnIndex]].footer && tableColumns[tableHeader[columnIndex]].footer.type === 'number' ? numeral(tableColumns[tableHeader[columnIndex]].footer.value).format(tableColumns[tableHeader[columnIndex]].format) : null}
                    {tableColumns[tableHeader[columnIndex]] && tableColumns[tableHeader[columnIndex]].footer && tableColumns[tableHeader[columnIndex]].footer.type === 'operation' ? numeral(this.calculateFooterOperation(dataField, tableColumns[tableHeader[columnIndex]].footer.value)).format(tableColumns[tableHeader[columnIndex]].format) : null}
                </span>
            </div>
        );
    }

    onScrollUpdate({ clientHeight, scrollHeight, scrollTop }) {

        if (scrollTop === 0) {
            this.updateDimensions();
        }
    }

    updateRowHeight() {
        if (!globalIsScrolling && this.state.tableSettings.useDynamicRowHeight) {
            if (this.bodyTable.Grid._renderedRowStopIndex === this.state.tableData.length - 1) {
                this.bodyTable.recomputeRowHeights();
                this.bodyTable.scrollToRow(this.state.tableData.length -1);
            } else {
                this.updateDimensions();
            }
        }
    }

    render() {

        const NO_DATA_MESSAGE = 'No hay datos para los filtros seleccionados.';
        let { tableColumns, noDataMessage } = this.props;
        let { tableSettings, totalWidth, tableHeader, tableData, originalTableData, scrollBarSize } = this.state;

        let rowGetter = ({ index }) => this.getData(tableData, index);
        let dataTableColumns = [];

        // Data table columns
        for (let i = 0; i < tableHeader.length; i++) {
            let column = tableHeader[i];
            let customStyle = Object.assign({}, tableSettings.rowStyle);

            if (tableColumns[column]) {

                if (tableColumns[column].style) {
                    Object.keys(tableColumns[column].style).forEach(styleField => {
                        customStyle[styleField] = tableColumns[column].style[styleField];
                    });
                }

                customStyle.display = 'flex';
                customStyle.flexDirection = 'column';
                customStyle.justifyContent = 'center';

                if (tableColumns[column].columnType === 'text') {
                    customStyle.wordWrap = 'normal';
                    if (tableSettings.useDynamicRowHeight) {
                        customStyle.whiteSpace = 'pre-line';
                    } else {
                        customStyle.whiteSpace = 'nowrap';
                    }
                }

                delete(customStyle.borderBottom);
                delete(customStyle.borderBottomColor);

                if (i === tableHeader.length - 1) {
                    delete(customStyle.borderRight);
                }

                dataTableColumns.push(
                    <Column
                        key={i}
                        label={tableColumns[column].title}
                        dataKey={Array.isArray(tableColumns[column].dataField) ? tableColumns[column].dataField[0] : tableColumns[column].dataField}
                        disableSort={!this.isSortEnabled}
                        cellRenderer={(data) => this.renderCell(data, column, i, customStyle)}
                        style={customStyle}
                        flexGrow={1}
                        width={tableColumns[column].width}
                        minWidth={tableColumns[column].width}
                    />
                );
            }
        }

        if (originalTableData.length > 0) {

            return (
                <div style={{display: 'flex', flex: '1 1 auto', width: '100%', overflowX: 'auto'}}>
                    <AutoSizer disableHeight>
                        {({width}) => (
                            <div style={{minWidth: totalWidth + scrollBarSize}}>
                                {tableSettings.showHeader ? (
                                    <Grid
                                        ref={(ref) => this.setHeaderGrid(ref)}
                                        columnWidth={this.getColumnWidth}
                                        columnCount={tableHeader.length}
                                        height={this.state.headerHeight}
                                        overscanColumnCount={tableSettings.overscanColumnCount}
                                        cellRenderer={this.renderHeader}
                                        rowHeight={tableSettings.useDynamicHeaderRowHeight ? this.getHeaderRowHeight : tableSettings.headerHeight}
                                        rowCount={1}
                                        width={width > totalWidth ? width - scrollBarSize : totalWidth}
                                    />
                                ) : null}
                                {tableSettings.filterable ? (
                                    <Grid
                                        ref={(ref) => this.setFilterGrid(ref)}
                                        columnWidth={this.getColumnWidth}
                                        columnCount={tableHeader.length}
                                        height={this.state.filterRowHeight}
                                        overscanColumnCount={tableSettings.overscanColumnCount}
                                        cellRenderer={this.renderFilterRow}
                                        rowHeight={tableSettings.useDynamicFilterRowHeight ? this.getFilterRowHeight : tableSettings.filterRowHeight}
                                        rowCount={1}
                                        width={width > totalWidth ? width - scrollBarSize : totalWidth}
                                    />
                                ) : null}
                                <Table
                                    ref={(ref) => this.setBodyTable(ref)}
                                    disableHeader={true}
                                    noRowsRenderer={this.noRowsRenderer}
                                    overscanRowCount={tableSettings.overscanRowCount}
                                    rowHeight={tableSettings.useDynamicRowHeight ? this.getRowHeight : tableSettings.rowHeight}
                                    rowGetter={rowGetter}
                                    rowCount={tableData.length}
                                    width={width > totalWidth ? width : totalWidth + scrollBarSize}
                                    height={tableSettings.height}
                                    rowStyle={(data) => this.getTableRowStyle(data, width)}
                                    onScroll={tableSettings.useDynamicRowHeight ? _.debounce(this.onScrollUpdate, 250) : () =>{}}
                                    scrollToAlignment="end"
                                >
                                    {dataTableColumns}
                                </Table>
                                {tableSettings.showFooter ? (
                                    <Grid
                                        ref={(ref) => this.setFooterGrid(ref)}
                                        columnWidth={this.getColumnWidth}
                                        columnCount={tableHeader.length}
                                        height={tableSettings.footerHeight}
                                        overscanColumnCount={tableSettings.overscanColumnCount}
                                        cellRenderer={this.renderFooter}
                                        rowHeight={tableSettings.footerHeight}
                                        rowCount={1}
                                        width={width > totalWidth ? width - scrollBarSize : totalWidth}
                                    />
                                ) : null}
                            </div>
                        )}
                    </AutoSizer>
                </div>
            );
        } else if(originalTableData.length === 0) {
            return (
                <div className="pv15 text-center" style={{color: '#666'}}>
                    <span>{noDataMessage || NO_DATA_MESSAGE}</span>
                </div>
            );
        } else {
            return null;
        }
    }
}

VirtualizedTable.defaultProps = {
    tableSettings: {
        showHeader: true,
        showFooter: false,
        sortable: true,
        filterable: true,
        defaultSortFields: [],
        defaultSortDirections: [],
        multiSortable: false,
        tableSortRowLimit: 25000,
        headerStyle: styles.defaultHeaderStyle,
        filterRowStyle: styles.defaultFilterRowStyle,
        inputStyle: styles.defaultInputStyle,
        rowStyle: styles.defaultRowStyle,
        footerStyle: styles.defaultFooterStyle,
        headerHeight: 38,
        filterRowHeight: 38,
        rowHeight: 38,
        footerHeight: 48,
        useDynamicHeaderRowHeight: true,
        useDynamicFilterRowHeight: true,
        useDynamicRowHeight: true,
        height: 320,
        overscanRowCount: 10,
        overscanColumnCount: 10,
        strippedRows: true,
        isDraggable: false
    },
    onUpdate: () => {}
};

VirtualizedTable.propTypes = {
    tableSettings: PropTypes.object.isRequired,
    tableColumns: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]).isRequired,
    tableData: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]).isRequired,
    onRowCountChange: PropTypes.func,
    noDataMessage: PropTypes.string
};

export default withDragDropContext(VirtualizedTable);