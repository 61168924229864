import axios from 'axios';

import Auth from '../lib/auth';

export const ORG_STRUCTURE_REQUEST = 'ORG_STRUCTURE_REQUEST';
export const ORG_STRUCTURE_SUCCESS = 'ORG_STRUCTURE_SUCCESS';
export const ORG_STRUCTURE_ERROR = 'ORG_STRUCTURE_ERROR';

export function getOrgStructure() {
    return async dispatch => {
        try {
            const token = Auth.getLocalJwt();
            dispatch(getOrgStructureRequest());
            const res = await axios.get('/api/estructura-organizativa', { headers: { 'Authorization': 'Bearer ' + token } });
            if( res.data && res.data.status === 1) {
                dispatch(getOrgStructureSuccess(res.data.payload));
            } else {
                dispatch(getOrgStructureError(res.data.payload));
            }
        } catch (error) {
            dispatch(getOrgStructureError(error));
        }
    };
};

function getOrgStructureRequest() {
    return {
        type: 'ORG_STRUCTURE_REQUEST',
        isFetching: true
    };
}

function getOrgStructureSuccess (payload) {
    return {
        type: 'ORG_STRUCTURE_SUCCESS',
        isFetching: false,
        error: false,
        payload
    };
}

function getOrgStructureError(errorMessage) {
    return {
        type: 'ORG_STRUCTURE_ERROR',
        isFetching: false,
        error: true,
        errorMessage
    };
}