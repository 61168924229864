import {Map, fromJS, List} from "immutable";

import {
    AUDIT_QUERY_REQUEST, AUDIT_QUERY_SUCCESS, AUDIT_QUERY_ERROR,
} from '../actions/auditLog';

const auditLog = (state = Map({
    payload: List([]),
    isFetching: false,
    error: false
}), action) => {
    let newState;
    switch (action.type) {

        // Requests
        case AUDIT_QUERY_REQUEST:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;

        // Success
        case AUDIT_QUERY_SUCCESS:
            newState = state.merge({
                payload: fromJS(action.payload),
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;

        // Errors
        case AUDIT_QUERY_ERROR:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            });
            return newState;
        default:
            return state;
    }
};

export default auditLog;
