import React, { Component } from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import { List, Map } from "immutable";
import { Checkbox, Icon } from "@blueprintjs/core";
import Modal from '@atlaskit/modal-dialog';
import _ from 'lodash';

// Components
import Select from '../components/virtualized-select';
import DateInput from '../components/date-input';
import VirtualizedTable from '../components/virtualized-table';
import CustomIcon from '../components/CustomIcon';
import HomePageAttachsDialog from "../components/HomePageAttachsDialog";
import HomePageDocsDownloadDialog from "../components/HomePageDocsDownloadDialog";
import { attachTypes } from "../components/attachTypes";
import Loading from '../components/Loading';

// Actions
import { getOrgStructure } from "../actions/organizationalStructure";
import { getDocuments, cleanDocuments } from "../actions/documents";
import { downloadDocs, downloadCpd, downloadAtt, downloadPrr } from "../actions/content";
import { getCustomerStats } from "../actions/stats";
import { getCustomers } from "../actions/customers";
import { createSign } from "../actions/signs";

// Libs
import { prepareDocsJSON } from '../lib/sign-lib';
import { sortArray } from '../lib/sort-array';

// Styles
const styles = {
    headerPage: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20
    },
    statsArea: {
        wrapper: {
            display: 'flex',
            flexWrap: 'wrap',
            marginTop: 15,
            marginBottom: 15
        },
        item: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            padding: '8px 10px',
            marginRight: 10,
            marginBottom: 10,
            width: 225,
            fontSize: 14,
            indicator: {
                fontSize: 16
            }
        }
    },
    filtersArea: {
        wrapper: {
            display: 'flex',
            flexWrap: 'wrap',
            fontSize: 14,
            marginBottom: 15
        },
        item: {
            display: 'flex',
            flexDirection: 'column',
            width: 250,
            marginRight: 20,
            marginBottom: 15,
            input: {
                width: '250px'
            }
        },
        buttonItem: {
            display: 'flex',
            flexDirection: 'column',
            width: 100,
            marginBottom: 20,
            alignSelf: 'flex-end',
            button: {
                color: '#fff',
                padding: '4px 8px',
                borderRadius: 2
            }
        },
        loading: {
            display: 'flex',
            width: 50,
            paddingLeft: 15,
            flexDirection: 'column',
            marginBottom: 20,
            alignSelf: 'flex-end',
        }
    },
    tableHeader: {
        wrapper: {
            marginBottom: 15,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        totalDocs: {
            display: 'flex',
            alignItems: 'center',
            fontSize: 18,
            fontWeight: 'bold'
        },
        selectedDocs: {
            display: 'flex',
            alignItems: 'center',
            //backgroundColor: '#fff',
            padding: 10,
            fontWeight: 'bold',
            visibility: 'hidden',
            opacity: 0,
            transition: 'visibility 0.5s ease-in-out, opacity 0.5s ease-in-out'
        },
        buttonItem: {
            marginLeft: 15,
            color: '#fff'
        }
    },
    table: {
        wrapper: {
            backgroundColor: '#fff'
        }

    },
    modal: {
        subtitle: {
            display: 'flex',
            padding: '15px 0',
            fontSize: 16,
            fontWeight: 'bold',
            color: '#2F3862'
        }
    }
};
const iconSize = 20;
moment.locale('es');
class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedCustomer: {},
            selectedDelegationId: -1,
            selectedOfficeId: -1,
            selectedCenterId: -1,
            fromDate: moment().startOf('year'),
            toDate: moment().endOf('day'),
            selectedStateId: -1,
            allSelectedDocs: false,
            selectedDocs: new Set(),
            modalIsOpen: false,
            modalType: null,
            modalData: null,
            formData: null,
            includeAttachs: false
        };

        this.downloadAtt = this.downloadAtt.bind(this);
    }

    componentDidMount() {

        // Dispatch cleanDocuments
        this.props.cleanDocuments();

        // Dispatch get Organization Structure
        this.props.getOrgStructure();

        // Dispatch getCustomerStats when mounting only when user type is "cliente" or "gestor" (we get the first customer available)
        // Otherwise we need to get customers ("administrador", to make a selection)
        if(this.props.user) {
            if(this.props.user.get('tipo') === 'cliente' || this.props.user.get('tipo') === 'gestor') {

                // Get the first customer of cliente_id array
                let selectedCustomer = this.props.user.getIn(['cliente_id', 0, '_id']);

                if(selectedCustomer) {
                    this.setState({
                        selectedCustomer: selectedCustomer
                    }, () => this.props.getCustomerStats(selectedCustomer));
                }

            } else if(this.props.user.get('tipo') === 'administrador') {
                this.props.getCustomers();
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        // If new customers are received then we select the first customer and dispatch getCustomerStats
        if (this.props.customersPayload !== prevProps.customersPayload) {
            let selectedCustomer = this.props.customersPayload.getIn([0, '_id']);
            if(selectedCustomer) {
                this.setState({
                    selectedCustomer: selectedCustomer
                }, () => this.props.getCustomerStats(selectedCustomer));
            }
        }
    }

    componentWillUnmount() {
        // Dispatch cleanDocuments
        this.props.cleanDocuments();

    }

    // Control the state of allSelectedDocs and selectedDocs
    handleCheckbox(type, data) {
        let allSelectedDocs = this.state.allSelectedDocs, selectedDocs;
        switch (type) {
            case 'mainTableAll':
                selectedDocs = new Set();
                if (!allSelectedDocs) {
                    data.forEach( id => selectedDocs.add(id) );
                }
                allSelectedDocs = !allSelectedDocs;
                this.setState({
                    allSelectedDocs,
                    selectedDocs
                });
                break;
            case 'mainTable':
                selectedDocs = new Set(this.state.selectedDocs);
                selectedDocs.has(data) ? selectedDocs.delete(data) : selectedDocs.add(data);
                allSelectedDocs = selectedDocs.size === this.props.documents.count();
                this.setState({
                    allSelectedDocs,
                    selectedDocs
                });
                break;
            default:
                break;
        }
    };

    // Documents actions
    prepareQuery() {
        this.props.getDocuments({
            ...(this.state.selectedDelegationId !== -1) ? {delegacion_id: this.state.selectedDelegationId} : '',
            ...(this.state.selectedOfficeId !== -1) ? {oficina_id: this.state.selectedOfficeId} : '',
            ...(this.state.selectedCenterId !== -1) ? {centro_id: this.state.selectedCenterId} : '',
            fecha_desde: this.state.fromDate,
            fecha_hasta: this.state.toDate,
            ...(this.state.selectedCenterId !== -1) ? {centro_id: this.state.selectedCenterId} : '',
            ...(this.state.selectedStateId !== -1) ? {estado: this.state.selectedStateId} : '',
            cliente_id: this.state.selectedCustomer
        });
        this.setState({
            selectedDocs: new Set()
        });
    };

    downloadDocuments = () => {
        let cpdIds = [], prrIds = [], attachTypes;

        this.props.documents.map( doc => {
            if ([...this.state.selectedDocs].includes(doc.get('_id'))) {
                switch (doc.get('tipo_documento')) {
                    case 'cpd':
                        return cpdIds.push(doc.get('_id'));
                    case 'prr':
                        return prrIds.push(doc.get('_id'));
                    default:
                        return null;
                }
            } else {
                return null;
            }
        });

        attachTypes = (this.state.formData && this.state.formData.tipo_anexos) || [];

        this.props.downloadDocs(cpdIds, prrIds, this.state.includeAttachs, attachTypes);
    };

    downloadDoc(docType, docId, options) {
        switch (docType) {
            case 'cpd':
                this.props.downloadCpd(docId, options);
                break;
            case 'prr':
                this.props.downloadPrr(docId);
                break;
            default:
                break;
        }
    };

    downloadAtt(cpdId, attId) {
        this.props.downloadAtt(cpdId, attId);
    };

    // formData Handlers
    handleFormData = (formData) => {
        this.setState({
            formData
        }, () => this.validateFormData());
    };

    validateFormData() {
        switch (this.state.formData.fdType) {
            case 'includeAttachs':
                if (this.state.formData.tipo_anexos.length > 0) {
                    this.setState({
                        includeAttachs: true
                    });
                } else {
                    this.setState({
                        includeAttachs: false
                    });
                }
                break;
            default:
                this.setState({
                    includeAttachs: false
                });
                break;
        }
    };

    // Modal Handlers
    handleModal = (modalType, modalData) => {
        this.setState({
            modalType,
            modalIsOpen: true,
            modalData
        });
    };

    closeModal = () => {
        this.setState({
            modalType: null,
            modalIsOpen: false,
            modalData: null
        })
    };

    render() {
        // State
        let {
            selectedDelegationId,
            selectedOfficeId,
            selectedCenterId,
            selectedStateId,
            fromDate,
            toDate,
            allSelectedDocs,
            selectedDocs,
            modalIsOpen,
            modalType,
            modalData
        } = this.state;

        // Props
        let delegationFilterOptions = this.props.delegations.toJS(),
            officeFilterOptions = this.props.offices.toJS(),
            centerFilterOptions = this.props.centers.toJS(),
            stateFilterOptions = [
                {id: -1, estado: 'Todos'},
                {id: 'firmado', estado: 'Firmado'},
                {id: 'pendiente', estado: 'Pendiente'}
            ];

        // Stats Area
        const totalDocs = this.props.customerStatsPayload && this.props.customerStatsPayload.get('total');
        const signedDocs = this.props.customerStatsPayload && this.props.customerStatsPayload.get('signed');
        const pendingDocs = this.props.customerStatsPayload && this.props.customerStatsPayload.get('pending');
        const numDocsFound = this.props.documents.count();

        let statsZone = (
            <div style={styles.statsArea.wrapper}>
                <div className="badge badge-primary" style={{ ...styles.statsArea.item, backgroundColor: '#2F3862'}}>
                    <span>Total CPD’s y prórrogas</span>
                    <span style={styles.statsArea.item.indicator}>{totalDocs}</span>
                </div>
                <div className="badge badge-success" style={styles.statsArea.item}>
                    <span>Firmados</span>
                    <span style={styles.statsArea.item.indicator}>{signedDocs}</span>
                </div>
                <div className="badge badge-danger" style={styles.statsArea.item}>
                    <span>Pendientes de firma</span>
                    <span style={styles.statsArea.item.indicator}>{pendingDocs}</span>
                </div>
            </div>
        );
        // Customers Area
        let companyName = null;
        if(this.props.user) {
            if(this.props.user.get('tipo') === 'cliente') {
                companyName = (
                    <div>
                        <span>Empresa: </span><b>{this.props.user.getIn(['cliente_id', 0, 'nombre'])}</b>
                    </div>
                )
            } else {
                let options = [];

                if(this.props.user.get('tipo') === 'gestor') {
                    options = this.props.user.get('cliente_id') ? this.props.user.get('cliente_id').toJS() : [];
                } else if(this.props.user.get('tipo') === 'administrador') {
                    options = this.props.customersPayload.toJS();
                }

                // sort options by name
                options = sortArray(options, 'nombre');

                companyName = (
                    <div style={ {
                        display: 'flex',
                        alignItems: 'baseline'

                    } }>
                        <div className="fw600 mr15">Empresa</div>
                        <div id="Empresa">
                            <Select
                                style={styles.filtersArea.item.input}
                                options={options}
                                value={this.state.selectedCustomer}
                                labelKey='nombre'
                                valueKey='_id'
                                matchProp='nombre'
                                multi={false}
                                searchable={true}
                                clearable={false}
                                onChange={(selectedValue) => this.setState({
                                    selectedCustomer: selectedValue._id
                                }, () => this.props.getCustomerStats(selectedValue._id))}
                                parentId="Empresa"
                            />
                        </div>
                </div>);
            }
        }


        // Filters Area
        if (selectedDelegationId !== -1) {
            // Offices filter
            officeFilterOptions = officeFilterOptions.filter(office => {
                return office.delegacion_id === selectedDelegationId || office.oficina_id === -1;
            });
            // Centers filter
            centerFilterOptions = centerFilterOptions.filter(center => {
                return center.delegacion_id === selectedDelegationId || center.centro_id === -1;
            });
        }
        if (selectedOfficeId !== -1) {
            // Centers filter
            centerFilterOptions = centerFilterOptions.filter(center => {
                return center.oficina_id === selectedOfficeId || center.centro_id === -1;
            });
        }
        let filtersArea = (
            <div style={styles.filtersArea.wrapper}>
                <div style={styles.filtersArea.item}>
                    <div className="mb10 fw600" id="Delegacion">Delegación</div>
                    <Select
                        style={styles.filtersArea.item.input}
                        options={delegationFilterOptions}
                        value={selectedDelegationId}
                        labelKey='nombre'
                        valueKey='delegacion_id'
                        matchProp='nombre'
                        multi={false}
                        searchable={true}
                        clearable={false}
                        onChange={(selectedValue) => this.setState({
                            selectedDelegationId: selectedValue.delegacion_id
                        })}
                        parentId="Delegacion"
                    />
                </div>
                <div style={styles.filtersArea.item}>
                    <div className="mb10 fw600" id="Oficina">Oficina</div>
                    <Select
                        style={styles.filtersArea.item.input}
                        options={officeFilterOptions}
                        value={selectedOfficeId}
                        labelKey='nombre'
                        valueKey='oficina_id'
                        matchProp='nombre'
                        multi={false}
                        searchable={true}
                        clearable={false}
                        onChange={(selectedValue) => this.setState({
                            selectedOfficeId: selectedValue.oficina_id
                        })}
                        parentId="Oficina"
                    />
                </div>
                <div style={styles.filtersArea.item}>
                    <div className="mb10 fw600" id="Centro">Centro</div>
                    <Select
                        style={styles.filtersArea.item.input}
                        options={centerFilterOptions}
                        value={selectedCenterId}
                        labelKey='nombre'
                        valueKey='centro_id'
                        matchProp='nombre'
                        multi={false}
                        searchable={true}
                        clearable={false}
                        onChange={(selectedValue) => this.setState({
                            selectedCenterId: selectedValue.centro_id
                        })}
                        parentId="Centro"
                    />
                </div>
                <div style={ { ...styles.filtersArea.item, width: 150 } }>
                    <div className="mb10 fw600">Desde</div>
                    <DateInput
                        onChange={(selectedValue) => this.setState({
                            fromDate: selectedValue
                        })}
                        value={fromDate}
                        inputWrapperStyle={{height: 40}}
                        inputStyle={{height: 37, borderRadius: 0, border: '1px solid #ccc'}}
                        iconStyle={{top: 9, right: 12}}
                    />
                </div>
                <div style={ { ...styles.filtersArea.item, width: 150 } }>
                    <div className="mb10 fw600">Hasta</div>
                    <DateInput
                        onChange={(selectedValue) => this.setState({
                            toDate: selectedValue.endOf('day')
                        })}
                        value={toDate}
                        inputWrapperStyle={{height: 40}}
                        inputStyle={{height: 37, borderRadius: 0, border: '1px solid #ccc'}}
                        iconStyle={{top: 9, right: 12}}
                    />
                </div>
                <div style={{ ...styles.filtersArea.item, width: 125 }}>
                    <div className="mb10 fw600" id="Estado">Estado</div>
                    <Select
                        options={stateFilterOptions}
                        value={selectedStateId}
                        labelKey='estado'
                        valueKey='id'
                        matchProp='estado'
                        multi={false}
                        searchable={true}
                        clearable={false}
                        onChange={(selectedValue) => this.setState({
                            selectedStateId: selectedValue.id
                        })}
                        parentId="Estado"
                    />
                </div>
                <div style={styles.filtersArea.buttonItem}>
                    <button style={styles.filtersArea.buttonItem.button}
                            className="btn btn-custom"
                            onClick={() => {
                                this.prepareQuery();
                                this.props.getCustomerStats(this.state.selectedCustomer);
                            }}
                    >Buscar
                    </button>
                </div>
                <div style={styles.filtersArea.loading}>
                    <Loading
                        isFetching={this.props.documentsIsFetching}
                    />
                </div>
            </div>);

        // Documents List Area
        let tableSettings = {
            sortable: false,
            filterable: false,
            defaultSortFields: [],
            defaultSortDirections: ['asc'],
            height: 545,
            rowHeight: 50,
            strippedRows: false,
            conditionalRowStyle: [{
                dataField: 'tipo_documento',
                operation: '=',
                value: 'prr',
                style: {
                    backgroundColor: '#E8F1F7'
                }
            }],
            rowStyle: {
                fontSize: 13
            }
        };
        let tableData = this.props.documents.toJS();
        let tableColumns = {
            checkbox: {
                title: (
                    <Checkbox
                        checked={ allSelectedDocs }
                        onChange={() => this.handleCheckbox('mainTableAll', tableData.map( doc => doc._id))}
                        style={{ marginTop: 10 }}
                    />
                ),
                columnType: 'function',
                dataField: '_id',
                width: 50,
                style: { display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', maxWidth: 50 },
                sortable: false,
                filterable: false,
                nodeContent: value => {
                    return (
                        <div style={{display: 'flex'}}>
                            <Checkbox
                                checked={selectedDocs.has(value)}
                                onChange={() => this.handleCheckbox('mainTable', value)}
                                style={{ marginTop: 5 }}
                            />
                        </div>
                    );
                }
            },
            nombre: {
                title: "Nombre",
                columnType: 'text',
                dataField: 'nombre',
                width: 300,
                style: { fontWeight: 'bold' },
                sortable: false,
                filterable: false
            },
            delegacion: {
                title: "Delegación",
                columnType: 'text',
                dataField: 'delegacion.nombre',
                width: 100,
                style: {},
                sortable: false,
                filterable: false
            },
            oficina: {
                title: "Oficina",
                columnType: 'text',
                dataField: 'oficina.nombre',
                width: 100,
                style: {},
                sortable: false,
                filterable: false
            },
            centro: {
                title: "Centro",
                columnType: 'text',
                dataField: 'centro.nombre',
                width: 100,
                style: {},
                sortable: false,
                filterable: false
            },
            fecha_documento: {
                title: "Fecha Documento",
                columnType: 'function',
                dataField: 'fecha_documento',
                width: 200,
                style: { maxWidth: 200 },
                sortable: false,
                filterable: false,
                nodeContent: (value) => {
                    return moment(value).format('DD/MM/YYYY');
                }
            },
            fecha_firma: {
                title: "Fecha Firma",
                columnType: 'function',
                dataField: 'fecha_firma',
                width: 200,
                style: { maxWidth: 200 },
                sortable: false,
                filterable: false,
                nodeContent: (value) => {
                    return value ? moment(value).format('DD/MM/YYYY') : '--';
                }
            },
            estado: {
                title: "Estado",
                columnType: 'function',
                dataField: 'estado',
                width: 100,
                style: { maxWidth: 100 },
                sortable: false,
                filterable: false,
                nodeContent: (value) => {
                    let status = value === 'firmado' ? 'success' : value === 'pendiente' ? 'danger' : 'info';
                    return (
                        <div style={{}}>
                            <span
                                className={'badge badge-' + status}
                                style={{
                                    padding: '5px 8px',
                                    fontSize: 12
                                }}
                            >
                                {_.capitalize(value)}
                            </span>
                        </div>
                    );
                }
            },
            actions: {
                title: '',
                columnType: 'function',
                dataField: '_id',
                width: 140,
                style: { maxWidth: 140 },
                sortable: false,
                filterable: false,
                nodeContent: (docId) => {
                    const doc = tableData.find(doc => doc._id === docId);
                    const attachs = doc.anexos || [];
                    let formDownload;

                    if (doc.tipo_documento === 'cpd') {
                        if (attachs.length > 0) {
                            formDownload = (
                                <CustomIcon
                                    iconType="download"
                                    size={iconSize}
                                    counter={doc.descargas}
                                    title="Descargar"
                                    color="#183650"
                                    onClick={() => this.downloadDoc(doc.tipo_documento, docId, {
                                        incluir_anexos: true,
                                        tipo_anexos: ['CPD', 'CTR', 'RIN', 'EPI', 'RIC', 'RA', 'NIF', 'HUE', 'TRA', 'RM', 'FRM', 'FES', 'CTRP', 'CPDP']
                                    })}
                                />
                            )
                        } else {
                            formDownload = (
                                <CustomIcon
                                    iconType="download"
                                    size={iconSize}
                                    counter={doc.descargas}
                                    title="Descargar"
                                    color="#183650"
                                    onClick={() => this.downloadDoc(doc.tipo_documento, docId, {incluir_anexos: false})}
                                />
                            );
                        }
                    }

                    if (doc.tipo_documento === 'prr') {
                        formDownload = (
                            <CustomIcon
                                iconType="download"
                                size={iconSize}
                                counter={doc.descargas}
                                title="Descargar"
                                color="#183650"
                                onClick={() => this.downloadDoc(doc.tipo_documento, docId)}
                            />
                        );
                    }

                    return (
                        <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                            {formDownload}
                            <CustomIcon
                                iconType="paperclip"
                                size={iconSize}
                                counter={attachs.length}
                                hidden={attachs.length <= 0}
                                title="Anexos"
                                color="#183650"
                                onClick={this.handleModal.bind(this, 'attachsDialog', {
                                    attachs,
                                    doc: {
                                        id: docId,
                                        nombre: doc.nombre,
                                    }
                                })}
                            />
                            <CustomIcon
                                iconType="draw"
                                size={iconSize}
                                hidden={doc.estado === 'firmado'}
                                title="Firmar"
                                color="#183650"
                                onClick={this.props.createSign.bind(this, prepareDocsJSON(this.props.documents, new Set([docId])))}
                            />
                        </div>
                    );
                }
            }
        };

        // Modal Area
        let modal = null;

        if(modalIsOpen) {
            switch (modalType) {
                case 'attachsDialog':
                    modal = {
                        width: 'large',
                        title: 'Anexos del CPD',
                        body: (
                            <HomePageAttachsDialog
                                attachs={ modalData.attachs }
                                doc={ modalData.doc }
                                download={ this.downloadAtt }
                            />
                        ),
                        footer: (
                            <div className="p15 text-right">
                                <button
                                    key="close"
                                    type="button"
                                    className="btn btn-sm"
                                    label={'CERRAR'}
                                    style={{
                                        color: 'gray',
                                        marginRight: 15
                                    }}
                                    onClick={this.closeModal}
                                >
                                    {'CERRAR'}
                                </button>
                            </div>
                        )
                    };
                    break;
                case 'docsDownloadDialog':
                    const docsIds = [...this.state.selectedDocs];

                    const data = modalData.filter(doc => docsIds.find(id => id === doc._id))
                        .map(doc => {
                            const attachs = doc.anexos || [];
                            let docType;
                            switch (doc.tipo_documento) {
                                case 'cpd':
                                    docType = 'CPD';
                                    break;
                                case 'prr':
                                    docType = 'Prorroga';
                                    break;
                                default:
                                    docType = doc.tipo_documento;
                                    break;
                            }
                            return ({
                                nombre: doc.nombre,
                                tipo: docType,
                                anexos: attachs.length > 0 ? attachs.length : '-'
                            });
                        });

                    modal = {
                        width: 'large',
                        title: 'Descargar documentos',
                        body: (
                            <HomePageDocsDownloadDialog
                                tableData={ data }
                                attachTypes={ attachTypes }
                                onFormData={ this.handleFormData }
                            />
                        ),
                        footer: (
                            <div className="p15 text-right">
                                <button
                                    key="cancel"
                                    type="button"
                                    className="btn btn-sm"
                                    label={'CANCELAR'}
                                    style={{
                                        color: 'gray',
                                        marginRight: 15
                                    }}
                                    onClick={this.closeModal}
                                >
                                    {'CANCELAR'}
                                </button>
                                <button
                                    key="accept"
                                    type="button"
                                    className="btn btn-sm btn-success"
                                    onClick={this.downloadDocuments}
                                >
                                    {'DESCARGAR'}
                                </button>
                            </div>
                        )
                    };
                    break;
                case 'hasSignedDocSelected':
                    modal = {
                        width: 'x-large',
                        title: 'Firmar CPD',
                        body: (
                            <div style={{display: "flex"}}>
                                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <Icon
                                        className={"icon-custom"}
                                        title={false}
                                        icon={"warning-sign"}
                                        iconSize={24}
                                        color={"yellow"}
                                    />
                                </div>
                                <span style={{marginLeft: "20px"}}>Se ha seleccionado algún documento que ya ha sido firmado previamente. Por favor, realice una selección con todos los documentos pendientes de firma.</span>
                            </div>
                        ),
                        footer: (
                            <div className="p15 text-right">
                                <button
                                    key="close"
                                    type="button"
                                    className="btn btn-sm"
                                    label={'CERRAR'}
                                    style={{
                                        color: 'gray',
                                        marginRight: 15
                                    }}
                                    onClick={this.closeModal}
                                >
                                    {'CERRAR'}
                                </button>
                            </div>
                        )
                    };
                    break;
                default:
                    modal = {
                        title: '',
                        body: null,
                        footer: null
                    }
                    break;
            }
        }

        return (
            <div>
                <div style={styles.headerPage}>
                    {statsZone}
                    {companyName}
                </div>
                {filtersArea}
                <div style={styles.tableHeader.wrapper}>
                    <div style={styles.tableHeader.totalDocs}>
                        <span>CPD’s y prórrogas encontrados: {numDocsFound}</span>
                    </div>
                    <div style={
                        selectedDocs.size > 0 ?
                            {
                                ...styles.tableHeader.selectedDocs,
                                visibility: 'visible',
                                opacity: 1
                            }
                            : styles.tableHeader.selectedDocs
                    }>
                        <span>Documentos seleccionados: {selectedDocs.size}</span>
                        <button style={styles.tableHeader.buttonItem}
                                className="btn btn-custom btn-sm"
                                onClick={this.handleModal.bind(this, 'docsDownloadDialog', tableData)}>Descargar
                        </button>
                        <button style={styles.tableHeader.buttonItem}
                                className="btn btn-custom btn-sm"
                                onClick={() => {
                                    let hasSignedDocSelected = false;
                                    // check if user try to sign a signed doc
                                    for (const doc of selectedDocs) {
                                        const findDoc = this.props.documents.find(document => document.get("_id") === doc);
                                        if (findDoc.get("estado") === "firmado") {
                                            hasSignedDocSelected = true;
                                            break;
                                        }
                                    }
                                    if (hasSignedDocSelected) {
                                        // open dialog with message
                                        this.handleModal.bind(this, 'hasSignedDocSelected')();
                                    } else {
                                        // sign docs
                                        this.props.createSign.bind(this, prepareDocsJSON(this.props.documents, selectedDocs))();
                                    }
                                }}
                        >Firmar
                        </button>
                    </div>
                </div>
                <div className="container-fluid" style={styles.table.wrapper}>
                    <div className="row">
                        <div className="col">
                            <VirtualizedTable
                                tableSettings={tableSettings}
                                tableColumns={tableColumns}
                                tableData={tableData}
                            />
                        </div>
                    </div>
                </div>
                { modalIsOpen && (
                    <Modal
                        width={modal.width}
                        heading={modal.title}
                        footer={() => modal.footer}
                        autoFocus={false}
                        onClose={this.closeModal}
                    >
                        {modal.body}
                    </Modal>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    // Reducers
    const orgStructureReducer = state.organizationalStructure;
    const documentsReducer = state.documents;
    const statsReducer = state.stats;
    const sessionReducer = state.auth.session;
    const customersReducer = state.customers;

    // Documents
    const documents = documentsReducer.get('payload') || List([]);
    const documentsIsFetching = documentsReducer.get('isFetching') || false;

    // Organizational Structure
    const orgStructure = orgStructureReducer.get('payload') || Map({});
    let delegations = List([
            Map({
                delegacion_id: -1,
                nombre: 'Todas'
            })
        ]),
        offices = List([
            Map({
                delegacion_id: -1,
                oficina_id: -1,
                nombre: 'Todas'
            })
        ]),
        centers = List([
            Map({
                delegacion_id: -1,
                oficina_id: -1,
                centro_id: -1,
                nombre: 'Todos'
            })
        ]);

    (orgStructure.get('delegaciones') || List([])).map((delegation) => {
        (delegation.get('oficinas') || List([])).map((office) => {
            (office.get('centros') || List([])).map(center => {

                return centers = centers.insert(
                    centers.size,
                    center
                        .set('delegacion_id', delegation.get('delegacion_id'))
                        .set('oficina_id', office.get('oficina_id'))
                        .delete('_id')
                        .delete('en_baja')
                );
            });

            return offices = offices.insert(
                offices.size,
                office
                    .set('delegacion_id', delegation.get('delegacion_id'))
                    .delete('centros')
                    .delete('_id')
                    .delete('en_baja')
            );
        });

        return delegations = delegations.insert(
            delegations.size,
            delegation
                .delete('oficinas')
                .delete('_id')
        );
    });

    // Stats
    const customerStatsIsFetching = statsReducer.getIn(['customer', 'isFetching']) || false;
    const customerStatsError = statsReducer.getIn(['customer', 'error']) || false;
    const customerStatsPayload = statsReducer.getIn(['customer', 'payload']) || Map({});

    // Session
    const user = sessionReducer.get('user') || Map({});

    // User Organizational Structure
    const userOrgStructure = (user && user.get('estructura_organizativa')) || Map({});
    let userDelegations = List([
            Map({
                delegacion_id: -1,
                nombre: 'Todas'
            })
        ]),
        userOffices = List([
            Map({
                delegacion_id: -1,
                oficina_id: -1,
                nombre: 'Todas'
            })
        ]),
        userCenters = List([
            Map({
                delegacion_id: -1,
                oficina_id: -1,
                centro_id: -1,
                nombre: 'Todos'
            })
        ]);

    (userOrgStructure.get('delegaciones') || List([])).map((delegation) => {
        (delegation.get('oficinas') || List([])).map((office) => {
            (office.get('centros') || List([])).map(center => {

                return userCenters = userCenters.insert(
                    userCenters.size,
                    center
                        .set('delegacion_id', delegation.get('delegacion_id'))
                        .set('oficina_id', office.get('oficina_id'))
                        .delete('_id')
                        .delete('en_baja')
                );
            });

            return userOffices = userOffices.insert(
                userOffices.size,
                office
                    .set('delegacion_id', delegation.get('delegacion_id'))
                    .delete('centros')
                    .delete('_id')
                    .delete('en_baja')
            );
        });

        return userDelegations = userDelegations.insert(
            userDelegations.size,
            delegation
                .delete('oficinas')
                .delete('_id')
        );
    });

    // If user is of type "cliente" or "gestor" we need to filter the organizational structure (delegations, offices, centers)
    if(user.get('tipo') === 'cliente' || user.get('tipo') === 'gestor') {
        delegations = delegations.filter( (delegation) => {
            return userDelegations.findIndex( (item) => item.get('delegacion_id') === delegation.get('delegacion_id')) !== -1;
        });

        offices = offices.filter( (office) => {
            return userOffices.findIndex( (item) => item.get('oficina_id') === office.get('oficina_id')) !== -1;
        });

        centers = centers.filter( (center) => {
            return userCenters.findIndex( (item) => item.get('centro_id') === center.get('centro_id')) !== -1;
        });

    }


    // Customers
    const customersIsFetching = customersReducer.get('isFetching') || false;
    const customersError = customersReducer.get('error') || false;
    const customersPayload = customersReducer.get('payload') || List({});

    return {
        documents, documentsIsFetching,
        delegations, offices, centers,
        customerStatsIsFetching, customerStatsError, customerStatsPayload,
        user,
        customersIsFetching, customersError, customersPayload
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getOrgStructure: () => dispatch(getOrgStructure()),
        getDocuments: (query) => dispatch(getDocuments(query)),
        cleanDocuments: () => dispatch(cleanDocuments()),
        downloadDocs: (cpdIds, prrIds, includeAtts, attsType) => dispatch(downloadDocs(cpdIds, prrIds, includeAtts, attsType)),
        downloadCpd: (cpdId, includeAtts) => dispatch(downloadCpd(cpdId, includeAtts)),
        downloadPrr: (prrId) => dispatch(downloadPrr(prrId)),
        downloadAtt: (cpdId, attId) => dispatch(downloadAtt(cpdId, attId)),
        getCustomerStats: (customerId) => dispatch(getCustomerStats(customerId)),
        getCustomers: () => dispatch(getCustomers()),
        createSign: (selectedDocs) => dispatch(createSign(selectedDocs))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);