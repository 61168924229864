import React from 'react';
import { ClipLoader } from "react-spinners";

const Loading = ({ isFetching, size, color, style = {}}) => {

    return (
        <div style={style}>
            <ClipLoader
                sizeUnit={"px"}
                size={size || 30}
                color={color || '#2F3862'}
                loading={isFetching}
            />
        </div>
    );
};

export default Loading;
