import React from 'react';

import { Icon, Checkbox } from "@blueprintjs/core";

export default class OrganizationalStructureConfiguration extends React.Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.handleOpenAll = this.handleOpenAll.bind(this);
        this.handleCloseAll = this.handleCloseAll.bind(this);
    }

    handleClick(item) {
        let listItems = this.props.listItems.slice();

        listItems.map(listItem => {
            if (item.level === 'delegacion' && listItem.level === 'delegacion' && item.id === listItem.id) {
                listItem.opened = !listItem.opened;
            }

            if (item.level === 'delegacion' && listItem.level === 'oficina' && item.id === listItem.delegacion_id) {
                listItem.opened = item.opened;
                listItem.show = !listItem.show;
            }

            if (item.level === 'delegacion' && listItem.level === 'centro' && item.id === listItem.delegacion_id) {
                listItem.show = item.opened;
            }

            if (item.level === 'oficina' && listItem.level === 'oficina' && item.id === listItem.id) {
                listItem.opened = !listItem.opened;
            }

            if (item.level === 'oficina' && listItem.level === 'centro' && item.id.substr(item.id.indexOf('_') + 1) === listItem.oficina_id) {
                listItem.show = !listItem.show;
            }
            return listItem;
        });

        this.props.onUpdateListItems(listItems);
    }

    handleCheck(item) {
        let listItems = this.props.listItems.slice();

        // Update the checked item and its children (interactions "parent and children")
        listItems.map(listItem => {
            if (item.level === 'delegacion' && listItem.level === 'delegacion' && item.id === listItem.id) {
                listItem.opened = true;
                listItem.checked = !item.checked;
            }

            if (item.level === 'delegacion' && listItem.level === 'oficina' && item.id === listItem.delegacion_id) {
                listItem.opened = true;
                listItem.show = true;
                listItem.checked = item.checked;
            }

            if (item.level === 'delegacion' && listItem.level === 'centro' && item.id === listItem.delegacion_id) {
                listItem.show = true;
                listItem.checked = item.checked;
            }

            if (item.level === 'oficina' && listItem.level === 'oficina' && item.id === listItem.id) {
                listItem.opened = true;
                listItem.checked = !item.checked;
            }

            if (item.level === 'oficina' && listItem.level === 'centro' && item.id.substr(item.id.indexOf('_') + 1) === listItem.oficina_id) {
                listItem.show = true;
                listItem.checked = item.checked;
            }

            if (item.level === 'centro' && listItem.level === 'centro' && item.id === listItem.id) {
                listItem.checked = !item.checked;
            }
            return listItem;
        });

        // Find if any children of delegaciones or oficinas is checked and check it
        // Used to update the items in the list based on interactions "children to parent"
        listItems.map(listItem => {
            if (listItem.level === 'delegacion' || listItem.level === 'oficina') {
                listItem.checked = false;
                this.props.listItems.forEach(item => {
                    if (item.level === 'centro') {
                        if (listItem.level + '_id' === 'delegacion_id' && item[listItem.level + '_id'] === listItem.id && item.checked) {
                            listItem.checked = true;
                        }
                        if (listItem.level + '_id' === 'oficina_id' && item[listItem.level + '_id'] === listItem.id.substr(listItem.id.indexOf('_') + 1) && item.checked) {
                            listItem.checked = true;
                        }
                    }
                });
            }
            return listItem;
        });

        this.props.onUpdateListItems(listItems);
    }

    handleOpenAll() {
        let listItems = this.props.listItems.slice();

        listItems.map(listItem => {
            switch (listItem.level) {
                case 'delegacion':
                    listItem.opened = true;
                    break;
                case 'oficina':
                    listItem.opened = true;
                    listItem.show = true;
                    break;
                case 'centro':
                    listItem.show = true;
                    break;
                default:
                    return listItem;
            }
            return listItem;
        });

        this.props.onUpdateListItems(listItems);
    }

    handleCloseAll() {
        let listItems = this.props.listItems.slice();

        listItems.map(listItem => {
            switch (listItem.level) {
                case 'delegacion':
                    listItem.opened = false;
                    break;
                case 'oficina':
                    listItem.opened = false;
                    listItem.show = false;
                    break;
                case 'centro':
                    listItem.show = false;
                    break;
                default:
                    return listItem;
            }
            return listItem;
        });

        this.props.onUpdateListItems(listItems);
    }

    render() {
        const styles = {
            delegacion: {
                display: 'flex',
                height: 30,
                fontWeight: 'bold',
                marginLeft: 0
            },
            oficina: {
                display: 'flex',
                height: 30,
                marginLeft: 30
            },
            centro: {
                display: 'flex',
                height: 30,
                marginLeft: 60
            }
        };

        const listItems = this.props.listItems.map(item => {
            let itemContent = null;

            if (item.show || item.level === 'delegacion') {
                itemContent = (
                    <div key={item.id} style={styles[item.level]}>
                        <Checkbox
                            checked={item.checked}
                            onChange={this.handleCheck.bind(this, item)}
                            label=""
                        />
                        {item.level === 'delegacion' || item.level === 'oficina' ? (
                            <div style={{ width: 18, marginRight: 10 }}>
                                <Icon
                                    className="icon-custom"
                                    title={false}
                                    icon={item.opened ? 'caret-down' : 'caret-right'}
                                    iconSize={18}
                                    onClick={this.handleClick.bind(this, item)}
                                />
                            </div>
                        ) : null}
                        {item.level === 'delegacion' ? (<div>{`${item.name} (${item.id})`}</div>) : null}
                        {item.show && item.level === 'oficina' ? (<div>{`${item.name} (${item.id.substr(item.id.indexOf('_') + 1)})`}</div>) : null}
                        {item.show && item.level === 'centro' ? (<div>{`${item.name} (${item.id.substr(item.id.lastIndexOf('_') + 1)})`}</div>) : null}
                    </div>
                );
            }
            return itemContent;
        });

        return (
            <div style={{ margin: 20 }}>
                <div style={{ display: 'flex', flexDirection: 'row-reverse', marginBottom: 20 }}>
                    <div style={{ color: '#fff' }}>
                        <a className="btn btn-custom btn-sm" onClick={this.handleCloseAll}>Contraer todos</a>
                    </div>
                    <div style={{ marginRight: 20, color: '#fff' }}>
                        <a className="btn btn-custom btn-sm" onClick={this.handleOpenAll}>Expandir todos</a>
                    </div>
                </div>
                <div style={{ maxHeight: 350, overflowY: 'auto' }}>
                    {listItems}
                </div>
            </div>
        );
    }
}