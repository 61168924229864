import React from 'react';

const KpiBox = ({ value, text, boxStyle, valueStyle, textStyle }) => {
    return (
        <div style={boxStyle || {}}>
            <div style={valueStyle || {}}>{value}</div>
            <div style={textStyle || {}}>{text}</div>
        </div>
    );
};

export default KpiBox;