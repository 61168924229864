import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Routes from './routes/Routes';
import configureStore from './store';
import Auth from './lib/auth';

// Adding Bootstrap
import 'bootstrap/dist/css/bootstrap.css';
import 'jquery';
import 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.js';

// Adding Blueprint
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

// Adding css styles
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import './theme/css/index.css';

import { getSessionData } from './actions/auth';

let store = configureStore();

// Dispatch getSessionData
store.dispatch(getSessionData());

// Inject store to authentication library
Auth.init(store);

ReactDOM.render(
  <Provider store={store}>
    <Routes />
  </Provider>,
  document.getElementById('root')
);
