import React from "react";

import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/component/grid';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/dataZoom';

const Echarts = ({ data, legend, tooltip, grid, xAxis, yAxis, dataZoom, series, color, style, option }) => {

    const handleData = (type, field, data) => {

        const getData = (dataIndex, data) => {

            let newData = [];
            if (data.header.dataDirection === 'column') {
                if (!Array.isArray(data.data[dataIndex])) return newData;
                return data.data[dataIndex];
            }

            if (data.header.dataDirection === 'row') {
                data.data.forEach(row => newData.push(row[dataIndex]));
                return newData;
            }

            return newData;
        };

        const getName = (dataIndex, data) => data.header.fields[dataIndex].label || '';

        if (!(
                type &&
                field &&
                field.type &&
                data &&
                data.header &&
                Array.isArray(data.header.fields) &&
                Array.isArray(data.data)
            )) return null;

        const dataIndex = data.header.fields.findIndex(row => row.fieldName === field.name);

        switch (type) {
            case 'serie':
                return {
                    type: field.type,
                    ...field.name ? {
                        name: getName(dataIndex, data),
                        ...dataIndex !== -1 ? { data: getData(dataIndex, data) } : null
                    } : null,
                    ...field.itemStyle ? { itemStyle: field.itemStyle } : null,
                    ...field.stack ? { stack: field.stack } : null,
                    ...field.label ? { label: field.label } : null,
                    ...field.barWidth ? { barWidth: field.barWidth } : null,
                    ...field.barGap ? { barGap: field.barGap } : null,
                    ...field.barCategoryGap ? { barCategoryGap: field.barCategoryGap } : null
                };
            case 'axis':
                return {
                    type: field.type,
                    ...field.name && dataIndex !== -1 ? { data: getData(dataIndex, data) } : null,
                    ...field.axisLabel ? { axisLabel: field.axisLabel } : null,
                    ...field.position ? { position: field.position } : null,
                    ...field.inverse ? { inverse: field.inverse } : null,
                    ...field.show === 'false' ? { show: false } : null,
                };
            default:return null;
        }
    };

    return (
        option ? (
            <ReactEchartsCore
                echarts={echarts}
                option={option}
                style={{
                    width: '100%',
                    height: '100%',
                    ...style
                }}
            />
        ) : (
            <ReactEchartsCore
                echarts={echarts}
                option={{
                    legend,
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        },
                        ...tooltip
                    },
                    grid: {
                        right: 0,
                        left: 0,
                        bottom: 0,
                        containLabel: true,
                        ...grid
                    },
                    xAxis: {
                        splitLine: {
                            lineStyle: {
                                type: 'dashed'
                            }
                        },
                        ...handleData('axis', xAxis, data)
                    },
                    yAxis: {
                        splitLine: {
                            lineStyle: {
                                type: 'dashed'
                            }
                        },
                        ...handleData('axis', yAxis, data)
                    },
                    ...dataZoom ? { dataZoom } : null,
                    series: series.map(serie => handleData('serie', serie, data)),
                    ...color ? { color } : null
                }}
                notMerge={true}
                style={{
                    width: '100%',
                    height: '100%',
                    ...style
                }}
            />
        )
    );
};

export default Echarts;