import React, { Component } from 'react';
import _ from 'lodash';
import { isEmail } from 'validator';

import TextInput from '../components/text-input';

class UserEditDialog extends Component {
    constructor(props){
        super(props);

        this.state = {
            nombre: '',
            apellidos: '',
            email: '',
            telefono: '',
            validEmail: true
        };

        this.onChangeInput = this.onChangeInput.bind(this);
    }

    componentDidMount() {
        if (this.props.selectedUser) {
            this.setState({
                nombre: this.props.selectedUser.get('nombre'),
                apellidos: this.props.selectedUser.get('apellidos'),
                email: this.props.selectedUser.get('email'),
                telefono: this.props.selectedUser.get('telefono'),
                validEmail: isEmail(this.props.selectedUser.get('email'))
            });
        }
    }

    onChangeInput(inputName, inputValue) {
        this.setState({
            [inputName]: inputValue,
            ...(inputName === 'email') ? { validEmail: isEmail(inputValue) } : {}
        }, () => this.props.onChangeFormData(this.state));
    }

    render() {
        const { nombre, apellidos, email, telefono, validEmail } = this.state;
        const { selectedUser } = this.props;

        return (
            <div>
                <div className="row mb5">
                    <div className="col-12">
                        <span style={{ fontWeight: 'bold', color: '#076BAD' }}>{selectedUser.get('full_name')}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12" style={{ color: '#076BAD' }}>
                        <span>(Tipo: </span>
                        <span style={{ fontWeight: 'bold' }}>{_.capitalize(selectedUser.get('tipo'))}</span>
                        {selectedUser.get('tipo') === 'cliente' ? (
                            <div style={{ display: 'inline' }}>
                                <span>, Cliente: </span>
                                <span style={{ fontWeight: 'bold' }}>{selectedUser.toJS()['cliente_id'][0]['nombre']}</span>
                            </div>
                        ) : null}
                        <span>)</span>
                    </div>
                </div>
                <div className="row" style={{ marginTop: 20 }}>
                    <div className="col-6">
                        <TextInput
                            value={nombre}
                            style={{ borderColor: '#ccc' }}
                            onChange={(event) => this.onChangeInput('nombre', event.target.value)}
                            labelText={'Nombre *'}
                        />
                    </div>
                    <div className="col-6">
                        <TextInput
                            value={apellidos}
                            style={{ borderColor: '#ccc' }}
                            onChange={(event) => this.onChangeInput('apellidos', event.target.value)}
                            labelText={'Apellidos'}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <TextInput
                            value={email}
                            style={{ borderColor: '#ccc' }}
                            onChange={(event) => this.onChangeInput('email', event.target.value)}
                            labelText={'Email *'}
                            helpText={validEmail ? 'Introduce un email válido' : null}
                            errorText={!validEmail ? 'Email no válido' : null}
                        />
                    </div>
                    <div className="col-6">
                        <TextInput
                            value={telefono}
                            style={{ borderColor: '#ccc' }}
                            onChange={(event) => this.onChangeInput('telefono', event.target.value)}
                            labelText={'Teléfono'}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default UserEditDialog;