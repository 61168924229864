import axios from 'axios';

import Auth from '../lib/auth';
import { saveFile } from '../lib/content-lib';

// Download Documents Actions
export function downloadDocs(cpds, prorrogas, incluir_anexos, tipo_anexos) {
    return async dispatch => {
        try {
            const token = Auth.getLocalJwt();
            const res = await axios.post('/api/cpds/download/', { cpds, prorrogas, incluir_anexos, tipo_anexos }, {
                headers: { 'Authorization': 'Bearer ' + token },
                responseType: 'blob'
            });
            if( res.status === 200 ) {
                saveFile(res);
            } else {
                //console.log('error, no data');
            }
        } catch (error) {
            //console.log('error: ', error);
        }
    };
}

// Download Cpd Actions
export function downloadCpd(cpdId, includeAtts) {
    return async dispatch => {
        try {
            const token = Auth.getLocalJwt();
            const res = await axios.post('/api/cpds/'+ cpdId + '/download/', includeAtts, {
                headers: { 'Authorization': 'Bearer ' + token },
                responseType: 'blob'
            });
            if( res.status === 200 ) {
                saveFile(res);
            } else {
                //console.log('error, no data');
            }
        } catch (error) {
            //console.log('error: ', error);
        }
    };
}

// Download Att Actions
export function downloadAtt(cpdId, attId) {
    return async dispatch => {
        try {
            const token = Auth.getLocalJwt();
            const res = await axios.get('/api/cpds/'+ cpdId + '/anexos/'+ attId + '/download/', {
                headers: { 'Authorization': 'Bearer ' + token },
                responseType: 'blob'
            });
            if( res.status === 200 ) {
                saveFile(res);
            } else {
                //console.log('error, no data');
            }
        } catch (error) {
            //console.log('error: ', error);
        }
    };
}

// Download Prr Actions
export function downloadPrr(prrId) {
    return async dispatch => {
        try {
            const token = Auth.getLocalJwt();
            const res = await axios.get('/api/prorrogas/' + prrId + '/download/', {
                headers: { 'Authorization': 'Bearer ' + token },
                responseType: 'blob'
            });
            if( res.status === 200 ) {
                saveFile(res);
            } else {
                //console.log('error, no data');
            }
        } catch (error) {
            //console.log('error: ', error);
        }
    };
}