import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { List, Map } from 'immutable';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

// Components
import VirtualizedTable from '../components/virtualized-table';
import CustomIcon from '../components/CustomIcon';
import Modal from '@atlaskit/modal-dialog';
import { Checkbox } from '@blueprintjs/core';

// Actions
import { getEmployeeDetails } from '../actions/employees';
import { getEmployeeDocuments, downloadEmployeeDocuments } from '../actions/employeeDocuments';
import { getCustomers } from '../actions/customers';
import SignDocument from './SignDocument';

// TODO: mover estilos a index.scss
const styles = {
    statsArea: {
        wrapper: {
            display: 'flex',
            flexWrap: 'wrap',
            fontSize: 14,
            marginLeft: 10
        },
        item: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            padding: '8px 10px',
            margin: 10,
            width: 225,
            fontSize: 14,
            indicator: {
                fontSize: 16
            },
            cursor: 'pointer'
        }
    },
    legend: {
        marginLeft: 10,
        width: 20,
        height: 20,
        backgroundColor: '#E8F1F7',
        border: 'solid 1px #ddd'
    },
    selectedDocsHidden: {
        visibility: 'hidden',
        opacity: 0,
        transition: 'all 0.5s ease-in-out'
    },
    selectedDocsVisible: {
        visibility: 'visible',
        opacity: 1,
        transition: 'all 0.5s ease-in-out'
    },
    table: {
        wrapper: {
            backgroundColor: '#fff'
        }

    },
    modal: {
        subtitle: {
            display: 'flex',
            padding: '15px 0',
            fontSize: 16,
            fontWeight: 'bold',
            color: '#076BAD'
        }
    }
};

const EmployeeProfilePage = (props) => {

    const { id } = props.match.params;

    const [originalTableData, setOriginalTableData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [signDialogIsOpen, setSignDialogIsOpen] = useState(false);
    const [signDocumentId, setSignDocumentId] = useState(null);
    const [documentProps, setDocumentProps] = useState({});
    const [allSelectedDocs, setAllSelectedDocs] = useState(false);
    const [selectedDocs, setSelectedDocs] = useState(new Set());
    const [status, setStatus] = useState('todos');

    useEffect(() => {
        // Control what user type can access
        if (props.user && props.user.get('tipo') !== 'administrador') {
            props.history.push('/');
        }

        if (id) {
            props.getEmployeeDetails(id);
            props.getEmployeeDocuments({ trabajador_id: id });
        }
        props.getCustomers();
    }, [id]);

    useEffect(() => {
        setDocumentProps(props.employeeDocuments.find(document => document.get('id') === signDocumentId));
        setOriginalTableData(props.employeeDocuments && props.employeeDocuments.toJS());
        setTableData(props.employeeDocuments && props.employeeDocuments.toJS());
    }, [signDocumentId, props.employeeDocuments]);

    // filter for documents status clicked
    useEffect(() => {
        if (status === 'todos') {
            setTableData(originalTableData);
        } else {
            setTableData(originalTableData.filter(doc => doc.estado === status));
        }
    }, [status]);

    function handleMenuClick(documentId, action) {
        if (action === 'sign') {
            setSignDialogIsOpen(true);
            setSignDocumentId(documentId);
        }
    }

    // Control the state of allSelectedDocs and selectedDocs
    function handleCheckbox(type, data) {
        let getSelectedDocs;
        if (type === 'mainTableAll') {
            getSelectedDocs = new Set();
            if (!allSelectedDocs) data.forEach(id => getSelectedDocs.add(id));
            setAllSelectedDocs(!allSelectedDocs);
            setSelectedDocs(getSelectedDocs);
        } else if (type === 'mainTable') {
            getSelectedDocs = new Set(selectedDocs);
            getSelectedDocs.has(data) ? getSelectedDocs.delete(data) : getSelectedDocs.add(data);
            setAllSelectedDocs(getSelectedDocs.size === props.employeeDocuments.count());
            setSelectedDocs(getSelectedDocs);
        }
    }

    // match clientes ids with props customers _id and get nombre
    const customerNames = props.employeeDetails.get('clientes') && props.employeeDetails.get('clientes').map(id => {
        const customer = props.customers.find(customer => customer.get('_id') === id);
        return customer ? customer.get('nombre') : null;
    });

    // Documents List Area
    const tableSettings = {
        sortable: true,
        filterable: false,
        defaultSortFields: ['fecha_creacion'],
        defaultSortDirections: ['desc'],
        height: 480,
        rowHeight: 54,
        strippedRows: false,
        conditionalRowStyle: [{
            dataField: 'tipo_documento',
            operation: '=',
            value: 'prr',
            style: {
                backgroundColor: '#E8F1F7'
            }
        }],
        rowStyle: {
            fontSize: 13
        }
    };

    const tableColumns = {
        checkbox: {
            title: (
                <Checkbox
                    checked={ allSelectedDocs }
                    onChange={() => handleCheckbox('mainTableAll', tableData.map(doc => doc.id))}
                    style={{ marginTop: 10 }}
                />
            ),
            columnType: 'function',
            dataField: 'id',
            width: 50,
            style: { display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', maxWidth: 50 },
            sortable: false,
            filterable: false,
            nodeContent: value => {
                return (
                    <div style={{display: 'flex'}}>
                        <Checkbox
                            checked={selectedDocs.has(value)}
                            onChange={() => handleCheckbox('mainTable', value)}
                            style={{ marginTop: 5 }}
                        />
                    </div>
                );
            }
        },
        nombre: {
            title: 'Nombre',
            columnType: 'function',
            dataField: 'id',
            width: 140,
            style: { maxWidth: 400 },
            sortable: true,
            filterable: false,
            nodeContent: (documentId) => {
                return (
                    <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                        <span
                            style={{ fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer', whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
                            onClick={() => handleMenuClick(documentId, 'sign')}
                        >
                            {props.employeeDocuments.find(document => document.get('id') === documentId) && props.employeeDocuments.find(document => document.get('id') === documentId).get('nombre')}
                        </span>
                    </div>
                );
            }
        },
        cliente: {
            title: "Cliente",
            columnType: 'function',
            dataField: 'cliente_id',
            width: 120,
            style: { fontWeight: 'bold'},
            sortable: true,
            filterable: false,
            nodeContent: (value) => {
                const customer = props.customers.find(customer => customer.get('_id') === value);

                if (customer) {
                    return customer.get('nombre');
                } else {
                    return null;
                }
            }
        },
        tipo: {
            title: "Tipo",
            columnType: 'text',
            dataField: 'tipo',
            width: 100,
            style: { maxWidth: 100 },
            sortable: true,
            filterable: false
        },
        requiere_firma: {
            title: "Requiere firma",
            columnType: 'boolean',
            dataField: 'requiere_firma',
            width: 50,
            style: { textTransform: 'uppercase', maxWidth: 150 },
            sortable: true,
            filterable: false
        },
        fecha_creacion: {
            title: "Fecha documento",
            columnType: 'function',
            dataField: 'fecha_creacion',
            width: 180,
            style: { maxWidth: 150 },
            sortable: true,
            filterable: false,
            nodeContent: (value) => {
                return moment(value).format('DD/MM/YYYY') ;
            }
        },
        fecha_firma: {
            title: "Fecha firma",
            columnType: 'function',
            dataField: 'fecha_firma',
            width: 180,
            style: { maxWidth: 125 },
            sortable: true,
            filterable: false,
            nodeContent: (value) => {
                return value ? moment(value).format('DD/MM/YYYY') : '--';
            }
        },
        estado: {
            title: "Estado",
            columnType: 'function',
            dataField: 'estado',
            width: 100,
            style: { maxWidth: 100 },
            sortable: true,
            filterable: false,
            nodeContent: (value) => {
                let status = value === 'firmado' ? 'success' : value === 'pendiente' ? 'danger' : 'info';
                return (
                    <div>
                        <span
                            className={value == null ? '' : 'badge badge-' + status}
                            style={{
                                padding: '5px 8px',
                                fontSize: 12
                        }}>{value == null ? '--' : _.capitalize(value)}</span>
                    </div>
                );
            }
        },
        actions: {
            title: 'Ver documento',
            columnType: 'function',
            dataField: 'id',
            width: 40,
            style: { maxWidth: 140 },
            sortable: false,
            filterable: false,
            nodeContent: (documentId) => {
                return (
                    <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                        <CustomIcon
                            iconType="document"
                            size={20}
                            title="Ver documento"
                            color="#183650"
                            onClick={() => handleMenuClick(documentId, 'sign')}
                        />
                    </div>
                );
            }
        }
    };

    return (
        <div className='card'>
            <div className='card-body'>
                <div className="container-fluid ficha">
                    <div className='row' style={{borderBottom: '1px solid rgb(224, 224, 224)'}}>
                        <div className='col'>
                            <div style={{ display: 'flex', margin: '5px auto' }}>
                                <CustomIcon
                                    iconType="arrow-left"
                                    size={20}
                                    title="Perfil"
                                    color="#183650"
                                    onClick={() => props.history.goBack()}
                                />
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ fontSize: 14, fontWeight: 'bold' }}>Ficha del trabajador</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{marginTop: 10}}>
                        <div className='col'>
                            <div className='row' style={{margin: 10}}>
                                <div className='col' style={{maxWidth: 'max-content'}}>
                                    <h3 style={{ margin: 'auto' }}>{props.employeeDetails.get('nombre')} {props.employeeDetails.get('apellidos')}</h3>
                                </div>
                                <div className='col' style={{ display: 'flex', alignItems: 'center' }}>
                                    <span
                                        className={props.employeeDetails.get('estado') === 'activo' ? 'badge badge-success' : 'badge badge-danger'}
                                        style={{
                                            padding: '5px 8px',
                                            fontSize: 12
                                    }}>{_.capitalize(props.employeeDetails.get('estado'))}</span>
                                </div>
                            </div>
                            <div className='row' style={{margin: '15px 15px 0px 15px'}}>
                                <div className='col'>
                                    <div className='row' style={{margin: '10px auto'}}>
                                        <div className='col' style={{maxWidth: 100, textAlign: 'right'}}>
                                            <span>Código:</span>
                                        </div>
                                        <div className="col" style={{paddingLeft: 0}}>
                                            <b>{props.employeeDetails.get('trabajador_id')}</b>
                                        </div>
                                    </div>
                                    <div className='row' style={{margin: '10px auto'}}>
                                        <div className='col' style={{maxWidth: 100, textAlign: 'right'}}>
                                            <span>NIF / NIE:</span>
                                        </div>
                                        <div className="col" style={{paddingLeft: 0}}>
                                            <b>{props.employeeDetails.get('documento_identificativo')}</b>
                                        </div>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='row' style={{margin: '10px auto'}}>
                                        <div className='col' style={{maxWidth: 100, textAlign: 'right'}}>
                                            <span>Email:</span>
                                        </div>
                                        <div className="col" style={{paddingLeft: 0}}>
                                            <b>{props.employeeDetails.get('email')}</b>
                                        </div>
                                    </div>
                                    <div className='row' style={{margin: '10px auto'}}>
                                        <div className='col' style={{maxWidth: 100, textAlign: 'right'}}>
                                            <span>Teléfono:</span>
                                        </div>
                                        <div className="col" style={{paddingLeft: 0}}>
                                            <b>{props.employeeDetails.get('telefono')}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{margin: '0px 15px 15px 15px'}}>
                                <div className='col'>
                                    <div className='row'>
                                        <div className='col' style={{maxWidth: 115, textAlign: 'right'}}>
                                            <span>Clientes:</span>
                                        </div>
                                        <div className="col" style={{paddingLeft: 0}}>
                                            <b>{customerNames && customerNames.filter(Boolean).join('; ')}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container-fluid'>
                    <div className="row">
                        <div className="col" style={styles.statsArea.wrapper}>
                            <div style={{ display: 'flex', alignItems: 'center', fontSize: '18px', fontWeight: 'bold', marginRight: 10 }}>
                                <span>Documentos del trabajador</span>
                            </div>
                            <div className="badge badge-primary" style={styles.statsArea.item} onClick={() => setStatus('todos')}>
                                <span>Total</span>
                                <span style={styles.statsArea.item.indicator}>{props.employeeDocuments.size}</span>
                            </div>
                            <div className="badge badge-success" style={styles.statsArea.item} onClick={() => setStatus('firmado')}>
                                <span>Firmados</span>
                                <span style={styles.statsArea.item.indicator}>{props.employeeDocuments.filter(document => document.get('estado') === 'firmado').size}</span>
                            </div>
                            <div className="badge badge-danger" style={styles.statsArea.item} onClick={() => setStatus('pendiente')}>
                                <span>Pendientes de firma</span>
                                <span style={styles.statsArea.item.indicator}>{props.employeeDocuments.filter(document => document.get('estado') === 'pendiente').size}</span>
                            </div>
                            <div className="badge badge-info" style={{ ...styles.statsArea.item, background: '#979696' }} onClick={() => setStatus(null)}>
                                <span>No requieren firma</span>
                                <span style={styles.statsArea.item.indicator}>{props.employeeDocuments.filter(document => document.get('estado') == null).size}</span>
                            </div>
                            {selectedDocs.size > 0 && (
                                <button
                                    className="btn btn-custom btn-sm mr-3"
                                    onClick={() => {
                                        const downloadDocumentsIds = [];
                                        for (const doc of selectedDocs) downloadDocumentsIds.push(doc);
                                        props.downloadEmployeeDocuments(downloadDocumentsIds)
                                    }}
                                    style={{ height: '32px', margin: 'auto' }}
                                >
                                    <span>Descargar ({ selectedDocs.size })</span>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                <div className="container-fluid" style={{ height: '100%' }}>
                    <div className="row">
                        <div className="col">
                            <VirtualizedTable 
                                tableSettings={tableSettings}
                                tableColumns={tableColumns}
                                tableData={tableData}
                            />
                        </div>
                    </div>
                </div>
                {signDialogIsOpen && documentProps && (
                    <Modal
                        onClose={() => setSignDialogIsOpen(false)}
                        width={'xxl-large'}
                        autoFocus={false}
                        footer={() => (
                            <div className="p15 text-right">
                                <button
                                    key="close"
                                    type="button"
                                    className="btn btn-sm"
                                    label={'CERRAR'}
                                    style={{
                                        color: 'gray',
                                        marginRight: 15
                                    }}
                                    onClick={() => setSignDialogIsOpen(false)}
                                >
                                    {'CERRAR'}
                                </button>
                            </div>
                        )}
                    >
                        <SignDocument 
                            documentId={signDocumentId} 
                            employeeId={id} 
                            documentProps={documentProps} 
                            employeeDetails={props.employeeDetails}
                            setSignDialogIsOpen={setSignDialogIsOpen}
                        />
                    </Modal>
                )}
            </div>
        </div>
    );
};

function mapStateToProps(state) {

    // Reducers
    const employeesReducer = state.employees;
    const employeeDocumentsReduce = state.employeeDocuments;
    const customersReducer = state.customers;
    const sessionReducer = state.auth.session;

    // Session
    const user = sessionReducer.get('user') || Map({});

    // Customers
    let customers = List([
        Map({
            _id: -1,
            nombre: 'Todos'
        })
    ]);
    (customersReducer.get('payload') || List([])).map(customer => {
        return customers = customers.insert(
            customers.size,
            customer
                .delete('cif')
                .delete('estado')
                .delete('fecha_actualizacion')
                .delete('fecha_creacion')
                .delete('__v')
                .delete('id')
        );
    });

    // Employee Details
    const employeeDetails = employeesReducer.get('employeeDetails') || {};

    // Employee Documents
    const employeeDocuments = employeeDocumentsReduce.get('documents') || [];
    const employeeDocumentsIsFetching = employeeDocumentsReduce.get('downloadDocumentIsFetching') || false;

    return {
        employeeDetails, employeeDocuments, employeeDocumentsIsFetching, customers, user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getEmployeeDetails: (query) => dispatch(getEmployeeDetails(query)),
        getEmployeeDocuments: (query) => dispatch(getEmployeeDocuments(query)),
        downloadEmployeeDocuments: (documentsIds) => dispatch(downloadEmployeeDocuments(documentsIds)),
        getCustomers: () => dispatch(getCustomers())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmployeeProfilePage));
