import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import moment from 'moment';

// Components
import PDFViewer from "../components/PDFViewer";
import SignatureComponent from '../components/SignatureCanvas';
import PDFGenerator from '../components/PDFGenerator';
import Modal from '@atlaskit/modal-dialog';

// Actions
import { downloadEmployeeDocument } from "../actions/employeeDocuments";

const SignDocument = (props) => {
    const { documentId, employeeId, documentProps, employeeDetails, setSignDialogIsOpen } = props;

    const [signature, setSignature] = useState('');
    const [signatureDialogIsOpen, setSignatureDialogIsOpen] = useState(false);
    const [isSignatureCleared, setIsSignatureCleared] = useState(false);
    const [saveSignature, setSaveSignature] = useState(false);
    const [generatePdf, setGeneratedPdf] = useState(false);

    const handleSignatureChange = (newSignature) => {
        setSignature(newSignature);
    };

    useEffect(() => {
        if (generatePdf) {
            setSignatureDialogIsOpen(false);
            setSignature(false);
            setSignDialogIsOpen(false);
        }
        props.downloadEmployeeDocument(documentId);
    }, [generatePdf]);

    return (
        <div style={{ height: '1150px', marginTop: '20px', maxHeight: '100%' }}>
            {documentProps && (
                <div className='sign-header'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col' style={{ maxWidth: 350 }}>
                                <div className='row'>
                                    <div className='col' style={{ maxWidth: 350, width: 'max-content' }}>
                                        <span>Documento</span>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col' style={{ maxWidth: 350, width: 'max-content' }}>
                                        <b>{documentProps.get('nombre')}</b>
                                    </div>
                                </div>
                            </div>
                            <div className='col' style={{ maxWidth: 350 }}>
                                <div className='row'>
                                    <div className='col' style={{ maxWidth: 350, width: 'max-content' }}>
                                        <span>Trabajador</span>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col' style={{ maxWidth: 350, width: 'max-content' }}>
                                        <b>{employeeDetails.get('nombre')} {employeeDetails.get('apellidos')}</b>
                                    </div>
                                </div>
                            </div>
                            <div className='col' style={{  }}>
                                <div className='row'>
                                    <div className='col'>
                                        <span>Tipo</span>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col'>
                                        <b>{documentProps.get('tipo')}</b>
                                    </div>
                                </div>
                            </div>
                            <div className='col' style={{  }}>
                                <div className='row'>
                                    <div className='col'>
                                        <span>Requiere firma</span>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col'>
                                        <b>{documentProps.get('requiere_firma') ? 'Si' : 'No'}</b>
                                    </div>
                                </div>
                            </div>
                            <div className='col' style={{  }}>
                                <div className='row'>
                                    <div className='col'>
                                        <span>Fecha documento</span>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col'>
                                        <b>{moment(documentProps.get('fecha_creacion')).format('DD/MM/YYYY')}</b>
                                    </div>
                                </div>
                            </div>
                            {documentProps.get('estado') === 'firmado' && (
                                <div className='col' style={{ }}>
                                    <div className='row'>
                                        <div className='col'>
                                            <span>Fecha firma</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col'>
                                            <b>{moment(documentProps.get('fecha_firma')).format('DD/MM/YYYY')}</b>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {documentProps.get('estado') != null && (
                                <div className='col' style={{  }}>
                                    <div className='row'>
                                        <div className='col'>
                                            <span>Estado</span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col'>
                                            <span className={`badge badge-${documentProps.get('estado') === 'firmado' ? 'success' : 'danger'}`}>
                                                {documentProps.get('estado').toUpperCase()}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {documentProps.get('estado') === 'pendiente' && (
                        <button 
                            onClick={() => setSignatureDialogIsOpen(true)}
                            className='btn btn-custom btn-sm'
                            style={{
                                color: 'gray',
                                margin: '0px auto auto',
                                height: '31px'
                            }}
                        >
                            FIRMAR
                        </button>
                    )}
                </div>
            )}
            <PDFViewer 
                pdfBlob={props.downloadDocument} 
                isFetching={props.downloadDocumentIsFetching}
            />
            {signatureDialogIsOpen && (
                <Modal
                    onClose={() => setSignatureDialogIsOpen(false)}
                    heading={'Firmar documento'}
                    width={'medium'}
                    autoFocus={false}
                    footer={() => (
                        <div className="p15 text-right" style={{display: 'flex'}}>
                            <button 
                                className='btn btn-sm'
                                style={{
                                    color: 'gray',
                                    margin: 'auto auto auto 0'
                                }}
                                onClick={() => setIsSignatureCleared(true)}>
                                    BORRAR
                            </button>
                            <button
                                key="close"
                                type="button"
                                className="btn btn-sm"
                                label={'CERRAR'}
                                style={{
                                    color: 'gray',
                                    marginRight: 15
                                }}
                                onClick={() => setSignatureDialogIsOpen(false)}
                            >
                                {'CERRAR'}
                            </button>
                            <PDFGenerator
                                signature={signature}
                                originalPdfBlob={props.downloadDocument}
                                setSaveSignature={setSaveSignature}
                                employeeId={employeeId}
                                documentId={documentId}
                                generatePdf={generatePdf}
                                setGeneratedPdf={setGeneratedPdf}
                                documentProps={documentProps}
                            />
                        </div>
                    )}
                >
                    <SignatureComponent 
                        onSignatureChange={handleSignatureChange} 
                        isSignatureCleared={isSignatureCleared} 
                        setIsSignatureCleared={setIsSignatureCleared} 
                        saveSignature={saveSignature}
                        setSaveSignature={setSaveSignature}
                    />
                </Modal>
            )}
        </div>
    );
};

function mapStateToProps(state) {

    // Reducers
    const employeeDocumentsReducer = state.employeeDocuments;

    // Employee documents
    const downloadDocument = employeeDocumentsReducer.get('downloadDocument') || {};
    const downloadDocumentIsFetching = employeeDocumentsReducer.get('downloadDocumentIsFetching') || false;

    return {
        downloadDocument, downloadDocumentIsFetching
    }
}

function mapDispatchToProps(dispatch) {
    return {
        downloadEmployeeDocument: (documentId) => dispatch(downloadEmployeeDocument(documentId))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignDocument);
