import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Colors } from "@blueprintjs/core";
import { Map } from 'immutable';
import _ from 'lodash';

import { getEmployeesStats } from '../actions/employeeStats';
import Echarts from '../components/echarts';

import KpiBox from '../components/KpiBox';
import moment from 'moment';

class EmployeeStatsPage extends Component {
    componentDidMount() {
        this.props.getEmployeesStats();
    }

    render() {
        const { employeeStats } = this.props;

        const basicStyles = {
            box: {
                width: 210,
                height: 50
            },
            value: {
                fontSize: 24,
                textAlign: 'left',
                marginTop: 0
            },
            text: {
                fontSize: 16,
                textAlign: 'left',
                marginTop: 0
            }
        };

        let statistics = {};
        if (employeeStats.getIn(['numDocuments', 'signedIn'])) {
            statistics = employeeStats.getIn(['numDocuments', 'signedIn']).toJS();
        }

        return (
            <div style={{ margin: '30px 20px' }}>
                <div className="row">
                    <div className="col-12 col-lg-6 mb15">
                        <div className="p5">
                            <div style={{ width: '100%' }}>
                                <h4 style={{ borderBottom: '1px solid #ccc', paddingBottom: 5 }}>Trabajadores</h4>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', maxWidth: 100 }}>
                                    <div>
                                        <KpiBox
                                            value={employeeStats.getIn(['numEmployees', 'total'])}
                                            text={'Totales'}
                                            boxStyle={_.merge({}, basicStyles.box)}
                                            valueStyle={_.merge({ color: '#2F3862' }, basicStyles.value)}
                                            textStyle={_.merge({ color: '#2F3862' }, basicStyles.text)}
                                        />
                                    </div>
                                    <div>
                                        <KpiBox
                                            value={employeeStats.getIn(['numEmployees', 'active'])}
                                            text={'Activos'}
                                            boxStyle={_.merge({}, basicStyles.box)}
                                            valueStyle={_.merge({ color: Colors.GREEN4 }, basicStyles.value)}
                                            textStyle={_.merge({ color: Colors.GREEN4 }, basicStyles.text)}
                                        />
                                    </div>
                                    <div>
                                        <KpiBox
                                            value={employeeStats.getIn(['numEmployees', 'inactive'])}
                                            text={'Inactivos'}
                                            boxStyle={_.merge({}, basicStyles.box)}
                                            valueStyle={_.merge({ color: Colors.RED4 }, basicStyles.value)}
                                            textStyle={_.merge({ color: Colors.RED4 }, basicStyles.text)}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, flexWrap: 1, alignItems: 'flex-end' }} >
                                    <h5 style={{ paddingBottom: 5, minWidth: 265, maxWidth: 500, width: '100%' }}>
                                        Últimos trabajadores registrados
                                    </h5>
                                    <div style={{ maxHeight: 180, overflowY: 'auto', minWidth: 265, maxWidth: 500, width: '100%' }}>
                                        {employeeStats && employeeStats.get('employees') &&
                                            employeeStats.get('employees')
                                                .sortBy(employee => moment(employee.get('fecha_creacion')))
                                                .reverse()
                                                .slice(0, 5)
                                                .map(employee => {
                                                    return (
                                                        <div key={employee.get('_id')} style={{ display: 'flex', height: 30 }}>
                                                            <div style={{ width: '70%' }}>{employee.get('nombre')} {employee.get('apellidos')}</div>
                                                            <div style={{ width: '30%' }}>{moment(employee.get('fecha_creacion')).format('DD/MM/YYYY')}</div>
                                                        </div>
                                                    );
                                                })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 mb15">
                        <div className="p5">
                            <div style={{ width: '100%' }}>
                                <h4 style={{ borderBottom: '1px solid #ccc', paddingBottom: 5 }}>Documentos trabajadores</h4>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', maxWidth: 100 }}>
                                    <div>
                                        <KpiBox
                                            value={employeeStats.getIn(['numDocuments', 'total'])}
                                            text={'Totales'}
                                            boxStyle={_.merge({}, basicStyles.box)}
                                            valueStyle={_.merge({ color: '#2F3862' }, basicStyles.value)}
                                            textStyle={_.merge({ color: '#2F3862' }, basicStyles.text)}
                                        />
                                    </div>
                                    <div>
                                        <KpiBox
                                            value={employeeStats.getIn(['numDocuments', 'signed'])}
                                            text={'Firmados'}
                                            boxStyle={_.merge({}, basicStyles.box)}
                                            valueStyle={_.merge({ color: Colors.GREEN4 }, basicStyles.value)}
                                            textStyle={_.merge({ color: Colors.GREEN4 }, basicStyles.text)}
                                        />
                                    </div>
                                    <div>
                                        <KpiBox
                                            value={employeeStats.getIn(['numDocuments', 'pending'])}
                                            text={'Pendientes'}
                                            boxStyle={_.merge({}, basicStyles.box)}
                                            valueStyle={_.merge({ color: Colors.RED4 }, basicStyles.value)}
                                            textStyle={_.merge({ color: Colors.RED4 }, basicStyles.text)}
                                        />
                                    </div>
                                    <div>
                                        <KpiBox
                                            value={employeeStats.getIn(['numDocuments', 'withoutStatus'])}
                                            text={'No requieren firma'}
                                            boxStyle={_.merge({}, basicStyles.box)}
                                            valueStyle={_.merge({ color: '#2F3862' }, basicStyles.value)}
                                            textStyle={_.merge({ color: '#2F3862' }, basicStyles.text)}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, flexWrap: 1, alignItems: 'flex-end' }} >
                                    <h5 style={{ paddingBottom: 5, minWidth: 265, maxWidth: 500, width: '100%' }}>
                                        Documentos pendientes de firmar por trabajador
                                    </h5>
                                    <div style={{ maxHeight: 180, overflowY: 'auto', minWidth: 265, maxWidth: 500, width: '100%' }}>
                                        {employeeStats && employeeStats.get('pendingDocuments') && employeeStats.get('pendingDocuments').slice(0, 5).map(pendingDocument => {
                                            return (
                                                <div key={pendingDocument.get('cliente_id')} style={{ display: 'flex', height: 30 }}>
                                                    <div style={{ width: '70%' }}>{pendingDocument.get('nombre')}</div>
                                                    <div style={{ width: '30%' }}>{pendingDocument.get('qty')}</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="p5">
                            <div className="row mb15">
                                <div className="col-12 col-lg-8 mb15">
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div>
                                            <h5 style={{ minWidth: 265, maxWidth: '100%', width: '100%' }}>
                                                Documentos en los últimos 12 meses
                                            </h5>
                                            {statistics && statistics.dataset ? (
                                                <Echarts
                                                    option={{
                                                        legend: { top: 'auto', bottom: 0 },
                                                        tooltip: {},
                                                        dataset: statistics.dataset,
                                                        xAxis: { type: 'category' },
                                                        yAxis: {},
                                                        series: [
                                                          { type: 'bar', name: 'Pendiente', itemStyle: { color: Colors.RED4  } }, 
                                                          { type: 'bar', name: 'Firmado', itemStyle: { color: Colors.GREEN4  } }
                                                        ],
                                                        grid: {
                                                            top: '15px',
                                                            left: '30px',
                                                            right: '0',
                                                            bottom: '45px'
                                                        }
                                                    }}
                                                    style={{height: '250px'}}
                                                />
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {

    // Reducers
    const statsReducer = state.employeeStats;

    // Stats
    const employeeStats = statsReducer.get('payload') || Map({});

    return {
        employeeStats
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getEmployeesStats: () => dispatch(getEmployeesStats())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeStatsPage);
