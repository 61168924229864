import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import InfiniteCalendar from 'react-infinite-calendar';
import 'react-infinite-calendar/styles.css';
import MaskedInput from 'react-maskedinput';
import _ from 'lodash';

// Components
import Modal from '@atlaskit/modal-dialog';
import { grey, red } from 'material-colors';



export default class DateInput extends Component {

    constructor(props){
        super(props);

        this.state = {
            date: props.value.isValid() ? props.value : moment(),
            isValid: props.value.isValid(),
            textDate: props.value.isValid() ? props.value.format(props.format) : moment().format(props.format),
            openDialog: false
        };

        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onChangeInput = this.onChangeInput.bind(this);
        this.onChangeDatePicker = this.onChangeDatePicker.bind(this);
    }

    openDialog() {
        this.setState({
            openDialog: true
        });
    }

    closeDialog() {
        this.setState({
            openDialog: false
        });
    }

    onChangeInput(evt) {
        let { format } = this.props;

        this.setState({
            textDate: evt.target.value,
            date: moment(evt.target.value, format),
            isValid: moment(evt.target.value, format).isValid()
        });
    }

    onBlur(evt){
        this.setState({
            date: moment(evt.target.value, this.props.format).isValid() ? moment(evt.target.value, this.props.format) : moment(),
            isValid: moment(evt.target.value, this.props.format).isValid()
        }, moment(evt.target.value, this.props.format).isValid() ? this.props.onChange(moment(evt.target.value, this.props.format)) : this.props.onChange(moment('')));
    }

    onChangeDatePicker(date) {
        let { format } = this.props;

        this.props.onChange(moment(date, format));
        this.setState({
            date: moment(date, format),
            isValid: moment(date, this.props.format).isValid(),
            textDate: moment(date, format).format(format)
        }, this.closeDialog);
    }

    render() {
        let { className, style, labelStyle, inputStyle, inputWrapperStyle, iconStyle, labelText, errorText, helpText, format, placeholder, value } = this.props;

        let locale = moment.locale(),
            localeConfig = {
                blank: 'Selecciona una fecha',
                locale:  locale,
                headerFormat: 'DD/MM/YYYY',
                todayLabel: {
                    long: 'Hoy',
                },
                weekdays : 'Dom_Lun_Mar_Mie_Jue_Vie_Sáb'.split('_'),
                weekStartsOn: 1
            };

        let mask = format.replace(/D/g, '1').replace(/M/g, '1').replace(/Y/g, '1');

        // Styles
        let styles = {
            root: {},
            inputWrapper: {
                position: 'relative'
            },
            input: {},
            label: {},
            icon: {
                cursor: 'pointer',
                position: 'absolute',
                top: 6,
                right: 5,
                color: grey[700]
            },
            dialogContentStyle: {
                width: 352,
                height: 475
            },
            dialogBodyStyle: {
                padding: 0,
                height: 475
            }
        };

        // Merge default styles with props
        styles.root = _.merge(styles.root, style);
        styles.input = _.merge(styles.input, inputStyle);
        styles.inputWrapper = _.merge(styles.inputWrapper, inputWrapperStyle);
        styles.label = _.merge(styles.label, labelStyle);
        styles.icon = _.merge(styles.icon, iconStyle);

        let labelElement = labelText && <label style={styles.label}>{labelText}</label>;
        let errorElement = errorText && <span className="help-block text-danger">{errorText}</span>;
        let helpElement = helpText && <span className="help-block">{helpText}</span>;

        styles.input.color = this.state.isValid ? grey[700] : red[500];

        return (
            <div
                className={className}
                style={styles.root}
            >
                {labelElement}
                <div style={styles.inputWrapper}>
                    <MaskedInput
                        className="form-control"
                        style={styles.input}
                        placeholder={placeholder}
                        mask={mask}
                        maxLength={format.length}
                        onChange={this.onChangeInput}
                        onBlur={this.onBlur}
                        value={value.format(format)}
                    />
                    {this.props.showDatePicker ?
                        <i className="material-icons"
                           color={styles.icon.color}
                           style={styles.icon}
                           onClick={this.openDialog}
                        >
                            event
                        </i> : null}
                </div>
                {helpElement}
                {errorElement}

                { this.state.openDialog && (
                    <Modal
                        width={350}
                        autoFocus={false}
                        onClose={this.closeDialog}
                        body={ () => {
                            return <InfiniteCalendar
                                width={350}
                                height={320}
                                rowHeight={50}
                                selected={this.state.date}
                                locale={localeConfig}
                                onSelect={this.onChangeDatePicker}
                                minDate={this.props.minValue ? this.props.minValue.toDate() : new Date(1980, 0, 1)}
                                maxDate={this.props.maxValue ? this.props.maxValue.toDate() : new Date(2050, 11, 31)}
                            />
                        }}
                        shouldCloseOnOverlayClick={false}
                    />
                )}
            </div>
        );
    }
}

DateInput.defaultProps = {
    pattern: ['d', 'm', 'Y'],
    format: 'DD/MM/YYYY',
    placeholder: 'DD/MM/YYYY',
    showDatePicker: true
};

DateInput.propTypes = {

    /**
     * The css class name of the root element.
     */
    className: PropTypes.string,

    /**
     * Override the inline-styles of the root element.
     */
    style:  PropTypes.object,

    /**
     * Override the inline-styles of the label element.
     */
    labelStyle: PropTypes.object,

    /**
     * Override the inline-styles of the input wrapper element.
     */
    inputWrapperStyle: PropTypes.object,

    /**
     * Override the inline-styles of the input element.
     */
    inputStyle: PropTypes.object,

    /**
     * Override the inline-styles of the icon element.
     */
    iconStyle: PropTypes.object,

    /**
     * The content to use for label text.
     */
    labelText: PropTypes.string,

    /**
     * Callback function that is fired when the input's value changes.
     * Signature:
     *  function(event: object, newValue: string) => void
     *  event: Change event targeting the text field.
     */
    onChange: PropTypes.func.isRequired,

    /**
     * The value of the text input as a moment object.
     */
    value: PropTypes.object.isRequired,

    /**
     * The error text content to display.
     */
    errorText: PropTypes.string,

    /**
     * The help text content to display.
     */
    helpText: PropTypes.string,

    /**
     * The text pattern for filter the cleave.js component.
     */
    pattern: PropTypes.array,

    /**
     * The date format to display.
     */
    format: PropTypes.string.isRequired,

    /**
     * The flag that enable / disable the icon and date picker.
     */
    showDatePicker: PropTypes.bool,

    /**
     * The minimum value of the text input as a moment object.
     */
    minValue: PropTypes.object,

    /**
     * The maximum value of the text input as a moment object.
     */
    maxValue: PropTypes.object,
    /**
     * Placeholder to show in input component.
     */
    placeholder: PropTypes.string
};