/**
 * Function to sort an objects array by object property
 * @param {Array} elements Array of elements to sort
 * @param {String} field Object property to sort the array
 * @returns {Array} Array of elements sorted by field
 */
export function sortArray(elements, field) {
    elements.sort((a, b) => {
        if ((a[field]).toUpperCase() > (b[field]).toUpperCase()) return 1;
        if ((a[field]).toUpperCase() < (b[field]).toUpperCase()) return -1;
        // a must be equal to b
        return 0;
    });

    return elements;
};
