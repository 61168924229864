import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';


// Set middleware based on environment
let middleware = [thunkMiddleware];
let composeEnhancers;
if (process.env.NODE_ENV !== 'production') {
    middleware = [...middleware /*, Add more middleware if needed*/];

    // Activating ReduxDevTools
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
    middleware = [...middleware];
    composeEnhancers = compose;
}

export default function configureStore(initialState) {
    const store = createStore(rootReducer, initialState, composeEnhancers(
        applyMiddleware(...middleware)
    ));
    return store;
}