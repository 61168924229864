import {Map, fromJS, List} from "immutable";

// TODO: borrar documentos de trabajadores??
import {
    EMPLOYEE_DOCUMENTS_REQUEST, EMPLOYEE_DOCUMENTS_SUCCESS, EMPLOYEE_DOCUMENTS_ERROR,
    DOWNLOAD_DOCUMENT_REQUEST, DOWNLOAD_DOCUMENT_SUCCESS, DOWNLOAD_DOCUMENT_ERROR,
    DOWNLOAD_DOCUMENTS_REQUEST, DOWNLOAD_DOCUMENTS_SUCCESS, DOWNLOAD_DOCUMENTS_ERROR,
    UPLOAD_DOCUMENT_REQUEST, UPLOAD_DOCUMENT_SUCCESS, UPLOAD_DOCUMENT_ERROR
} from '../actions/employeeDocuments';

const employeeDocuments = (state = Map({
    documents: List([]),
    downloadDocument: Map({}),
    uploadDocument: Map({}),
    isFetching: false,
    downloadDocumentIsFetching: false,
    error: false
}), action) => {
    const payload = state.get('documents');
    let newState, index, updatedPayload;

    switch (action.type) {
        // Get Employee Documents
        case EMPLOYEE_DOCUMENTS_REQUEST:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case EMPLOYEE_DOCUMENTS_SUCCESS:
            newState = state.merge({
                documents: fromJS(action.payload),
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case EMPLOYEE_DOCUMENTS_ERROR:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            });
            return newState;
        
        // Download a single document
        case DOWNLOAD_DOCUMENT_REQUEST:
            newState = state.merge({
                downloadDocumentIsFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case DOWNLOAD_DOCUMENT_SUCCESS:
            newState = state.merge({
                downloadDocument: fromJS(action.payload),
                downloadDocumentIsFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case DOWNLOAD_DOCUMENT_ERROR:
            newState = state.merge({
                downloadDocumentIsFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            });
            return newState;
        case UPLOAD_DOCUMENT_SUCCESS:
            // Find the index of the upload document to be updated inside the List
            const actionPayload = action.payload.payload;
            index = payload.findIndex(item => item.get('id') === actionPayload.id);
            
            // Update the payload list
            if(index !== -1) {
                updatedPayload = payload.update(index, item => fromJS({
                    cliente_id: item.get('cliente_id'),
                    descargas: actionPayload.descargas,
                    estado: actionPayload.estado,
                    extension: actionPayload.extension,
                    fecha_creacion: actionPayload.fecha_creacion,
                    fecha_firma: actionPayload.fecha_firma,
                    id: actionPayload.id,
                    nombre: actionPayload.nombre,
                    requiere_firma: actionPayload.requiere_firma,
                    tamanio: actionPayload.tamanio,
                    tipo: actionPayload.tipo,
                    trabajador_id: actionPayload.trabajador_id
                }));
            }
            newState = state.merge({
                documents: updatedPayload,
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case DOWNLOAD_DOCUMENTS_REQUEST:
        case DOWNLOAD_DOCUMENTS_SUCCESS:
        case DOWNLOAD_DOCUMENTS_ERROR:
        case UPLOAD_DOCUMENT_REQUEST:
        case UPLOAD_DOCUMENT_ERROR:
        default:
            return state;
    };
};

export default employeeDocuments;
