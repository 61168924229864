import React, { Component } from 'react';
import { isEmail } from 'validator';
import { Icon } from '@blueprintjs/core';

class ForgotPasswordForm extends Component {
    constructor(props) {
        super(props);

        this.state= {
            email: ''
        };

        this.handlePressEnter = this.handlePressEnter.bind(this);
        this.forgotPassword = this.forgotPassword.bind(this);
        this.toggleScreen = this.toggleScreen.bind(this);
        this.onCloseErrorMessage = this.onCloseErrorMessage.bind(this);
        this.onChangeTextField = this.onChangeTextField.bind(this);
        this.canSubmit = this.canSubmit.bind(this);
    }

    handlePressEnter(e) {
        const ENTER = 13;
        if(e.keyCode === ENTER && this.canSubmit()) {
            this.forgotPassword();
        }
    }

    forgotPassword() {
        this.props.forgotPassword(this.state.email);
        this.setState({ email: '' });
    }

    toggleScreen() {
        this.setState({
            email: ''
        });
        this.props.cleanError();
        this.props.toggleScreen('signin');
    }

    // Error Message component event for close/hide the error
    onCloseErrorMessage() {
        this.props.cleanError();
    }

    onChangeTextField(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    canSubmit() {
        return isEmail(this.state.email);
    }

    render() {
        let form = (
            <div className="animated fadeIn">
                <div className="col" style={styles.inputWrapper}>
                    <h4 style={{ fontSize: 16, display: 'flex', justifyContent: 'center' }}>Solicita nueva contraseña</h4>
                </div>
                <form id="form-login">
                    <div className="col" style={styles.inputWrapper}>
                        <div style={{
                            display: 'flex',
                            width: '100%',
                            border: '1px solid #e0e0e0',
                            alignItems: 'center'
                        }}>
                            <div style={{ paddingLeft: 10 }}>
                                <Icon icon="user" iconSize={20} />
                            </div>
                            <input
                                style={styles.input}
                                className="form-control"
                                type="text"
                                name="email"
                                placeholder={'Email'}
                                value={this.state.email}
                                onChange={this.onChangeTextField}
                                onKeyDown={this.handlePressEnter}
                            />
                        </div>
                    </div>
                    {this.props.error ? (
                        <div className="col" style={styles.inputWrapper}>
                            <div className={`alert alert-danger`} style={styles.input} >
                                <i className="fa fa-info p-r-10" style={styles.info}/>
                                <button type="button" className="close" aria-label="Close">
                                    <span aria-hidden="true" onClick={() => this.props.cleanError()}>&times;</span>
                                </button>
                                <span>El E-Mail introducido no pertenece a ningún usuario.</span>
                            </div>
                        </div>
                    ) : null}
                    <div className="col" style={styles.inputWrapper}>
                        <button
                            key='cancel-button'
                            type="button"
                            className="btn btn-default btn-sm mr15"
                            style={{ ...styles.submitButton, cursor: 'pointer', backgroundColor: 'white', border: '1px solid #e0e0e0' }}
                            onClick={this.toggleScreen}
                        >
                            {'Volver'}
                        </button>
                        <button
                            className="btn btn-custom btn-sm"
                            style={{ ...styles.submitButton, cursor: 'pointer' }}
                            type="button"
                            onClick={this.forgotPassword}
                            disabled={!this.canSubmit()}
                        >
                            ENVIAR CONTRASEÑA
                        </button>
                    </div>
                </form>
            </div>
        );

        let successMessage = (
            <form id="form-login">
                <div className="col" style={styles.inputWrapper}>
                    <div className={`alert alert-success`} style={styles.input} >
                        <i className="fa fa-info p-r-10" style={styles.info}/>
                        <span>Se le ha enviado un correo electrónico para continuar con el proceso de cambio de contraseña</span>
                    </div>
                </div>
                <div className="col" style={styles.inputWrapper}>
                    <button
                        key='cancel-button'
                        type="button"
                        className="btn btn-primary btn-sm mr15"
                        style={{ ...styles.submitButton, cursor: 'pointer' }}
                        onClick={() => {
                            this.props.forgotPasswordSuccess();
                            this.props.cleanError();
                        }}
                    >
                        {'Volver'}
                    </button>
                </div>
            </form>
        );

        return this.props.success ? successMessage : form;


    }
}

export default ForgotPasswordForm;

const styles = {
    inputWrapper: {
        marginBottom: 25
    },
    input: {
        borderRadius: 0,
        outline: 'none',
        border: 'none',
        boxShadow: 'none'
    },
    submitButton: {
        borderRadius: 0,
        minWidth: 100,
        marginBottom: 25
    },
    info: {
        marginLeft: 0,
        marginRight: 15
    }
};