import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

// Components
import Signin from '../containers/Signin';
import ResetPassword from '../components/ResetPassword';
import AppLayout from '../containers/AppLayout';

const CustomRoute = ({ component: Component, failRedirect, restricted, isLoggedIn, ...rest }) => {

    return (
        <Route {...rest} render={props => (
            (isLoggedIn && restricted) || (!isLoggedIn && !restricted) ? (
                <Component {...props} />
            ) : (
                <Redirect to={{
                    pathname: failRedirect,
                    state: { from: props.location }
                }} />
            )
        )}/>
    );
};

class Routes extends Component {

    render() {
        return (
            <Router>
                <Switch>
                    <CustomRoute
                        exact
                        path="/signin"
                        component={Signin}
                        failRedirect="/"
                        isLoggedIn={this.props.isLoggedIn}
                    />
                    <CustomRoute
                        path="/reset/:resetToken"
                        component={ResetPassword}
                        failRedirect="/"
                        isLoggedIn={this.props.isLoggedIn}
                    />
                    <CustomRoute
                        restricted
                        path="/"
                        component={AppLayout}
                        failRedirect="/signin"
                        isLoggedIn={this.props.isLoggedIn}
                    />
                </Switch>
            </Router>
        );
    }
}

function mapStateToProps(state) {

    return {
        isLoggedIn: state.auth.session.get('isLoggedIn')
    }
}

export default connect(mapStateToProps)(Routes);
