import React, { Component } from 'react';

class ResetPasswordForm extends Component {
    constructor(props) {
        super(props);

        this.state= {
            newPassword: '',
            errorMessage: '',
            repeatPassword: ''
        };

        this.onChangeTextField = this.onChangeTextField.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
        this.canSubmit = this.canSubmit.bind(this);
        this.goTo = this.goTo.bind(this);
    }

    validatePasswords(passwordA, passwordB){
        if(!passwordA || passwordA.length <= 6 || !passwordB) {
            return false;
        }
        return passwordA === passwordB;
    }

    onChangeTextField(e) {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            let { newPassword, repeatPassword } = this.state;

            if (newPassword && newPassword.length <= 6) {
                this.setState({
                    errorMessage: 'La contraseña debe tener al menos 7 caracteres.'
                });
            } else if (newPassword !== repeatPassword) {
                this.setState({
                    errorMessage: 'Las contraseñas no coinciden'
                });
            } else {
                this.setState({
                    errorMessage: ''
                });
            }
        });
    }

    resetPassword() {
        let { newPassword, repeatPassword } = this.state;

        if(this.canSubmit()) {
            this.props.resetPassword(newPassword, repeatPassword, this.props.token);
        }
    }

    canSubmit() {
        return this.validatePasswords(this.state.newPassword, this.state.repeatPassword);
    }

    goTo(path) {
        this.props.goTo(path);
    }

    render() {
        const form = (
            <form id="forgot-password-form">
                <div className="col" style={styles.inputWrapper}>
                    <input
                        style={styles.input}
                        className="form-control"
                        type="password"
                        name="newPassword"
                        placeholder={'Contraseña'}
                        value={this.state.newPassword}
                        onChange={this.onChangeTextField}
                    />
                </div>
                <div className="col" style={styles.inputWrapper}>
                    <input
                        style={styles.input}
                        className="form-control"
                        type="password"
                        name="repeatPassword"
                        placeholder={'Reescribir contraseña'}
                        value={this.state.repeatPassword}
                        onChange={this.onChangeTextField}
                    />
                </div>
                <div className="col" style={styles.inputWrapper}>
                    <button
                        key='cancel-button'
                        type="button"
                        className="btn btn-default btn-sm mr15"
                        onClick={this.goTo.bind(this, '/signin')}
                    >
                        {'VOLVER'}
                    </button>

                    <button
                        className="btn btn-primary btn-sm"
                        type="button"
                        onClick={this.resetPassword}
                        disabled={!this.canSubmit()}
                    >
                        Modificar contraseña
                    </button>
                </div>
            </form>
        );

        const success = (
            <form id="forgot-password-form">
                <div className="col" style={styles.inputWrapper}>
                    <div className={`alert alert-success`} style={styles.input} >
                        <i className="fa fa-info p-r-10" style={styles.info}/>
                        <span>La contraseña se ha modificado correctamente.</span>
                    </div>
                </div>
                <div className="col" style={styles.inputWrapper}>
                    <button
                        key='cancel-button'
                        type="button"
                        className="btn btn-primary btn-sm mr15"
                        onClick={this.goTo.bind(this, '/')}
                    >
                        {'Ir a página principal'}
                    </button>
                </div>
            </form>
        );
        return (
            <div className="animated fadeIn">
                <div className="col" style={styles.inputWrapper}>
                    <h3 style={{ fontSize: 16 }}>Restablece tu contraseña</h3>
                </div>
                {this.props.success ? success : form}
            </div>
        );
    }
}

const styles = {
    inputWrapper: {
        marginBottom: 25
    },
    input: {
        borderRadius: 0
    },
    submitButton: {
        borderRadius: 0,
        minWidth: 100,
        marginBottom: 25
    }
};
export default ResetPasswordForm;