import React, { Component } from 'react';
import { connect } from 'react-redux';

import { checkToken, resetPassword } from '../actions/auth';
import bgImage from '../assets/img/home.jpg';
import logo from '../assets/img/logo_fenicia.png';

import ResetPasswordForm from './ResetPasswordForm';

const styles = {
    pageWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: `url(${bgImage}) center center no-repeat fixed`,
        height: '100%'
    },
    loginContainer: {
        background: 'rgba(255,255,255,.7)',
        maxWidth: '400px',
        width: '90%'
    },
    logoWrapper: {
        height: 80,
        width: '100%',
        padding: '10px',
        margin: '30px 0',
        background: `url(${logo}) center top no-repeat`,
        backgroundSize: '90% auto'
    },
    bodyWrapper: {
        padding: '10px',
        width: '100%'
    },
    footerWrapper: {
        padding: '10px',
        backgroundColor: 'rgb(134, 149, 163, 0.7)',
        color: '#fff',
        width: '100%',
        height: 50,
        fontSize: 13
    },
    submitButton: {
        borderRadius: 0,
        minWidth: 100,
        marginBottom: 25
    }
};


class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            notificationMessage: null,
            messageType: '',
            token: null
        };

        this.resetPassword = this.resetPassword.bind(this);
        this.goTo = this.goTo.bind(this);
    }

    componentWillMount() {
        let token = this.props.match.params.resetToken || null;

        if(token) {
            this.props.checkToken(token);
        }

        if(this.props.location.pathname === '/reset/' + token) {
            this.setState({
                token: token
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.checkTokenError) {
            this.setState({
                notificationMessage: nextProps.checkTokenErrorMessage,
                messageType: 'error'
            });
        }

        if(nextProps.resetPasswordSuccess) {
            this.goTo('/');
        }

        if(nextProps.resetPasswordError) {
            this.goTo('/signin');
        }
    }

    resetPassword(newPassword, verifyPassword, token) {
        this.props.resetPassword(newPassword, verifyPassword, token);
    }

    goTo(path) {
        this.props.history.push(path);
    }

    render() {
        let content = null;
        let notificationMessage = (
            <div className="col" style={styles.inputWrapper}>
                <div className={`alert alert-danger`} style={styles.input} >
                    <span>El token no es válido</span>
                </div>
                <button
                    style={styles.submitButton}
                    className="btn btn-primary btn-sm"
                    type="button"
                    onClick={this.goTo.bind(this, '/')}
                >
                    {'VOLVER'}
                </button>
            </div>
        );

        if(!this.props.checkTokenSuccess && !this.props.isLoggedIn){
            if(this.state.notificationMessage) {
                content = notificationMessage;
            } else {
                content = null;
            }
        } else {
            if(this.state.notificationMessage) {
                content = notificationMessage;
            } else {
                content = (
                    <ResetPasswordForm
                        token={this.state.token}
                        user={this.props.user}
                        success={this.props.resetPasswordSuccess}
                        resetPassword={this.resetPassword}
                        goTo={this.goTo}
                    />
                );
            }
        }

        return (
            <div style={styles.pageWrapper}>
                <div className="row" style={styles.loginContainer}>
                    <div style={styles.logoWrapper} />
                    <div style={styles.bodyWrapper}>
                        {content}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {

    // Reducers
    const sessionReducer = state.auth.session;
    const checkTokenReducer = state.auth.checkToken;
    const resetPasswordReducer = state.auth.resetPassword;

    // Session
    const isLoggedIn = sessionReducer.isLoggedIn;
    const userLoggedIn = sessionReducer.user;


    // Check Token
    const checkTokenIsFetching = checkTokenReducer.get('isFetching'),
        checkTokenError = checkTokenReducer.get('error'),
        checkTokenErrorMessage = checkTokenReducer.get('errorMessage') || '',
        checkTokenSuccess = checkTokenReducer.get('success');

    // Reset Password
    const resetPasswordIsFetching = resetPasswordReducer.get('isFetching'),
        resetPasswordError = resetPasswordReducer.get('error'),
        resetPasswordErrorCode = resetPasswordReducer.get('errorCode'),
        resetPasswordSuccess = resetPasswordReducer.get('success');

    // Look for error code in 18n strings
    let resetPasswordErrorMessage = resetPasswordError ? ('' + resetPasswordErrorCode) : '';


    // Return via props
    let user = isLoggedIn ?  userLoggedIn : false;

    return {
        isLoggedIn,
        user,
        checkTokenIsFetching, checkTokenSuccess, checkTokenError, checkTokenErrorMessage,
        resetPasswordIsFetching, resetPasswordSuccess, resetPasswordError, resetPasswordErrorMessage
    }
}

function mapDispatchToProps(dispatch) {
    return {
        checkToken: (token) => dispatch(checkToken(token)),
        resetPassword: (newPassword, verifyPassword, token) => dispatch(resetPassword(newPassword, verifyPassword, token))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);