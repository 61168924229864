import React, {Component} from 'react';

// Components
import DateInput from '../components/date-input';
import moment from "moment/moment";

class UploadPrrDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            docDate: moment(),
            selectedFile: -1
        }
    }

    sendFormData() {
        this.props.onFormData({
            fdType: 'uploadPrr',
            fecha_documento: this.state.docDate,
            prr: this.state.selectedFile
        });
    }

    updateFiltersState(key, value) {
        switch (key) {
            case 'docDate':
                this.setState({
                    docDate: value
                }, () => this.sendFormData());
                break;
            case 'file':
                this.setState({
                    selectedFile: value
                }, () => this.sendFormData());
                break;
            default:
                break;
        }
    }

    render() {
        // States
        const {
            docDate,
            selectedFile
        } = this.state;

        const styles = {
            uploadDoc: {
                display: 'flex',
                alignItems: 'center',
                padding: '15px 0',
                button: {
                    label: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 0,
                        cursor: 'pointer'
                    },
                    input: {
                        display: 'none'
                    }
                },
                selectedFileName: {
                    marginLeft: 10
                }
            },
            filtersArea: {
                wrapper: {
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    margin: '15px 0',
                    fontSize: 14
                },
                item: {
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: 20,
                    input: {
                        width: '100%'
                    }
                }
            }
        };
        const subtitleText = 'La prórroga subida se encontrará en estado "Pendiente" y deberá estar firmada electrónicamente por Fenicia del Sur.';

        return (
            <div>
                <div className="mb15" style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    color: '#076BAD'
                }} >
                    <p>CPD Asociado:</p>
                    <p>{ this.props.docName }</p>
                </div>
                <div className="mb15">
                    <span>{ subtitleText }</span>
                </div>
                <div style={styles.uploadDoc}>
                    <div
                        style={ styles.uploadDoc.button }
                        className="btn btn-custom btn-sm"
                    >
                        <label
                            style={ styles.uploadDoc.button.label }
                        >
                            <input
                                style={ styles.uploadDoc.button.input }
                                type="file"
                                onChange={(e) => this.updateFiltersState('file', e.target.files[0])}
                            />
                            <span>Seleccionar fichero</span>
                        </label>
                    </div>
                    <span style={ styles.uploadDoc.selectedFileName }>
                        { selectedFile === -1 ? 'No se ha seleccionado ningún fichero.' : selectedFile.name }
                    </span>
                </div>
                <div style={styles.filtersArea.wrapper}>
                    <div style={ { ...styles.filtersArea.item, width: 250 } }>
                        <div className="mb10 fw600">Fecha documento</div>
                        <DateInput
                            onChange={(selectedValue) => this.updateFiltersState('docDate', selectedValue)}
                            value={ docDate }
                            inputWrapperStyle={{height: 40}}
                            inputStyle={{height: 36, borderRadius: 0, border: '1px solid #ccc'}}
                            iconStyle={{top: 9, right: 12}}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default UploadPrrDialog;