import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Colors } from "@blueprintjs/core";
import { Map } from 'immutable';
import _ from 'lodash';
import moment from 'moment';

import { getControlPanelStats } from '../actions/stats';
import Echarts from '../components/echarts';

import KpiBox from '../components/KpiBox';

class StatsPage extends Component {
    componentDidMount() {
        this.props.getControlPanelStats();

        // Control what user type can access
        if(this.props.user && this.props.user.get('tipo') === 'cliente') {
            this.props.history.push('/');
        }
    }

    render() {
        const { stats } = this.props;

        const basicStyles = {
            box: {
                width: 210,
                height: 50
            },
            value: {
                fontSize: 24,
                textAlign: 'left',
                marginTop: 0
            },
            text: {
                fontSize: 16,
                textAlign: 'left',
                marginTop: 0
            }
        };

        let statistics = {};
        if (stats.getIn(['numUsers', 'signIn'])) {
            statistics = stats.getIn(['numUsers', 'signIn']).toJS();
        }

        return (
            <div style={{ margin: '30px 20px' }}>
                <div className="row">
                    <div className="col-12 col-lg-6 mb15">
                        <div className="p5">
                            <div style={{ width: '100%' }}>
                                <h4 style={{ borderBottom: '1px solid #ccc', paddingBottom: 5 }}>Clientes</h4>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', maxWidth: 100 }}>
                                    <div>
                                        <KpiBox
                                            value={stats.getIn(['numCustomers', 'total'])}
                                            text={'Totales'}
                                            boxStyle={_.merge({}, basicStyles.box)}
                                            valueStyle={_.merge({ color: '#2F3862' }, basicStyles.value)}
                                            textStyle={_.merge({ color: '#2F3862' }, basicStyles.text)}
                                        />
                                    </div>
                                    <div>
                                        <KpiBox
                                            value={stats.getIn(['numCustomers', 'active'])}
                                            text={'Activos'}
                                            boxStyle={_.merge({}, basicStyles.box)}
                                            valueStyle={_.merge({ color: Colors.GREEN4 }, basicStyles.value)}
                                            textStyle={_.merge({ color: Colors.GREEN4 }, basicStyles.text)}
                                        />
                                    </div>
                                    <div>
                                        <KpiBox
                                            value={stats.getIn(['numCustomers', 'inactive'])}
                                            text={'Inactivos'}
                                            boxStyle={_.merge({}, basicStyles.box)}
                                            valueStyle={_.merge({ color: Colors.RED4 }, basicStyles.value)}
                                            textStyle={_.merge({ color: Colors.RED4 }, basicStyles.text)}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, flexWrap: 1, alignItems: 'flex-end' }} >
                                    <h5 style={{ paddingBottom: 5, minWidth: 265, maxWidth: 500, width: '100%' }}>
                                        Últimos clientes registrados
                                    </h5>
                                    <div style={{ maxHeight: 180, overflowY: 'auto', minWidth: 265, maxWidth: 500, width: '100%' }}>
                                        {stats && stats.get('customers') && stats.get('customers').map(customer => {
                                            return (
                                                <div key={customer.get('_id')} style={{ display: 'flex', height: 30 }}>
                                                    <div style={{ width: '70%' }}>{customer.get('nombre')}</div>
                                                    <div style={{ width: '30%' }}>{moment(customer.get('fecha_creacion')).format('DD/MM/YYYY')}</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 mb15">
                        <div className="p5">
                            <div style={{ width: '100%' }}>
                                <h4 style={{ borderBottom: '1px solid #ccc', paddingBottom: 5 }}>CPD's y prórrogas</h4>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', maxWidth: 100 }}>
                                    <div>
                                        <KpiBox
                                            value={stats.getIn(['numDocuments', 'total'])}
                                            text={'Totales'}
                                            boxStyle={_.merge({}, basicStyles.box)}
                                            valueStyle={_.merge({ color: '#2F3862' }, basicStyles.value)}
                                            textStyle={_.merge({ color: '#2F3862' }, basicStyles.text)}
                                        />
                                    </div>
                                    <div>
                                        <KpiBox
                                            value={stats.getIn(['numDocuments', 'signed'])}
                                            text={'Firmados'}
                                            boxStyle={_.merge({}, basicStyles.box)}
                                            valueStyle={_.merge({ color: Colors.GREEN4 }, basicStyles.value)}
                                            textStyle={_.merge({ color: Colors.GREEN4 }, basicStyles.text)}
                                        />
                                    </div>
                                    <div>
                                        <KpiBox
                                            value={stats.getIn(['numDocuments', 'pending'])}
                                            text={'Pendientes'}
                                            boxStyle={_.merge({}, basicStyles.box)}
                                            valueStyle={_.merge({ color: Colors.RED4 }, basicStyles.value)}
                                            textStyle={_.merge({ color: Colors.RED4 }, basicStyles.text)}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, flexWrap: 1, alignItems: 'flex-end' }} >
                                    <h5 style={{ paddingBottom: 5, minWidth: 265, maxWidth: 500, width: '100%' }}>
                                        Clientes con CPD's y prórrogas pendientes
                                    </h5>
                                    <div style={{ maxHeight: 180, overflowY: 'auto', minWidth: 265, maxWidth: 500, width: '100%' }}>
                                        {stats && stats.get('pendingDocuments') && stats.get('pendingDocuments').map(pendingDocument => {
                                            return (
                                                <div key={pendingDocument.get('cliente_id')} style={{ display: 'flex', height: 30 }}>
                                                    <div style={{ width: '70%' }}>{pendingDocument.get('nombre')}</div>
                                                    <div style={{ width: '30%' }}>{pendingDocument.get('qty')}</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="p5">
                            <div style={{ width: '100%' }}>
                                <h4 style={{ borderBottom: '1px solid #ccc', paddingBottom: 5 }}>Usuarios</h4>
                            </div>
                            <div className="row mb15">
                                <div className="col-12 col-lg-8 mb15">
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className="mb15" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div>
                                                <KpiBox
                                                    value={stats.getIn(['numUsers', 'total'])}
                                                    text={'Totales'}
                                                    boxStyle={{ ...basicStyles.box, width: 150 }}
                                                    valueStyle={_.merge({ color: '#2F3862' }, basicStyles.value)}
                                                    textStyle={_.merge({ color: '#2F3862' }, basicStyles.text)}
                                                />
                                            </div>
                                            <div>
                                                <KpiBox
                                                    value={stats.getIn(['numUsers', 'active'])}
                                                    text={'Activos'}
                                                    boxStyle={{ ...basicStyles.box, width: 150 }}
                                                    valueStyle={_.merge({ color: Colors.GREEN4 }, basicStyles.value)}
                                                    textStyle={_.merge({ color: Colors.GREEN4 }, basicStyles.text)}
                                                />
                                            </div>
                                            <div>
                                                <KpiBox
                                                    value={stats.getIn(['numUsers', 'inactive'])}
                                                    text={'Inactivos'}
                                                    boxStyle={{ ...basicStyles.box, width: 150 }}
                                                    valueStyle={_.merge({ color: Colors.RED4 }, basicStyles.value)}
                                                    textStyle={_.merge({ color: Colors.RED4 }, basicStyles.text)}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <h5 style={{ minWidth: 265, maxWidth: 500, width: '100%' }}>
                                                Accesos en los últimos 12 meses
                                            </h5>
                                            {statistics && statistics.header && statistics.header.fields ? (
                                                <Echarts
                                                    data={statistics}
                                                    series={
                                                        statistics.header.fields
                                                                  .filter(f => f.fieldName !== 'month')
                                                                  .map(f => {
                                                                      return {
                                                                          type: 'bar',
                                                                          name: f.fieldName,
                                                                          itemStyle: {
                                                                              color: '#2F3862'
                                                                          },
                                                                          label: {
                                                                              show: true,
                                                                              position: 'insideBottom',
                                                                              offset: [0, 1],
                                                                              formatter: (params) => {
                                                                                  return params.data > 0 ? `${params.data}` : '';
                                                                              },
                                                                              color: 'white',
                                                                              backgroundColor: '#2F3862',
                                                                              padding: 3
                                                                          }
                                                                      }
                                                                  })
                                                    }
                                                    xAxis={{
                                                        type: 'category',
                                                        name: 'month',
                                                        inverse: false
                                                    }}
                                                    yAxis={{
                                                        type: 'value'
                                                    }}
                                                    grid={{
                                                        top: '15px',
                                                        left: '15px',
                                                        right: '15px',
                                                        bottom: '15px'
                                                    }}
                                                    style={{height: '200px'}}
                                                />
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-4 mb15">
                                    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, flexWrap: 1, alignItems: 'flex-end' }} >
                                        <h5 style={{ paddingBottom: 5, minWidth: 265, maxWidth: 500, width: '100%' }}>
                                            Últimos usuarios registrados
                                        </h5>
                                        <div style={{ maxHeight: 200, overflowY: 'auto', minWidth: 265, maxWidth: 500, width: '100%' }}>
                                            {stats && stats.get('users') && stats.get('users').map(user => {
                                                return (
                                                    <div key={user.get('_id')} style={{ display: 'flex', height: 30 }}>
                                                        <div style={{ width: '70%' }}>{user.get('full_name')}</div>
                                                        <div style={{ width: '30%' }}>{moment(user.get('fecha_creacion')).format('DD/MM/YYYY')}</div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {

    // Reducers
    const statsReducer = state.stats;

    // Stats
    const stats = statsReducer.getIn(['controlPanel', 'payload']) || Map({});

    return {
        stats
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getControlPanelStats: () => dispatch(getControlPanelStats())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StatsPage);
