import axios from 'axios';
import Auth from '../lib/auth';
import utilsLib from './utils.lib';

export const GET_CUSTOMERS_REQUEST = 'GET_CUSTOMERS_REQUEST';
export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS';
export const GET_CUSTOMERS_ERROR = 'GET_CUSTOMERS_ERROR';

export const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_ERROR = 'CREATE_CUSTOMER_ERROR';

export const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_ERROR = 'UPDATE_CUSTOMER_ERROR';

export const DELETE_CUSTOMER_REQUEST = 'DELETE_CUSTOMER_REQUEST';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_ERROR = 'DELETE_CUSTOMER_ERROR';

export const CHANGE_STATUS_CUSTOMER_REQUEST = 'CHANGE_STATUS_CUSTOMER_REQUEST';
export const CHANGE_STATUS_CUSTOMER_SUCCESS = 'CHANGE_STATUS_CUSTOMER_SUCCESS';
export const CHANGE_STATUS_CUSTOMER_ERROR = 'CHANGE_STATUS_CUSTOMER_ERROR';

// Get customers
export function getCustomers() {
    return async (dispatch) => {
        try {
            dispatch(getCustomersRequest());

            const token = Auth.getLocalJwt();
            const res = await axios.get('/api/clientes', { headers: { 'Authorization': 'Bearer ' + token } });

            dispatch(utilsLib.response(res, getCustomersSuccess, getCustomersError));
        } catch (err) {
            dispatch(utilsLib.response(err, getCustomersError));
        }
    };
};

function getCustomersRequest() {
    return {
        type: GET_CUSTOMERS_REQUEST,
        isFetching: true
    };
}

function getCustomersSuccess (payload) {
    return {
        type: GET_CUSTOMERS_SUCCESS,
        isFetching: false,
        error: false,
        payload
    };
}

function getCustomersError(errorMessage) {
    return {
        type: GET_CUSTOMERS_ERROR,
        isFetching: false,
        error: true,
        errorMessage
    };
}

// Create customer
export function createCustomer(customer) {
    return async (dispatch) => {
        try {
            dispatch(createCustomerRequest());

            const token = Auth.getLocalJwt();
            const res = await axios.post('/api/clientes', customer, { headers: { 'Authorization': 'Bearer ' + token } });

            dispatch(utilsLib.response(res, createCustomerSuccess, createCustomerError));
        } catch (err) {
            dispatch(utilsLib.response(err, createCustomerError));
        }
    };
};

function createCustomerRequest() {
    return {
        type: CREATE_CUSTOMER_REQUEST,
        isFetching: true
    };
}

function createCustomerSuccess (payload) {
    return {
        type: CREATE_CUSTOMER_SUCCESS,
        isFetching: false,
        error: false,
        payload
    };
}

function createCustomerError(errorMessage) {
    return {
        type: CREATE_CUSTOMER_ERROR,
        isFetching: false,
        error: true,
        errorMessage
    };
}

// Update customer
export function updateCustomer(customer = {}) {
    return async (dispatch) => {
        try {
            dispatch(updateCustomerRequest());

            const token = Auth.getLocalJwt();
            const res = await axios.put(`/api/clientes/${customer._id}`, customer, { headers: { 'Authorization': 'Bearer ' + token } });

            dispatch(utilsLib.response(res, updateCustomerSuccess, updateCustomerError));
        } catch (err) {
            dispatch(utilsLib.response(err, updateCustomerError));
        }
    };
};

function updateCustomerRequest() {
    return {
        type: UPDATE_CUSTOMER_REQUEST,
        isFetching: true
    };
}

function updateCustomerSuccess (payload) {
    return {
        type: UPDATE_CUSTOMER_SUCCESS,
        isFetching: false,
        error: false,
        payload
    };
}

function updateCustomerError(errorMessage) {
    return {
        type: UPDATE_CUSTOMER_ERROR,
        isFetching: false,
        error: true,
        errorMessage
    };
}

// Change customer status
export function changeCustomerStatus(customerId, status) {
    return async (dispatch) => {
        try {
            dispatch(changeCustomerStatusRequest());

            const token = Auth.getLocalJwt();
            const res = await axios.put(`/api/clientes/${customerId}/status`, { estado: status }, { headers: { 'Authorization': 'Bearer ' + token } });

            dispatch(utilsLib.response(res, changeCustomerStatusSuccess, changeCustomerStatusError));
        } catch (err) {
            dispatch(utilsLib.response(err, changeCustomerStatusError));
        }
    };
};

function changeCustomerStatusRequest() {
    return {
        type: CHANGE_STATUS_CUSTOMER_REQUEST,
        isFetching: true
    };
}

function changeCustomerStatusSuccess (payload) {
    return {
        type: CHANGE_STATUS_CUSTOMER_SUCCESS,
        isFetching: false,
        error: false,
        payload
    };
}

function changeCustomerStatusError(errorMessage) {
    return {
        type: CHANGE_STATUS_CUSTOMER_ERROR,
        isFetching: false,
        error: true,
        errorMessage
    };
}

// Delete customer
export function deleteCustomer(customerId) {
    return async (dispatch) => {
        try {
            dispatch(deleteCustomerRequest());

            const token = Auth.getLocalJwt();
            const res = await axios.delete(`/api/clientes/${customerId}`, { headers: { 'Authorization': 'Bearer ' + token } });

            dispatch(utilsLib.response(res, deleteCustomerSuccess, deleteCustomerError));
        } catch (err) {
            dispatch(utilsLib.response(err, deleteCustomerError));
        }
    };
};

function deleteCustomerRequest() {
    return {
        type: DELETE_CUSTOMER_REQUEST,
        isFetching: true
    };
}

function deleteCustomerSuccess (payload) {
    return {
        type: DELETE_CUSTOMER_SUCCESS,
        isFetching: false,
        error: false,
        payload
    };
}

function deleteCustomerError(errorMessage) {
    return {
        type: DELETE_CUSTOMER_ERROR,
        isFetching: false,
        error: true,
        errorMessage
    };
}
