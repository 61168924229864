import React, { useState } from 'react';
import moment from 'moment';

// Components
import Select from '../../components/virtualized-select';
import Loading from '../../components/Loading';
import DateInput from '../../components/date-input';

// Styles
import styles from './styles.json';

export const FiltersArea = (props) => {
    const { 
        employeesIsFetching,
        employeesFilterOptions,
        customerFilterOptions,
        prepareQuery = () => {} 
    } = props;

    // TODO: Añadir opciones múltiples en los select de los filtros
    // mirar ejemplo: http://bvaughn.github.io/react-virtualized-select/

    const [selectedCustomerId, setSelectedCustomerId] = useState(-1);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState(-1);
    const [selectedStateId, setSelectedStateId] = useState(-1);
    const [typeFilter, setTypeFilter] = useState(-1);
    const [requireSignFilter, setRequireSignFilter] = useState(-1);
    const [fromCreation, setFromCreation] = useState(moment().startOf('year'));
    const [toCreation, setToCreation] = useState(moment().endOf('year'));

    const stateFilterOptions = [
        { id: -1, estado: 'Todos' },
        { id: 'firmado', estado: 'Firmado' },
        { id: 'pendiente', estado: 'Pendiente' }
    ];

    const typeFilterOptions = [
        { id: -1, nombre: 'Todos' },
        { id: 'CPD', nombre: 'CPD' },
        { id: 'CTR', nombre: 'CTR' },
        { id: 'RA', nombre: 'RA' },
        { id: 'NIF', nombre: 'NIF' },
        { id: 'HUE', nombre: 'HUE' },
        { id: 'TRA', nombre: 'TRA' },
        { id: 'RM', nombre: 'RM' },
        { id: 'FRM', nombre: 'FRM' },
        { id: 'FES', nombre: 'FES' },
        { id: 'RIN', nombre: 'RIN' },
        { id: 'EPI', nombre: 'EPI' },
        { id: 'RIC', nombre: 'RIC' },
        { id: 'PRO', nombre: 'PRO' },
    ];

    const requireSignFilterOptions = [
        { id: -1, nombre: 'Todos' },
        { id: 'si', nombre: 'Si' },
        { id: 'no', nombre: 'No' }
    ];

    return (
        <div style={ styles.filtersArea.wrapper }>
            <div style={ styles.filtersArea.item }>
                <div className="mb10 fw600" id="Cliente">Cliente</div>
                <Select
                    options={customerFilterOptions}
                    value={selectedCustomerId}
                    labelKey='nombre'
                    valueKey='_id'
                    matchProp='nombre'
                    multi={false}
                    searchable={true}
                    clearable={false}
                    onChange={(selectedValue) => setSelectedCustomerId(selectedValue._id)}
                    parentId="Cliente"
                />
            </div>
            <div style={ styles.filtersArea.item }>
                <div className="mb10 fw600" id="Trabajador">Trabajador</div>
                <Select
                    options={employeesFilterOptions}
                    value={selectedEmployeeId}
                    labelKey='nombre'
                    valueKey='trabajador_id'
                    matchProp='nombre'
                    multi={false}
                    searchable={true}
                    clearable={false}
                    onChange={(selectedValue) => setSelectedEmployeeId(selectedValue.trabajador_id)}
                    parentId="Cliente"
                />
            </div>
            <div style={{ ...styles.filtersArea.item, width: 'unset', minWidth: 100 }}>
                <div className="mb10 fw600" id="Tipo">Tipo</div>
                <Select
                    options={typeFilterOptions}
                    value={typeFilter}
                    labelKey='nombre'
                    valueKey='id'
                    matchProp='nombre'
                    multi={false}
                    searchable={true}
                    clearable={false}
                    onChange={(selectedValue) => setTypeFilter(selectedValue.id)}
                    parentId="Tipo"
                />
            </div>
            <div style={{ ...styles.filtersArea.item, width: 150 }}>
                <div className="mb10 fw600" id="Estado">Requiere firma</div>
                <Select
                    options={requireSignFilterOptions}
                    value={requireSignFilter}
                    labelKey='nombre'
                    valueKey='id'
                    matchProp='nombre'
                    multi={false}
                    searchable={true}
                    clearable={false}
                    onChange={(selectedValue) => setRequireSignFilter(selectedValue.id)}
                    parentId="Requiere firma"
                />
            </div>
            <div style={{ ...styles.filtersArea.item, width: 150 }}>
                <div className="mb10 fw600" id="Estado">Estado</div>
                <Select
                    options={stateFilterOptions}
                    value={selectedStateId}
                    labelKey='estado'
                    valueKey='id'
                    matchProp='estado'
                    multi={false}
                    searchable={true}
                    clearable={false}
                    onChange={(selectedValue) => setSelectedStateId(selectedValue.id)}
                    parentId="Estado"
                />
            </div>
            <div style={ { ...styles.filtersArea.item, width: 150 } }>
                <div className="mb10 fw600">Creado desde</div>
                <DateInput
                    onChange={(selectedValue) => setFromCreation(selectedValue)}
                    value={fromCreation}
                    inputWrapperStyle={{height: 40}}
                    inputStyle={{ height: 36, borderRadius: 0, border: '1px solid #ccc' }}
                    iconStyle={{top: 9, right: 12}}
                />
            </div>
            <div style={ { ...styles.filtersArea.item, width: 150 } }>
                <div className="mb10 fw600">Creado hasta</div>
                <DateInput
                    onChange={(selectedValue) => setToCreation(selectedValue.endOf('day'))}
                    value={toCreation}
                    inputWrapperStyle={{height: 40}}
                    inputStyle={{ height: 36, borderRadius: 0, border: '1px solid #ccc' }}
                    iconStyle={{top: 9, right: 12}}
                />
            </div>
            
            <div style={styles.filtersArea.buttonItem}>
                <button
                    className="btn btn-custom"
                    style={styles.filtersArea.buttonItem.button}
                    onClick={() => prepareQuery({
                        cliente_id: selectedCustomerId,
                        estado: selectedStateId,
                        tipo: typeFilter,
                        requiere_firma: requireSignFilter,
                        fecha_desde: fromCreation,
                        fecha_hasta: toCreation,
                        trabajador_id: selectedEmployeeId
                    })}
                >
                    Buscar
                </button>
            </div>
            <div style={styles.filtersArea.loading}>
                <Loading
                    isFetching={employeesIsFetching}
                />
            </div>
        </div>
    );
};

export default FiltersArea;
