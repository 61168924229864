import React, { Component } from 'react';
import TextInput from '../components/text-input';

class CustomerDialog extends Component {
    constructor(props){
        super(props);

        const customer = props.customer || {};

        this.state = {
            nombre: customer.nombre || '',
            cif: customer.cif || ''
        };

        this.onChangeInput = this.onChangeInput.bind(this);
    }


    onChangeInput(inputName, inputValue) {
        this.setState({
            [inputName]: inputValue
        }, () => this.props.onChangeFormData(Object.assign({}, this.props.customer || {}, {nombre: this.state.nombre, cif: this.state.cif})));
    }

    render() {
        const { nombre, cif } = this.state;

        return (
            <div style={{ marginTop: 20 }}>
                <div className="row">
                    <div className="col">
                        <TextInput
                            value={nombre}
                            style={{ borderColor: '#ccc' }}
                            onChange={(event) => this.onChangeInput('nombre', event.target.value)}
                            labelText={'Nombre *'}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <TextInput
                            value={cif}
                            style={{ borderColor: '#ccc' }}
                            onChange={(event) => this.onChangeInput('cif', event.target.value)}
                            labelText={this.props.dialogType === 'create' ? 'CIF *' : 'CIF'}
                            disabled={this.props.dialogType === 'create' ? false : true}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default CustomerDialog;