import React, {Component} from 'react';

class DeleteDocDialog extends Component {

    render() {
        let body;

        switch (this.props.doc.get('tipo_documento')) {
            case 'cpd':
                body = (
                    <div>
                        <div className="mb15" style={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            color: '#076BAD'
                        }} >
                            <p>{ this.props.doc.get('nombre') }</p>
                        </div>
                        <p>Se va a eliminar el CPD con todos sus anexos y prórrogas, ¿estás seguro?.</p>
                    </div>
                );
                break;
            case 'prr':
                body = (
                    <div>
                        <div className="mb15" style={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            color: '#076BAD'
                        }} >
                            <p>{ this.props.doc.get('nombre') }</p>
                        </div>
                        <p>Se va a eliminar la prórroga, ¿estás seguro?.</p>
                    </div>
                );
                break;
            default:
                body = (
                    <div>
                        <div className="mb15" style={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            color: '#076BAD'
                        }} >
                            <p>{ this.props.doc.get('nombre') }</p>
                        </div>
                        <p>Se va a eliminar el documento, ¿estás seguro?.</p>
                    </div>
                );
                break;
        }

        return (
            <div>
                { body }
            </div>
        );
    }
}

export default DeleteDocDialog;