import React from 'react';

import { Checkbox } from "@blueprintjs/core";

export default class CustomersListConfiguration extends React.Component {
    constructor(props) {
        super(props);

        this.handleCheck = this.handleCheck.bind(this);
        this.handleSelectAll = this.handleSelectAll.bind(this);
        this.handleUnselectAll = this.handleUnselectAll.bind(this);
    }

    handleCheck(item) {
        let listItems = this.props.customersList.slice();

        // Update the checked item
        listItems.map(listItem => {
            if (item._id === listItem._id) {
                listItem.checked = !item.checked;
            }
            return listItem;
        });

        this.props.onUpdateCustomersList(listItems);
    }

    handleSelectAll() {
        let listItems = this.props.customersList.slice();

        // Update all items to checked
        listItems.map(listItem => {
            listItem.checked = true;
            return listItem;
        });

        this.props.onUpdateCustomersList(listItems);
    }

    handleUnselectAll() {
        let listItems = this.props.customersList.slice();

        // Update all items to checked
        listItems.map(listItem => {
            listItem.checked = false;
            return listItem;
        });

        this.props.onUpdateCustomersList(listItems);
    }

    render() {
        const listItems = this.props.customersList.map(item => {
            return (
                <div key={item._id} style={{ display: 'flex', height: 30, marginLeft: 0 }}>
                    <Checkbox
                        checked={item.checked}
                        onChange={this.handleCheck.bind(this, item)}
                        label=""
                    />
                    <div>{`${item.nombre} (${item.cif})`}</div>
                </div>
            );
        });

        return (
            <div style={{ margin: 20 }}>
                <div style={{ display: 'flex', flexDirection: 'row-reverse', marginBottom: 20 }}>
                    <div style={{ color: '#fff' }}>
                        <a className="btn btn-custom btn-sm" onClick={this.handleUnselectAll}>Deseleccionar todos</a>
                    </div>
                    <div style={{ color: '#fff', marginRight: 20 }}>
                        <a className="btn btn-custom btn-sm" onClick={this.handleSelectAll}>Seleccionar todos</a>
                    </div>
                </div>
                <div style={{ maxHeight: 350, overflowY: 'auto' }}>
                    {listItems}
                </div>
            </div>
        );
    }
}