import { grey, black } from "material-colors";

// Header
export const defaultHeaderStyle  = {
    display: 'flex',
    flexGrow: 1,
    position: 'relative',
    left: 0,
    color: black,
    textAlign: 'left',
    fontWeight: 600,
    paddingLeft: 12,
    paddingRight: 12,
    verticalAlign: 'middle',
    wordWrap: 'normal',
    whiteSpace: 'pre-line'
};

// Filter Row
export const defaultFilterRowStyle  = {
    //display: 'flex',
    flexGrow: 1,
    position: 'relative',
    left: 0,
    textAlign: 'left',
    color: grey[700],
    fontWeight: 600,
    paddingLeft: 12,
    paddingRight: 12,
    verticalAlign: 'middle',
    wordWrap: 'normal',
    whiteSpace: 'pre-line',
    borderBottom: '1px solid',
    borderBottomColor: grey[300]
};

// Table Row
export const defaultRowStyle  = {
    textAlign: 'left',
    color: black,
    fontWeight: 'normal',
    marginLeft: 0,
    marginRight: 0,
    verticalAlign: 'middle',
    borderBottom: '1px solid',
    borderBottomColor: grey[300],
    textOverflow: 'ellipsis',
    height: '100%'
};

// Footer
export const defaultFooterStyle  = {
    display: 'flex',
    flexGrow: 1,
    position: 'relative',
    left: 0,
    color: black,
    textAlign: 'left',
    fontWeight: 600,
    paddingLeft: 12,
    paddingRight: 12,
    verticalAlign: 'middle',
    wordWrap: 'normal',
    whiteSpace: 'pre-line',
    top: 10
};

// Input
export const defaultInputStyle  = {
    height: 26,
    backgroundColor: 'transparent',
    border: '1px solid',
    borderColor: grey[300],
    borderRadius: 1,
    paddingTop: 6,
    paddingBottom: 6,
    paddingRight: 6,
    paddingLeft: 6,
    width: '100%',
    fontSize: 11,
    fontWeight: 600,
    outline: 0
};


// Select
export const defaultSelectStyle  = {
    display: 'block',
    height: 26,
    lineHeight: '26px',
    backgroundColor: 'transparent',
    width: '80%',
    paddingLeft: 6,
    paddingRight: 6,
    fontSize: 11,
    fontWeight: 600,
    color: '#676a6c',
    textOverflow: 'ellipsis'
};