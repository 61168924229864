import React, {Component} from 'react';

class UpdateDocDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedFile: -1
        }
    }

    sendFormData() {
        this.props.onFormData({
            fdType: 'updateDoc',
            doc: this.state.selectedFile
        });
    }

    updateFiltersState(key, value) {
        switch (key) {
            case 'file':
                this.setState({
                    selectedFile: value
                }, () => this.sendFormData());
                break;
            default:
                break;
        }
    }

    render() {
        // States
        const { selectedFile } = this.state;

        const styles = {
            uploadDoc: {
                display: 'flex',
                alignItems: 'center',
                padding: '15px 0',
                button: {
                    label: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 0,
                        cursor: 'pointer'
                    },
                    input: {
                        display: 'none'
                    }
                },
                selectedFileName: {
                    marginLeft: 10
                }
            },
            filtersArea: {
                wrapper: {
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    margin: '15px 0',
                    fontSize: 14
                },
                item: {
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: 20,
                    input: {
                        width: '100%'
                    }
                }
            }
        };

        let docType;

        switch (this.props.doc.get('tipo_documento')) {
            case 'cpd':
                docType = 'CPD asociado:';
                break;
            case 'prr':
                docType = 'Prórroga asociada:';
                break;
            default:
                docType = 'Documento asociado:'
                break;
        }

        return (
            <div>
                <div className="mb15" style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    color: '#076BAD'
                }} >
                    <p>{ docType }</p>
                    <p>{ this.props.doc.get('nombre') }</p>
                </div>
                <div className="mb15">
                    <p>El fichero seleccionado sustituirá al fichero asociado al CPD o Prórroga anterior.</p>
                    <p>No se produce cambio de estado por lo que el fichero a subir deberá estar firmado digitalmente por una, si está en estado "Pendiente", o ambas partes, si está en estado "Firmado".</p>
                </div>
                <div style={styles.uploadDoc}>
                    <div
                        style={ styles.uploadDoc.button }
                        className="btn btn-custom btn-sm"
                    >
                        <label
                            style={ styles.uploadDoc.button.label }
                        >
                            <input
                                style={ styles.uploadDoc.button.input }
                                type="file"
                                onChange={(e) => this.updateFiltersState('file', e.target.files[0])}
                            />
                            <span>Seleccionar fichero</span>
                        </label>
                    </div>
                    <span style={ styles.uploadDoc.selectedFileName }>
                        { selectedFile === -1 ? 'No se ha seleccionado ningún fichero.' : selectedFile.name }
                    </span>
                </div>
            </div>
        );
    }
}

export default UpdateDocDialog;