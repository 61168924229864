import React from 'react';
import { Icon } from "@blueprintjs/core";
import PropTypes from "prop-types";

export default function CustomIcon(props) {
    const styles = {
        wrapper: {
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'center',
            width: props.size ? props.size + 20 : 40,
            height: props.size ? props.size + 10 : 30
        },
        wrapperHidden: {
            display: 'none',
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'center',
            width: props.size ? props.size + 20 : 40,
            height: props.size ? props.size + 10 : 30
        },
        counter: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 1,
            minWidth: 15,
            height: 15,
            padding: 2,
            borderRadius: 15,
            border: '1px solid black',
            backgroundColor: 'white',
            fontSize: 10,
            fontWeight: 'bold',
            color: 'green'
        }
    };

    let counter = props.counter ? (<span style={ styles.counter }>{ props.counter }</span>) : null;

    return (
        <div style={ { ...styles.wrapper, ...props.style } } >
            <Icon
                className={ props.className }
                title={ props.title }
                icon={ props.hidden ? null : props.iconType }
                iconSize={ props.size }
                color={ props.color }
                onClick={ props.onClick }
            />
            { counter }
        </div>
    );
};

CustomIcon.defaultProps = {
    className: 'icon-custom',
    title: '',
    hidden: false
};

CustomIcon.propTypes = {

    /**
     * ClassName passed to Icon element
     */
    className: PropTypes.string,

    /**
     * Title passed to Icon element
     */
    title: PropTypes.string,

    /**
     * Flag to show / hide the Icon element
     */
    hidden: PropTypes.bool,

    /**
     * Size of the Icon element
     */
    size: PropTypes.number,

    /**
     * Icon type (blue print icon)
     */
    iconType: PropTypes.string,

    /**
     * String hexadecimal code with the color of the Icon element
     */
    color: PropTypes.string,

    /**
     * Function dispatched on onclik event
     */
    onClick: PropTypes.func,

    /**
     * Counter wrapper overheaded to the Icon element
     */
    counter: PropTypes.number
};