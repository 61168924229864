import React from 'react';

import { Icon } from "@blueprintjs/core";

export default class OrganizationalStructureList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            listItems: []
        };

        this.calculateListItems = this.calculateListItems.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleOpenAll = this.handleOpenAll.bind(this);
        this.handleCloseAll = this.handleCloseAll.bind(this);
    }

    componentDidMount() {
        this.calculateListItems();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.orgStructure !== this.props.orgStructure) {
            this.calculateListItems();
        }
    }

    calculateListItems() {
        if (this.props.orgStructure && this.props.orgStructure.size > 0) {
            let listItems = [];

            this.props.orgStructure.get('delegaciones').forEach(delegacion => {

                // Add delegacion item
                listItems.push({
                    level: 'delegacion',
                    name: delegacion.get('nombre'),
                    id: `${delegacion.get('delegacion_id')}`,
                    opened: false
                });

                delegacion.get('oficinas').forEach(oficina => {

                    // Add oficina item
                    listItems.push({
                        level: 'oficina',
                        name: oficina.get('nombre'),
                        id: `${delegacion.get('delegacion_id')}_${oficina.get('oficina_id')}`,
                        delegacion_id: `${delegacion.get('delegacion_id')}`,
                        opened: false,
                        show: false
                    });

                    oficina.get('centros').forEach(centro => {

                        // Add centro item
                        listItems.push({
                            level: 'centro',
                            name: centro.get('nombre'),
                            id: `${delegacion.get('delegacion_id')}_${oficina.get('oficina_id')}_${centro.get('centro_id')}`,
                            delegacion_id: `${delegacion.get('delegacion_id')}`,
                            oficina_id: `${oficina.get('oficina_id')}`,
                            show: false
                        });
                    });
                });
            });

            this.setState({
                listItems
            });
        }
    }

    handleClick(item) {
        let listItems = this.state.listItems.slice();

        listItems.map(listItem => {
            if (item.level === 'delegacion' && listItem.level === 'delegacion' && item.id === listItem.id) {
                listItem.opened = !listItem.opened;
            }
            if (item.level === 'delegacion' && listItem.level === 'oficina' && item.id === listItem.delegacion_id) {
                listItem.opened = item.opened;
                listItem.show = !listItem.show;
            }
            if (item.level === 'delegacion' && listItem.level === 'centro' && item.id === listItem.delegacion_id) {
                listItem.show = item.opened;
            }
            if (item.level === 'oficina' && listItem.level === 'oficina' && item.id === listItem.id) {
                listItem.opened = !listItem.opened;
            }
            if (item.level === 'oficina' && listItem.level === 'centro' && item.id.substr(item.id.indexOf('_') + 1) === listItem.oficina_id) {
                listItem.show = !listItem.show;
            }
            return listItem;
        });

        this.setState({
            listItems
        });
    }

    handleOpenAll() {
        let listItems = this.state.listItems.slice();

        listItems.map(listItem => {
            switch (listItem.level) {
                case 'delegacion':
                    listItem.opened = true;
                    break;
                case 'oficina':
                    listItem.opened = true;
                    listItem.show = true;
                    break;
                case 'centro':
                    listItem.show = true;
                    break;
                default:
                    return listItem;
            }
            return listItem;
        });

        this.setState({
            listItems
        });
    }

    handleCloseAll() {
        let listItems = this.state.listItems.slice();

        listItems.map(listItem => {
            switch (listItem.level) {
                case 'delegacion':
                    listItem.opened = false;
                    break;
                case 'oficina':
                    listItem.opened = false;
                    listItem.show = false;
                    break;
                case 'centro':
                    listItem.show = false;
                    break;
                default:
                    return listItem;
            }
            return listItem;
        });

        this.setState({
            listItems
        });
    }

    render() {
        const styles = {
            delegacion: {
                display: 'flex',
                height: 30,
                fontWeight: 'bold',
                marginLeft: 0
            },
            oficina: {
                display: 'flex',
                height: 30,
                marginLeft: 30
            },
            centro: {
                height: 30,
                marginLeft: 90
            }
        };

        const listItems = this.state.listItems.map(item => {
            let itemContent = null;

            if (item.show || item.level === 'delegacion') {
                itemContent = (
                    <div key={item.id} style={styles[item.level]}>
                        {item.level === 'delegacion' || item.level === 'oficina' ? (
                            <div style={{ width: 18, marginRight: 10 }}>
                                <Icon
                                    className="icon-custom"
                                    title={false}
                                    icon={item.opened ? 'caret-down' : 'caret-right'}
                                    iconSize={18}
                                    onClick={this.handleClick.bind(this, item)}
                                />
                            </div>
                        ) : null}
                        {item.level === 'delegacion' ? (<div>{`${item.name} (${item.id})`}</div>) : null}
                        {item.show && item.level === 'oficina' ? (<div>{`${item.name} (${item.id.substr(item.id.indexOf('_') + 1)})`}</div>) : null}
                        {item.show && item.level === 'centro' ? (<div>{`${item.name} (${item.id.substr(item.id.lastIndexOf('_') + 1)})`}</div>) : null}
                    </div>
                );
            }
            return itemContent;
        });

        return (
            <div>
                <div style={{ display: 'flex', flexDirection: 'row-reverse', marginBottom: 20 }}>
                    <div style={{ color: '#fff' }}>
                        <a className="btn btn-custom btn-sm" onClick={this.handleCloseAll}>Contraer todos</a>
                    </div>
                    <div style={{ marginRight: 20, color: '#fff' }}>
                        <a className="btn btn-custom btn-sm" onClick={this.handleOpenAll}>Expandir todos</a>
                    </div>
                </div>
                {listItems}
            </div>
        );
    }
}