import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class NotFoundPage extends Component {

    render() {
        return (
            <div className="animated fadeIn" style={{paddingTop: 100}}>
                <div className="text-center">
                    <i className="material-icons" style={{fontSize: 70, color: 'red'}}>error_outline</i>
                </div>
                <div className="mt40 mb25 text-center">
                    <h1 className="mt30 fs26">{'Página no encontrada'}</h1>
                    <div className="mt30">
                        <Link to="/">{'Ir a la página principal'}</Link> | <a onClick={this.props.history.goBack}>{'Volver a la página anterior'}</a>
                    </div>
                </div>
            </div>
        );
    }
}