import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import classnames from 'classnames';
import { connect } from 'react-redux';

// Components
import EmployeePage from './Employee/EmployeePage';
import EmployeeDocuments from './EmployeeDocuments/EmployeeDocuments';
import EmployeeStatsPage from './EmployeeStatsPage';
import {Map} from "immutable";

class EmployeesPanelPage extends Component {
    constructor(props){
        super(props);

        this.state = {
            tabIndex: this.getSelectedIndex()
        };

        // Control what user type can access
        if(this.props.user && this.props.user.get('tipo') !== 'administrador') {
            this.props.history.push('/');
        }

        this.getSelectedIndex = this.getSelectedIndex.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    getSelectedIndex() {

        // Check if any child route is active. As last step, check if principal route is active to assign default tab index.
        switch(this.props.history.location.pathname) {
            case '/trabajadores':
                return 0;
            case '/trabajadores/buscador-trabajadores':
                return 1;
            case '/trabajadores/buscador-documentos':
                return 2;
            default:
                return 0;
        }
    }

    handleTabChange(index, lastIndex, event) {
        let route = '';

        switch(index) {
            case 0:
                route = '/trabajadores';
                break;
            case 1:
                route = '/trabajadores/buscador-trabajadores';
                break;
            case 2:
                route = '/trabajadores/buscador-documentos';
                break;
            default:
                break;
        }

        this.props.history.push(route);
        this.setState({
            tabIndex: this.getSelectedIndex()
        });
    }

    render() {
        let { tabIndex } = this.state;

        return (
            <div>
                <div className="card" style={{ marginBottom: 20 }}>
                    <div className="card-body">
                        <Tabs
                            selectedIndex={tabIndex}
                            onSelect={this.handleTabChange}
                            selectedTabClassName="active"
                        >
                            <TabList className="nav nav-tabs nav-tabs-simple">
                                <Tab className="nav-item">
                                    <a className={classnames({ active: tabIndex === 0})}>Resumen</a>
                                </Tab>
                                <Tab className="nav-item">
                                    <a className={classnames({ active: tabIndex === 1})}>Buscador de trabajadores</a>
                                </Tab>
                                <Tab className="nav-item">
                                    <a className={classnames({ active: tabIndex === 2})}>Buscador de documentos</a>
                                </Tab>
                            </TabList>

                            <TabPanel>
                                <EmployeeStatsPage {...this.props} />
                            </TabPanel>
                            <TabPanel>
                                <EmployeePage {...this.props} />
                            </TabPanel>
                            <TabPanel>
                                <EmployeeDocuments {...this.props} />
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {

    // Reducers
    const sessionReducer = state.auth.session;

    // Session
    const user = sessionReducer.get('user') || Map({});

    return {
        user
    };
}

export default connect(mapStateToProps)(EmployeesPanelPage);