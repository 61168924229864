import {Map, List} from "immutable";

import {
    CREATE_SIGN_REQUEST,
    CREATE_SIGN_SUCCESS,
    CREATE_SIGN_ERROR
} from '../actions/signs';

const signs = (state = Map({
  payload: List([]),
  isFetching: false,
  error: false
}), action) => {
  let newState;
  switch (action.type) {

      // Request
      case CREATE_SIGN_REQUEST:
          newState = state.merge({
              isFetching: action.isFetching,
              error: action.error
          });
          return newState;

      // Success
      case CREATE_SIGN_SUCCESS:

          const urlData = 'appfenicia://' + action.payload;
          window.location = urlData;

          newState = state.merge({
              isFetching: action.isFetching,
              error: action.error,
              payload: action.payload
          });
          return newState;

      // Errors
      case CREATE_SIGN_ERROR:
          newState = state.merge({
              isFetching: action.isFetching,
              error: action.error,
              errorMessage: action.errorMessage
          });
          return newState;
      default:
          return state;
  }
};

export default signs;
