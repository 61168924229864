import React, { Component}  from 'react';
import { connect } from 'react-redux';
import {List, Map} from "immutable";

import Header from '../components/Header';
import MainRoutes from '../routes/MainRoutes';
import { AppToaster } from '../components/toaster';

import { markNotificationAsShown, deleteNotification } from '../actions/notifications';
import { signout } from '../actions/auth';

class AppLayout extends Component {

    componentDidUpdate() {
        const { notifications } = this.props;

        if (notifications && notifications.size > 0) {
            notifications.forEach(notification => {
                const notificationId = notification.get('notificationId');
                if (!notification.get('shown')) {

                    // onDismiss is a function called when the notification closes. We use this to delete the notification from Redux store.
                    notification = notification.set('onDismiss', () => this.props.deleteNotification(notificationId));

                    // Show the notification in app toaster if not already shown
                    AppToaster.show(notification.toJS());

                    // Immediately mark the notification as shown
                    this.props.markNotificationAsShown(notificationId);
                }
            });
        }
    }

    render() {
        return [
            <Header
                key="header"
                {...this.props}
            /> ,
            <div
                key="content"
                id="content"
                className="container-fluid"
            >
                <MainRoutes />
            </div>
        ];
    }
}

function mapStateToProps(state) {

    // Reducers
    const notificationsReducer = state.notifications;
    const sessionReducer = state.auth.session;

    // Notifications
    const notifications = notificationsReducer || List([]);

    // Session
    const user = sessionReducer.get('user') || Map({});

    return {
        notifications,
        user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        markNotificationAsShown: (notificationId) => dispatch(markNotificationAsShown(notificationId)),
        deleteNotification: (notificationId) => dispatch(deleteNotification(notificationId)),
        signout: () => dispatch(signout())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);