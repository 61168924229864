import axios from 'axios';
import Auth from '../lib/auth';

export const EMPLOYEES_REQUEST = 'EMPLOYEES_REQUEST';
export const EMPLOYEES_SUCCESS = 'EMPLOYEES_SUCCESS';
export const EMPLOYEES_ERROR = 'EMPLOYEES_ERROR';

export const EMPLOYEE_DETAILS_REQUEST = 'EMPLOYEE_DETAILS_REQUEST';
export const EMPLOYEE_DETAILS_SUCCESS = 'EMPLOYEE_DETAILS_SUCCESS';
export const EMPLOYEE_DETAILS_ERROR = 'EMPLOYEE_DETAILS_ERROR';

// Get Employees actions
export function getEmployees(query) {
    return async dispatch => {
        try {
            const token = Auth.getLocalJwt();
            dispatch(getEmployeesRequest());
            const res = await axios.post('/api/trabajadores/query', query, { headers: { 'Authorization': 'Bearer ' + token } });
            if (res.data && res.data.status === 1) {
                dispatch(getEmployeesSuccess(res.data.payload));
            } else {
                dispatch(getEmployeesError(res.data.payload));
            }
        } catch (error) {
            dispatch(getEmployeesError(error));
        }
    }
}

// Get Employee Details actions
export function getEmployeeDetails(trabajadorId) {
    return async dispatch => {
        try {
            const token = Auth.getLocalJwt();
            dispatch(getEmployeeDetailsRequest());
            const res = await axios.get(`/api/trabajadores/${trabajadorId}`, { headers: { 'Authorization': 'Bearer ' + token } });
            if (res.data && res.data.status === 1) {
                dispatch(getEmployeeDetailsSuccess(res.data.payload));
            } else {
                dispatch(getEmployeeDetailsError(res.data.payload));
            }
        } catch (error) {
            dispatch(getEmployeeDetailsError(error));
        }
    }
}

function getEmployeesRequest() {
    return {
        type: 'EMPLOYEES_REQUEST',
        isFetching: true
    };
}

function getEmployeesSuccess(payload) {
    return {
        type: 'EMPLOYEES_SUCCESS',
        isFetching: false,
        error: false,
        payload
    };
}

function getEmployeesError(errorMessage) {
    return {
        type: 'EMPLOYEES_ERROR',
        isFetching: false,
        error: true,
        errorMessage
    };
}

function getEmployeeDetailsRequest() {
    return {
        type: 'EMPLOYEE_DETAILS_REQUEST',
        isFetching: true
    };
}

function getEmployeeDetailsSuccess(payload) {
    return {
        type: 'EMPLOYEE_DETAILS_SUCCESS',
        isFetching: false,
        error: false,
        payload
    };
}

function getEmployeeDetailsError(errorMessage) {
    return {
        type: 'EMPLOYEE_DETAILS_ERROR',
        isFetching: false,
        error: true,
        errorMessage
    };
}
