import React, { Component } from 'react';
import { connect } from 'react-redux';

// Actions
import { changeOwnUserPassword } from '../actions/auth';

import bgImage from '../assets/img/home3.jpg';

import TextInput from '../components/text-input';

const styles = {
    pageWrapper: {
        background: `url(${bgImage}) center 60px no-repeat fixed`,
        backgroundSize: '100% auto',
        height: '320px',
        marginTop: -15,
        marginRight: -15,
        marginLeft: -15
    },
    cardWrapper: {
        justifyContent: 'center',
        marginRight: 0,
        marginLeft: 0
    },
    card: {
        background: '#fff',
        marginTop: 140,
        minHeight: 100
    }
};

class UserProfile extends Component {
    constructor(props){
        super(props);

        this.state = {
            currentPassword: '',
            password: '',
            verifyPassword: '',
            validPassword: false,
            passwordMatches: false,
            canSubmit: false,
            showChangePasswordForm: false
        };

        this.onChangeInput = this.onChangeInput.bind(this);
        this.verifyForm = this.verifyForm.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.resetFormData = this.resetFormData.bind(this);
    }

    onChangeInput(inputName, inputValue) {
        this.setState({
            [inputName]: inputValue,
            ...(inputName === 'password') ? { validPassword: inputValue.length > 5 } : {},
            ...(inputName === 'password') ? { passwordMatches: inputValue === this.state.verifyPassword } : {},
            ...(inputName === 'verifyPassword') ? { passwordMatches: inputValue === this.state.password } : {}
        }, this.verifyForm);
    }

    verifyForm() {
        let { currentPassword, password, verifyPassword } = this.state;

        let canSubmit = currentPassword && password.length > 5 && password === verifyPassword;
        this.setState({
            canSubmit: canSubmit
        });
    }

    changePassword() {
        let { currentPassword, password, verifyPassword } = this.state;

        if(this.state.canSubmit) {
            let data = {
                currentPassword: currentPassword,
                newPassword: password,
                verifyPassword: verifyPassword
            };

            this.props.changeOwnUserPassword(data);
            this.resetFormData();
        }
    }

    resetFormData() {
        this.setState({
            currentPassword: '',
            password: '',
            verifyPassword: '',
            validPassword: false,
            passwordMatches: false,
            canSubmit: false,
            showChangePasswordForm: false
        });
    }

    render() {
        let { user } = this.props;
        const { showChangePasswordForm, currentPassword, password, verifyPassword, validPassword, passwordMatches, canSubmit } = this.state;

        return (
            <div style={styles.pageWrapper}>
                <div className="row" style={styles.cardWrapper}>
                    <div className="col-10 col-lg-6 card" style={styles.card}>
                        <div className="card-body">
                            <h4 className="card-title">Hola {user.get('nombre')}!</h4>
                            <h6 className="card-subtitle mb-2 text-muted">Esta es la página de tu perfil de usuario</h6>
                            <br/>
                            <p>Estos son tus datos:</p>
                            <br/>
                            <p>Nombre y Apellidos: <b>{user.get('full_name')}</b></p>
                            <p>Email: <b>{user.get('email')}</b></p>
                            <p>Estado: <span className="badge badge-success">{capitalizeFirstLetter(user.get('estado'))}</span></p>
                            {user.get('tipo') === 'cliente' ? (
                                <p>Empresa: <b>{user.getIn(['cliente_id', 0, 'nombre'])} - CIF: {user.getIn(['cliente_id', 0, 'cif'])}</b></p>
                            ) : (
                                <div>
                                    <p>Perfil de usuario: <b>{capitalizeFirstLetter(user.get('tipo'))}</b></p>
                                    <p>Empresas:</p>
                                    <ul>
                                    {user.get('cliente_id') && user.get('cliente_id').toJS().map( (empresa, index) => {
                                        return <li key={index}><b>{empresa.nombre}</b> - CIF: {empresa.cif}</li>;
                                    })}
                                    </ul>
                                </div>
                            )}


                            <br/>
                            {!showChangePasswordForm ? (
                                <a onClick={ () => this.setState({showChangePasswordForm: true})} className="btn btn-custom btn-sm">Cambiar contraseña</a>
                            ) : null}

                            {this.state.showChangePasswordForm ? (
                                <div style={{ marginTop: 20 }}>
                                    <div className="row">
                                        <div className="col-6">
                                            <TextInput
                                                type={'password'}
                                                value={currentPassword}
                                                style={{ borderColor: '#ccc' }}
                                                onChange={(event) => this.onChangeInput('currentPassword', event.target.value)}
                                                labelText={'Contraseña actual *'}
                                                errorText={!currentPassword ? 'Su contraseña actual no puede estar vacía' : null}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <TextInput
                                                type={'password'}
                                                value={password}
                                                style={{ borderColor: '#ccc' }}
                                                onChange={(event) => this.onChangeInput('password', event.target.value)}
                                                labelText={'Nueva contraseña *'}
                                                helpText={validPassword ? 'Al menos 6 caracteres' : null}
                                                errorText={!validPassword ? 'Contraseña demasiado corta' : null}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <TextInput
                                                type={'password'}
                                                value={verifyPassword}
                                                style={{ borderColor: '#ccc' }}
                                                onChange={(event) => this.onChangeInput('verifyPassword', event.target.value)}
                                                labelText={'Vuelve a introducir la contraseña *'}
                                                helpText={passwordMatches ? 'Las contraseñas coinciden' : null}
                                                errorText={!passwordMatches ? 'Las contraseñas no coinciden' : null}
                                            />
                                        </div>
                                    </div>
                                    <div className="row p15 text-right">
                                        <button
                                            key="cancel"
                                            type="button"
                                            className="btn btn-sm"
                                            style={{
                                                color: 'gray',
                                                marginRight: 15
                                            }}
                                            onClick={() => this.resetFormData()}
                                        >
                                            {'Cancelar'}
                                        </button>
                                        {canSubmit ? (
                                            <button
                                                key="accept"
                                                type="button"
                                                className="btn btn-sm btn-success"
                                                onClick={() => this.changePassword()}
                                            >
                                                {'Cambiar contraseña'}
                                            </button>
                                        ) : null}

                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


function mapStateToProps(state) {

    // Reducers
    const sessionReducer = state.auth.session;

    // Session
    const isLoggedIn = sessionReducer.isLoggedIn,
        user = sessionReducer.get('user');


    return {
        isLoggedIn, user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeOwnUserPassword: (data) => dispatch(changeOwnUserPassword(data)),

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
