import {Map, fromJS, List} from "immutable";

import {
    DOCUMENTS_REQUEST, DOCUMENTS_SUCCESS, DOCUMENTS_ERROR,
    UPLOAD_CPD_ERROR, UPLOAD_CPD_REQUEST, UPLOAD_CPD_SUCCESS,
    UPDATE_CPD_REQUEST, UPDATE_CPD_SUCCESS, UPDATE_CPD_ERROR,
    DELETE_CPD_REQUEST, DELETE_CPD_SUCCESS, DELETE_CPD_ERROR,
    UPLOAD_ATTACH_REQUEST, UPLOAD_ATTACH_SUCCESS, UPLOAD_ATTACH_ERROR,
    DELETE_ATTACH_REQUEST, DELETE_ATTACH_SUCCESS, DELETE_ATTACH_ERROR,
    UPLOAD_PRR_REQUEST, UPLOAD_PRR_SUCCESS, UPLOAD_PRR_ERROR,
    UPDATE_PRR_REQUEST, UPDATE_PRR_SUCCESS, UPDATE_PRR_ERROR,
    DELETE_PRR_REQUEST, DELETE_PRR_SUCCESS, DELETE_PRR_ERROR,
    CLEAN_DOCUMENTS
} from '../actions/documents';

const documents = (state = Map({
    payload: List([]),
    isFetching: false,
    error: false
}), action) => {
    const payload = state.get('payload');
    let newState, index, updatedPayload;

    switch (action.type) {
        // Get Documents
        case DOCUMENTS_REQUEST:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case DOCUMENTS_SUCCESS:
            newState = state.merge({
                payload: fromJS(action.payload),
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case DOCUMENTS_ERROR:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            });
            return newState;

        // Upload CPD
        case UPLOAD_CPD_REQUEST:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case UPLOAD_CPD_SUCCESS:

            // Find the index of the cpd to be inserted inside the List
            index = payload.findIndex( doc => {
                    return doc.get('tipo_documento') === 'cpd' && doc.get('fecha_documento') > action.cpd.fecha_documento;
            });
            // Update the payload list
            if(index === -1) {
                updatedPayload = payload.insert(payload.size, fromJS(action.cpd));
            } else {
                updatedPayload = payload.insert(index, fromJS(action.cpd));
            }

            newState = state.merge({
                payload: updatedPayload,
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;

        case UPLOAD_CPD_ERROR:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            });
            return newState;

        // Update CPD
        case UPDATE_CPD_REQUEST:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case UPDATE_CPD_SUCCESS:
            // Find the index of the cpd to be updated inside the List
            index = payload.findIndex( item => item.get('_id') === action.cpd._id );
            // Update the payload list
            if(index !== -1) {
                updatedPayload = payload.update(index, item => fromJS(action.cpd));
            }
            newState = state.merge({
                payload: updatedPayload,
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case UPDATE_CPD_ERROR:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            });
            return newState;

        // Delete CPD
        case DELETE_CPD_REQUEST:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case DELETE_CPD_SUCCESS:
            updatedPayload = payload.filter( doc => {
                if (doc.get('tipo_documento') === 'prr') {
                    return doc.get('cpd_id') !== action.cpd._id;
                }
                return doc.get('_id') !== action.cpd._id;
            });
            newState = state.merge({
                payload: updatedPayload,
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case DELETE_CPD_ERROR:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            });
            return newState;

        // Upload Attach
        case UPLOAD_ATTACH_REQUEST:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case UPLOAD_ATTACH_SUCCESS:
            // Find the index of the cpd to be updated inside the List
            index = payload.findIndex( item => item.get('_id') === action.cpd._id );
            // Update the payload list
            if(index !== -1) {
                updatedPayload = payload.update(index, item => fromJS(action.cpd));
            }
            newState = state.merge({
                payload: updatedPayload,
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case UPLOAD_ATTACH_ERROR:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            });
            return newState;

        // Delete Attach
        case DELETE_ATTACH_REQUEST:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case DELETE_ATTACH_SUCCESS:
            // Find the index of the cpd to be updated inside the List
            index = payload.findIndex( item => item.get('_id') === action.cpd._id );
            // Update the payload list
            if(index !== -1) {
                updatedPayload = payload.update(index, item => fromJS(action.cpd));
            }
            newState = state.merge({
                payload: updatedPayload,
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case DELETE_ATTACH_ERROR:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            });
            return newState;

        // Upload Prorogation
        case UPLOAD_PRR_REQUEST:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case UPLOAD_PRR_SUCCESS:
            // Find the index of the cpd of the prorogation to be added inside the List
            index = payload.findIndex( item => item.get('_id') === action.prr.cpd_id );
            // Update the payload list
            if(index !== -1) {
                updatedPayload = payload.insert(index + 1, fromJS(action.prr));
            }
            newState = state.merge({
                payload: updatedPayload,
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case UPLOAD_PRR_ERROR:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            });
            return newState;

        // Update Prorogation
        case UPDATE_PRR_REQUEST:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case UPDATE_PRR_SUCCESS:
            // Find the index of the prorogation to be updated inside the List
            index = payload.findIndex( item => item.get('_id') === action.prr._id );
            // Update the payload list
            if(index !== -1) {
                updatedPayload = payload.update(index, item => fromJS(action.prr));
            }
            newState = state.merge({
                payload: updatedPayload,
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case UPDATE_PRR_ERROR:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            });
            return newState;

        // Delete Prorogation
        case DELETE_PRR_REQUEST:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case DELETE_PRR_SUCCESS:
            // Find the index of the prorogation to be deleted inside the List
            index = payload.findIndex( item => item.get('_id') === action.prr._id );
            // Update the payload list
            if(index !== -1) {
                updatedPayload = payload.delete(index);
            }
            newState = state.merge({
                payload: updatedPayload,
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case DELETE_PRR_ERROR:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            });
            return newState;

        // Clean Documents
        case CLEAN_DOCUMENTS:
            newState = state.merge({
                payload: fromJS(action.payload),
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;

        default:
            return state;
    }
};

export default documents;
