import {Map, fromJS} from "immutable";

import {ORG_STRUCTURE_REQUEST, ORG_STRUCTURE_SUCCESS, ORG_STRUCTURE_ERROR} from '../actions/organizationalStructure';

const organizationalStructure = (state = Map({
    payload: Map({}),
    isFetching: false,
    error: false
}), action) => {
    let newState;
    switch (action.type) {
        // Get Documents
        case ORG_STRUCTURE_REQUEST:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case ORG_STRUCTURE_SUCCESS:
            newState = state.merge({
                payload: fromJS(action.payload),
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case ORG_STRUCTURE_ERROR:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            });
            return newState;
        default:
            return state;
    }
}

export default organizationalStructure;
