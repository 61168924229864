
/**
 * Prepare docs_JSON to send it
 * @param docs, all documents in redux store
 * @param selectedDocs, Set of _id's of the selected documents to sign
 */
export function prepareDocsJSON(docs, selectedDocs) {
    let cpdIds = [], prrIds = [];

    docs.map( doc => {
        if ([...selectedDocs].includes(doc.get('_id'))) {
            switch (doc.get('tipo_documento')) {
                case 'cpd':
                    return cpdIds.push(doc.get('_id'));
                case 'prr':
                    return prrIds.push(doc.get('_id'));
                default:
                    return null;
            }
        } else {
            return null;
        }
    });

    return {
        cpds: cpdIds,
        prorrogas: prrIds
    }
}