import { fromJS, List } from 'immutable';
import moment from 'moment';
import { Intent } from '@blueprintjs/core';

import { ADD_NOTIFICATION, MARK_NOTIFICATION_AS_SHOWN, DELETE_NOTIFICATION } from '../actions/notifications';

const initialState = List([]);

export default function notifications(state = initialState, action) {
    let newState, index;

    switch (action.type) {

        /* Add notification. Notification object has this fields:
        *
        *   message - String, mandatory.
        *   color - String, optional. Use one of these: 'green', 'yellow', 'red'. They will be translated to BlueprintJS constants.
        *   icon - String, optional. BlueprintJS icon string.
        *   timeout - Number, optional. By default notifications will close in 5000ms.
        **/
        case ADD_NOTIFICATION:
            let newNotification = fromJS(action.notification);

            // Prepare aditional fields or those that need custom logic

            // notificationId contains the UNIX Timestamp in milliseconds --> http://momentjs.com/docs/#/displaying/unix-timestamp-milliseconds/
            const timestamp = moment().valueOf();
            newNotification = newNotification.set('notificationId', timestamp);

            // shown defaults to false, in order to force the notification to be shown to the user
            newNotification = newNotification.set('shown', false);

            // intent reflects the color of the notifications. Uses Blueprint's Intent: https://github.com/palantir/blueprint/blob/e4bcdf2c/packages/core/src/common/intent.ts#L12
            let intent = Intent.NONE;
            switch (newNotification.get('color')) {
                case 'green':
                    intent = Intent.SUCCESS;
                    break;
                case 'yellow':
                    intent = Intent.WARNING;
                    break;
                case 'red':
                    intent = Intent.DANGER;
                    break;
                default:
                    intent = Intent.PRIMARY;
            }
            newNotification = newNotification.set('intent', intent);
            newNotification = newNotification.delete('color');

            newState = state.push(newNotification);
            return newState;
        case MARK_NOTIFICATION_AS_SHOWN:

            // Find the index of the notification to be marked as shown inside the List
            index = state.findIndex(item => item.get('notificationId') === action.notificationId);

            // Update the list
            if (index !== -1) {
                newState = state.update(index, () => state.get(index).set('shown', true));
            }
            return newState;
        case DELETE_NOTIFICATION:

            // Delete the notification by filtering based on notificationId
            newState = state.filter(item => item.get('notificationId') !== action.notificationId);
            return newState;
        default:
            return state;
    }
}