import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import classnames from 'classnames';
import { connect } from 'react-redux';

// Components
import StatsPage from './StatsPage';
import CustomersPage from './CustomersPage';
import UsersPage from './UsersPage';
import DocumentsPage from './DocumentsPage';
import AuditLogPage from './AuditLogPage';
import OrganizationalStructurePage from './OrganizationalStructurePage';
import {Map} from "immutable";


class ControlPanelPage extends Component {
    constructor(props){
        super(props);

        this.state = {
            tabIndex: this.getSelectedIndex()
        };

        this.getSelectedIndex = this.getSelectedIndex.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
    }

    getSelectedIndex() {

        // Check if any child route is active. As last step, check if principal route is active to assign default tab index.
        switch(this.props.history.location.pathname) {
            case '/panel-control':
                return 0;
            case '/panel-control/clientes':
                return 1;
            case '/panel-control/usuarios':
                return 2;
            case '/panel-control/documentos':
                return 3;
            case '/panel-control/auditoria':
                return 4;
            case '/panel-control/estructura-organizativa':
                return 5;
            default:
                return 0;
        }
    }

    handleTabChange(index, lastIndex, event) {
        let route = '';

        switch(index) {
            case 0:
                route = '/panel-control';
                break;
            case 1:
                route = '/panel-control/clientes';
                break;
            case 2:
                route = '/panel-control/usuarios';
                break;
            case 3:
                route = '/panel-control/documentos';
                break;
            case 4:
                route = '/panel-control/auditoria';
                break;
            case 5:
                route = '/panel-control/estructura-organizativa';
                break;
            default:
                break;
        }

        this.props.history.push(route);
        this.setState({
            tabIndex: this.getSelectedIndex()
        });
    }

    render() {
        let { tabIndex } = this.state;

        return (
            <div>
            <h1 style={{ fontSize: 20 }}>Panel de control</h1>
                <div className="card" style={{ marginBottom: 20 }}>
                    <div className="card-body">
                        <Tabs
                            selectedIndex={tabIndex}
                            onSelect={this.handleTabChange}
                            selectedTabClassName="active"
                        >
                            <TabList className="nav nav-tabs nav-tabs-simple">
                                <Tab className="nav-item">
                                    <a className={classnames({ active: tabIndex === 0})}>Resumen</a>
                                </Tab>
                                <Tab className="nav-item">
                                    <a className={classnames({ active: tabIndex === 1})}>Clientes</a>
                                </Tab>
                                <Tab className="nav-item">
                                    <a className={classnames({ active: tabIndex === 2})}>Usuarios</a>
                                </Tab>
                                <Tab className="nav-item">
                                    <a className={classnames({ active: tabIndex === 3})}>Documentos</a>
                                </Tab>
                                {this.props.user.get('tipo') === 'administrador' ? (
                                    <Tab className="nav-item">
                                        <a className={classnames({ active: tabIndex === 4})}>Auditoría</a>
                                    </Tab>
                                ) : null }

                                {this.props.user.get('tipo') === 'administrador' ? (
                                    <Tab className="nav-item">
                                        <a className={classnames({ active: tabIndex === 5})}>Estructura Organizativa</a>
                                    </Tab>
                                ) : null }
                            </TabList>

                            <TabPanel>
                                <StatsPage {...this.props} />
                            </TabPanel>
                            <TabPanel>
                                <CustomersPage {...this.props} />
                            </TabPanel>
                            <TabPanel>
                                <UsersPage {...this.props} />
                            </TabPanel>
                            <TabPanel>
                                <DocumentsPage {...this.props} />
                            </TabPanel>

                            {this.props.user.get('tipo') === 'administrador' ? (
                                <TabPanel>
                                    <AuditLogPage {...this.props} />
                                </TabPanel>
                            ) : null }

                            {this.props.user.get('tipo') === 'administrador' ? (
                                <TabPanel>
                                    <OrganizationalStructurePage {...this.props} />
                                </TabPanel>
                            ) : null }
                        </Tabs>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {

    // Reducers
    const sessionReducer = state.auth.session;

    // Session
    const user = sessionReducer.get('user') || Map({});

    return {
        user
    };
}

export default connect(mapStateToProps)(ControlPanelPage);