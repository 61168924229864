import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map } from "immutable";

import { getOrgStructure } from '../actions/organizationalStructure';
import OrganizationalStructureList from '../components/OrganizationalStructureList'

class OrganizationalStructurePage extends Component {

    componentDidMount() {
        this.props.getOrgStructure();

        // Control what user type can access
        if(this.props.user && (this.props.user.get('tipo') === 'cliente' || this.props.user.get('tipo') === 'gestor')) {
            this.props.history.push('/');
        }
    }

    render() {
        return (
            <div style={{ margin: 20 }}>
                <OrganizationalStructureList
                    orgStructure={this.props.orgStructure}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {

    // Reducers
    const orgStructureReducer = state.organizationalStructure;

    // Organizational Structure
    const orgStructure = orgStructureReducer.get('payload') || Map({});

    return {
        orgStructure
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getOrgStructure: () => dispatch(getOrgStructure())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationalStructurePage);
