import React, { Component } from 'react';

// Components
import CustomIcon from '../components/CustomIcon';
import {attachTypes} from "./attachTypes";

class HomePageAttachsDialog extends Component {

    attachsTable = () => {
        let table = [];

        this.props.attachs.forEach( (attach, i) => {
            table.push(
                <tr key={i}>
                    <th scope="row" style={{ verticalAlign: 'middle' }} >{attach.nombre}</th>
                    <td style={{width: 200, verticalAlign: 'middle' }}>
                        {attachTypes.filter(att => {
                            return att.get('tipo') === attach.tipo;
                        }).get(0).get('desc')
                        }
                    </td>
                    <td style={{ width: 50 }} >
                        <CustomIcon
                            iconType="download"
                            size={ 20 }
                            counter={ attach.descargas }
                            title="Descargar"
                            color="#183650"
                            onClick={this.props.download.bind(this, this.props.doc.id, attach._id)}
                        />
                    </td>
                </tr>
            );
        } );

        return table;
    };

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col">
                        <span style={{fontWeight: 'bold', fontSize: 16, color: '#076BAD'}}>{this.props.doc.nombre}</span>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <table className="table" style={{ fontSize: 13 }} >
                            <thead>
                            <tr>
                                <th scope="col" style={{border: 'none'}}>Nombre documento</th>
                                <th scope="col" style={{border: 'none'}}>Tipo anexo</th>
                            </tr>
                            </thead>
                            <tbody>
                                {this.attachsTable()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default HomePageAttachsDialog;