/**
 * Receive Axios handled error from to return string Message
 *
 * @param {Object || Error} err
 * @returns {string}
 */
exports.getErrorMessage = (err = {}) => {
    let errMsg = '';

    // Server response  falls out of the range of 2xx
    if (err.response && err.response.data && err.response.data.issuePayload) {
        errMsg = this.getWarningsMessages(err.response.data.issuePayload);
    }

    // Other errors
    else {
        errMsg = err.message || err.toString();
    }

    return errMsg;
};

/**
 * Return error message from 'issuePayload' error/s
 *
 * @param {Error || Error[] || String || String[]} issuePayload
 * @returns {string}
 */
exports.getWarningsMessages = (issuePayload) => {
    let errMsg = '';

    if (issuePayload instanceof Error) {
        errMsg = issuePayload.message;
    } else if (issuePayload instanceof Array) {
        issuePayload.forEach((issue) => errMsg += `${issue.message || issue.toString()}. `);
    } else {
        errMsg = issuePayload.toString();
    }

    return errMsg;
};

/**
 *
 * @param {Object || Error} res Receiving response or handled error from axios request
 * @param {Function} funcSuccess (Function success || function error) to generate redux state data
 * @param {Function || undefined} funcError to generate redux state data
 * @returns {Object} Redux state data generated by function (funcSuccess || funcError)
 */
exports.response = (res, funcSuccess, funcError) => {

    // This is error response
    if (!funcError) {
        funcError = funcSuccess;
        let err = res;

        return funcError(this.getErrorMessage(err));
    }

    // Server response falls in the range of 2xx
    else {

        // Success
        if( res.data && res.data.status === 1) {
            return funcSuccess(res.data.payload);
        }

        // 200 with warnings
        else if (res.data && res.data.issuePayload) {
            return funcError(this.getWarningsMessages(res.data.issuePayload));
        }

        // 200 without warnings && res.data.status !== 1
        else {
            return funcError(`Status ${res.data.status}. Error desconocido`);
        }
    }
};