import React, {Component} from 'react';
import {connect} from "react-redux";
import {List } from "immutable";
import moment from 'moment';

// Components
import Select from '../components/virtualized-select';
import VirtualizedTable from '../components/virtualized-table';
import DateInput from '../components/date-input';
import Loading from '../components/Loading';

// Actions
import {getCustomers} from "../actions/customers";
import {getUsers} from "../actions/users";
import {auditQuery} from "../actions/auditLog";
import { sortArray } from '../lib/sort-array';

// Styles
const styles = {
    filtersArea: {
        wrapper: {
            display: 'flex',
            flexWrap: 'wrap',
            fontSize: 14,
            margin: '20px 20px 0'
        },
        item: {
            display: 'flex',
            flexDirection: 'column',
            width: 250,
            marginRight: 20,
            marginBottom: 15,
            input: {
                width: '250px'
            }
        },
        buttonItem: {
            display: 'flex',
            flexDirection: 'column',
            width: 100,
            marginBottom: 20,
            alignSelf: 'flex-end',
            button: {
                color: '#fff',
                padding: '4px 8px',
                borderRadius: 2
            }
        },
        loading: {
            display: 'flex',
            width: 50,
            paddingLeft: 15,
            flexDirection: 'column',
            marginBottom: 20,
            alignSelf: 'flex-end',
        }
    }
};

const TODOS_VALUE = 'todos_values';

class AuditLogsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // Filter states
            selectedCustomerId: TODOS_VALUE,
            selectedUserId: TODOS_VALUE,
            fromDate: moment().startOf('year'),
            toDate: moment().endOf('day'),
            selectedAuditLog: { object: TODOS_VALUE, action: TODOS_VALUE, tittle: 'Todos' },
            selectedEvent: 'Todos',
            allSelectedLogs: false,
            selectedLogs: new Set(),
            selectedLog: null,
            formData: {},
            isFormData: false
        };
    };

    componentDidMount() {
        this.props.getCustomers();
        this.props.getUsers();
    };

    componentDidUpdate(prevProps) {

    }

    // Handlers
    prepareQuery() {
        const { selectedUserId: user, selectedCustomerId: customer, selectedAuditLog: auditLog, fromDate: from, toDate: to, selectedEvent: event } = this.state;

        const query = {
            ...auditLog && auditLog.object !== TODOS_VALUE ? { object: auditLog.object, action: auditLog.action } : {},
            ...user && user !== TODOS_VALUE ? { user } : {},
            ...customer && customer !== TODOS_VALUE ? { customer } : {},
            ...from ? { from: from.toDate() } : {},
            ...to ? { to: to.toDate() } : {},
            ...to ? { to: to.toDate() } : {},
            ...event ? { event } : {}
        };

        this.props.auditQuery(query);
    };

    render() {

        // State
        let {
            selectedCustomerId,
            selectedUserId,
            selectedAuditLog,
            selectedEvent,
            fromDate,
            toDate,
        } = this.state;

        selectedCustomerId = selectedCustomerId || TODOS_VALUE;
        selectedUserId = selectedUserId || TODOS_VALUE;
        fromDate = fromDate || moment().startOf('year');
        toDate = toDate || moment().endOf('day');
        selectedAuditLog = selectedAuditLog || { object: TODOS_VALUE, action: TODOS_VALUE, tittle: 'Todos' };
        selectedEvent = selectedEvent || TODOS_VALUE;

        // Props
        let customerFilterOptions = this.props.customers.toJS(),
            usersFilterOptions = this.props.users.toJS(),
            auditLogsFilterOptions = this.props.auditLogs.toJS().filter(log => auditListMap.get(`${log.object}-${log.action}`)).map(log => {
                log.tipoEvento =  auditListMap.get(`${log.object}-${log.action}`);
                return log;
            });

        // Add Todos
        usersFilterOptions.unshift({ _id: TODOS_VALUE, nombre: 'Todos' });
        customerFilterOptions.unshift({ _id: TODOS_VALUE, nombre: 'Todos' });

        // Sort users and customer options
        usersFilterOptions = sortArray(usersFilterOptions, 'nombre');
        customerFilterOptions = sortArray(customerFilterOptions, 'nombre');

        let events = ['Todos', 'Correcto', 'Error'];

        let filtersArea = (
            <div style={styles.filtersArea.wrapper} >
                <div style={{ ...styles.filtersArea.item, width: 300 }}>
                    <div className="mb10 fw600" id="Tipo de evento">Tipo de evento</div>
                    <Select
                        style={{ width: 300 }}
                        options={auditList}
                        value={selectedAuditLog.tittle}
                        labelKey='tittle'
                        valueKey='tittle'
                        matchProp='tittle'
                        multi={false}
                        searchable={true}
                        clearable={false}
                        onChange={(selectedValue) => this.setState({
                            selectedAuditLog: selectedValue
                        })}
                        parentId="Tipo de evento"
                    />
                </div>
                <div style={styles.filtersArea.item}>
                    <div className="mb10 fw600" id="Usuario">Usuario</div>
                    <Select
                        style={styles.filtersArea.item.input}
                        options={usersFilterOptions}
                        value={selectedUserId}
                        labelKey='nombre'
                        valueKey='_id'
                        matchProp='nombre'
                        multi={false}
                        searchable={true}
                        clearable={false}
                        onChange={(selectedValue) => this.setState({
                            selectedUserId: selectedValue ? selectedValue._id : selectedValue
                        })}
                        parentId="Usuario"
                    />
                </div>
                <div style={styles.filtersArea.item}>
                    <div className="mb10 fw600" id="Cliente">Cliente</div>
                    <Select
                        style={styles.filtersArea.item.input}
                        options={customerFilterOptions}
                        value={selectedCustomerId}
                        labelKey='nombre'
                        valueKey='_id'
                        matchProp='nombre'
                        multi={false}
                        searchable={true}
                        clearable={false}
                        onChange={(selectedValue) => this.setState({
                            selectedCustomerId: selectedValue ? selectedValue._id : selectedValue
                        })}
                        parentId="Cliente"
                    />
                </div>
                <div style={{ ...styles.filtersArea.item, width: 180 }}>
                    <div className="mb10 fw600">Desde</div>
                    <DateInput
                        onChange={(selectedValue) => this.setState({
                            fromDate: selectedValue
                        })}
                        value={fromDate}
                        inputWrapperStyle={{height: 40}}
                        inputStyle={{height: 37, borderRadius: 0, border: '1px solid #ccc'}}
                        iconStyle={{top: 9, right: 12}}
                    />
                </div>
                <div style={{ ...styles.filtersArea.item, width: 180 }}>
                    <div className="mb10 fw600">Hasta</div>
                    <DateInput
                        onChange={(selectedValue) => this.setState({
                            toDate: selectedValue.endOf('day')
                        })}
                        value={toDate}
                        inputWrapperStyle={{height: 40}}
                        inputStyle={{height: 37, borderRadius: 0, border: '1px solid #ccc'}}
                        iconStyle={{top: 9, right: 12}}
                    />
                </div>
                <div style={{ ...styles.filtersArea.item, width: 160 }}>
                    <div className="mb10 fw600" id="Estado del evento">Estado del evento</div>
                    <Select
                        style={{ width: 160 }}
                        options={events.map(event =>  { return {name: event, value: event}})}
                        value={selectedEvent}
                        labelKey='name'
                        valueKey='name'
                        multi={false}
                        searchable={true}
                        clearable={false}
                        onChange={(selectedValue) => this.setState({
                            selectedEvent: selectedValue.name
                        })}
                        parentId="Estado del evento"
                    />
                </div>
                <div style={styles.filtersArea.buttonItem}>
                    <button
                        style={styles.filtersArea.buttonItem.button}
                        className="btn btn-custom"
                        onClick={ () => this.prepareQuery() }
                    >Buscar
                    </button>
                </div>
                <div style={styles.filtersArea.loading}>
                    <Loading
                        isFetching={this.props.auditLogsIsFeching}
                    />
                </div>
            </div>
        );

        // Documents List Area
        let tableSettings = {
            sortable: true,
            filterable: true,
            defaultSortFields: [],
            defaultSortDirections: ['asc'],
            height: 450,
            rowHeight: 54,
            strippedRows: false,
            conditionalRowStyle: [{
                dataField: 'error',
                operation: '=',
                value: true,
                style: {
                    backgroundColor: '#ffd3d3'
                }
            }]
        };
        let tableData = auditLogsFilterOptions;
        let tableColumns = {
            tipo: {
                title: "Tipo de evento",
                columnType: 'text',
                dataField: 'tipoEvento',
                width: 170,
                sortable: true,
                filterable: true,
            },
            usuario: {
                title: "Usuario",
                columnType: 'text',
                dataField: 'user',
                width: 170,
                sortable: true,
                filterable: true
            },
            cliente: {
                title: "Cliente",
                columnType: 'text',
                dataField: 'customer',
                width: 170,
                sortable: true,
                filterable: true
            },
            fecha: {
                title: "Fecha de evento",
                columnType: 'date',
                dataField: 'timestamp',
                format: 'DD-MM-YYYY HH:mm:ss',
                width: 120,
                sortable: true,
                filterable: true
            },
            detalles: {
                title: "Detalle",
                columnType: 'function',
                dataField: 'message',
                width: 400,
                sortable: false,
                filterable: false,
                nodeContent: (field) => {
                    return field;
                }
            }
        };

        return (
            <div>
                {filtersArea}
                <div style={{ fontSize: 18, fontWeight: 'bold', marginLeft: 20, marginTop: 20 }}>
                    <span>{`Total Eventos: ${auditLogsFilterOptions ? auditLogsFilterOptions.length : 0}`}</span>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <VirtualizedTable
                                tableSettings={tableSettings}
                                tableColumns={tableColumns}
                                tableData={tableData}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {

    // Reducers
    const customersReducer = state.customers;
    const usersReducer = state.users;
    const auditLogsReducer = state.auditLog;

    // Is feching
    const customersIsFeching = customersReducer.get('isFetching') || false;
    const usersIsFeching = usersReducer.get('isFetching') || false;
    const auditLogsIsFeching = auditLogsReducer.get('isFetching') || false;

    // Error
    const customersError = customersReducer.get('error') || false;
    const usersError = usersReducer.get('error') || false;
    const auditLogsError = auditLogsReducer.get('error') || false;

    // Error message
    const customersErrorMessage = customersReducer.get('errorMessage') || '';
    const usersErrorMessage = usersReducer.get('errorMessage') || '';
    const auditLogsErrorMessage = auditLogsReducer.get('errorMessage') || '';

    // Success
    const customersSuccess = customersReducer.get('success') || false;
    const usersSuccess = usersReducer.get('success') || false;
    const auditLogsSuccess = auditLogsReducer.get('success') || false;

    // Payload
    const customers = customersReducer.get('payload') || new List();
    const users = usersReducer.get('payload') || new List();
    const auditLogs = auditLogsReducer.get('payload') || new List();

    return {
        customersIsFeching, customersError, customersErrorMessage, customersSuccess, customers,
        usersIsFeching, usersError, usersErrorMessage, usersSuccess, users,
        auditLogsIsFeching, auditLogsError, auditLogsErrorMessage, auditLogsSuccess, auditLogs
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getCustomers: () => dispatch(getCustomers()),
        getUsers: () => dispatch(getUsers()),
        auditQuery: (query) => dispatch(auditQuery(query))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuditLogsPage);

const auditList = [

    // Usuarios
    { object: TODOS_VALUE, action: TODOS_VALUE, tittle: 'Todos' },
    { object: 'usuarios', action: 'signin', tittle: 'Iniciar sesión' },
    { object: 'usuarios', action: 'signout', tittle: 'Cerrar sesión' },
    { object: 'usuarios', action: 'forgot', tittle: 'Contraseña olvidada' },
    { object: 'usuarios', action: 'validateResetToken', tittle: 'Validar token para cambiar contraseña olvidada' },
    { object: 'usuarios', action: 'reset', tittle: 'Cambiar contraseña olvidada' },
    { object: 'usuarios', action: 'password', tittle: 'Cambiar contraseña' },
    // { object: 'usuarios', action: 'read', tittle: 'Obtener usuarios' }, // Ignored in logger
    { object: 'usuarios', action: 'update', tittle: 'Actualizar usuario' },
    // { object: 'usuarios', action: 'readOne', tittle: 'Obtener usuario' }, // Ignored in logger
    { object: 'usuarios', action: 'userPassword', tittle: 'Cambiar contraseña a un usuario' },
    { object: 'usuarios', action: 'create', tittle: 'Crear usuario' },
    { object: 'usuarios', action: 'updateStatus', tittle: 'Actualizar estado a un usuario' },

    // Clientes
    // { object: 'clientes', action: 'read', tittle: 'Obtener clientes' }, // Ignored in logger
    { object: 'clientes', action: 'create', tittle: 'Crear cliente' },
    { object: 'clientes', action: 'update', tittle: 'Actualizar cliente' },
    { object: 'clientes', action: 'updateStatus', tittle: 'Actualizar el estado del cliente' },

    // CPDs
    { object: 'cpds', action: 'createCpd', tittle: 'Crear CPD' },
    // { object: 'cpds', action: 'query', tittle: 'Obtener CPDs' }, // Ignored in logger
    { object: 'cpds', action: 'updateCpd', tittle: 'Actualizar CPD' },
    { object: 'cpds', action: 'deleteCpd', tittle: 'Borrar CPD' },
    { object: 'cpds', action: 'downloadCpd', tittle: 'Descargar CPD' },
    { object: 'cpds', action: 'createAtt', tittle: 'Crear anexo' },
    // { object: 'cpds', action: 'readAtt', tittle: 'Leer anexo' }, // Ignored in logger
    { object: 'cpds', action: 'deleteAtt', tittle: 'Borrar anexo' },
    { object: 'cpds', action: 'downloadAtt', tittle: 'Descargar anexo' },
    { object: 'cpds', action: 'createPrr', tittle: 'Crear prórroga' },
    { object: 'cpds', action: 'updatePrr', tittle: 'Actualizar prórroga' },
    { object: 'cpds', action: 'deletePrr', tittle: 'Borrar prórroga' },
    { object: 'cpds', action: 'downloadPrr', tittle: 'Descargar prórroga' },
    { object: 'cpds', action: 'download', tittle: 'Descargar CPD completo' },

    // Estructura organizativa
    { object: 'estructura_organizativa', action: 'update', tittle: 'Actualizar estructura organizativa' },
    // { object: 'estructura_organizativa', action: 'read', tittle: 'Obtener estructura organizativa' }, // Ignored in logger

    // Stats
    // { object: 'stats', action: 'getControlPanelStats', tittle: 'Obtener estadísticas del panel de control' }, // Ignored in logger
    // { object: 'stats', action: 'getCustomerStats', tittle: 'Obtener estadísticas del cliente' }, // Ignored in logger

    // Audit
    // { object: 'audit', action: 'query', tittle: 'Obtener logs' } // Ignored in logger
];

const auditListMap = new Map(auditList.map(field => [`${field.object}-${field.action}`, field.tittle]));