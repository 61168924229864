import axios from 'axios';
import Auth from '../lib/auth';

import { addNotification } from './notifications';

export const USERS_REQUEST = 'USERS_REQUEST';
export const USERS_SUCCESS = 'USERS_SUCCESS';
export const USERS_ERROR = 'USERS_ERROR';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const CHANGE_USER_STATUS_REQUEST = 'CHANGE_USER_STATUS_REQUEST';
export const CHANGE_USER_STATUS_SUCCESS = 'CHANGE_USER_STATUS_SUCCESS';
export const CHANGE_USER_STATUS_ERROR = 'CHANGE_USER_STATUS_ERROR';

export const CHANGE_USER_PASSWORD_REQUEST = 'CHANGE_USER_PASSWORD_REQUEST';
export const CHANGE_USER_PASSWORD_SUCCESS = 'CHANGE_USER_PASSWORD_SUCCESS';
export const CHANGE_USER_PASSWORD_ERROR = 'CHANGE_USER_PASSWORD_ERROR';

export const getUsers = () => {
    return async dispatch => {
        try {
            const token = Auth.getLocalJwt();
            dispatch(getUsersRequest());
            const res = await axios.get('/api/usuarios', { headers: { 'Authorization': 'Bearer ' + token } });
            if (res.data && res.data.status === 1) {
                dispatch(getUsersSuccess(res.data.payload));
            } else {
                dispatch(getUsersError(res.data.payload));
            }
        } catch (error) {
            dispatch(getUsersError(error));
        }
    };
};

function getUsersRequest() {
    return {
        type: USERS_REQUEST,
        isFetching: true
    };
}

function getUsersSuccess (payload) {
    return {
        type: USERS_SUCCESS,
        isFetching: false,
        error: false,
        payload
    };
}

function getUsersError(errorMessage) {
    return {
        type: USERS_ERROR,
        isFetching: false,
        error: true,
        errorMessage
    };
}

export const updateUser = (user) => {
    return async dispatch => {
        try {
            const token = Auth.getLocalJwt();
            dispatch(updateUserRequest());
            const res = await axios.put(`/api/usuarios/${user._id}`, { ...user }, { headers: { 'Authorization': 'Bearer ' + token } });

            if (res.data && res.data.status === 1) {
                dispatch(updateUserSuccess(res.data.payload));

                // Create notification for update user success
                dispatch(addNotification({
                    message: 'Usuario modificado correctamente',
                    color: 'green',
                    icon: 'tick'
                }));
            } else {
                dispatch(updateUserError(res.data.payload));
            }
        } catch (error) {
            dispatch(updateUserError(error));

            // Create notification for update user error
            dispatch(addNotification({
                message: 'Error al actualizar el usuario: el email ya está en uso',
                color: 'red',
                icon: 'cross'
            }));
        }
    };
};

function updateUserRequest() {
    return {
        type: UPDATE_USER_REQUEST,
        isFetching: true
    };
}

function updateUserSuccess (payload) {
    return {
        type: UPDATE_USER_SUCCESS,
        isFetching: false,
        error: false,
        payload
    };
}

function updateUserError(errorMessage) {
    return {
        type: UPDATE_USER_ERROR,
        isFetching: false,
        error: true,
        errorMessage
    };
}

export const createUser = (user) => {
    return async dispatch => {
        try {
            const token = Auth.getLocalJwt();
            dispatch(createUserRequest());
            const res = await axios.post('/api/usuarios', { ...user }, { headers: { 'Authorization': 'Bearer ' + token } });

            if (res.data && res.data.status === 1) {
                dispatch(createUserSuccess(res.data.payload));

                // Create notification for create user success
                dispatch(addNotification({
                    message: 'Usuario creado correctamente',
                    color: 'green',
                    icon: 'tick'
                }));
            } else {
                dispatch(createUserError(res.data.payload));
            }
        } catch (error) {
            dispatch(createUserError(error));

            // Create notification for create user error
            dispatch(addNotification({
                message: 'Error al crear el usuario: el email ya está en uso',
                color: 'red',
                icon: 'cross'
            }));
        }
    };
};

function createUserRequest() {
    return {
        type: CREATE_USER_REQUEST,
        isFetching: true
    };
}

function createUserSuccess (payload) {
    return {
        type: CREATE_USER_SUCCESS,
        isFetching: false,
        error: false,
        created: true,
        payload
    };
}

function createUserError(errorMessage) {
    return {
        type: CREATE_USER_ERROR,
        isFetching: false,
        error: true,
        errorMessage
    };
}

export const deleteUser = (userId) => {
    return async dispatch => {
        try {
            const token = Auth.getLocalJwt();
            dispatch(deleteUserRequest());
            const res = await axios.delete(`/api/usuarios/${userId}`, { headers: { 'Authorization': 'Bearer ' + token } });

            if (res.data && res.data.status === 1) {
                dispatch(deleteUserSuccess(res.data.payload));

                // Create notification for change user status success
                dispatch(addNotification({
                    message: `Usuario eliminado correctamente`,
                    color: 'green',
                    icon: 'tick'
                }));
            } else {
                dispatch(deleteUserError(res.data.payload));
            }
        } catch (error) {
            dispatch(deleteUserError(error));

            // Create notification for change user status error
            dispatch(addNotification({
                message: `Error al eliminar el usuario`,
                color: 'red',
                icon: 'cross'
            }));
        }
    };
};

function deleteUserRequest() {
    return {
        type: DELETE_USER_REQUEST,
        isFetching: true
    };
}

function deleteUserSuccess (payload) {
    return {
        type: DELETE_USER_SUCCESS,
        isFetching: false,
        error: false,
        created: true,
        payload
    };
}

function deleteUserError(errorMessage) {
    return {
        type: DELETE_USER_ERROR,
        isFetching: false,
        error: true,
        errorMessage
    };
}

export const changeUserStatus = (userId, status) => {
    return async dispatch => {
        try {
            const token = Auth.getLocalJwt();
            dispatch(changeUserStatusRequest());
            const res = await axios.put(`/api/usuarios/${userId}/status`, { estado: status }, { headers: { 'Authorization': 'Bearer ' + token } });

            if (res.data && res.data.status === 1) {
                dispatch(changeUserStatusSuccess(res.data.payload));

                // Create notification for change user status success
                dispatch(addNotification({
                    message: `Usuario ${status === 'activo' ? 'activado' : 'desactivado'} correctamente`,
                    color: 'green',
                    icon: 'tick'
                }));
            } else {
                dispatch(changeUserStatusError(res.data.payload));
            }
        } catch (error) {
            dispatch(changeUserStatusError(error));

            // Create notification for change user status error
            dispatch(addNotification({
                message: `Error al ${status === 'activo' ? 'activar' : 'desactivar'} el usuario`,
                color: 'red',
                icon: 'cross'
            }));
        }
    };
};

function changeUserStatusRequest() {
    return {
        type: CHANGE_USER_STATUS_REQUEST,
        isFetching: true
    };
}

function changeUserStatusSuccess (payload) {
    return {
        type: CHANGE_USER_STATUS_SUCCESS,
        isFetching: false,
        error: false,
        updated: true,
        payload
    };
}

function changeUserStatusError(errorMessage) {
    return {
        type: CHANGE_USER_STATUS_ERROR,
        isFetching: false,
        error: true,
        errorMessage
    };
}

export const changeUserPassword = (userId, passwordObject) => {
    return async dispatch => {
        try {
            const token = Auth.getLocalJwt();
            dispatch(changeUserPasswordRequest());
            const res = await axios.post(`/api/usuarios/${userId}/password`, { ...passwordObject }, { headers: { 'Authorization': 'Bearer ' + token } });

            if (res.data && res.data.status === 1) {
                dispatch(changeUserPasswordSuccess(res.data.payload));

                // Create notification for change password success
                dispatch(addNotification({
                    message: 'Contraseña modificada correctamente',
                    color: 'green',
                    icon: 'tick'
                }));
            } else {
                dispatch(changeUserPasswordError(res.data.payload));
            }
        } catch (error) {
            dispatch(changeUserPasswordError(error));

            // Create notification for create user error
            dispatch(addNotification({
                message: 'Error al cambiar la contraseña del usuario',
                color: 'red',
                icon: 'cross'
            }));
        }
    };
};

function changeUserPasswordRequest() {
    return {
        type: CHANGE_USER_PASSWORD_REQUEST,
        isFetching: true
    };
}

function changeUserPasswordSuccess (payload) {
    return {
        type: CHANGE_USER_PASSWORD_SUCCESS,
        isFetching: false,
        error: false,
        updated: true,
        payload
    };
}

function changeUserPasswordError(errorMessage) {
    return {
        type: CHANGE_USER_PASSWORD_ERROR,
        isFetching: false,
        error: true,
        errorMessage
    };
}