import React, { useState } from 'react';

import Select from '../../components/virtualized-select';
import Loading from '../../components/Loading';

// Styles
import styles from './styles.json';

export const FiltersArea = (props) => {
    const { 
        employeesIsFetching,
        customerFilterOptions,
        prepareQuery = () => {} 
    } = props;

    const [selectedEmployeeId, setSelectedEmployeeId] = useState('');
    const [selectedCustomerId, setSelectedCustomerId] = useState(-1);
    const [selectedStateId, setSelectedStateId] = useState(-1);
    const [identifyDocumentFilter, setIdentifyDocumentFilter] = useState('');
    const [nameFilter, setNameFilter] = useState('');
    const [surnamesFilter, setSurnamesFilter] = useState('');

    const stateFilterOptions = [
        { id: -1, estado: 'Todos' },
        { id: 'activo', estado: 'Activo' },
        { id: 'inactivo', estado: 'Inactivo' }
    ];

    return (
        <div style={ styles.filtersArea.wrapper }>
            <div style={ styles.filtersArea.item }>
                <div className="mb10 fw600" id="Trabajador">Código</div>
                <input
                    className='filter-text-input'
                    type="text"
                    value={selectedEmployeeId}
                    onChange={(e) => setSelectedEmployeeId(e.target.value)}
                    placeholder="Ingrese código"
                />
            </div>
            <div style={styles.filtersArea.item}>
                <div className="mb10 fw600" id="NIFE/NIE">NIF/NIE</div>
                <input
                    className='filter-text-input'
                    type="text"
                    value={identifyDocumentFilter}
                    onChange={(e) => setIdentifyDocumentFilter(e.target.value)}
                    placeholder="Ingrese NIF/NIE"
                />
            </div>
            <div style={styles.filtersArea.item}>
                <div className="mb10 fw600" id="Nombre">Nombre</div>
                <input
                    className='filter-text-input'
                    type="text"
                    value={nameFilter}
                    onChange={(e) => setNameFilter(e.target.value)}
                    placeholder="Ingrese nombre"
                />
            </div>
            <div style={styles.filtersArea.item}>
                <div className="mb10 fw600" id="Apellidos">Apellidos</div>
                <input
                    className='filter-text-input'
                    type="text"
                    value={surnamesFilter}
                    onChange={(e) => setSurnamesFilter(e.target.value)}
                    placeholder="Ingrese apellidos"
                />
            </div>
            <div style={ styles.filtersArea.item }>
                <div className="mb10 fw600" id="Cliente">Cliente</div>
                <Select
                    options={customerFilterOptions}
                    value={selectedCustomerId}
                    labelKey='nombre'
                    valueKey='_id'
                    matchProp='nombre'
                    multi={false}
                    searchable={true}
                    clearable={false}
                    onChange={(selectedValue) => setSelectedCustomerId(selectedValue._id)}
                    parentId="Cliente"
                />
            </div>
            <div style={{ ...styles.filtersArea.item, width: 150 }}>
                <div className="mb10 fw600" id="Estado">Estado</div>
                <Select
                    options={stateFilterOptions}
                    value={selectedStateId}
                    labelKey='estado'
                    valueKey='id'
                    matchProp='estado'
                    multi={false}
                    searchable={true}
                    clearable={false}
                    onChange={(selectedValue) => setSelectedStateId(selectedValue.id)}
                    parentId="Estado"
                />
            </div>
            
            <div style={styles.filtersArea.buttonItem}>
                <button
                    className="btn btn-custom"
                    style={styles.filtersArea.buttonItem.button}
                    onClick={() => prepareQuery({
                        cliente_id: selectedCustomerId,
                        nombre: nameFilter,
                        apellidos: surnamesFilter,
                        documento_identificativo: identifyDocumentFilter,
                        estado: selectedStateId,
                        trabajador_id: selectedEmployeeId
                    })}
                >
                    Buscar
                </button>
            </div>
            <div style={styles.filtersArea.loading}>
                <Loading
                    isFetching={employeesIsFetching}
                />
            </div>
        </div>
    );
};

export default FiltersArea;
