import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import {List, Map} from "immutable";
import _ from 'lodash';

// Components
import Select from '../components/virtualized-select';
import DateInput from '../components/date-input';
import VirtualizedTable from '../components/virtualized-table';
import Modal from '@atlaskit/modal-dialog';
import {Checkbox, Icon, Popover, PopoverInteractionKind, Position, Menu, MenuItem} from "@blueprintjs/core";
import DocumentsPageAttachsDialog from "../components/DocumentsPageAttachsDialog";
import HomePageDocsDownloadDialog from "../components/HomePageDocsDownloadDialog";
import CreateCpdDialog from "../components/CreateCpdDialog";
import UploadPrrDialog from "../components/UploadPrrDialog";
import UpdateDocDialog from "../components/UpdateDocDialog";
import DeleteDocDialog from "../components/DeleteDocDialog";
import {attachTypes} from "../components/attachTypes";
import Loading from '../components/Loading';

// Actions
import {getOrgStructure} from "../actions/organizationalStructure";
import {
    getDocuments,
    uploadCpd, updateCpd, deleteCpd,
    uploadAttach, deleteAttach,
    uploadPrr, updatePrr, deletePrr
} from "../actions/documents";
import {downloadDocs, downloadCpd, downloadAtt, downloadPrr} from "../actions/content";
import {getCustomers} from "../actions/customers";
import { createSign } from '../actions/signs';

// Libs
import { prepareDocsJSON } from '../lib/sign-lib';

// Styles
const styles = {
    filtersArea: {
        wrapper: {
            display: 'flex',
            flexWrap: 'wrap',
            fontSize: 14,
            margin: '20px 20px 0'
        },
        item: {
            display: 'flex',
            flexDirection: 'column',
            width: 250,
            marginRight: 20,
            marginBottom: 15,
            input: {
                width: '250px'
            }
        },
        buttonItem: {
            display: 'flex',
            flexDirection: 'column',
            width: 100,
            marginBottom: 20,
            alignSelf: 'flex-end',
            button: {
                color: '#fff',
                padding: '4px 8px',
                borderRadius: 2
            }
        },
        loading: {
            display: 'flex',
            width: 50,
            paddingLeft: 15,
            flexDirection: 'column',
            marginBottom: 20,
            alignSelf: 'flex-end',
        }
    },
    statsArea: {
        wrapper: {
            display: 'flex',
            flexWrap: 'wrap',
            fontSize: 14,
            // margin: '0px 20px 20px'
            marginLeft: 10
        },
        item: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            padding: '8px 10px',
            // marginRight: 10,
            // marginBottom: 10,
            margin: 10,
            width: 225,
            fontSize: 14,
            indicator: {
                fontSize: 16
            }
        }
    },
    legend: {
        marginLeft: 10,
        width: 20,
        height: 20,
        backgroundColor: '#E8F1F7',
        border: 'solid 1px #ddd'
    },
    selectedDocsHidden: {
        visibility: 'hidden',
        opacity: 0,
        transition: 'all 0.5s ease-in-out'
    },
    selectedDocsVisible: {
        visibility: 'visible',
        opacity: 1,
        transition: 'all 0.5s ease-in-out'
    },
    table: {
        wrapper: {
            backgroundColor: '#fff'
        }

    },
    modal: {
        subtitle: {
            display: 'flex',
            padding: '15px 0',
            fontSize: 16,
            fontWeight: 'bold',
            color: '#076BAD'
        }
    }
};

class DocumentsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedCustomerId: -1,
            selectedDelegationId: -1,
            selectedOfficeId: -1,
            selectedCenterId: -1,
            fromDate: moment().startOf('year'),
            toDate: moment().endOf('day'),
            selectedStateId: -1,
            allSelectedDocs: false,
            selectedDocs: new Set(),
            selectedDoc: null,
            modalIsOpen: false,
            modalType: null,
            formData: {},
            canSubmit: false
        };

        this.downloadAtt = this.downloadAtt.bind(this);
        this.uploadCpd = this.uploadCpd.bind(this);
        this.handleFormData = this.handleFormData.bind(this);
    };

    componentDidMount() {
        this.props.getOrgStructure();
        this.props.getCustomers();

        // Control what user type can access
        if(this.props.user && this.props.user.get('tipo') === 'cliente') {
            this.props.history.push('/');
        }
    };

    componentDidUpdate(prevProps) {
        // Update selectedDoc when change
        if (prevProps.documents !== this.props.documents && this.state.selectedDoc !== null ) {
            this.setState({
                selectedDoc: this.props.documents.find(doc => doc.get('_id') === this.state.selectedDoc.get('_id'))
            });
        }
    };

    // Control the state of allSelectedDocs and selectedDocs
    handleCheckbox(type, data) {
        let allSelectedDocs = this.state.allSelectedDocs, selectedDocs;
        switch (type) {
            case 'mainTableAll':
                selectedDocs = new Set();
                if (!allSelectedDocs) {
                    data.forEach( id => selectedDocs.add(id) );
                }
                allSelectedDocs = !allSelectedDocs;
                this.setState({
                    allSelectedDocs,
                    selectedDocs
                });
                break;
            case 'mainTable':
                selectedDocs = new Set(this.state.selectedDocs);
                selectedDocs.has(data) ? selectedDocs.delete(data) : selectedDocs.add(data);
                allSelectedDocs = selectedDocs.size === this.props.documents.count();
                this.setState({
                    allSelectedDocs,
                    selectedDocs
                });
                break;
            default:
                break;
        }
    };

    // Popover actions
    selectDoc = (docId) => {
        this.setState({
            selectedDoc: this.props.documents.find(doc => doc.get('_id') === docId)
        });
    };

    handleMenuClick = (actionType) => {
        if (this.state.selectedDoc) {
            switch (actionType) {
                case 'downloadCpd':
                    this.props.downloadCpd(this.state.selectedDoc.get('_id'), {});
                    break;
                case 'attachs':
                    this.handleModal('attachsDialog');
                    break;
                case 'sign':
                    this.props.createSign(prepareDocsJSON(this.props.documents, new Set([this.state.selectedDoc.get('_id')])));
                    break;
                case 'uploadPrr':
                    this.handleModal('uploadPrrDialog');
                    break;
                case 'exchangeCpd':
                    this.handleModal('updateDocDialog');
                    break;
                case 'deleteCpd':
                    this.handleModal('deleteDocDialog');
                    break;
                case 'downloadPrr':
                    this.props.downloadPrr(this.state.selectedDoc.get('_id'));
                    break;
                case 'exchangePrr':
                    this.handleModal('updateDocDialog');
                    break;
                case 'deletePrr':
                    this.handleModal('deleteDocDialog');
                    break;
                default:
                    return;
            }
        }
    };

    // Documents actions
    prepareQuery() {
        this.props.getDocuments({
            ...(this.state.selectedCustomerId !== -1) ? {cliente_id: this.state.selectedCustomerId} : '',
            ...(this.state.selectedDelegationId !== -1) ? {delegacion_id: this.state.selectedDelegationId} : '',
            ...(this.state.selectedOfficeId !== -1) ? {oficina_id: this.state.selectedOfficeId} : '',
            ...(this.state.selectedCenterId !== -1) ? {centro_id: this.state.selectedCenterId} : '',
            fecha_desde: this.state.fromDate,
            fecha_hasta: this.state.toDate,
            ...(this.state.selectedCenterId !== -1) ? {centro_id: this.state.selectedCenterId} : '',
            ...(this.state.selectedStateId !== -1) ? {estado: this.state.selectedStateId} : ''
        });
        this.setState({
            selectedDocs: new Set()
        });
    };

    downloadDocuments = () => {
        let cpdIds = [], prrIds = [];

        this.props.documents.map( doc => {
            if ([...this.state.selectedDocs].includes(doc.get('_id'))) {
                switch (doc.get('tipo_documento')) {
                    case 'cpd':
                        return cpdIds.push(doc.get('_id'));
                    case 'prr':
                        return prrIds.push(doc.get('_id'));
                    default:
                        return null;
                }
            } else {
                return null;
            }
        });

        this.props.downloadDocs(cpdIds, prrIds, this.state.canSubmit, this.state.formData.tipo_anexos);
    };

    updateDoc = () => {
        if (this.state.canSubmit) {
            const formData = new FormData();

            switch (this.state.selectedDoc.get('tipo_documento')) {
                case 'cpd':
                    formData.append('cpd', this.state.formData.doc);
                    this.props.updateCpd(this.state.selectedDoc.get('_id'), formData);
                    break;
                case 'prr':
                    formData.append('prr', this.state.formData.doc);
                    this.props.updatePrr(this.state.selectedDoc.get('_id'), formData);
                    break;
                default:
                    break;
            }


            this.closeModal();
        }
    };

    // CPD actions
    uploadCpd() {
        if (this.state.canSubmit) {
            const formData = new FormData();

            formData.append('cliente_id', this.state.formData.cliente_id);
            formData.append('delegacion_id', this.state.formData.delegacion_id);
            formData.append('oficina_id', this.state.formData.oficina_id);
            formData.append('centro_id', this.state.formData.centro_id);
            formData.append('fecha_documento', this.state.formData.fecha_documento);
            formData.append('cpd', this.state.formData.cpd);

            this.props.uploadCpd(formData);
            this.closeModal();
        }
    };

    // Attach actions
    uploadAttach = () => {
        if (this.state.canSubmit) {
            const cpdId = this.state.formData.cpdId;
            const formData = new FormData();
            const attach = attachTypes.find( attach => attach.get('tipo') === this.state.formData.attachType );

            formData.append('tipo', attach.get('tipo'));
            formData.append('att', this.state.formData.attachFile);

            this.props.uploadAttach(cpdId, formData);
            this.setState({ canSubmit: false });
        }
    };

    downloadAtt(cpdId, attId) {
        this.props.downloadAtt(cpdId, attId);
    };

    deleteAttach = (cpdId, attachId) => {
        this.props.deleteAttach(cpdId, attachId);
    };

    // Prorogation actions
    uploadPrr = () => {
        if (this.state.canSubmit) {
            const formData = new FormData();

            formData.append('fecha_documento', this.state.formData.fecha_documento);
            formData.append('prr', this.state.formData.prr);

            this.props.uploadPrr(this.state.selectedDoc.get('_id'), formData);
            this.closeModal();
        }
    };

    // formData Handlers
    handleFormData(formData) {
        this.setState({
            formData
        }, () => this.validateFormData());
    };

    validateFormData() {
        switch (this.state.formData.fdType) {
            case 'uploadCpd':
                if (
                    this.state.formData.cliente_id !== -1 &&
                    this.state.formData.oficina_id !== -1 &&
                    this.state.formData.delegacion_id !== -1 &&
                    this.state.formData.centro_id !== -1 &&
                    this.state.formData.fecha_documento &&
                    this.state.formData.cpd !== -1
                ) { 
                    this.setState({
                        canSubmit: true
                    });
                } else {
                    this.setState({
                        canSubmit: false
                    });
                }
                break;
            case 'uploadAttach':
            if (
                this.state.formData.attachType !== -1 &&
                this.state.formData.attachFile !== -1
            ) { 
                this.setState({
                    canSubmit: true
                });
            } else {
                this.setState({
                    canSubmit: false
                });
            }
            break;
            case 'uploadPrr':
                if (
                    this.state.formData.fecha_documento &&
                    this.state.formData.prr !== -1
                ) {
                    this.setState({
                        canSubmit: true
                    });
                } else {
                    this.setState({
                        canSubmit: false
                    });
                }
                break;
            case 'updateDoc':
                if (this.state.formData.doc !== -1) {
                    this.setState({
                        canSubmit: true
                    });
                } else {
                    this.setState({
                        canSubmit: false
                    });
                }
                break;
            case 'includeAttachs':
                if (this.state.formData.tipo_anexos.length > 0) {
                    this.setState({
                        canSubmit: true
                    });
                } else {
                    this.setState({
                        canSubmit: false
                    });
                }
                break;
            default:
                this.setState({
                    canSubmit: false
                });
                break;
        }
    };

    // Modal Handlers
    handleModal = (modalType) => {
        this.setState({
            modalType,
            modalIsOpen: true
        });
    };

    closeModal = () => {
        this.setState({
            modalType: null,
            modalIsOpen: false,
            canSubmit: false,
            selectedDoc: null
        });
    };

    render() {
        // State
        let {
            selectedCustomerId,
            selectedDelegationId,
            selectedOfficeId,
            selectedCenterId,
            selectedStateId,
            fromDate,
            toDate,
            allSelectedDocs,
            selectedDocs,
            selectedDoc,
            modalIsOpen,
            modalType,
            canSubmit
        } = this.state;

        // Props
        let tableData = this.props.documents.toJS(),
            customerFilterOptions = this.props.customers.toJS(),
            delegationFilterOptions = this.props.delegations.toJS(),
            officeFilterOptions = this.props.offices.toJS(),
            centerFilterOptions = this.props.centers.toJS(),
            stateFilterOptions = [
                {id: -1, estado: 'Todos'},
                {id: 'firmado', estado: 'Firmado'},
                {id: 'pendiente', estado: 'Pendiente'}
            ];

        // Filters Area
        if (selectedDelegationId !== -1) {
            // Offices filter
            officeFilterOptions = officeFilterOptions.filter(office => {
                return office.delegacion_id === selectedDelegationId || office.oficina_id === -1;
            });
            // Centers filter
            centerFilterOptions = centerFilterOptions.filter(center => {
                return center.delegacion_id === selectedDelegationId || center.centro_id === -1;
            });
        }
        if (selectedOfficeId !== -1) {
            // Centers filter
            centerFilterOptions = centerFilterOptions.filter(center => {
                return center.oficina_id === selectedOfficeId || center.centro_id === -1;
            });
        }
        let filtersArea = (
            <div style={ styles.filtersArea.wrapper }>
                <div style={ styles.filtersArea.item }>
                    <div className="mb10 fw600" id="Cliente">Cliente</div>
                    <Select
                        options={customerFilterOptions}
                        value={selectedCustomerId}
                        labelKey='nombre'
                        valueKey='_id'
                        matchProp='nombre'
                        multi={false}
                        searchable={true}
                        clearable={false}
                        onChange={(selectedValue) => this.setState({
                            selectedCustomerId: selectedValue._id
                        })}
                        parentId="Cliente"
                    />
                </div>
                <div style={ styles.filtersArea.item }>
                    <div className="mb10 fw600" id="Delegacion">Delegación</div>
                    <Select
                        options={delegationFilterOptions}
                        value={selectedDelegationId}
                        labelKey='nombre'
                        valueKey='delegacion_id'
                        matchProp='nombre'
                        multi={false}
                        searchable={true}
                        clearable={false}
                        onChange={(selectedValue) => this.setState({
                            selectedDelegationId: selectedValue.delegacion_id
                        })}
                        parentId="Delegacion"
                    />
                </div>
                <div style={ styles.filtersArea.item }>
                    <div className="mb10 fw600" id="Oficina">Oficina</div>
                    <Select
                        options={officeFilterOptions}
                        value={selectedOfficeId}
                        labelKey='nombre'
                        valueKey='oficina_id'
                        matchProp='nombre'
                        multi={false}
                        searchable={true}
                        clearable={false}
                        onChange={(selectedValue) => this.setState({
                            selectedOfficeId: selectedValue.oficina_id
                        })}
                        parentId="Oficina"
                    />
                </div>
                <div style={ styles.filtersArea.item }>
                    <div className="mb10 fw600" id="Centro">Centro</div>
                    <Select
                        options={centerFilterOptions}
                        value={selectedCenterId}
                        labelKey='nombre'
                        valueKey='centro_id'
                        matchProp='nombre'
                        multi={false}
                        searchable={true}
                        clearable={false}
                        onChange={(selectedValue) => this.setState({
                            selectedCenterId: selectedValue.centro_id
                        })}
                        parentId="Centro"
                    />
                </div>
                <div style={ { ...styles.filtersArea.item, width: 150 } }>
                    <div className="mb10 fw600">Desde</div>
                    <DateInput
                        onChange={(selectedValue) => this.setState({
                            fromDate: selectedValue
                        })}
                        value={fromDate}
                        inputWrapperStyle={{height: 40}}
                        inputStyle={{ height: 36, borderRadius: 0, border: '1px solid #ccc' }}
                        iconStyle={{top: 9, right: 12}}
                    />
                </div>
                <div style={ { ...styles.filtersArea.item, width: 150 } }>
                    <div className="mb10 fw600">Hasta</div>
                    <DateInput
                        onChange={(selectedValue) => this.setState({
                            toDate: selectedValue.endOf('day')
                        })}
                        value={toDate}
                        inputWrapperStyle={{height: 40}}
                        inputStyle={{ height: 36, borderRadius: 0, border: '1px solid #ccc' }}
                        iconStyle={{top: 9, right: 12}}
                    />
                </div>
                <div style={ { ...styles.filtersArea.item, width: 125 } }>
                    <div className="mb10 fw600" id="Estado">Estado</div>
                    <Select
                        options={stateFilterOptions}
                        value={selectedStateId}
                        labelKey='estado'
                        valueKey='id'
                        matchProp='estado'
                        multi={false}
                        searchable={true}
                        clearable={false}
                        onChange={(selectedValue) => this.setState({
                            selectedStateId: selectedValue.id
                        })}
                        parentId="Estado"
                    />
                </div>
                <div style={styles.filtersArea.buttonItem}>
                    <button
                        className="btn btn-custom"
                        style={styles.filtersArea.buttonItem.button}
                        onClick={() => this.prepareQuery()}
                    >
                        Buscar
                    </button>
                </div>
                <div style={styles.filtersArea.loading}>
                    <Loading
                        isFetching={this.props.documentsIsFetching}
                    />
                </div>
            </div>);

        // Stats Area
        const totalDocs = this.props.documents.size;
        const signedDocs = this.props.documents.filter( doc => doc.get('estado') === 'firmado').size;

        let statsArea = (
            <div className="row">
                <div className="col" style={styles.statsArea.wrapper}>
                    <div className="badge badge-primary" style={styles.statsArea.item}>
                        <span>Total CPD’s y prórrogas</span>
                        <span style={styles.statsArea.item.indicator}>{totalDocs}</span>
                    </div>
                    <div className="badge badge-success" style={styles.statsArea.item}>
                        <span>Firmados</span>
                        <span style={styles.statsArea.item.indicator}>{ signedDocs }</span>
                    </div>
                    <div className="badge badge-danger" style={styles.statsArea.item}>
                        <span>Pendientes de firma</span>
                        <span style={styles.statsArea.item.indicator}>{ totalDocs - signedDocs }</span>
                    </div>
                </div>
                <div className="col-auto d-flex justify-content-end align-items-center" style={{ marginLeft: 10, marginRight: 10 }} >
                        <span className="d-flex mr-3" style={ styles.legend }>
                        </span>
                    <span className="font-weight-bold mr-3">Prórroga</span>
                    <span style={ selectedDocs.size > 0 ? styles.selectedDocsVisible : styles.selectedDocsHidden }>
                            <button
                                className="btn btn-custom btn-sm mr-3"
                                onClick={this.handleModal.bind(this, 'docsDownloadDialog')}
                            >
                                <span>Descargar ({ selectedDocs.size })</span>
                            </button>
                            <button
                                className="btn btn-custom btn-sm mr-3"
                                onClick={() => {
                                    let hasSignedDocSelected = false;
                                    // check if user try to sign a signed doc
                                    for (const doc of selectedDocs) {
                                        const findDoc = this.props.documents.find(document => document.get("_id") === doc);
                                        if (findDoc.get("estado") === "firmado") {
                                            hasSignedDocSelected = true;
                                            break;
                                        }
                                    }
                                    if (hasSignedDocSelected) {
                                        // open dialog with message
                                        this.handleModal.bind(this, 'hasSignedDocSelected')();
                                    } else {
                                        // sign docs
                                        this.props.createSign.bind(this, prepareDocsJSON(this.props.documents, selectedDocs))();
                                    }
                                }}
                            >
                                <span>Firmar ({ selectedDocs.size })</span>
                            </button>
                        </span>

                    <button
                        className="btn btn-custom btn-sm"
                        onClick={this.handleModal.bind(this, 'uploadCpdDialog')}
                    >
                        <span>Subir CPD</span>
                    </button>
                </div>
            </div>
        );

        // Documents List Area
        let tableSettings = {
            sortable: false,
            filterable: false,
            defaultSortFields: [],
            defaultSortDirections: ['asc'],
            height: 485,
            rowHeight: 54,
            strippedRows: false,
            conditionalRowStyle: [{
                dataField: 'tipo_documento',
                operation: '=',
                value: 'prr',
                style: {
                    backgroundColor: '#E8F1F7'
                }
            }],
            rowStyle: {
                fontSize: 13
            }
        };
        let tableColumns = {
            checkbox: {
                title: (
                    <Checkbox
                        checked={ allSelectedDocs }
                        onChange={() => this.handleCheckbox('mainTableAll', tableData.map( doc => doc._id))}
                        style={{ marginTop: 10 }}
                    />
                ),
                columnType: 'function',
                dataField: '_id',
                width: 50,
                style: { display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', maxWidth: 50 },
                sortable: false,
                filterable: false,
                nodeContent: value => {
                    return (
                        <div style={{display: 'flex'}}>
                            <Checkbox
                                checked={selectedDocs.has(value)}
                                onChange={() => this.handleCheckbox('mainTable', value)}
                                style={{ marginTop: 5 }}
                            />
                        </div>
                    );
                }
            },
            nombre: {
                title: "Nombre",
                columnType: 'text',
                dataField: 'nombre',
                width: 300,
                style: { fontWeight: 'bold' },
                sortable: false,
                filterable: false
            },
            cliente: {
                title: "Cliente",
                columnType: 'text',
                dataField: 'cliente_id.nombre',
                width: 100,
                style: { fontWeight: 'bold' },
                sortable: false,
                filterable: false
            },
            delegacion: {
                title: "Delegación",
                columnType: 'text',
                dataField: 'delegacion.nombre',
                width: 100,
                style: {},
                sortable: false,
                filterable: false
            },
            oficina: {
                title: "Oficina",
                columnType: 'text',
                dataField: 'oficina.nombre',
                width: 100,
                style: {},
                sortable: false,
                filterable: false
            },
            centro: {
                title: "Centro",
                columnType: 'text',
                dataField: 'centro.nombre',
                width: 100,
                style: {},
                sortable: false,
                filterable: false
            },
            fecha_documento: {
                title: "Fecha Documento",
                columnType: 'function',
                dataField: 'fecha_documento',
                width: 100,
                style: { maxWidth: 150 },
                sortable: false,
                filterable: false,
                nodeContent: (value) => {
                    return moment(value).format('DD/MM/YYYY') ;
                }
            },
            fecha_firma: {
                title: "Fecha Firma",
                columnType: 'function',
                dataField: 'fecha_firma',
                width: 100,
                style: { maxWidth: 125 },
                sortable: false,
                filterable: false,
                nodeContent: (value) => {
                    return value ? moment(value).format('DD/MM/YYYY') : '--';
                }
            },
            estado: {
                title: "Estado",
                columnType: 'function',
                dataField: 'estado',
                width: 100,
                style: { maxWidth: 100 },
                sortable: false,
                filterable: false,
                nodeContent: (value) => {
                    let status = value === 'firmado' ? 'success' : value === 'pendiente' ? 'danger' : 'info';
                    return (
                        <div style={{}}>
                            <span
                                className={'badge badge-' + status}
                                style={{
                                    padding: '5px 8px',
                                    fontSize: 12
                            }}>{_.capitalize(value)}</span>
                        </div>
                    );
                }
            },
            actions: {
                title: '',
                columnType: 'function',
                dataField: '_id',
                width: 30,
                style: { padding: 0, maxWidth: 30 },
                sortable: false,
                filterable: false,
                nodeContent: (docId) => {
                    const doc = tableData.find( doc => doc._id === docId );
                    let popoverContent;

                    switch (doc.tipo_documento) {
                        case 'cpd':
                            popoverContent = (
                                <Menu>
                                    <MenuItem icon="download" onClick={this.handleMenuClick.bind(this, 'downloadCpd')} text="Descargar" />
                                    <MenuItem icon="paperclip" onClick={this.handleMenuClick.bind(this, 'attachs')} text="Anexos" />
                                    <MenuItem icon="draw" onClick={this.handleMenuClick.bind(this, 'sign')} text="Firmar" disabled={doc.estado === "firmado" ? true : false} />
                                    <MenuItem icon="upload" onClick={this.handleMenuClick.bind(this, 'uploadPrr')} text="Subir prórroga" />
                                    <MenuItem icon="exchange" onClick={this.handleMenuClick.bind(this, 'exchangeCpd')} text="Sustituir documento" />
                                    <MenuItem icon="trash" onClick={this.handleMenuClick.bind(this, 'deleteCpd')} text="Eliminar" />
                                </Menu>
                            );
                            break;
                        case 'prr':
                            popoverContent = (
                                <Menu>
                                    <MenuItem icon="download" onClick={this.handleMenuClick.bind(this, 'downloadPrr')} text="Descargar" />
                                    <MenuItem icon="draw" onClick={this.handleMenuClick.bind(this, 'sign')} text="Firmar" />
                                    <MenuItem icon="exchange" onClick={this.handleMenuClick.bind(this, 'exchangeCpd')} text="Sustituir documento" />
                                    <MenuItem icon="trash" onClick={this.handleMenuClick.bind(this, 'deletePrr')} text="Eliminar" />
                                </Menu>
                            );
                            break;
                        default:
                            break;
                    }

                    return (
                        <Popover
                            content={ popoverContent }
                            interactionKind={PopoverInteractionKind.CLICK}
                            position={Position.BOTTOM_RIGHT}
                        >
                            <Icon
                                className="icon-custom"
                                title={false}
                                icon={'more'}
                                iconSize={18}
                                onClick={this.selectDoc.bind(this, docId)}
                            />
                        </Popover>
                    );
                }
            }
        };

        // Modal Area
        let modal = null;

        if (modalIsOpen) {
            switch (modalType) {
                case 'attachsDialog':
                    modal = {
                        width: 'large',
                        title: 'Anexos del CPD',
                        body: (
                            <DocumentsPageAttachsDialog
                                attachTypes={ attachTypes }
                                attachs={ selectedDoc.toJS().anexos }
                                doc={ selectedDoc.toJS() }
                                uploadAttach={ this.uploadAttach }
                                downloadAttach={ this.downloadAtt }
                                deleteAttach={ this.deleteAttach }
                                onFormData={ this.handleFormData }
                                canSubmit={canSubmit}
                            />
                        ),
                        footer: (
                            <div className="pb15 text-right">
                                <button
                                    key="close"
                                    type="button"
                                    className="btn btn-sm"
                                    label={'CERRAR'}
                                    style={{
                                        color: 'gray',
                                        marginRight: 15
                                    }}
                                    onClick={this.closeModal}
                                >
                                    {'CERRAR'}
                                </button>
                            </div>
                        )
                    };
                    break;
                case 'docsDownloadDialog':
                    const docsIds = [...this.state.selectedDocs];
                    const data = tableData.filter(doc => docsIds.find(id => id === doc._id))
                        .map(doc => {
                            const attachs = doc.anexos || [];
                            let docType;
                            switch (doc.tipo_documento) {
                                case 'cpd':
                                    docType = 'CPD';
                                    break;
                                case 'prr':
                                    docType = 'Prorroga';
                                    break;
                                default:
                                    docType = doc.tipo_documento;
                                    break;
                            }
                            return ({
                                nombre: doc.nombre,
                                tipo: docType,
                                anexos: attachs.length > 0 ? attachs.length : '-'
                            });
                        });
                    modal = {
                        width: 'large',
                        text: 'Descargar documentos',
                        body: (
                            <HomePageDocsDownloadDialog
                                tableData={ data }
                                attachTypes={ attachTypes }
                                onFormData={ this.handleFormData }
                            /> ),
                        footer: (
                            <div className="p15 text-right">
                                <button
                                    key="cancel"
                                    type="button"
                                    className="btn btn-sm"
                                    label={'CANCELAR'}
                                    style={{
                                        color: 'gray',
                                        marginRight: 15
                                    }}
                                    onClick={this.closeModal}
                                >
                                    {'CANCELAR'}
                                </button>
                                <button
                                    key="accept"
                                    type="button"
                                    className="btn btn-sm btn-success"
                                    onClick={this.downloadDocuments}
                                >
                                    {'DESCARGAR'}
                                </button>
                            </div>
                        )
                    };
                    break;
                case 'uploadCpdDialog':
                    modal = {
                        width: 'x-large',
                        title: 'Subir CPD',
                        body: (
                            <CreateCpdDialog
                                filters={ {
                                    customers: customerFilterOptions,
                                    delegations: delegationFilterOptions,
                                    centers: this.props.centers.toJS(),
                                    offices: this.props.offices.toJS()
                                } }
                                onFormData={ this.handleFormData }
                            />
                        ),
                        footer: (
                            <div className="p15 text-right">
                                <button
                                    key="close"
                                    type="button"
                                    className="btn btn-sm"
                                    label={'CANCELAR'}
                                    style={{
                                        color: 'gray',
                                        marginRight: 15
                                    }}
                                    onClick={this.closeModal}
                                >
                                    {'CANCELAR'}
                                </button>
                                <button
                                    key="accept"
                                    type="button"
                                    className="btn btn-sm btn-success"
                                    onClick={this.uploadCpd}
                                    disabled={!canSubmit}
                                >
                                    {'SUBIR DOCUMENTO'}
                                </button>
                            </div>
                        )
                    };
                    break;
                case 'uploadPrrDialog':
                    modal = {
                        width: 'x-large',
                        title: 'Subir prórroga',
                        body: (
                            <UploadPrrDialog
                                onFormData={ this.handleFormData }
                                docName={ selectedDoc.get('nombre') }
                            />
                        ),
                        footer: (
                            <div className="p15 text-right">
                                <button
                                    key="close"
                                    type="button"
                                    className="btn btn-sm"
                                    label={'CANCELAR'}
                                    style={{
                                        color: 'gray',
                                        marginRight: 15
                                    }}
                                    onClick={this.closeModal}
                                >
                                    {'CANCELAR'}
                                </button>
                                <button
                                    key="accept"
                                    type="button"
                                    className="btn btn-sm btn-success"
                                    onClick={this.uploadPrr}
                                    disabled={!canSubmit}
                                >
                                    {'SUBIR DOCUMENTO'}
                                </button>
                            </div>
                        )
                    };
                    break;
                case 'updateDocDialog':
                    modal = {
                        width: 'x-large',
                        title: 'Sustituir documento',
                        body: (
                            <UpdateDocDialog
                                onFormData={ this.handleFormData }
                                doc={ selectedDoc }
                            />
                        ),
                        footer: (
                            <div className="p15 text-right">
                                <button
                                    key="close"
                                    type="button"
                                    className="btn btn-sm"
                                    label={'CANCELAR'}
                                    style={{
                                        color: 'gray',
                                        marginRight: 15
                                    }}
                                    onClick={this.closeModal}
                                >
                                    {'CANCELAR'}
                                </button>
                                <button
                                    key="accept"
                                    type="button"
                                    className="btn btn-sm btn-success"
                                    onClick={this.updateDoc}
                                    disabled={!canSubmit}
                                >
                                    {'SUSTITUIR DOCUMENTO'}
                                </button>
                            </div>
                        )
                    };
                    break;
                case 'deleteDocDialog':
                    switch(selectedDoc.get('tipo_documento')) {
                        case 'cpd':
                            modal = {
                                width: 'medium',
                                title: 'Eliminar CPD',
                                body: (
                                    <DeleteDocDialog
                                        doc={ selectedDoc }
                                    />
                                ),
                                footer: (
                                    <div className="p15 text-right">
                                        <button
                                            key="close"
                                            type="button"
                                            className="btn btn-sm"
                                            label={'CANCELAR'}
                                            style={{
                                                color: 'gray',
                                                marginRight: 15
                                            }}
                                            onClick={this.closeModal}
                                        >
                                            {'CANCELAR'}
                                        </button>
                                        <button
                                            key="accept"
                                            type="button"
                                            className="btn btn-sm btn-danger"
                                            onClick={() => this.props.deleteCpd(selectedDoc.get('_id')).then(this.closeModal())}
                                        >
                                            {'ELIMINAR CPD'}
                                        </button>
                                    </div>
                                )
                            };
                            break;
                        case 'prr':
                            modal = {
                                width: 'medium',
                                title: 'Eliminar Prórroga',
                                body: (
                                    <DeleteDocDialog
                                        doc={ selectedDoc }
                                    />
                                ),
                                footer: (
                                    <div className="p15 text-right">
                                        <button
                                            key="close"
                                            type="button"
                                            className="btn btn-sm"
                                            label={'CANCELAR'}
                                            style={{
                                                color: 'gray',
                                                marginRight: 15
                                            }}
                                            onClick={this.closeModal}
                                        >
                                            {'CANCELAR'}
                                        </button>
                                        <button
                                            key="accept"
                                            type="button"
                                            className="btn btn-sm btn-danger"
                                            onClick={() => this.props.deletePrr(selectedDoc.get('_id')).then(this.closeModal())}
                                        >
                                            {'ELIMINAR PRÓRROGA'}
                                        </button>
                                    </div>
                                )
                            };
                            break;
                        default:
                            break;
                    }
                    break;
                case 'hasSignedDocSelected':
                    modal = {
                        width: 'x-large',
                        title: 'Firmar CPD',
                        body: (
                            <div style={{display: "flex"}}>
                                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <Icon
                                        className={"icon-custom"}
                                        title={false}
                                        icon={"warning-sign"}
                                        iconSize={24}
                                        color={"yellow"}
                                    />
                                </div>
                                <span style={{marginLeft: "20px"}}>Se ha seleccionado algún documento que ya ha sido firmado previamente. Por favor, realice una selección con todos los documentos pendientes de firma.</span>
                            </div>
                        ),
                        footer: (
                            <div className="p15 text-right">
                                <button
                                    key="close"
                                    type="button"
                                    className="btn btn-sm"
                                    label={'CERRAR'}
                                    style={{
                                        color: 'gray',
                                        marginRight: 15
                                    }}
                                    onClick={this.closeModal}
                                >
                                    {'CERRAR'}
                                </button>
                            </div>
                        )
                    };
                    break;
                default:
                    modal = {
                        width: 'medium',
                        title: '',
                        body: null,
                        footer: null
                    }
                    break;
            }
        }

        return (
            <div>
                {filtersArea}
                {statsArea}
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <VirtualizedTable
                                tableSettings={tableSettings}
                                tableColumns={tableColumns}
                                tableData={tableData}
                            />
                        </div>
                    </div>
                </div>
                {modalIsOpen && (
                    <Modal
                        onClose={this.closeModal}
                        heading={modal.title}
                        width={modal.width}
                        autoFocus={false}
                        footer={() => modal.footer}
                    >
                        {modal.body}
                    </Modal>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    
    // Reducers
    const orgStructureReducer = state.organizationalStructure;
    const documentsReducer = state.documents;
    const customersReducer = state.customers;
    const sessionReducer = state.auth.session;

    // Documents
    const documents = documentsReducer.get('payload') || List([]);
    const documentsIsFetching = documentsReducer.get('isFetching') || false;

    // Organizational Structure
    const orgStructure = orgStructureReducer.get('payload') || Map({});
    let delegations = List([
            Map({
                delegacion_id: -1,
                nombre: 'Todas'
            })
        ]),
        offices = List([
            Map({
                delegacion_id: -1,
                oficina_id: -1,
                nombre: 'Todas'
            })
        ]),
        centers = List([
            Map({
                delegacion_id: -1,
                oficina_id: -1,
                centro_id: -1,
                nombre: 'Todos'
            })
        ]);
    (orgStructure.get('delegaciones') || List([])).map((delegation) => {
        (delegation.get('oficinas') || List([])).map((office) => {
            (office.get('centros') || List([])).map(center => {

                return centers = centers.insert(
                    centers.size,
                    center
                        .set('delegacion_id', delegation.get('delegacion_id'))
                        .set('oficina_id', office.get('oficina_id'))
                        .delete('_id')
                        .delete('en_baja')
                );
            });

            return offices = offices.insert(
                offices.size,
                office
                    .set('delegacion_id', delegation.get('delegacion_id'))
                    .delete('centros')
                    .delete('_id')
                    .delete('en_baja')
            );
        });

        return delegations = delegations.insert(
            delegations.size,
            delegation
                .delete('oficinas')
                .delete('_id')
        );
    });

    // Customers    
    let customers = List([
        Map({
            _id: -1,
            nombre: 'Todos'
        })
    ]);
    (customersReducer.get('payload') || List([])).map( customer => {
        return customers = customers.insert(
            customers.size,
            customer
                .delete('cif')
                .delete('estado')
                .delete('fecha_actualizacion')
                .delete('fecha_creacion')
                .delete('__v')
                .delete('id')
        );
    });

    // Session
    const user = sessionReducer.get('user') || Map({});

    // User Organizational Structure
    const userOrgStructure = (user && user.get('estructura_organizativa')) || Map({});
    let userDelegations = List([
            Map({
                delegacion_id: -1,
                nombre: 'Todas'
            })
        ]),
        userOffices = List([
            Map({
                delegacion_id: -1,
                oficina_id: -1,
                nombre: 'Todas'
            })
        ]),
        userCenters = List([
            Map({
                delegacion_id: -1,
                oficina_id: -1,
                centro_id: -1,
                nombre: 'Todos'
            })
        ]);

    (userOrgStructure.get('delegaciones') || List([])).map((delegation) => {
        (delegation.get('oficinas') || List([])).map((office) => {
            (office.get('centros') || List([])).map(center => {

                return userCenters = userCenters.insert(
                    userCenters.size,
                    center
                        .set('delegacion_id', delegation.get('delegacion_id'))
                        .set('oficina_id', office.get('oficina_id'))
                        .delete('_id')
                        .delete('en_baja')
                );
            });

            return userOffices = userOffices.insert(
                userOffices.size,
                office
                    .set('delegacion_id', delegation.get('delegacion_id'))
                    .delete('centros')
                    .delete('_id')
                    .delete('en_baja')
            );
        });

        return userDelegations = userDelegations.insert(
            userDelegations.size,
            delegation
                .delete('oficinas')
                .delete('_id')
        );
    });

    // If user is of type "cliente" or "gestor" we need to filter the organizational structure (delegations, offices, centers)
    if(user.get('tipo') === 'cliente' || user.get('tipo') === 'gestor') {
        delegations = delegations.filter( (delegation) => {
            return userDelegations.findIndex( (item) => item.get('delegacion_id') === delegation.get('delegacion_id')) !== -1;
        });

        offices = offices.filter( (office) => {
            return userOffices.findIndex( (item) => item.get('oficina_id') === office.get('oficina_id')) !== -1;
        });

        centers = centers.filter( (center) => {
            return userCenters.findIndex( (item) => item.get('centro_id') === center.get('centro_id')) !== -1;
        });

    }

    return {
        customers,
        delegations,
        offices,
        centers,
        documents, documentsIsFetching
    };    
}

function mapDispatchToProps(dispatch) {
    return {
        getOrgStructure: () => dispatch(getOrgStructure()),
        getDocuments: (query) => dispatch(getDocuments(query)),
        getCustomers: () => dispatch(getCustomers()),
        downloadDocs: (cpdIds, prrIds, includeAtts, attsType) => dispatch(downloadDocs(cpdIds, prrIds, includeAtts, attsType)),
        downloadCpd: (cpdId, includeAtts) => dispatch(downloadCpd(cpdId, includeAtts)),
        downloadPrr: (prrId) => dispatch(downloadPrr(prrId)),
        downloadAtt: (cpdId, attId) => dispatch(downloadAtt(cpdId, attId)),
        uploadCpd: (fromData) => dispatch(uploadCpd(fromData)),
        updateCpd: (cpdId, fromData) => dispatch(updateCpd(cpdId, fromData)),
        deleteCpd: (cpdId) => dispatch(deleteCpd(cpdId)),
        uploadAttach: (cpdId, fromData) => dispatch(uploadAttach(cpdId, fromData)),
        deleteAttach: (cpdId, attachId) => dispatch(deleteAttach(cpdId, attachId)),
        uploadPrr: (cpdId, fromData) => dispatch(uploadPrr(cpdId, fromData)),
        updatePrr: (prrId, fromData) => dispatch(updatePrr(prrId, fromData)),
        deletePrr: (prrId) => dispatch(deletePrr(prrId)),
        createSign: (docs_JSON) => dispatch(createSign(docs_JSON))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsPage);