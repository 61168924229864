import React, { Component } from 'react';
import { connect } from 'react-redux';

// Actions
import { signin, cleanSigninError, forgotPassword, cleanForgotPasswordError } from '../actions/auth';

import bgImage from '../assets/img/home.jpg';
import logo from '../assets/img/logo_fenicia.png';


// Components
import SigninForm from '../components/SigninForm';
import ForgotPasswordForm from '../components/ForgotPasswordForm';

const styles = {
    pageWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: `url(${bgImage}) center center no-repeat fixed`,
        height: '100%'
    },
    loginContainer: {
        background: 'rgba(255,255,255,.7)',
        maxWidth: '400px',
        width: '90%'
    },
    logoWrapper: {
        height: 80,
        width: '100%',
        padding: '10px',
        margin: '30px 0',
        backgroundImage: `url(${logo})`,
        backgroundSize: 'content',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center top'
    },
    bodyWrapper: {
        padding: '10px',
        width: '100%'
    },
    footerWrapper: {
        padding: '10px',
        backgroundColor: '#4256AD',
        color: '#fff',
        width: '100%',
        height: 50,
        fontSize: 13,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textDecoration: 'underline',
        fontWeight: 'bold',
        paddingLeft: 40
    }
};

class Signin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            screen: 'signin'
        };

        this.toggleScreen = this.toggleScreen.bind(this);

        this.handleSignin = this.handleSignin.bind(this);
        this.handleCleanSigninError = this.handleCleanSigninError.bind(this);

        this.handleForgotPassword = this.handleForgotPassword.bind(this);
        this.handleCleanForgotPasswordError = this.handleCleanForgotPasswordError.bind(this);
        this.handleForgotPasswordSuccess = this.handleForgotPasswordSuccess.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.isLoggedIn) {
            this.props.history.push('/');
        }
    }

    toggleScreen(screen) {
        if (screen) {
            this.setState({
                screen: screen
            });
        }
    }

    handleSignin(credentials) {
        this.props.signin({
            username:  credentials.email,
            password: credentials.password
        });
    }

    handleCleanSigninError() {
        this.props.cleanSigninError();
    }

    handleForgotPassword(email){
        if(email){
            this.props.forgotPassword(email);
        }
    }

    handleForgotPasswordSuccess(){
        this.setState({
                screen: 'signin'
            });
    }

    handleCleanForgotPasswordError() {
        this.props.cleanForgotPasswordError();
    }

    render() {
        let formBody;

        switch (this.state.screen) {
            case 'signin':
                formBody = (
                    <SigninForm
                        toggleScreen={this.toggleScreen}
                        signin={this.handleSignin}
                        error={this.props.signinErrorMessage}
                        cleanSigninError={this.handleCleanSigninError}
                    />
                );
                break;
            case 'forgot-password':
                formBody = (
                    <ForgotPasswordForm
                        screen={this.state.screen}
                        toggleScreen={this.toggleScreen}
                        forgotPassword={this.handleForgotPassword}
                        forgotPasswordSuccess={this.handleForgotPasswordSuccess}
                        cleanError={this.handleCleanForgotPasswordError}
                        isFetching={this.props.forgotPasswordIsFetching}
                        success={this.props.forgotPasswordSuccess}
                        error={this.props.forgotPasswordError}
                        errorMessage={this.props.forgotPasswordErrorMessage}
                    />
                );
                break;
            default:
                formBody = null;
                break;
        }

        return (
            <div className="login-wrapper">
                <div className="bg-pic">
                    <img src={bgImage} className="lazy" alt="Fenicia del Sur"/>
                    <div className="bg-caption pull-bottom sm-pull-bottom text-white p-l-20 m-b-20">
                    </div>
                </div>
                <div style={{ height: '100%' }}>
                    <div className="login-container bg-white">
                        <div>
                        </div>
                        <div className="p-l-50 m-l-20 p-r-50 m-r-20 p-t-50 m-t-30 sm-p-l-15 sm-p-r-15 sm-p-t-40" style={{ width: '80%' }}>
                            <div className="text-center">
                                <img style={{ width: 200, margin: "40px auto" }} src={logo} alt="logo"/>
                            </div>
                            {formBody}
                        </div>
                        {this.state.screen === 'signin' ? (
                            <div style={styles.footerWrapper}>
                                <a onClick={() => this.toggleScreen('forgot-password')}>¿Has olvidado tu contraseña?</a>
                            </div>
                        ) : <div></div>}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {

    // Reducers
    const sessionReducer = state.auth.session;
    const forgotPasswordReducer = state.auth.forgotPassword;

    // Session
    const isLoggedIn = sessionReducer.isLoggedIn,
        errorCode = sessionReducer.get('errorSignin');

    // Forgot Password
    const forgotPasswordIsFetching = forgotPasswordReducer.get('isFetching'),
        forgotPasswordError = forgotPasswordReducer.get('error'),
        forgotPasswordErrorCode = forgotPasswordReducer.get('errorCode'),
        forgotPasswordSuccess = forgotPasswordReducer.get('success');

    let forgotPasswordErrorMessage = forgotPasswordError ? ('' + forgotPasswordErrorCode) : '';

    return {
        isLoggedIn,
        signinErrorMessage: errorCode || '',
        forgotPasswordIsFetching, forgotPasswordSuccess, forgotPasswordErrorMessage, forgotPasswordError
    };
}

function mapDispatchToProps(dispatch) {
    return {
        signin: ({ username, password }) => dispatch(signin({ username, password })),
        cleanSigninError: () => dispatch(cleanSigninError()),
        forgotPassword: (email) => dispatch(forgotPassword(email)),
        cleanForgotPasswordError: () => dispatch(cleanForgotPasswordError())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
