import { Map, fromJS, List } from "immutable";

import { EMPLOYEES_STATS_REQUEST, EMPLOYEES_STATS_SUCCESS, EMPLOYEES_STATS_ERROR } from "../actions/employeeStats";

const employeeStats = (state = Map({
    payload: List([]),
    isFetching: false,
    error: false
}), action) => {
    let newState;

    switch (action.type) {
        // Get Employee Documents
        case EMPLOYEES_STATS_REQUEST:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case EMPLOYEES_STATS_SUCCESS:
            newState = state.merge({
                payload: fromJS(action.payload),
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case EMPLOYEES_STATS_ERROR:
            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error,
                errorMessage: action.errorMessage
            });
            return newState;
        default:
            return state;
    };
};

export default employeeStats;
