import React, { Component } from 'react';
import { Checkbox } from "@blueprintjs/core";

import TextInput from '../components/text-input';

class UserChangePasswordDialog extends Component {
    constructor(props){
        super(props);

        this.state = {
            password: '',
            verifyPassword: '',
            sendEmail: false,
            validPassword: false,
            passwordMatches: false
        };

        this.onChangeInput = this.onChangeInput.bind(this);
    }

    onChangeInput(inputName, inputValue) {
        this.setState({
            [inputName]: inputValue,
            ...(inputName === 'password') ? { validPassword: inputValue.length > 5 } : {},
            ...(inputName === 'password') ? { passwordMatches: inputValue === this.state.verifyPassword } : {},
            ...(inputName === 'verifyPassword') ? { passwordMatches: inputValue === this.state.password } : {}
        }, () => this.props.onChangeFormData(this.state));
    }

    render() {
        const { password, verifyPassword, sendEmail, validPassword, passwordMatches } = this.state;

        return (
            <div style={{ marginTop: 20 }}>
                <div className="row">
                    <div className="col-6">
                        <TextInput
                            type={'password'}
                            value={password}
                            style={{ borderColor: '#ccc' }}
                            onChange={(event) => this.onChangeInput('password', event.target.value)}
                            labelText={'Contraseña *'}
                            helpText={validPassword ? 'Al menos 6 caracteres' : null}
                            errorText={!validPassword ? 'Contraseña demasiado corta' : null}
                        />
                    </div>
                    <div className="col-6">
                        <TextInput
                            type={'password'}
                            value={verifyPassword}
                            style={{ borderColor: '#ccc' }}
                            onChange={(event) => this.onChangeInput('verifyPassword', event.target.value)}
                            labelText={'Vuelve a introducir la contraseña *'}
                            helpText={passwordMatches ? 'Las contraseñas coinciden' : null}
                            errorText={!passwordMatches ? 'Las contraseñas no coinciden' : null}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div style={{ display: 'flex', height: 30 }}>
                            <Checkbox
                                checked={sendEmail}
                                onChange={(event) => this.onChangeInput('sendEmail', event.target.checked)}
                                label=""
                            />
                            <div>{`Enviar email al usuario`}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UserChangePasswordDialog;