import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { merge } from 'lodash';
import classnames from 'classnames';

export default class TextInput extends Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState){

        // Checking each prop
        return Object.keys(this.props).some(key => this.props[key] !== nextProps[key]);
    }

    onChange(event) {
        this.props.onChange(event);
    }

    onBlur(event) {
        this.props.onBlur(event);
    }

    onKeyDown(event) {
        this.props.onKeyDown(event);
    }

    render() {
        const { type, name, className, style, labelStyle, inputStyle, labelText, value, errorText, helpText, formStyling, required } = this.props;

        // Styles
        const styles = {
            root: {},
            input: {},
            label: {},
            required: {
                color: 'red'
            }
        };

        // Merge default styles with props
        styles.root = merge(styles.root, style);
        styles.input = merge(styles.input, inputStyle);
        styles.label = merge(styles.label, labelStyle);

        const labelElement = labelText && (required && formStyling === 'traditional' ? (
            <label style={styles.label}>{labelText} <span style={styles.required}>*</span></label>
        ) : (
            <label style={styles.label}>{labelText}</label>
        ));

        const errorElement = errorText && <span className="help-block text-danger fs11">{errorText}</span>;
        const helpElement = helpText && <span className="help-block fs11">{helpText}</span>;

        let rootClassNames = classnames(className, 'form-group', {
            'form-group-default':  formStyling === 'default',
            'required': required
        });

        return (
            <div
                className={rootClassNames}
                style={styles.root}
            >
                {labelElement}
                <input
                    type={type}
                    name={name}
                    className="form-control"
                    style={styles.input}
                    value={value}
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                    onKeyDown={this.onKeyDown}
                    disabled={this.props.disabled}
                    placeholder={this.props.placeholder}
                />
                {helpElement}
                {errorElement}
            </div>
        );
    }
}

TextInput.defaultProps = {
    formStyling: 'default',
    required: false,
    type: 'text',
    value: '',
    disabled: false,
    onChange: () => null,
    onKeyDown: () => null,
    onBlur: () => null
};

TextInput.propTypes = {

    /**
     * The style type of the input form. (default --> label inside or traditional --> label outside)
     */
    formStyling:  PropTypes.oneOf(['default', 'traditional']),

    /**
     * The input type. (text or password)
     */
    type:  PropTypes.oneOf(['text', 'password', 'number']),

    /**
     * The input name.
     */
    name:  PropTypes.string,

    /**
     * The css class name of the root element.
     */
    className: PropTypes.string,

    /**
     * Boolean prop indicating if the form is required
     */
    required: PropTypes.bool,

    /**
     * Override the inline-styles of the root element.
     */
    style:  PropTypes.object,

    /**
     * Override the inline-styles of the label element.
     */
    labelStyle: PropTypes.object,

    /**
     * Override the inline-styles of the input element.
     */
    inputStyle: PropTypes.object,

    /**
     * The content to use for label text.
     */
    labelText: PropTypes.string,

    /**
     * Callback function that is fired when the input's value changes.
     * Signature:
     *  function(event: object) => void
     *  event: Change event targeting the text field.
     */
    onChange: PropTypes.func,

    /**
     * Callback function that is fired when the input receives a key down event.
     * Signature:
     *  function(event: object) => void
     *  event: Key event targeting the text field.
     */
    onKeyDown: PropTypes.func,

    /**
     * The value of the text input.
     */
    value: PropTypes.string,

    /**
     * The error text content to display.
     */
    errorText: PropTypes.string,

    /**
     * The help text content to display.
     */
    helpText: PropTypes.string
};