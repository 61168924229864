import React, { Component } from 'react';
import { isEmail } from 'validator';
import { Icon } from '@blueprintjs/core';

class SigninForm extends Component {
    constructor(props) {
        super(props);

        this.state= {
            email: '',
            password: '',
            canSubmit: false,
            showError:false
        };

        this.signin = this.signin.bind(this);
        this.onCloseErrorMessage = this.onCloseErrorMessage.bind(this);
        this.onChangeTextField = this.onChangeTextField.bind(this);
        this.handlePressEnter = this.handlePressEnter.bind(this);
    }

    componentWillMount() {
        this.props.cleanSigninError();
    }

    signin() {
        this.props.signin({
            email: this.state.email,
            password: this.state.password
        });
    }

    onCloseErrorMessage() {
        this.props.cleanSigninError();
    }

    static canSubmit(state) {
        return isEmail(state.email, { allow_utf8_local_part: false }) && state.password !== '';
    }

    onChangeTextField(e) {
        let tempStateVar = {
            ...this.state,
            [e.target.name]: e.target.value
        };

        this.setState({
            [e.target.name]: e.target.value,
            canSubmit: SigninForm.canSubmit(tempStateVar)
        });
    }

    handlePressEnter(e) {
        const ENTER = 13;
        if(e.keyCode === ENTER && SigninForm.canSubmit(this.state)) {
            this.signin();
        }
    }

    render() {
        return (
            <div>
                <div className="col" style={{ ...styles.inputWrapper,  }}>
                    <h4 style={{ fontSize: 16, display: 'flex', justifyContent: 'center' }}>Accede a tu cuenta</h4>
                </div>
                <form id="form-login">
                    <div className="col" style={styles.inputWrapper}>
                        <div style={{
                            display: 'flex',
                            width: '100%',
                            border: '1px solid #e0e0e0',
                            alignItems: 'center'
                        }}>
                            <div style={{ paddingLeft: 10 }}>
                                <Icon icon="user" iconSize={20} />
                            </div>
                            <input
                                style={styles.input}
                                className="form-control"
                                type="email"
                                name="email"
                                placeholder={'Email'}
                                value={this.state.email}
                                onChange={this.onChangeTextField}
                                onKeyDown={this.handlePressEnter}
                            />
                        </div>
                    </div>
                    <div className="col" style={styles.inputWrapper}>
                        <div style={{
                            display: 'flex',
                            width: '100%',
                            border: '1px solid #e0e0e0',
                            alignItems: 'center'
                        }}>
                            <div style={{ paddingLeft: 10 }}>
                                <Icon icon="lock" iconSize={20} />
                            </div>
                            <input
                                style={styles.input}
                                className="form-control"
                                type="password"
                                name="password"
                                placeholder={'Contraseña'}
                                value={this.state.password}
                                onChange={this.onChangeTextField}
                                onKeyDown={this.handlePressEnter}
                            />
                        </div>
                    </div>
                    {this.props.error ? (
                        <div className="col" style={styles.inputWrapper}>
                            <div className={`alert alert-danger`} style={styles.input} >
                                <i className="fa fa-info p-r-10" style={styles.info}/>
                                <button type="button" className="close" aria-label="Close">
                                    <span aria-hidden="true" onClick={() => this.props.cleanSigninError()}>&times;</span>
                                </button>
                                <span>{ this.props.error }</span>
                            </div>
                        </div>
                    ) : null}
                    <div className="col">
                        <button
                            style={{ ...styles.submitButton, cursor: !this.state.canSubmit ? 'not-allowed' : 'pointer' }}
                            className="btn btn-custom btn-sm"
                            type="button"
                            onClick={this.signin}
                            disabled={!this.state.canSubmit}
                        >
                            ACCEDER
                        </button>
                    </div>
                </form>
            </div>
        )
    }
}

export default SigninForm;

const styles = {
    inputWrapper: {
        margin: 10
    },
    input: {
        borderRadius: 0,
        outline: 'none',
        border: 'none',
        boxShadow: 'none'
    },
    submitButton: {
        borderRadius: 0,
        minWidth: 100,
        margin: 10
    },
    info: {
        marginLeft: 0,
        marginRight: 15
    }
};