import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import HomePage from '../containers/HomePage';
import NotFoundPage from '../components/NotFoundPage';
import ControlPanelPage from "../containers/ControlPanelPage";
import UserProfile from "../containers/UserProfile";
import EmployeeProfilePage from '../containers/EmployeeProfilePage';
import EmployeesPanelPage from '../containers/EmployeesPanelPage';

class MainRoutes extends Component {

    render() {
        return (
            <Switch>
                <Route path="/" component={HomePage} exact />
                <Route path="/panel-control" component={ControlPanelPage}/>
                <Route path="/trabajadores/trabajador/:id" component={EmployeeProfilePage}/>
                <Route path="/trabajadores" component={EmployeesPanelPage}/>
                <Route path="/perfil-usuario" component={UserProfile} exact/>
                <Route component={NotFoundPage}/>
            </Switch>
        );
    }
}

export default MainRoutes;