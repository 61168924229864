import axios from 'axios';
import Auth from '../lib/auth';
import { addNotification } from './notifications';

export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_ERROR = 'SIGNIN_ERROR';
export const SIGNIN_CLEAN_ERROR = 'SIGNIN_CLEAN_ERROR';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const FORGOT_PASSWORD_CLEAN_ERROR = 'FORGOT_PASSWORD_CLEAN_ERROR';

export const SIGNOUT = 'SIGNOUT';

export const CHECK_TOKEN_REQUEST = 'CHECK_TOKEN_REQUEST';
export const CHECK_TOKEN_SUCCESS = 'CHECK_TOKEN_SUCCESS';
export const CHECK_TOKEN_ERROR = 'CHECK_TOKEN_ERROR';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const IS_LOGGED_IN = 'IS_LOGGED_IN';
export const GET_USER_FROM_LOCAL_STORAGE = 'GET_USER_FROM_LOCAL_STORAGE';

export const START_JWT_VALIDATION = 'START_JWT_VALIDATION';

const JWT_VALIDATION_INTERVAL = 30000;


/* Inject middleware to axios requests */
axios.interceptors.response.use(Auth.checkResponse.bind(Auth), Auth.checkErrorResponse.bind(Auth));

// Signin
export function signin(credentials) {
    return dispatch => {

        if (credentials.username !== '' && credentials.password !== '') {
            return axios.post('/api/usuarios/signin', {}, {
                auth: {
                    username: credentials.username,
                    password: credentials.password
                }
            })
                .then(response => {
                    if (response.data && response.data.payload) {
                        let user = response.data.payload;
                        let authToken = Auth.getHeaderJwt(response.headers.authorization);

                        // Save the jwt token and user into local store
                        Auth.completeSignin(user, authToken);

                        dispatch(signinSuccess(user));

                        // Start JWT token validation
                        dispatch(startJWTValidation(dispatch));
                    } else {
                        dispatch(signinError(response.data.message || new Error(`Response data haven't message field`)));
                    }
                })
                .catch(err => {
                    let data = err.response.data;
                    dispatch(signinError(data.message));
                });
        }
    };
}

function signinSuccess(user) {
    return {
        type: SIGNIN_SUCCESS,
        user,
        isLoggedIn: true
    };
}

function signinError(error) {
    return {
        type: SIGNIN_ERROR,
        error
    };
}

export function cleanSigninError() {

    return {
        type: SIGNIN_CLEAN_ERROR
    };
}

export function forgotPassword(email) {
    return dispatch => {
        dispatch(forgotPasswordRequest());

        if (email !== '') {
            return axios.post('/api/usuarios/forgot', {email: email})
                .then(() => {
                    dispatch(forgotPasswordSuccess());
                }).catch((err) => {
                    let error = {};
                    error.code = err;
                    error.message = err;
                    dispatch(forgotPasswordFailure(error));
                });
        }
    };
}

function forgotPasswordRequest() {
    return {
        type: FORGOT_PASSWORD_REQUEST,
        isFetching: true,
        success: false,
        error: false
    };
}

function forgotPasswordSuccess() {
    return {
        type: FORGOT_PASSWORD_SUCCESS,
        isFetching: false,
        success: true,
        error: false
    };
}

function forgotPasswordFailure(error) {
    return {
        type: FORGOT_PASSWORD_ERROR,
        isFetching: false,
        success: false,
        error: true,
        errorMessage: error.message,
        errorCode: error.code
    };
}

export function cleanForgotPasswordError() {
    return {
        type: FORGOT_PASSWORD_CLEAN_ERROR,
        isFetching: false,
        success: false,
        error: false,
        errorMessage: '',
        errorCode: 0
    };
}

export function signout() {
    return dispatch => {
        let token = Auth.getLocalJwt();
        Auth.signout();

        return axios.get('/api/usuarios/signout', {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
            .catch(() => {
            })
            .finally(() => {
                dispatch(signoutSuccess());
            });
    };
}

function signoutSuccess() {
    return {
        type: SIGNOUT,
        user: null,
        isLoggedIn: false
    };
}

export function checkToken(token) {
    return dispatch => {
        dispatch(checkTokenRequest());

        if (token) {
            return axios.get('/api/usuarios/reset/' + token)
                .then(response => {
                    dispatch(checkTokenSuccess());
                })
                .catch(err => {
                    dispatch(checkTokenFailure(err));
                });
        }
    };
}

function checkTokenRequest() {
    return {
        type: CHECK_TOKEN_REQUEST,
        isFetching: true,
        success: false,
        error: false
    };
}

function checkTokenSuccess(payload) {
    return {
        type: CHECK_TOKEN_SUCCESS,
        isFetching: false,
        success: true,
        error: false,
        payload
    };
}

function checkTokenFailure(error) {
    return {
        type: CHECK_TOKEN_ERROR,
        isFetching: false,
        success: false,
        error: true,
        errorMessage: error.message
    };
}

/**
 * Reset Password
 * Resets the user password based on a valid token
 *
 * @param {object} token
 */
export function resetPassword(newPassword, verifyPassword, token) {
    return dispatch => {
        let passwordDetails = {
            newPassword: newPassword,
            verifyPassword: verifyPassword
        };

        dispatch(resetPasswordRequest());

        if (token) {
            return axios.post('/api/usuarios/reset/' + token, passwordDetails)
                .then(response => {
                    if (response.data && response.data.status === 1) {
                        let user = response.data.payload;
                        let authToken = Auth.getHeaderJwt(response.headers.authorization);

                        Auth.completeSignin(user, authToken);
                        dispatch(signinSuccess(user));
                        dispatch(resetPasswordSuccess());
                    }
                })
                .catch(err => {
                    dispatch(resetPasswordFailure(err));
                });
        }
    };
}

function resetPasswordRequest() {
    return {
        type: RESET_PASSWORD_REQUEST,
        isFetching: true,
        success: false,
        error: false
    };
}

function resetPasswordSuccess() {
    return {
        type: RESET_PASSWORD_SUCCESS,
        isFetching: false,
        success: true,
        error: false
    };
}

function resetPasswordFailure(error) {
    return {
        type: RESET_PASSWORD_ERROR,
        isFetching: false,
        success: false,
        error: true,
        errorMessage: error.message
    };
}

// SESSION MANAGAMENT

/**
 * isLoggedIn - return true/false if the user is logged in or not
 * @returns {{type: string, isLoggedIn: boolean}}
 */
export function isLoggedIn() {
    let isLoggedIn = Auth.isLoggedIn();

    return {
        type: IS_LOGGED_IN,
        isLoggedIn: isLoggedIn
    };
}

/**
 * Get user from local storage and save it in redux state
 */
function getUserFromLocalStorage() {
    let user = Auth.user() || null;

    return {
        type: GET_USER_FROM_LOCAL_STORAGE,
        user
    };
}

// Get Session Properties
export function getSessionData() {
    return dispatch => {
        dispatch(isLoggedIn());
        dispatch(getUserFromLocalStorage());

        if (Auth.isLoggedIn()) {

            // Start JWT token validation
            dispatch(startJWTValidation(dispatch));
        }
    };
}

// Check whether JWT has expired and user must be redirected to log in
// Execute periodically while user is logged in
function startJWTValidation(dispatch) {
    const id = setInterval(() => {
        if (!Auth.isLoggedIn()) {
            console.error('JWT expired. Forcing user to log out!');
            clearInterval(id);
            dispatch(signout());
        }
    }, JWT_VALIDATION_INTERVAL);

    return {
        type: START_JWT_VALIDATION
    };
}

/**
 * Action for changing user profile password
 * @param password
 * @returns {Function}
 */
export const changeOwnUserPassword = (data) => {
    return async dispatch => {
        try {
            const token = Auth.getLocalJwt();
            const res = await axios.post('/api/usuarios/password', data, { headers: { 'Authorization': 'Bearer ' + token } });

            if (res.data && res.data.status === 1) {

                // Notification for change password success
                dispatch(addNotification({
                    message: 'Contraseña modificada correctamente',
                    color: 'green',
                    icon: 'tick'
                }));

            }
        } catch (err) {
            let message = 'Error al modificar la contraseña';

            if(err.response && err.response.data && err.response.data.message) {
                let msg = err.response.data.message;

                if(msg === 'ERROR_PASSWORD_NOT_VALID') {
                    message = 'Error al modificar la contraseña: La contraseña actual no es correcta';
                } else if(msg === 'ERROR_NEW_PASSWORDS_NOT_MATCH') {
                    message = 'Error al modificar la contraseña: Las contraseñas no coinciden';
                }
            }

            // Notification for change password error
            dispatch(addNotification({
                message: message,
                color: 'red',
                icon: 'error'
            }));
        }
    };
};