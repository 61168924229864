// Attachs types list
import {List, Map} from "immutable";

export const attachTypes = new List([
    new Map({
        tipo: -1,
        desc: 'SELECCIONA UN TIPO'
    }),
    new Map({
        tipo: 'CPD',
        desc: 'CPD'
    }),
    new Map({
        tipo: 'CTR',
        desc: 'Contrato'
    }),
    new Map({
        tipo: 'RIN',
        desc: 'Recibí de Información'
    }),
    new Map({
        tipo: 'EPI',
        desc: 'EPI'
    }),
    new Map({
        tipo: 'RIC',
        desc: 'Recibí de Información Cliente'
    }),
    new Map({
        tipo: 'RA',
        desc: 'Alta'
    }),
    new Map({
        tipo: 'NIF',
        desc: 'NIF/NIE'
    }),
    new Map({
        tipo: 'HUE',
        desc: 'Huella'
    }),
    new Map({
        tipo: 'TRA',
        desc: 'Trámite'
    }),
    new Map({
        tipo: 'RM',
        desc: 'Reconocimiento médico'
    }),
    new Map({
        tipo: 'FRM',
        desc: 'Formación'
    }),
    new Map({
        tipo: 'FES',
        desc: 'Formación Específica'
    }),
    new Map({
        tipo: 'CTRP',
        desc: 'Prórroga de contrato'
    }),
    new Map({
        tipo: 'CPDP',
        desc: 'Prórroga de CPD'
    })
]);
