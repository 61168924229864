import { List, Map, fromJS } from 'immutable';

import {
    USERS_REQUEST, USERS_SUCCESS, USERS_ERROR,
    UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_ERROR,
    CREATE_USER_REQUEST, CREATE_USER_SUCCESS, CREATE_USER_ERROR,
    DELETE_USER_REQUEST, DELETE_USER_SUCCESS, DELETE_USER_ERROR,
    CHANGE_USER_STATUS_REQUEST, CHANGE_USER_STATUS_SUCCESS, CHANGE_USER_STATUS_ERROR,
    CHANGE_USER_PASSWORD_REQUEST, CHANGE_USER_PASSWORD_SUCCESS, CHANGE_USER_PASSWORD_ERROR
} from '../actions/users';

const initialState = Map({});

/**
 * Users reducer
 */

export default function users(state = initialState, action) {
    let newState, index = -1, updatedPayload;

    switch (action.type) {
        case USERS_REQUEST:
        case UPDATE_USER_REQUEST:
        case CREATE_USER_REQUEST:
        case DELETE_USER_REQUEST:
        case CHANGE_USER_STATUS_REQUEST:
        case CHANGE_USER_PASSWORD_REQUEST:
            newState = state
                .remove('isFetching')
                .remove('error')
                .remove('errorMessage')
                .merge({
                    isFetching: action.isFetching
                });
            return newState;
        case USERS_SUCCESS:
            newState = state
                .merge({
                    isFetching: action.isFetching,
                    payload: action.payload
                });
            return newState;
        case UPDATE_USER_SUCCESS:
        case CHANGE_USER_STATUS_SUCCESS:
        case CHANGE_USER_PASSWORD_SUCCESS:

            // Check if payload is a List (Array)
            if(List.isList(state.get('payload'))) {

                // Find the index of the user to be updated inside the List
                index = state.get('payload').findIndex(item => {
                    return item.get('_id') === action.payload._id;
                });

                // Update the payload list
                if (index !== -1) {
                    updatedPayload = state.get('payload').update(index, item => {
                        return fromJS(action.payload);
                    });
                }

                // If payload is a Map the payload to be updated is action.payload itself
            } else if(Map.isMap(state.get('payload'))) {
                updatedPayload = fromJS(action.payload);
            }
            newState = state
                .merge({
                    isFetching: action.isFetching,
                    error: action.error,
                    updated: action.updated,
                    payload: updatedPayload
                });
            return newState;

        case CREATE_USER_SUCCESS:
            // Check if payload is a List (Array)
            if(List.isList(state.get('payload'))) {
                updatedPayload = state.get('payload').push(fromJS(action.payload));

                // If payload is a Map the payload to be updated is action.payload itself
            } else if(Map.isMap(state.get('payload'))) {
                updatedPayload = fromJS(action.payload);
            }

            newState = state.merge({
                isFetching: action.isFetching,
                error: action.error,
                created: action.created,
                payload: updatedPayload
            });
            return newState;
        case DELETE_USER_SUCCESS:
            updatedPayload = state.get('payload').filter( user => {
                return user.get('_id') !== action.payload._id;
            });
            newState = state.merge({
                payload: updatedPayload,
                isFetching: action.isFetching,
                error: action.error
            });
            return newState;
        case USERS_ERROR:
        case UPDATE_USER_ERROR:
        case CREATE_USER_ERROR:
        case DELETE_USER_ERROR:
        case CHANGE_USER_STATUS_ERROR:
        case CHANGE_USER_PASSWORD_ERROR:
            newState = state
                .remove('isFetching')
                .remove('error')
                .remove('errorMessage')
                .merge({
                    isFetching: action.isFetching,
                    error: action.error,
                    errorMessage: action.errorMessage
                });
            return newState;
        default:
            return state;
    }
}
