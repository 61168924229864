import axios from 'axios';
import Auth from '../lib/auth';

export const EMPLOYEES_STATS_REQUEST = 'EMPLOYEES_STATS_REQUEST';
export const EMPLOYEES_STATS_SUCCESS = 'EMPLOYEES_STATS_SUCCESS';
export const EMPLOYEES_STATS_ERROR = 'EMPLOYEES_STATS_ERROR';

// Get Employees actions
export function getEmployeesStats(query) {
    return async dispatch => {
        try {
            const token = Auth.getLocalJwt();
            dispatch(getEmployeesStatsRequest());
            const res = await axios.post('/api/trabajadores/stats', query, { headers: { 'Authorization': 'Bearer ' + token } });
            if (res.data && res.data.status === 1) {
                dispatch(getEmployeesStatsSuccess(res.data.payload));
            } else {
                dispatch(getEmployeesStatsError(res.data.payload));
            }
        } catch (error) {
            dispatch(getEmployeesStatsError(error));
        }
    }
}

function getEmployeesStatsRequest() {
    return {
        type: 'EMPLOYEES_STATS_REQUEST',
        isFetching: true
    };
}

function getEmployeesStatsSuccess(payload) {
    return {
        type: 'EMPLOYEES_STATS_SUCCESS',
        isFetching: false,
        error: false,
        payload
    };
}

function getEmployeesStatsError(errorMessage) {
    return {
        type: 'EMPLOYEES_STATS_ERROR',
        isFetching: false,
        error: true,
        errorMessage
    };
}


