import axios from 'axios';
import Auth from '../lib/auth';
import utilsLib from './utils.lib';

export const AUDIT_QUERY_REQUEST = 'AUDIT_QUERY_REQUEST';
export const AUDIT_QUERY_SUCCESS = 'AUDIT_QUERY_SUCCESS';
export const AUDIT_QUERY_ERROR = 'AUDIT_QUERY_ERROR';

// Get customers
export function auditQuery(query = {}) {
    return async (dispatch) => {
        try {
            dispatch(queryRequest());

            const token = Auth.getLocalJwt();
            const res = await axios.post('/api/audit', { query }, { headers: { 'Authorization': 'Bearer ' + token } });

            dispatch(utilsLib.response(res, querySuccess, queryError));
        } catch (err) {
            dispatch(utilsLib.response(err, queryError));
        }
    };
};

function queryRequest() {
    return {
        type: AUDIT_QUERY_REQUEST,
        isFetching: true
    };
}

function querySuccess (payload) {
    return {
        type: AUDIT_QUERY_SUCCESS,
        isFetching: false,
        error: false,
        payload
    };
}

function queryError(errorMessage) {
    return {
        type: AUDIT_QUERY_ERROR,
        isFetching: false,
        error: true,
        errorMessage
    };
}

