import React, { useState, useEffect } from 'react';

const PDFViewer = ({ pdfBlob, isFetching }) => {
    const [pdfUrl, setPdfUrl] = useState(null);
    const [shouldCreateUrl, setShouldCreateUrl] = useState(false);

    useEffect(() => {
        // Verifica si pdfBlob es válido y si isFetching cambió de true a false
        if (pdfBlob !== null && pdfBlob.size > 0 && !isFetching && shouldCreateUrl) {
            setPdfUrl(URL.createObjectURL(pdfBlob));
            setShouldCreateUrl(false); // Establece shouldCreateUrl de nuevo a false
        }
    }, [pdfBlob, isFetching, shouldCreateUrl]);

    // Utiliza un useEffect separado para controlar shouldCreateUrl
    useEffect(() => {
        if (isFetching) {
            // Si isFetching es true, no debes crear la URL
            setShouldCreateUrl(false);
        } else {
            // Si isFetching cambió a false, puedes establecer shouldCreateUrl en true
            setShouldCreateUrl(true);
        }
    }, [isFetching]);

    return (
        <object data={pdfUrl} type={'application/pdf'} width="100%" height="100%" style={{maxHeight: 'calc(100% - 100px)'}} />
    );
};

export default PDFViewer;
