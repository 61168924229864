import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map, List } from 'immutable';
import _ from 'lodash';
import Modal from '@atlaskit/modal-dialog';
import { Icon, Popover, PopoverInteractionKind, Position, Menu, MenuItem } from "@blueprintjs/core";
import { getOrgStructure } from '../actions/organizationalStructure';
import { getCustomers, updateCustomer, createCustomer, changeCustomerStatus, deleteCustomer } from '../actions/customers';
import CustomerDialog from '../components/CustomerDialog';
import VirtualizedTable from '../components/virtualized-table';

class CustomerPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedCustomer: null,
            modalIsOpen: false,
            modalType: null,
            organizationalStructureListItems: [],
            customersList: [],
            canSubmit: false
        };

        this.handleCreateUser = this.handleCreateUser.bind(this);
        this.handleMenuClick = this.handleMenuClick.bind(this);
        this.selectUser = this.selectUser.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.onUpdateOrgListItems = this.onUpdateOrgListItems.bind(this);
        this.onUpdateCustomersList = this.onUpdateCustomersList.bind(this);
        this.onUpdateSelectedCustomer = this.onUpdateSelectedCustomer.bind(this);
        this.onChangeFormData = this.onChangeFormData.bind(this);
        this.onCreateCustomer = this.onCreateCustomer.bind(this);
        this.onUpdateCustomer = this.onUpdateCustomer.bind(this);
    };

    componentDidMount() {
        this.props.getOrgStructure();
        // this.props.getCustomers();
        this.props.getCustomers();

        // Control what user type can access
        if(this.props.user && this.props.user.get('tipo') === 'cliente') {
            this.props.history.push('/');
        }
    }

    componentDidUpdate(prevProps) {

        // Calculate initial customersList, based on complete customers
        if (this.props.customers !== prevProps.customers) {
            let customersList = [];

            if (this.props.customers && this.props.customers.size > 0) {
                this.props.customers.forEach(customer => {
                    customersList.push({
                        ...customer.toJS(),
                        checked: false
                    });
                });

                this.setState({
                    customersList
                });
            }
        }

        // Calculate initial organizationalStructureListItems, based on complete orgStructure
        if (this.props.orgStructure !== prevProps.orgStructure) {
            let listItems = [];

            if (this.props.orgStructure && this.props.orgStructure.size > 0) {
                this.props.orgStructure.get('delegaciones').forEach(delegacion => {

                    // Add delegacion item
                    listItems.push({
                        level: 'delegacion',
                        name: delegacion.get('nombre'),
                        id: delegacion.get('delegacion_id'),
                        opened: true,
                        checked: false
                    });
                    delegacion.get('oficinas').forEach(oficina => {

                        // Add oficina item
                        listItems.push({
                            level: 'oficina',
                            name: oficina.get('nombre'),
                            id: oficina.get('oficina_id'),
                            delegacion_id: delegacion.get('delegacion_id'),
                            opened: true,
                            show: true,
                            checked: false
                        });
                        oficina.get('centros').forEach(centro => {

                            // Add centro item
                            listItems.push({
                                level: 'centro',
                                name: centro.get('nombre'),
                                id: centro.get('centro_id'),
                                delegacion_id: delegacion.get('delegacion_id'),
                                oficina_id: oficina.get('oficina_id'),
                                show: true,
                                checked: false
                            });
                        });
                    });
                });

                this.setState({
                    organizationalStructureListItems: listItems
                });
            }
        }
    }

    handleCreateUser() {
        this.setState({
            modalIsOpen: true,
            modalType: 'createCustomer'
        });
    }

    // Handle selected menu action type
    handleMenuClick(actionType) {
        //const selectedCustomer = this.props.customers.find(customer => customer.get('_id') === this.state.selectedCustomer);
        let newState = Object.assign({}, this.state);
        switch (actionType) {

            // Handle user permissions dialog
            case 'editCustomer':
                newState.modalType = 'editCustomer';
                newState.modalIsOpen = true;
                break;
            case 'disableCustomer':
                newState.modalType = 'disableCustomer';
                newState.modalIsOpen = true;
                break;
            case 'enableCustomer':
                newState.modalType = 'enableCustomer';
                newState.modalIsOpen = true;
                break;
            case 'deleteCustomer':
                newState.modalType = 'deleteCustomer';
                newState.modalIsOpen = true;
                break;
            default:
                newState.modalType = actionType;
                newState.modalIsOpen = true;
                return;
        }

        this.setState({
            ...newState
        });

    }

    // Popover actions
    selectUser(userId) {
        this.setState({
            selectedCustomer: userId
        });
    }

    closeModal() {
        this.setState({
            modalIsOpen: false,
            modalType: null,
            canSubmit: false
        })
    }

    onUpdateOrgListItems(listItems) {
        this.setState({
            organizationalStructureListItems: listItems
        });
    }

    onUpdateCustomersList(customersList) {
        this.setState({
            customersList
        });
    }

    onUpdateSelectedCustomer(updateType) {
        let selectedCustomer = this.props.customers.find(customer => customer.get('_id') === this.state.selectedCustomer).toJS();
        let status;
        switch (updateType) {
            case 'enableCustomer':
            case 'disableCustomer':
                status = updateType === 'enableCustomer' ? 'activo' : 'inactivo';
                this.props.changeCustomerStatus(this.state.selectedCustomer, status);
                break;
            default:
                this.props.updateCustomer(selectedCustomer);
                break;
        }
        this.closeModal();
    }

    onChangeFormData(formData) {
        if (formData && formData.nombre && formData.cif) {
            let canSubmit = formData.nombre.length > 0 && formData.cif.length > 0;

            this.setState({
                createCustomerData: formData,
                canSubmit
            });
        }
    }

    onCreateCustomer() {
        if (this.state.canSubmit) {
            let newCustomer = { ...this.state.createCustomerData };

            // Clean unnecessary fields
            this.props.createCustomer(newCustomer);
            this.setState({
                createCustomerData: null,
                canSubmit: false
            });
        }
        this.closeModal();
    }

    onUpdateCustomer(customer) {
        if (this.state.canSubmit) {
            let editCustomer = Object.assign({}, customer, this.state.createCustomerData);

            this.props.updateCustomer(editCustomer);
            this.setState({
                createCustomerData: null,
                canSubmit: false
            });
        }
        this.closeModal();
    }

    onDeleteCustomer = () => {
        this.props.deleteCustomer(this.state.selectedCustomer);
        this.closeModal();
    };

    render() {
        const { modalIsOpen, modalType, canSubmit } = this.state;
        const selectedCustomer = this.props.customers.find(customer => customer.get('_id') === this.state.selectedCustomer);
        let modal = null;

        if (modalIsOpen) {
            switch (modalType) {
                case 'createCustomer':
                    modal = {
                        title: 'Crear cliente',
                        body: (
                            <CustomerDialog
                                onChangeFormData={this.onChangeFormData}
                                dialogType={'create'}
                            />
                        ),
                        footer: (
                            <div className="p15 text-right">
                                <button
                                    key="cancel"
                                    type="button"
                                    className="btn btn-sm"
                                    label={'CANCELAR'}
                                    style={{
                                        color: 'gray',
                                        marginRight: 15
                                    }}
                                    onClick={this.closeModal}
                                >
                                    {'CANCELAR'}
                                </button>
                                <button
                                    key="accept"
                                    type="button"
                                    className="btn btn-sm btn-success"
                                    onClick={this.onCreateCustomer}
                                    disabled={!canSubmit}
                                >
                                    {'CREAR CLIENTE'}
                                </button>
                            </div>
                        )
                    };

                    break;
                case 'editCustomer':
                    modal = {
                        title: 'Editar cliente',
                        body: (
                            <CustomerDialog
                                onChangeFormData={this.onChangeFormData}
                                customer={selectedCustomer.toJS()}
                                dialogType={'update'}

                            />
                        ),
                        footer: (
                            <div className="p15 text-right">
                                <button
                                    key="cancel"
                                    type="button"
                                    className="btn btn-sm"
                                    label={'CANCELAR'}
                                    style={{
                                        color: 'gray',
                                        marginRight: 15
                                    }}
                                    onClick={this.closeModal}
                                >
                                    {'CANCELAR'}
                                </button>
                                <button
                                    key="accept"
                                    type="button"
                                    className="btn btn-sm btn-success"
                                    onClick={() => this.onUpdateCustomer(this.createCustomerData)}
                                    disabled={!canSubmit}
                                >
                                    {'GUARDAR'}
                                </button>
                            </div>
                        )
                    };

                    break;
                case 'enableCustomer':
                case 'disableCustomer':
                    modal = {
                        title: modalType === 'enableCustomer' ? 'Activar cliente' : 'Desactivar cliente',
                        body: (
                            <div>
                                <div className="row mb5">
                                    <div className="col-12">
                                        <span style={{ fontWeight: 'bold', color: '#076BAD' }}>{selectedCustomer.get('nombre')}</span>
                                    </div>
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    <div className="row mb10">
                                        <div className="col-12">
                                            <span>Se va a {modalType === 'enableCustomer' ? 'activar' : 'desactivar'} el cliente, ¿estás seguro?.</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            {modalType === 'enableCustomer' ? (
                                                <span>Se deben activar manualmente los usuarios asociados al cliente que se quieran utilizar. Se podrán crear nuevos CPD's para este cliente.</span>
                                            ) : (
                                                <span>Automáticamente se desactivarán todos los usuarios asociados al cliente, por lo que no se podrán conectar. Ningún usuario podrá crear nuevos CPD's para este cliente.</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ),
                        footer: (
                            <div className="p15 text-right">
                                <button
                                    key="cancel"
                                    type="button"
                                    className="btn btn-sm"
                                    label={'CANCELAR'}
                                    style={{
                                        color: 'gray',
                                        marginRight: 15
                                    }}
                                    onClick={this.closeModal}
                                >
                                    {'CANCELAR'}
                                </button>
                                <button
                                    key="accept"
                                    type="button"
                                    className={modalType === 'enableCustomer' ? "btn btn-sm btn-success" : "btn btn-sm btn-danger"}
                                    onClick={this.onUpdateSelectedCustomer.bind(this, modalType)}
                                >
                                    {modalType === 'enableCustomer' ? (
                                        'ACTIVAR CLIENTE'
                                    ) : (
                                        'DESACTIVAR CLIENTE'
                                    )}
                                </button>
                            </div>
                        )
                    };

                    break;
                case 'deleteCustomer':
                    modal = {
                        title: 'Eliminar cliente',
                        body: (
                            <div>
                                <div className="row mb5">
                                    <div className="col-12">
                                        <span style={{ fontWeight: 'bold', color: '#076BAD' }}>{selectedCustomer.get('nombre')}</span>
                                    </div>
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    <div className="row mb10">
                                        <div className="col-12">
                                            <span>Se va a eliminar el cliente, ¿estás seguro?.</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <span>Automáticamente se eliminarán todos los usuarios asociados al cliente, por lo que no se podrán conectar. Ningún usuario podrá crear nuevos CPD's para este cliente.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ),
                        footer: (
                            <div className="p15 text-right">
                                <button
                                    key="cancel"
                                    type="button"
                                    className="btn btn-sm"
                                    label={'CANCELAR'}
                                    style={{
                                        color: 'gray',
                                        marginRight: 15
                                    }}
                                    onClick={this.closeModal}
                                >
                                    {'CANCELAR'}
                                </button>
                                <button
                                    key="accept"
                                    type="button"
                                    className={"btn btn-sm btn-danger"}
                                    onClick={this.onDeleteCustomer}
                                >ELIMINAR CLIENTE
                                </button>
                            </div>
                        )
                    };

                    break;
                default:
                    modal = {
                        title: '',
                        body: null,
                        footer: null
                    };
            }
        }

        const tableSettings = {
            sortable: true,
            filterable: true,
            defaultSortFields: ['_id'],
            defaultSortDirections: ['asc'],
            height: 450,
            rowHeight: 50,
            strippedRows: false,
            rowStyle: {
                fontSize: 13
            }
        };

        const tableData = this.props.customers.toJS();

        const tableColumns = {
            nombre: {
                title: "Nombre",
                columnType: 'text',
                dataField: 'nombre',
                width: 150,
                style: {},
                sortable: true,
                filterable: true
            },
            cif: {
                title: "CIF",
                columnType: 'text',
                dataField: 'cif',
                width: 150,
                style: {},
                sortable: true,
                filterable: true
            },
            estado: {
                title: "Estado",
                columnType: 'function',
                dataField: 'estado',
                width: 100,
                style: { padding: 0, paddingRight: 15, maxWidth: 100 },
                sortable: true,
                filterable: false,
                nodeContent: (value) => {
                    let status = value === 'activo' ? 'success' : value === 'inactivo' ? 'danger' : 'info';
                    return (
                        <div>
                            <span
                                className={'badge badge-' + status}
                                style={{
                                    padding: '5px 8px',
                                    fontSize: 12
                                }}
                            >
                                {_.capitalize(value)}
                            </span>
                        </div>
                    );
                }
            },
            actions: {
                title: '',
                columnType: 'function',
                dataField: '_id',
                width: 30,
                style: { alignItems: 'center', maxWidth: 30 },
                sortable: false,
                filterable: false,
                nodeContent: (value) => {
                    if(this.props.user.get('tipo') === 'administrador') {
                        return (
                            <Popover
                                content={popoverContent}
                                interactionKind={PopoverInteractionKind.CLICK}
                                position={Position.BOTTOM_RIGHT}
                            >
                                <Icon
                                    className="icon-custom"
                                    title={false}
                                    icon={'more'}
                                    iconSize={18}
                                    onClick={this.selectUser.bind(this, value)}
                                />
                            </Popover>
                        );
                    } else {
                        return null;
                    }
                }
            }
        };

        const popoverContent = (
            <Menu>
                <MenuItem icon="edit" onClick={this.handleMenuClick.bind(this, 'editCustomer')} text="Editar" />
                {selectedCustomer && selectedCustomer.get('estado') === 'activo' ? (
                    <MenuItem icon="lock" onClick={this.handleMenuClick.bind(this, 'disableCustomer')} text="Desactivar" />
                ) : null}
                {selectedCustomer && selectedCustomer.get('estado') === 'inactivo' ? (
                    <MenuItem icon="unlock" onClick={this.handleMenuClick.bind(this, 'enableCustomer')} text="Activar" />
                ) : null}
                <MenuItem icon="trash" onClick={this.handleMenuClick.bind(this, 'deleteCustomer')} text="Eliminar" />
            </Menu>
        );

        return (
            <div>
                <div style={{ marginTop: 20, marginBottom: 20, display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ marginLeft: 20 }}>
                        <div style={{ fontSize: 18, fontWeight: 'bold', marginTop: 8 }}>
                            <span>Total: {this.props.customers && this.props.customers.size} | </span>
                            <span>Activos: {this.props.customers && this.props.customers.filter(customer => customer.get('estado') === 'activo').size} | </span>
                            <span>Inactivos: {this.props.customers && this.props.customers.filter(customer => customer.get('estado') === 'inactivo').size}</span>
                        </div>
                    </div>

                    {this.props.user.get('tipo') === 'administrador' ? (
                        <div style={{ marginRight: 20 }}>
                            <div style={{ color: '#fff' }}>
                                <a className="btn btn-custom btn-sm" onClick={this.handleCreateUser}>Crear Cliente</a>
                            </div>
                        </div>
                    ) : null}

                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <VirtualizedTable
                                tableSettings={tableSettings}
                                tableColumns={tableColumns}
                                tableData={tableData}
                            />
                        </div>
                    </div>
                </div>
                {modalIsOpen && (
                    <Modal
                        heading={modal.title}
                        footer={() => modal.footer}
                        autoFocus={false}
                        onClose={() => {}}
                    >
                        {modal.body}
                    </Modal>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {

    // Reducers
    const orgStructureReducer = state.organizationalStructure;
    const customersReducer = state.customers;

    // Organizational Structure
    const orgStructure = orgStructureReducer.get('payload') || Map({});

    // Customers
    const customers = customersReducer.get('payload') || List([]);

    return {
        orgStructure,
        customers
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getOrgStructure: () => dispatch(getOrgStructure()),
        getCustomers: () => dispatch(getCustomers()),
        updateCustomer: (customer) => dispatch(updateCustomer(customer)),
        createCustomer: (customer) => dispatch(createCustomer(customer)),
        changeCustomerStatus: (customerId, status) => dispatch(changeCustomerStatus(customerId, status)),
        deleteCustomer: (customerId) => dispatch(deleteCustomer(customerId)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPage);
