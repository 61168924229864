import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Components
import { PDFDocument, rgb } from 'pdf-lib';

// Actions
import { uploadEmployeeDocument } from '../actions/employeeDocuments';

const PDFGenerator = (props) => {
    const { 
        signature,
        originalPdfBlob, 
        setSaveSignature, 
        employeeId, 
        documentId,
        generatePdf,
        setGeneratedPdf,
        documentProps
    } = props;

    useEffect(() => {
        if (signature && !generatePdf) handleGeneratePDF();
    }, [signature]);

    const handleGeneratePDF = async () => {
        // Cargar el PDF existente (debe estar en el mismo formato que necesitas)
        const existingPdfBytes = await originalPdfBlob.arrayBuffer();

        // Cargar el PDF existente
        const pdfDoc = await PDFDocument.load(existingPdfBytes);

        const signPage = documentProps.getIn(['firma_config', 'firma_en_paginas']);
        const positionX = documentProps.getIn(['firma_config', 'posicion_x']);
        const positionY = documentProps.getIn(['firma_config', 'posicion_y']);
        const width = documentProps.getIn(['firma_config', 'dimension_w']);
        const heigth = documentProps.getIn(['firma_config', 'dimension_h']);

        if (signPage === 'ultima') {
            // Si "ultima", añadir la firma solo en la última página
            const lastPage = pdfDoc.getPage(pdfDoc.getPageCount() - 1); // Obtener la última página
      
            // Obtener la imagen de la firma desde la URL base64
            const signatureImage = await pdfDoc.embedPng(signature);
      
            // Añadir la firma a la última página
            lastPage.drawImage(signatureImage, {
                x: positionX,
                y: positionY,
                width: width,
                height: heigth,
                color: rgb(0, 0, 0),
            });
        } else {
            // Si es "null", añadir la firma en todas las páginas
            const numPages = pdfDoc.getPageCount();
      
            for (let i = 0; i < numPages; i++) {
                const page = pdfDoc.getPage(i);
        
                // Obtener la imagen de la firma desde la URL base64
                const signatureImage = await pdfDoc.embedPng(signature);
        
                // Añadir la firma a la página
                page.drawImage(signatureImage, {
                    x: positionX,
                    y: positionY,
                    width: width,
                    height: heigth,
                    color: rgb(0, 0, 0),
                });
            }
        }

        // Generar un nuevo PDF con la firma añadida
        const pdfBytes = await pdfDoc.save();

        // Convertir el PDFBytes en un Blob
        const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

        const pdfFile = new File([pdfBlob], documentProps.get('nombre') + documentProps.get('extension'), { type: 'application/pdf' });
        props.uploadEmployeeDocument(employeeId, documentId, pdfFile);
        setGeneratedPdf(true);
    };

    return (
        <button 
            className='btn btn-custom btn-sm'
            style={{
                color: 'gray',
                marginRight: 15
            }}
            onClick={() => setSaveSignature(true)}>
                FIRMAR
        </button>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        uploadEmployeeDocument: (employeeId, documentId, document) => dispatch(uploadEmployeeDocument(employeeId, documentId, document))
    }
}

export default connect(null, mapDispatchToProps)(PDFGenerator);
