import { fromJS } from "immutable";

import { IS_LOGGED_IN, GET_USER_FROM_LOCAL_STORAGE, SIGNIN_SUCCESS, SIGNIN_ERROR, SIGNIN_CLEAN_ERROR, SIGNOUT } from '../../actions/auth';

let initialState = fromJS({
    isLoggedIn: false,
    user:{},
    errorCode: null
});

const auth = (state = initialState, action) => {
    let newState;
    switch (action.type) {

        // Signin
        case SIGNIN_SUCCESS:
            newState = state.merge({
                user: fromJS(action.user),
                isLoggedIn: action.isLoggedIn
            });
            return newState;
        case SIGNIN_ERROR:
            newState = state.merge({
                errorSignin: fromJS(action.error)
            });
            return newState;
        case SIGNIN_CLEAN_ERROR:
            newState = state.merge({
                errorSignin: ''
            });
            return newState;

        // Signout
        case SIGNOUT:
            newState = state.merge({
                user: action.user,
                isLoggedIn: action.isLoggedIn
            });
            return newState;

        // IsLoggedIn
        case IS_LOGGED_IN:
            newState = state.merge({
                isLoggedIn: action.isLoggedIn
            });
            return newState;

        // Get User
        case GET_USER_FROM_LOCAL_STORAGE:
            newState = state.merge({
                user: fromJS(action.user)
            });
            return newState;

        default:
            return state;
    }
};

export default auth;
