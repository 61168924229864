import axios from 'axios';

import Auth from '../lib/auth';

export const GET_STATS_CUSTOMER_REQUEST = 'GET_STATS_CUSTOMER_REQUEST';
export const GET_STATS_CUSTOMER_SUCCESS = 'GET_STATS_CUSTOMER_SUCCESS';
export const GET_STATS_CUSTOMER_ERROR = 'GET_STATS_CUSTOMER_ERROR';

export const GET_STATS_CONTROL_PANEL_REQUEST = 'GET_STATS_CONTROL_PANEL_REQUEST';
export const GET_STATS_CONTROL_PANEL_SUCCESS = 'GET_STATS_CONTROL_PANEL_SUCCESS';
export const GET_STATS_CONTROL_PANEL_ERROR = 'GET_STATS_CONTROL_PANEL_ERROR';

/**
 * Get customer stats action
 * @param id
 * @returns {Function}
 */
export function getCustomerStats(id) {
    return async dispatch => {
        try {
            if(id) {
                const token = Auth.getLocalJwt();
                dispatch(getCustomerStatsRequest());
                const res = await axios.get('/api/stats/cliente/' + id, { headers: { 'Authorization': 'Bearer ' + token } });
                if( res.data && res.data.status === 1) {
                    dispatch(getCustomerStatsSuccess(res.data.payload));
                } else {
                    dispatch(getCustomerStatsError(`Couldn't get customer stats`));
                }
            } else {
                dispatch(getCustomerStatsError(`Couldn't dispatch customer stats action: Customer Id not available`));
            }
        } catch (error) {
            dispatch(getCustomerStatsError(error));
        }
    };
}

function getCustomerStatsRequest() {
    return {
        type: 'GET_STATS_CUSTOMER_REQUEST',
        isFetching: true
    };
}

function getCustomerStatsSuccess (payload) {
    return {
        type: 'GET_STATS_CUSTOMER_SUCCESS',
        isFetching: false,
        error: false,
        payload
    };
}

function getCustomerStatsError(errorMessage) {
    return {
        type: 'GET_STATS_CUSTOMER_ERROR',
        isFetching: false,
        error: true,
        errorMessage
    };
}

/**
 * Get control panel stats action
 * @returns {Function}
 */
export function getControlPanelStats() {
    return async dispatch => {
        try {
            const token = Auth.getLocalJwt();
            dispatch(getControlPanelStatsRequest());
            const res = await axios.get('/api/stats/controlPanel', { headers: { 'Authorization': 'Bearer ' + token } });
            if( res.data && res.data.status === 1) {
                dispatch(getControlPanelStatsSuccess(res.data.payload));
            } else {
                dispatch(getControlPanelStatsError(`Couldn't get control panel stats`));
            }
        } catch (error) {
            dispatch(getControlPanelStatsError(error));
        }
    };
}

function getControlPanelStatsRequest() {
    return {
        type: 'GET_STATS_CONTROL_PANEL_REQUEST',
        isFetching: true
    };
}

function getControlPanelStatsSuccess (payload) {
    return {
        type: 'GET_STATS_CONTROL_PANEL_SUCCESS',
        isFetching: false,
        error: false,
        payload
    };
}

function getControlPanelStatsError(errorMessage) {
    return {
        type: 'GET_STATS_CONTROL_PANEL_ERROR',
        isFetching: false,
        error: true,
        errorMessage
    };
}