import axios from 'axios';
import Auth from '../lib/auth';
import { saveFile } from '../lib/content-lib';

import { addNotification } from './notifications';

export const EMPLOYEE_DOCUMENTS_REQUEST = 'EMPLOYEE_DOCUMENTS_REQUEST';
export const EMPLOYEE_DOCUMENTS_SUCCESS = 'EMPLOYEE_DOCUMENTS_SUCCESS';
export const EMPLOYEE_DOCUMENTS_ERROR = 'EMPLOYEE_DOCUMENTS_ERROR';

export const DOWNLOAD_DOCUMENT_REQUEST = 'DOWNLOAD_DOCUMENT_REQUEST';
export const DOWNLOAD_DOCUMENT_SUCCESS = 'DOWNLOAD_DOCUMENT_SUCCESS';
export const DOWNLOAD_DOCUMENT_ERROR = 'DOWNLOAD_DOCUMENT_ERROR';

export const DOWNLOAD_DOCUMENTS_REQUEST = 'DOWNLOAD_DOCUMENTS_REQUEST';
export const DOWNLOAD_DOCUMENTS_SUCCESS = 'DOWNLOAD_DOCUMENTS_SUCCESS';
export const DOWNLOAD_DOCUMENTS_ERROR = 'DOWNLOAD_DOCUMENTS_ERROR';

export const UPLOAD_DOCUMENT_REQUEST = 'UPLOAD_DOCUMENT_REQUEST';
export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
export const UPLOAD_DOCUMENT_ERROR = 'UPLOAD_DOCUMENT_ERROR';

// Get Employees Documents actions
export function getEmployeeDocuments(query) {
    return async dispatch => {
        try {
            const token = Auth.getLocalJwt();
            dispatch(getEmployeeDocumentsRequest());
            const res = await axios.post('/api/trabajadores/documentos/query', query, { headers: { 'Authorization': 'Bearer ' + token } });
            if (res.data && res.data.status === 1) {
                dispatch(getEmployeeDocumentsSuccess(res.data.payload));
            } else {
                dispatch(getEmployeeDocumentsError(res.data.payload));
            }
        } catch (error) {
            dispatch(getEmployeeDocumentsError(error));
        }
    }
}

// Download a single document
export function downloadEmployeeDocument(documentId) {
    return async dispatch => {
        try {
            const token = Auth.getLocalJwt();
            dispatch(downloadEmployeeDocumentRequest());
            const res = await axios.get(`/api/trabajadores/documentos/${documentId}/download`, { headers: { 'Authorization': 'Bearer ' + token }, responseType: 'blob' });
            if (res.status === 200) {
                dispatch(downloadEmployeeDocumentSuccess(res.data));
            } else {
                dispatch(downloadEmployeeDocumentError(res.data));
            }
        } catch (error) {
            dispatch(downloadEmployeeDocumentError(error));
        }
    }
}

// Download multiple documents
export function downloadEmployeeDocuments(documentsIds) {
    return async dispatch => {
        try {
            const token = Auth.getLocalJwt();
            const res = await axios.post('/api/trabajadores/documentos/download', { documentos: documentsIds }, { headers: { 'Authorization': 'Bearer ' + token }, responseType: 'blob' });
            if (res.status === 200) {
                saveFile(res);
                dispatch(addNotification({
                    message: 'Documento descargado correctamente',
                    color: 'green',
                    icon: 'tick'
                }));
            }
        } catch (error) {
            dispatch(addNotification({
                message: 'Error al descargar el documento',
                color: 'red',
                icon: 'cross'
            }));
            console.log(error);
        }
    }
}

// Upload a document
export function uploadEmployeeDocument(employeeId, documentId, document) {
    return async dispatch => {
        try {
            const token = Auth.getLocalJwt();
            dispatch(uploadEmployeeDocumentRequest());

            // Usar FormData para enviar el documento como archivo
            const formData = new FormData();
            formData.append('documento', document);
            formData.append('trabajador_id', employeeId);
            formData.append('document_id', documentId);

            const res = await axios.post(
                `/api/trabajadores/${employeeId}/subir_documento`, 
                formData, 
                { 
                    headers: { 'Authorization': 'Bearer ' + token },
                    'Content-Type': 'multipart/form-data'
                }
            );

            if (res.data && res.status === 200) {
                dispatch(uploadEmployeeDocumentSuccess(res.data));

                // Create notification for sign document success
                dispatch(addNotification({
                    message: 'Documento firmado correctamente',
                    color: 'green',
                    icon: 'tick'
                }));
            } else {
                dispatch(uploadEmployeeDocumentError(res.message));
            }
        } catch (error) {
            dispatch(uploadEmployeeDocumentError(error));
        }
    }
}

// Get documents
function getEmployeeDocumentsRequest() {
    return {
        type: 'EMPLOYEE_DOCUMENTS_REQUEST',
        isFetching: true
    };
}

function getEmployeeDocumentsSuccess(payload) {
    return {
        type: 'EMPLOYEE_DOCUMENTS_SUCCESS',
        isFetching: false,
        error: false,
        payload
    };
}

function getEmployeeDocumentsError(errorMessage) {
    return {
        type: 'EMPLOYEE_DOCUMENTS_ERROR',
        isFetching: false,
        error: true,
        errorMessage
    };
}

// Download a single document
function downloadEmployeeDocumentRequest() {
    return {
        type: 'DOWNLOAD_DOCUMENT_REQUEST',
        isFetching: true
    };
}

function downloadEmployeeDocumentSuccess(payload) {
    return {
        type: 'DOWNLOAD_DOCUMENT_SUCCESS',
        isFetching: false,
        error: false,
        payload
    };
}

function downloadEmployeeDocumentError(errorMessage) {
    return {
        type: 'DOWNLOAD_DOCUMENT_ERROR',
        isFetching: false,
        error: true,
        errorMessage
    }
}

// Upload a document
function uploadEmployeeDocumentRequest() {
    return {
        type: 'UPLOAD_DOCUMENT_REQUEST',
        isFetching: true
    };
}

function uploadEmployeeDocumentSuccess(payload) {
    return {
        type: 'UPLOAD_DOCUMENT_SUCCESS',
        isFetching: false,
        error: false,
        payload
    };
}

function uploadEmployeeDocumentError(errorMessage) {
    return {
        type: 'UPLOAD_DOCUMENT_ERROR',
        isFetching: false,
        error: true,
        errorMessage
    }
}
