import React, {Component} from 'react';

// Components
import VirtualizedTable from '../components/virtualized-table';
import {Checkbox} from "@blueprintjs/core";

class HomePageDocsDownloadDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedAttachTypes: new Set(),
            includeAttachs: false
        };
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.includeAttachs !== this.state.includeAttachs && !this.state.includeAttachs) {
            this.setState({
                selectedAttachTypes: new Set()
            }, () => this.sendFormData());
        }
    }

    sendFormData() {
        this.props.onFormData({
            fdType: 'includeAttachs',
            tipo_anexos: [...this.state.selectedAttachTypes]
        });
    }

    handleCheckbox(attachType) {
        const selectedAttachTypes = new Set(this.state.selectedAttachTypes);

        selectedAttachTypes.has(attachType)
            ? selectedAttachTypes.delete(attachType)
            : selectedAttachTypes.add(attachType);
        this.setState({
            selectedAttachTypes
        }, () => this.sendFormData());
    };

    attachTypeChecks = (attachFilter) => {
        let checks = [];

        this.props.attachTypes.map( (attach) => {
            if (attachFilter.includes(attach.get('tipo'))) {
                return checks.push(
                    <Checkbox
                        key={attach.get('tipo')}
                        checked={this.state.selectedAttachTypes.has(attach.get('tipo'))}
                        onChange={() => this.handleCheckbox(attach.get('tipo'))}
                        label={attach.get('desc')}
                    />
                );
            } else {
                return '';
            }
        } );

        return checks;
    };

    render() {
        // State
        let { includeAttachs } = this.state;

        // Props
        const { tableData } = this.props;

        const tableSettings = {
            sortable: false,
            filterable: false,
            defaultSortFields: [],
            defaultSortDirections: ['asc'],
            height: 216,
            rowHeight: 54,
            strippedRows: false,
            rowStyle: {
                fontSize: 13
            }
        };
        const tableColumns = {
            nombre: {
                title: "Nombre documento",
                columnType: 'text',
                dataField: 'nombre',
                width: 300,
                style: {},
                sortable: false,
                filterable: false
            },
            tipo: {
                title: "Tipo documento",
                columnType: 'text',
                dataField: 'tipo',
                width: 100,
                style: { maxWidth: 150 },
                sortable: false,
                filterable: false
            },
            num_anexos: {
                title: "#Anexos",
                columnType: 'text',
                dataField: 'anexos',
                width: 100,
                style: { maxWidth: 100, textAlign: 'right' },
                sortable: false,
                filterable: false
            }
        };

        const attachTypesCols = (
            <div>
                <hr/>
                <div className="row">
                    <div className="col">
                        { this.attachTypeChecks(['CPD', 'CTR', 'RIN', 'EPI', 'RIC']) }
                    </div>
                    <div className="col">
                        { this.attachTypeChecks(['RA', 'NIF', 'HUE', 'TRA', 'RM']) }
                    </div>
                    <div className="col">
                        { this.attachTypeChecks(['FRM', 'FES', 'CTRP', 'CPDP']) }
                    </div>
                </div>
                <hr/>
            </div>
        );

        return (
            <div>
                <div style={{
                    display: 'flex',
                    padding: '15px 0',
                    fontSize: 16,
                    fontWeight: 'bold',
                    color: '#076BAD'
                }}>
                    <span>{ 'Documentos Seleccionados: ' + tableData.length }</span>
                </div>
                <div className="row">
                    <div className="col">
                        <VirtualizedTable
                            tableSettings={ tableSettings }
                            tableColumns={ tableColumns }
                            tableData={ tableData }
                        />
                    </div>
                </div>
                <div style={{ marginTop: 20, fontWeight: 'bold' }} >
                    <Checkbox
                        onChange={() => this.setState({ includeAttachs: !includeAttachs })}
                        label="Incluir Anexos"
                    />
                </div>
                { includeAttachs ? attachTypesCols : '' }
            </div>
        );
    }
}

export default HomePageDocsDownloadDialog;